import { Component, OnInit, HostListener, ElementRef, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from "@angular/forms";

import { NgxSpinnerService } from "ngx-spinner";
import { Location, getLocaleDateTimeFormat } from "@angular/common";
// Import Service
import { ProjectideaServiceService } from "../../../Services/projectidea-service.service";
import { environment } from "../../../../environments/environment";
import { iif } from "rxjs";

declare var jquery: any;
declare var $: any;

interface personTeam {
  employeeId: string;
  employName: string;
}

@Component({
  selector: "app-edit-idea-component",
  templateUrl: "./edit-idea-component.component.html",
  styleUrls: ["./edit-idea-component.component.scss"]
})
export class EditIdeaComponentComponent implements OnInit {

  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjust(textArea);
  }

  @Input('autoresize') maxHeight: number;

  public hiddenProjectId;
  public urlHostImage = environment.URLUPLOAD;
  // Create From Data
  public createProjectIdea: FormGroup;
  public person: personTeam;

  public TabStep = "step1";
  public newAttribute: any = { email: "", name: "", description: "" };
  public fieldInputArray: Array<any> = [this.newAttribute];
  public lengthField = 0;
  public url = "../../../../../../assets/images/Group 2934.png";

  // set default file
  public fileToUpload: File = null;
  public fileSize: string = "";
  public imageName: string = "";

  public submitted = false;
  // public teamAdd: [{
  //   email,
  //   name,
  //   jobDescription,
  //   createDate,
  //   status,
  // }];
  // set data field input
  public inputTeam: any = [
    { email: "", name: "", jobDescription: "", status: "รอการตอบรับ", employeeId: "", position: "" },
    { email: "", name: "", jobDescription: "", status: "รอการตอบรับ", employeeId: "", position: "" },
    { email: "", name: "", jobDescription: "", status: "รอการตอบรับ", employeeId: "", position: "" },
    { email: "", name: "", jobDescription: "", status: "รอการตอบรับ", employeeId: "", position: "" },
    { email: "", name: "", jobDescription: "", status: "รอการตอบรับ", employeeId: "", position: "" },
    { email: "", name: "", jobDescription: "", status: "รอการตอบรับ", employeeId: "", position: "" },
    { email: "", name: "", jobDescription: "", status: "รอการตอบรับ", employeeId: "", position: "" },
    { email: "", name: "", jobDescription: "", status: "รอการตอบรับ", employeeId: "", position: "" },
    { email: "", name: "", jobDescription: "", status: "รอการตอบรับ", employeeId: "", position: "" },
  ];

  public deleteTeamMember: any = [];

  public team: any = {
    teamName: String,
    requireSkill: {
      developer: [Boolean],
      developerDescription: String,
      graphicDesign: [Boolean],
      graphicDesignDescription: String,
      engineering: [Boolean],
      engineeringDescription: String,
      marketing: [Boolean],
      marketingDescription: String,
      sales: [Boolean],
      salesDescription: String,
      other: [Boolean],
      otherDescription: String,
    },
    member: this.inputTeam
  };
  public teamTem: any = {
    teamName: String,
    requireSkill: {
      developer: [Boolean],
      developerDescription: String,
      graphicDesign: [Boolean],
      graphicDesignDescription: String,
      engineering: [Boolean],
      engineeringDescription: String,
      marketing: [Boolean],
      marketingDescription: String,
      sales: [Boolean],
      salesDescription: String,
      other: [Boolean],
      otherDescription: String,
    },
    member: this.inputTeam
  };
  fileToUploadRD: File = null;
  Project: any;
  ProjectOriginal: any;
  TeamDetail: any;
  Member2Delete: any;
  CountTeamMember;

  //public categorycount = 0;
  public categorySelectcount = 0;
  public categoryListAll = [];
  public categoryListMaster = [];
  public categoryListMasterNgFor = [];
  public categoryListEvent = [];
  public categoryListEventNgFor = [];
  public categoryListEvent2Select = [];
  public categorySelectedList = [];

  //Category = [];
  // categoryList = [
  //   { id: 1, formControl: 'Agriculture', name: 'Agriculture(การเกษตร)' },
  //   { id: 2, formControl: 'Education', name: 'Education(การศึกษา)' },
  //   { id: 3, formControl: 'E-Commerce', name: 'E-Commerce' },
  //   { id: 4, formControl: 'Energy', name: 'Energy & Utilities (พลังงานและสาธารณูปโภค)' },
  //   { id: 5, formControl: 'Transportation', name: 'Transportation & Logistics (ขนส่งและโลจิสติกส์)' },
  //   { id: 6, formControl: 'Tourisms', name: 'Tourisms & Leisure (การท่องเที่ยวและสันทนาการ)' },
  //   { id: 7, formControl: 'Home', name: 'Home & Office' },
  //   { id: 8, formControl: 'Health', name: 'Health Care Services (การแพทย์)' },
  //   { id: 9, formControl: 'Other', name: 'Other' }
  // ];


  constructor(
    private router: Router,
    private projectSer: ProjectideaServiceService,
    private formBuilder: FormBuilder,
    public spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private _location: Location,
    public element: ElementRef
  ) { }

  ngOnInit() {
    this.getCategoryList();
    this.getInit()
  }

  public countRowsInText(text) {
    let numerOfRowsInText = text.split('\n').length;
    let rows = numerOfRowsInText < 3 ? 3 : numerOfRowsInText;
    //
    return rows;
  }

  adjust(ta): void {
    //let ta = this.element.nativeElement.querySelector("textarea"),newHeight;
    let newHeight;
    if (ta) {
      ta.style.overflow = "hidden";
      ta.style.height = "auto";
      if (this.maxHeight) {
        
        newHeight = Math.min(ta.scrollHeight, this.maxHeight);
        
      } else {
        newHeight = ta.scrollHeight;
      }
      ta.style.height = newHeight + "px";
    }
  }

  checkValidationCategory() {
    for (let i = 0; i < this.categorySelectedList.length; i++) {
      if (this.categorySelectedList[i].status == true) {
        this.categorySelectcount = 0;
        break;
      }
      else {
        this.categorySelectcount += 1;
      }
    }
  }

  async getInit() {

    sessionStorage.setItem("barActive", "userprefile");

    window.scroll(0, 0);

    //const formControls = this.categoryList.map(control => new FormControl(false));

    this.createProjectIdea = this.formBuilder.group({
      _id: [""],
      projectName: ["", Validators.required],
      teamName: ["", Validators.required],
      targetGroup: ["", Validators.required],
      projectDescription: ["", Validators.required],
      projectObjectives: ["", Validators.required],
      projectExpectedResults: ["", Validators.required],
      category: ["", Validators.required],
      coverPic: [File],
      developer: [Boolean],
      graphicDesign: [Boolean],
      engineering: [Boolean],
      marketing: [Boolean],
      sales: [Boolean],
      other: [Boolean],
      developerDescription: [""],
      graphicDesignDescription: [""],
      engineeringDescription: [""],
      marketingDescription: [""],
      salesDescription: [""],
      otherDescription: [""],
      userId: [""],
      type: [""],
      fileProject: [File],
      categoryList: [""],
    });

    // GET ID PASS URL
    const id = this.route.snapshot.paramMap.get("id");
    // GET DATA SERVICES
    let idTemp = await this.projectSer.getIdeaById(id);

    this.Project = idTemp.data.data[0];
    this.ProjectOriginal = idTemp.data.data[0];

    this.TeamDetail = idTemp.data.data[0].teamData.member;
    this.CountTeamMember = 0;
    this.TeamDetail.forEach(element => {
      if (element.status != "ถูกเชิญออกจากโครงการ" && element.status != "ปฏิเสธคำเชิญ" && element.status != "สนใจเข้าร่วมโครงการ") {
        this.CountTeamMember += 1;
      }
    });

    this.checkValidationCategory();

    let resCategory = this.projectSer.getCategory();
    let resEvent = this.projectSer.getEvent();
    resCategory.then(data => {
      //
      //
      if (data.data.data != "ไม่พบข้อมูล") {
        data.data.data.forEach(element => {
          if (element.group == "master" && element.status != 'false') {
            this.categoryListMaster.push(element);
            this.categoryListMasterNgFor.push(element);
            this.categoryListAll.push(element);
          }
          // else if (element.group == "event") {
          //   this.categoryListEvent.push(element);
          //   this.categoryListEventNgFor.push(element);
          // }
        });

        //////////////////////////// set category selected 
        if ((this.Project.projectCategory.length > 0)) {
          for (let i = 0; i < this.categoryListAll.length; i++) {
            if (this.Project.projectCategory.findIndex(y => y.category == this.categoryListAll[i].code) != -1) {
              this.categorySelectedList.push({
                _id: this.categoryListAll[i]._id,
                code: this.categoryListAll[i].code,
                group: this.categoryListAll[i].group,
                status: true
              });
            }
            else {
              this.categorySelectedList.push({
                _id: this.categoryListAll[i]._id,
                code: this.categoryListAll[i].code,
                group: this.categoryListAll[i].group,
                status: false
              });
            }
          }
        }
        //////// set value save to selectlist
        //this.categorySelectedList = this.createProjectIdea.controls.categoryList.value;

        resEvent.then(data => {
          //
          if (data.data.data != "ไม่พบข้อมูล") {
            // 
            data.data.data.forEach(element => {
              if (element.group == "event") {
                this.categoryListMaster.push(element);
                this.categoryListMasterNgFor.push(element);
                this.categoryListAll.push(element);
              }
            });

            //////////////////////////// set category selected 
            this.categorySelectedList = [];

            if ((this.Project.projectCategory.length > 0)) {
              for (let i = 0; i < this.categoryListAll.length; i++) {
                if (this.Project.projectCategory.findIndex(y => y.category == this.categoryListAll[i].code) != -1) {
                  this.categorySelectedList.push({
                    _id: this.categoryListAll[i]._id,
                    code: this.categoryListAll[i].code,
                    group: this.categoryListAll[i].group,
                    status: true
                  });
                }
                else {
                  this.categorySelectedList.push({
                    _id: this.categoryListAll[i]._id,
                    code: this.categoryListAll[i].code,
                    group: this.categoryListAll[i].group,
                    status: false
                  });
                }
              }
            }

            //////////////////////////////

            //
            //
            this.categorySelectedList.forEach(element => {
              if (element.group == "master" && element.status == true) {
                let categoryTrue = this.categoryListMasterNgFor.filter(x => x.code == element.code)[0];
                let event2push = this.categoryListMasterNgFor.filter(x => x.projectCategoryId == categoryTrue._id);
                if (event2push) {
                  event2push.forEach(element => {
                    let o = this.categorySelectedList.filter(x => x.code == element.code);
                    this.categoryListEvent2Select.push({
                      _id: element._id,
                      code: element.code,
                      group: element.group,
                      status: o[0].status
                    });
                  });
                }
              }
            });
          }
        });

      }
    });



    this.createProjectIdea.controls["_id"].setValue(
      id
    );
    this.createProjectIdea.controls["projectName"].setValue(
      this.Project.projectName
    );
    this.createProjectIdea.controls["teamName"].setValue(
      this.Project.teamData.teamName
    );
    this.createProjectIdea.controls["targetGroup"].setValue(
      this.Project.targetGroup
    );
    this.createProjectIdea.controls["projectDescription"].setValue(
      this.Project.projectDescription
    );
    this.createProjectIdea.controls["projectObjectives"].setValue(
      this.Project.projectObjectives
    );
    this.createProjectIdea.controls["projectExpectedResults"].setValue(
      this.Project.projectExpectedResults
    );
    this.createProjectIdea.controls["category"].setValue(this.Project.category);

    this.createProjectIdea.controls["categoryList"].setValue(
      this.categorySelectedList
    );

    this.createProjectIdea.controls["coverPic"].setValue(
      this.Project.coverPicture
    );

    this.createProjectIdea.controls["developer"].setValue(
      this.Project.teamData.requireSkill.developer
    );
    this.createProjectIdea.controls["graphicDesign"].setValue(
      this.Project.teamData.requireSkill.graphicDesign
    );
    this.createProjectIdea.controls["engineering"].setValue(
      this.Project.teamData.requireSkill.engineering
    );
    this.createProjectIdea.controls["marketing"].setValue(
      this.Project.teamData.requireSkill.marketing
    );
    this.createProjectIdea.controls["sales"].setValue(
      this.Project.teamData.requireSkill.sales
    );
    this.createProjectIdea.controls["other"].setValue(
      this.Project.teamData.requireSkill.other
    );
    this.createProjectIdea.controls["developerDescription"].setValue(
      this.Project.teamData.requireSkill.developerDescription
    );
    this.createProjectIdea.controls["graphicDesignDescription"].setValue(
      this.Project.teamData.requireSkill.graphicDesignDescription
    );
    this.createProjectIdea.controls["engineeringDescription"].setValue(
      this.Project.teamData.requireSkill.engineeringDescription
    );
    this.createProjectIdea.controls["marketingDescription"].setValue(
      this.Project.teamData.requireSkill.marketingDescription
    );
    this.createProjectIdea.controls["salesDescription"].setValue(
      this.Project.teamData.requireSkill.salesDescription
    );
    this.createProjectIdea.controls["otherDescription"].setValue(
      this.Project.teamData.requireSkill.otherDescription
    );
    if (this.Project.coverPicture != "" && this.Project.coverPicture != null) {
      this.url = this.urlHostImage + this.Project.coverPicture;
    }
    this.imageName = this.Project.coverPicture.substr(this.Project.coverPicture.lastIndexOf("/") + 1);

  }

  get f() {
    return this.createProjectIdea.controls;
  }

  public getCategoryList() {

  }

  public selectMaster(item, index) {
    if (this.categorySelectedList.length != 0) {
      if (this.categorySelectedList[index].status == false) {
        this.categorySelectedList[index] = ({
          _id: this.categoryListAll[index]._id,
          code: this.categoryListAll[index].code,
          group: this.categoryListAll[index].group,
          status: true
        });
        this.categoryListMasterNgFor.forEach(element => {
          if (element.projectCategoryId == item._id && element.group == "event") {
            if (this.categoryListEvent2Select.findIndex((y => y.code == element.code)) == -1) {
              this.categoryListEvent2Select.push({
                _id: element._id,
                code: element.code,
                group: element.group,
                status: false
              });
            }
          }
        });
      }
      else {
        this.categorySelectedList[index] = ({
          _id: this.categoryListAll[index]._id,
          code: this.categoryListAll[index].code,
          group: this.categoryListAll[index].group,
          status: false
        });
        this.categoryListMasterNgFor.forEach(element => {
          if (element.projectCategoryId == item._id && element.group == "event") {
          }
        });
        this.categoryListAll.filter(x => x.projectCategoryId == this.categoryListAll[index]._id).forEach(element => {
          let indexselectEvent = this.categorySelectedList.findIndex(x => x.code == element.code);
          this.categorySelectedList[indexselectEvent] = ({
            _id: this.categoryListAll[indexselectEvent]._id,
            code: this.categoryListAll[indexselectEvent].code,
            group: this.categoryListAll[indexselectEvent].group,
            status: false
          });
          this.categoryListEvent2Select.splice(this.categoryListEvent2Select.findIndex(x => x.code == element.code), 1);
        });
      }
    }
    else {
      this.categorySelectedList = [];
      for (let i = 0; i < this.categoryListAll.length; i++) {
        if (this.categoryListAll[i].code == item.code) {
          this.categorySelectedList.push({
            _id: this.categoryListAll[i]._id,
            code: this.categoryListAll[i].code,
            group: this.categoryListAll[i].group,
            status: true
          });
          this.categoryListMasterNgFor.forEach(element => {
            if (element.projectCategoryId == item._id && element.group == "event") {
              if (this.categoryListEvent2Select.findIndex((y => y.code == element.code)) == -1) {
                this.categoryListEvent2Select.push({
                  _id: element._id,
                  code: element.code,
                  group: element.group,
                  status: false
                });
              }
            }
          });
        }
        else {
          this.categorySelectedList.push({
            _id: this.categoryListAll[i]._id,
            code: this.categoryListAll[i].code,
            group: this.categoryListAll[i].group,
            status: false
          });
        }
      }
    }
    //this.categorySelectedList  =[];

    this.createProjectIdea.controls["categoryList"].setValue(
      this.categorySelectedList
    );
  }

  public selectEvent(item, index) {
    if (this.categorySelectedList.length != 0) {
      if (this.categorySelectedList[index].status == false) {
        this.categorySelectedList[index] = ({
          _id: this.categoryListAll[index]._id,
          code: this.categoryListAll[index].code,
          group: this.categoryListAll[index].group,
          status: true
        });
      }
      else {
        this.categorySelectedList[index] = ({
          _id: this.categoryListAll[index]._id,
          code: this.categoryListAll[index].code,
          group: this.categoryListAll[index].group,
          status: false
        });
      }
    }
    else {
      this.categorySelectedList = [];
      for (let i = 0; i < this.categoryListAll.length; i++) {
        if (this.categoryListAll[i].code == item.code) {
          this.categorySelectedList.push({
            _id: this.categoryListAll[i]._id,
            code: this.categoryListAll[i].code,
            group: this.categoryListAll[i].group,
            status: true
          });
        }
        else {
          this.categorySelectedList.push({
            _id: this.categoryListAll[i]._id,
            code: this.categoryListAll[i].code,
            group: this.categoryListAll[i].group,
            status: false
          });
        }
      }
    }
    this.createProjectIdea.controls["categoryList"].setValue(
      this.categorySelectedList
    );
  }

  checkIfExists(item) {
    if (this.categoryListEvent2Select.findIndex(x => x.code == item.code) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  public checkProjectName() {
    
    
    let res = this.projectSer.checkProjectName(this.createProjectIdea.value.projectName.trim());
    res.then(data => {
      if (data.data.data == "ไม่พบข้อมูล") {
        //$("#projectNameSuccessModal").modal("show");
      } else {
        if (data.data.data[0].projectName != this.Project.projectName) {
          $("#projectFailNameModal").modal("show");
          this.TabStep = "step1";
          $("#step2").removeClass("active_line");
          $("#step3").removeClass("active_line");
          return window.scroll(0, 0);
        }
      }
    });
  }

  public onBackPage(): void {
    this._location.back();
  }

  public findName(email, index) {
    let res = this.projectSer.findNameByEmail(email);
    res.then(data => {
      if (data.data.data != "ไม่พบข้อมูล") {
        this.inputTeam[index].name = data.data.data.displayName;
        this.inputTeam[index].employeeId = data.data.data.employeeId;
        this.inputTeam[index].position = data.data.data.position ? data.data.data.position : "";
      } else {
        this.inputTeam[index].name = "";
        this.inputTeam[index].employeeId = "";
        this.inputTeam[index].position = "";
      }
    });
  }

  public handleFileInputRD(event) {
    if (
      event.target.files[0].type == "application/pdf"
    ) {
      this.fileToUploadRD = event.target.files[0];
    } else {
      $("#CreateFailpdf").modal("show");
    }
  }

  public addFieldValue(index): void {
    if (this.fieldInputArray.length < 8) {
      this.fieldInputArray.push(this.newAttribute);
      //this.newAttribute = {};
      this.lengthField += 1;
    }
  }

  public activeStep1() {
    this.TabStep = "step1";
    $("#step2").removeClass("active_line");
    $("#step3").removeClass("active_line");
  }
  public activeStep2() {
    this.TabStep = "step2";
    $("#step2").addClass("active_line");
    $("#step3").removeClass("active_line");
  }
  public activeStep3() {
    this.TabStep = "step3";
    $("#step2").addClass("active_line");
    $("#step3").addClass("active_line");
  }

  // Change Step Create Idea
  public changeStep(step): void {
    this.submitted = true;

    this.checkValidationCategory();

    this.checkProjectName();
    if (this.createProjectIdea.controls.projectName.invalid) {
      this.activeStep1();
      return window.scroll(0, 0);
    } else if (this.createProjectIdea.controls.targetGroup.invalid) {
      this.activeStep1();
      return window.scroll(0, 100);
    } else if (this.createProjectIdea.controls.projectDescription.invalid) {
      this.activeStep1();
      return window.scroll(0, 200);
    } else if (this.createProjectIdea.controls.projectObjectives.invalid) {
      this.activeStep1();
      return window.scroll(0, 300);
    } else if (this.createProjectIdea.controls.projectExpectedResults.invalid) {
      this.activeStep1();
      return window.scroll(0, 500);
    }
    // else if (this.createProjectIdea.controls.category.invalid) {
    //   this.activeStep1();
    //   return window.scroll(0, 600);
    // }
    else if (this.categorySelectcount > 0) {
      this.activeStep1();
      return window.scroll(0, 600);
    }
    else if (this.createProjectIdea.controls.teamName.invalid) {
      if (this.TabStep == 'step3') {
        this.activeStep3();
        return window.scroll(0, 0);
      }
    }
    if (this.imageName == '') {
      if (this.TabStep == 'step2') {
        this.activeStep2();
        var imageVa = document.getElementById("imageVa");
        var errimg = document.getElementById("errimg");
        imageVa.classList.add("Imagevalidate");
        errimg.classList.remove("co-hid");
        return window.scroll(0, 0);
      }
    }

    this.TabStep = step;
    window.scroll(0, 0);

    switch (this.TabStep) {
      case "step1": {
        $("#step2").removeClass("active_line");
        $("#step3").removeClass("active_line");
        // this.valuef = JSON.parse(localStorage.getItem("valueSave"));
        // this.createProjectIdea.removeControl("teamName");
        // this.createProjectIdea.addControl("teamName", this.formBuilder.control(this.valueSave.teamName));
        break;
      }
      case "step2": {
        $("#step2").addClass("active_line");
        $("#step3").removeClass("active_line");
        break;
      }
      case "step3": {
        $("#step2").addClass("active_line");
        $("#step3").addClass("active_line");
        break;
      }
    }
  }
  public onCheckboxChange() {

  }

  public getNamePathImage(event) {
    this.imageName = event;
  }

  public handleFileInput(event) {
    if (
      event.type == "image/png" ||
      event.type == "image/jpg" ||
      event.type == "image/jpeg" ||
      event.type == "image/gif"
    ) {
      this.createProjectIdea.patchValue({
        coverPic: event
      });
      if (event) {
        this.fileToUpload = event;
        // var reader = new FileReader();
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(event);

        reader.onload = (event: any) => {
          this.url = event.target.result;
        };
      }
    } else {
      $("#CreateFail").modal("show");
    }
  }

  public deleteSlotNewMember(index) {
    this.fieldInputArray.splice(index, 1);
    this.inputTeam[index] = { empEmail: "", empName: "", empDescription: "", empId: "" };
    this.lengthField -= 1;
    //this.onAutoSaveValue();
  }

  public deleteMember(memberId) {
    $("#DeleteModal").modal("show");
    this.TeamDetail.forEach(item => {
      if (item._id == memberId) {
        this.Member2Delete = {
          id: item._id,
          status: item.status,
          createDate: item.createDate,
          jobDescription: item.jobDescription,
          name: item.displayName,
          email: item.email,
        };
      }
    });
  }

  public confirmDeleteMember(memberId) {
    const id = this.route.snapshot.paramMap.get("id");
    // let deleteMember: any;
    this.TeamDetail.forEach(item => {
      if (item._id == memberId) {
        item.status = "ถูกเชิญออกจากโครงการ";
        this.deleteTeamMember.push({
          id: item._id,
          status: item.status,
          createDate: item.createDate,
          jobDescription: item.jobDescription,
          displayName: item.displayName,
          email: item.email,
          employeeId: item.employeeId,
        });
      }
    });
    this.Member2Delete = null;
    $("#DeleteModal").modal("hide");
    // let res = this.projectSer.deleteMember(this.Project, deleteMember);
    // res.then(data => {
    //   if (data.data.status == 200) {
    //     let idTemp = this.projectSer.getIdeaById(id);
    //     idTemp.then(dataMember => {
    //       this.TeamDetail = dataMember.data.data[0].teamData.member;
    //       this.CountTeamMember = 0;
    //       this.TeamDetail.forEach(element => {
    //         if (element.status != "ถูกเชิญออกจากโครงการ") {
    //           this.CountTeamMember += 1;
    //         }
    //       });
    //     });
    //     $("#DeleteModal").modal("hide");
    //     this.Member2Delete = null;
    //     $("#successDeleteModal").modal("show");
    //   }
    // });
  }

  public onSuccess(): void {
    const id = this.route.snapshot.paramMap.get("id");
    $("#CreateSuccess").modal("hide");
    this.router.navigate(["/userProfile/myidea/detailupdateidea", id]);
  }

  public onSubmitIdea() {

    this.submitted = true;

    this.team.teamName = this.createProjectIdea.controls["teamName"].value;

    // stop here if form is invalid
    if (this.createProjectIdea.controls.projectName.invalid) {
      this.activeStep1();
      return window.scroll(0, 0);
    } else if (this.createProjectIdea.controls.targetGroup.invalid) {
      this.activeStep1();
      return window.scroll(0, 0);
    } else if (this.createProjectIdea.controls.projectDescription.invalid) {
      this.activeStep1();
      return window.scroll(0, 0);
    } else if (this.createProjectIdea.controls.projectObjectives.invalid) {
      this.activeStep1();
      return window.scroll(0, 0);
    } else if (this.createProjectIdea.controls.projectExpectedResults.invalid) {
      this.activeStep1();
      return window.scroll(0, 0);
    }
    //  else if (this.createProjectIdea.controls.category.invalid) {
    //   this.activeStep1();
    //   return window.scroll(0, 0);
    // }
    else if (this.categorySelectcount > 0) {
      this.activeStep1();
      return window.scroll(0, 600);
    }
    else if (this.createProjectIdea.controls.teamName.invalid) {
      this.activeStep3();
      return window.scroll(0, 0);
    }
    else if (this.imageName == '') {
      this.activeStep2();
      var imageVa = document.getElementById("imageVa");
      var errimg = document.getElementById("errimg");
      imageVa.classList.add("Imagevalidate");
      errimg.classList.remove("co-hid");
      return window.scroll(0, 0);
    }

    sessionStorage.setItem("barActive", "home");

    let changeStory = [];
    if (this.ProjectOriginal.projectName != this.createProjectIdea.controls["projectName"].value) {
      changeStory.push({
        message: "เปลี่ยนชื่อโครงการจาก " + this.ProjectOriginal.projectName + " เป็น " + this.createProjectIdea.controls["projectName"].value,
      });
    }

    if (this.ProjectOriginal.teamData.teamName != this.createProjectIdea.controls["teamName"].value ||
      this.ProjectOriginal.targetGroup != this.createProjectIdea.controls["targetGroup"].value ||
      this.ProjectOriginal.projectDescription != this.createProjectIdea.controls["projectDescription"].value ||
      this.ProjectOriginal.projectObjectives != this.createProjectIdea.controls["projectObjectives"].value ||
      this.ProjectOriginal.projectExpectedResults != this.createProjectIdea.controls["projectExpectedResults"].value ||
      this.ProjectOriginal.category != this.createProjectIdea.controls["category"].value ||
      this.ProjectOriginal.coverPicture != this.createProjectIdea.controls["coverPic"].value ||
      this.ProjectOriginal.teamData.requireSkill.developer != this.createProjectIdea.controls["developer"].value ||
      this.ProjectOriginal.teamData.requireSkill.graphicDesign != this.createProjectIdea.controls["graphicDesign"].value ||
      this.ProjectOriginal.teamData.requireSkill.engineering != this.createProjectIdea.controls["engineering"].value ||
      this.ProjectOriginal.teamData.requireSkill.marketing != this.createProjectIdea.controls["marketing"].value ||
      this.ProjectOriginal.teamData.requireSkill.sales != this.createProjectIdea.controls["sales"].value ||
      this.ProjectOriginal.teamData.requireSkill.other != this.createProjectIdea.controls["other"].value ||
      this.ProjectOriginal.teamData.requireSkill.developerDescription != this.createProjectIdea.controls["developerDescription"].value ||
      this.ProjectOriginal.teamData.requireSkill.graphicDesignDescription != this.createProjectIdea.controls["graphicDesignDescription"].value ||
      this.ProjectOriginal.teamData.requireSkill.engineeringDescription != this.createProjectIdea.controls["engineeringDescription"].value ||
      this.ProjectOriginal.teamData.requireSkill.marketingDescription != this.createProjectIdea.controls["marketingDescription"].value ||
      this.ProjectOriginal.teamData.requireSkill.salesDescription != this.createProjectIdea.controls["salesDescription"].value ||
      this.ProjectOriginal.teamData.requireSkill.otherDescription != this.createProjectIdea.controls["otherDescription"].value) {
      changeStory.push({
        message: "แก้ไขข้อมูลรายละเอียดของโครงการ",
      });
    }

    let teamAdd = [];
    for (var i = 0; i < this.Project.teamData.member.length; i += 1) {
      //
      if (this.Project.teamData.member[i].email) {
        teamAdd.push({
          email: this.Project.teamData.member[i].email,
          displayName: this.Project.teamData.member[i].displayName ? this.Project.teamData.member[i].displayName : this.Project.teamData.member[i].name,
          jobDescription: this.Project.teamData.member[i].jobDescription,
          createDate: this.Project.teamData.member[i].createDate,
          status: this.Project.teamData.member[i].status,
          actionDate: this.Project.teamData.member[i].actionDate ? this.Project.teamData.member[i].actionDate : null,
          employeeId: this.Project.teamData.member[i].employeeId ? this.Project.teamData.member[i].employeeId : this.Project.teamData.member[i].email,
          position: this.Project.teamData.member[i].position ? this.Project.teamData.member[i].position : null,
          viewDeleteDate: this.Project.teamData.member[i].viewDeleteDate ? this.Project.teamData.member[i].viewDeleteDate : null
        });
      }
    }

    for (var i = 0; i < this.inputTeam.length; i += 1) {
      //
      if (this.inputTeam[i].email) {
        teamAdd.push({
          email: this.inputTeam[i].email,
          displayName: this.inputTeam[i].name == "" ? this.inputTeam[i].email : this.inputTeam[i].name,
          jobDescription: this.inputTeam[i].jobDescription,
          createDate: new Date(),
          status: this.inputTeam[i].status,
          actionDate: null,
          employeeId: this.inputTeam[i].employeeId == "" ? this.inputTeam[i].email : this.inputTeam[i].employeeId,
          position: this.inputTeam[i].position,
          viewDeleteDate: null,
          newMember: true
        });

        changeStory.push({
          message: "เชิญคุณ" + (this.inputTeam[i].name == "" ? this.inputTeam[i].email : this.inputTeam[i].name) + " เข้าร่วมโครงการ ",
        });

      }
    }

    let deleteMemberData = [];
    // ถูกเชิญออกจาก โครงการ
    for (var i = 0; i < this.deleteTeamMember.length; i += 1) {

      deleteMemberData.push({
        _id: this.deleteTeamMember[i].id,
        displayName: this.deleteTeamMember[i].displayName,
        email: this.deleteTeamMember[i].email,
        employeeId: this.deleteTeamMember[i].employeeId,
      });

      changeStory.push({
        message: "คุณ" + this.deleteTeamMember[i].displayName + " ถูกเชิญออกจากโครงการ",
      });
    }



    const id = this.route.snapshot.paramMap.get("id");

    let team = {
      teamName: this.team.teamName,
      requireSkill: {
        developer: this.createProjectIdea.value.developer,
        graphicDesign: this.createProjectIdea.value.graphicDesign,
        engineering: this.createProjectIdea.value.engineering,
        marketing: this.createProjectIdea.value.marketing,
        sales: this.createProjectIdea.value.sales,
        other: this.createProjectIdea.value.other,
        developerDescription: this.createProjectIdea.value.developerDescription,
        graphicDesignDescription: this.createProjectIdea.value.graphicDesignDescription,
        engineeringDescription: this.createProjectIdea.value.engineeringDescription,
        marketingDescription: this.createProjectIdea.value.marketingDescription,
        salesDescription: this.createProjectIdea.value.salesDescription,
        otherDescription: this.createProjectIdea.value.otherDescription,
      },
      member: teamAdd
    }

    this.spinner.show();
    //
    this.projectSer
      .updateIdeaById(id, this.createProjectIdea.value, JSON.stringify(team), this.imageName == this.ProjectOriginal.coverPicture.substr(this.ProjectOriginal.coverPicture.lastIndexOf("/") + 1) ? null : this.imageName,
       JSON.stringify(changeStory), JSON.stringify(deleteMemberData),"user")
      .then(Response => {
        
        setTimeout(() => {
          this.spinner.hide();
          if (Response.status == 200) {
            $("#ConfirmModal").modal("hide");
            $("#CreateSuccess").modal("show");
          } else if (Response.status == 413) {
            $("#ConfirmModal").modal("hide");
            $("#CreateFail").modal("show");
            this.TabStep = "step1";
          } else if (Response.status == 403) {
            $("#ConfirmModal").modal("hide");
            $("#CreateFail").modal("show");
          } else {
            $("#ConfirmModal").modal("hide");
            $("#CreateFail").modal("show");
          }
        }, 900);
      });
  }
}
