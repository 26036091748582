import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";

// Import Service
import { UserServiceService } from "../../../Services/user-service.service";

// Set const Jquery
declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-updateprofile",
  templateUrl: "./updateprofile.component.html",
  styleUrls: ["./updateprofile.component.scss"]
})
export class UpdateprofileComponent implements OnInit {
  updateprofileForm: FormGroup;
  submitted = false;

  dataUser: any;

  tempDataOlduser: any = {
    displayname: "",
    phone: "",
    position: "",
    region: "",
    employeeId: ""
  };

  // Check old data before update
  skillUser: any = {
    option1: false,
    option2: false,
    option3: false,
    option4: false,
    option5: false,
    option6: false
  };

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private userSer: UserServiceService,
    public spinner: NgxSpinnerService
  ) {}

  async ngOnInit() {
    // Set Bar active header
    sessionStorage.setItem("barActive", "userprefile");

    window.scroll(0, 0);

    this.updateprofileForm = this.formBuilder.group({
      empId: ["", Validators.required],
      Name: ["", Validators.required],
      phone: ["", Validators.required],
      position: ["", Validators.required],
      region: ["", Validators.required],
      otherSkill: [""]
    });

    // get data User
    let dataOldUpdate = await this.userSer.getProfileUser();
    
    this.dataUser = dataOldUpdate;

    this.tempDataOlduser.displayname = dataOldUpdate.displayName;
    this.tempDataOlduser.phone = dataOldUpdate.tel;
    this.tempDataOlduser.position = dataOldUpdate.position;
    if(dataOldUpdate.region == "" || dataOldUpdate.region == "undefined" || dataOldUpdate.region == undefined || dataOldUpdate.region == null){
      // 
      this.tempDataOlduser.region = "NONE";
      // 
    }else{
      this.tempDataOlduser.region = dataOldUpdate.region;
    }

    this.tempDataOlduser.employeeId = dataOldUpdate.employeeId;

    this.updateprofileForm.controls["region"].setValue(dataOldUpdate.region);

    let skillData : any = dataOldUpdate.skill
    //let itemSkill : any = skillData[0].split(",")
   
    skillData.forEach(data => {
      if (data == "Developer / IT") {
      } else if (data == "Graphic Design") {
      } else if (data == "Marketing") {
      } else if (data == "Designer UX/UI") {
      } else if (data == "Engineering") {
      } else if (data == "Sales") {
      } else {
        this.updateprofileForm.controls["otherSkill"].setValue(data);
      }
    });
    
    skillData.forEach(element => {
      if (element == "Developer / IT") this.skillUser.option1 = true;
      else if (element == "Graphic Design") this.skillUser.option2 = true;
      else if (element == "Marketing") this.skillUser.option3 = true;
      else if (element == "Designer UX/UI") this.skillUser.option4 = true;
      else if (element == "Engineering") this.skillUser.option5 = true;
      else if (element == "Sales") this.skillUser.option6 = true;
    });
  }

  get f() {
    return this.updateprofileForm.controls;
  }

  onSubmit() {
    let tempArr: any = { skill: [] };

    if (this.skillUser.option1 == true) {
      tempArr.skill.push("Developer / IT");
    }
    if (this.skillUser.option2 == true) {
      tempArr.skill.push("Graphic Design");
    }
    if (this.skillUser.option3 == true) {
      tempArr.skill.push("Marketing");
    }
    if (this.skillUser.option4 == true) {
      tempArr.skill.push("Designer UX/UI");
    }
    if (this.skillUser.option5 == true) {
      tempArr.skill.push("Engineering");
    }
    if (this.skillUser.option6 == true) {
      tempArr.skill.push("Sales");
    }

    this.submitted = true;
    let tempRegion = this.updateprofileForm.value.region;
    // stop here if form is invalid
    if (
      this.updateprofileForm.invalid ||
      this.updateprofileForm.value.region == "NONE" ||
      this.updateprofileForm.value.region == "" || 
      this.updateprofileForm.value.region == "undefined" ||
      this.updateprofileForm.value.region == undefined
    ) {
      
      return window.scroll(0, 0);
    }
    if (tempRegion == "") {
      tempRegion = this.tempDataOlduser.region;
    }
    // SHOW SPINER
    let dataUpdateprofile = {
      tel: this.updateprofileForm.value.phone,
      displayName: this.updateprofileForm.value.Name,
      region: tempRegion,
      position: this.updateprofileForm.value.position,
      skill: tempArr.skill,
      imgCover: this.dataUser.imgCover,
      imgProfile: this.dataUser.imgProfile,
      employeeId: this.updateprofileForm.value.empId
    };
    if (
      this.updateprofileForm.value.otherSkill != null &&
      this.updateprofileForm.value.otherSkill != ""
    ) {
      dataUpdateprofile.skill.push(this.updateprofileForm.value.otherSkill);
    }
    // set employee check authen
    if (
      (localStorage.getItem("EmployeeId") === "undefined" ||
      localStorage.getItem("EmployeeId") === undefined ||
      localStorage.getItem("EmployeeId") === null ||
      localStorage.getItem("EmployeeId") === "null" ||
      localStorage.getItem("EmployeeId") === "" )
    ) {
      localStorage.setItem("EmployeeId", this.updateprofileForm.value.empId);
      this.userSer.authenEmployeeId = this.updateprofileForm.value.empId;
    }

    if (
      (localStorage.getItem("Region") === "undefined" ||
      localStorage.getItem("Region") === undefined ||
      localStorage.getItem("Region") === null ||
      localStorage.getItem("Region") === "null" ||
      localStorage.getItem("Region") === "" )
    ) {
      localStorage.setItem("Region", this.updateprofileForm.value.region);
      this.userSer.authenRegion = this.updateprofileForm.value.region;
    }

    this.userSer.updateProfileUser(dataUpdateprofile).then(res => {
      this.spinner.show();
      setTimeout(() => {
        if (res.status) {
          this.spinner.hide();
          
          //$("#CreateSuccess").modal("show");
          let Projectid = localStorage.getItem("pid");
          if(Projectid != null){
            var urldetail = "";
            if (window.location.hostname === "localhost") {
              urldetail = "http://localhost:4200/detailhomeidea/"+Projectid;
            } else if (window.location.hostname === "inning.dld-test.com") {
              urldetail = "https://" + window.location.host + "/detailhomeidea/"+Projectid;
            } else {
              urldetail = "https://" + window.location.host + "/detailhomeidea/"+Projectid;
            }
            window.location.href = urldetail;
            localStorage.removeItem("pid");
          }
          else{
            $("#CreateSuccess").modal("show");
            localStorage.setItem("EmployeeId",this.updateprofileForm.value.empId);
          }

        } else {
          this.spinner.hide();
          alert("UNSUCCESS !!!");
        }
      }, 1000);
    });
  }

  onChangeSkill(event, data: any) {
    // Use appropriate model type instead of any
    if (event.target.value == "Developer / IT" && event.target.checked) {
      this.skillUser.option1 = true;
      $('#'+data).addClass('form-check-before');
    } else if (event.target.value == "Graphic Design" && event.target.checked) {
      this.skillUser.option2 = true;
      $('#'+data).addClass('form-check-before');
    } else if (event.target.value == "Marketing" && event.target.checked) {
      this.skillUser.option3 = true;
      $('#'+data).addClass('form-check-before');
    } else if (event.target.value == "Designer UX/UI" && event.target.checked) {
      this.skillUser.option4 = true;
      $('#'+data).addClass('form-check-before');
    }else if (event.target.value == "Engineering" && event.target.checked) {
      this.skillUser.option5 = true;
      $('#'+data).addClass('form-check-before');
    }else if (event.target.value == "Sales" && event.target.checked) {
      this.skillUser.option6 = true;
      $('#'+data).addClass('form-check-before');
    }

    if (event.target.value == "Developer / IT" && !event.target.checked) {
      this.skillUser.option1 = false;
      $('#'+data).removeClass('form-check-before');
    } else if (event.target.value == "Graphic Design" &&!event.target.checked) {
      this.skillUser.option2 = false;
      $('#'+data).removeClass('form-check-before');
    } else if (event.target.value == "Marketing" && !event.target.checked) {
      this.skillUser.option3 = false;
      $('#'+data).removeClass('form-check-before');
    } else if (event.target.value == "Designer UX/UI" &&!event.target.checked) {
      this.skillUser.option4 = false;
      $('#'+data).removeClass('form-check-before');
    }
    else if (event.target.value == "Engineering" &&!event.target.checked) {
      this.skillUser.option5 = false;
      $('#'+data).removeClass('form-check-before');
    }
    else if (event.target.value == "Sales" &&!event.target.checked) {
      this.skillUser.option6 = false;
      $('#'+data).removeClass('form-check-before');
    }
  }

  onChangeRegion(value) {
    this.tempDataOlduser.region = value;
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
