import { Component, OnInit, HostListener, ElementRef, Input, } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from "@angular/forms";

import { NgxSpinnerService } from "ngx-spinner";
// Import Service
import { ProjectideaServiceService } from "../../../Services/projectidea-service.service";
import { timeout } from "rxjs/operators";
import { OnChange } from "ngx-bootstrap";
import { forEach } from "@angular/router/src/utils/collection";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

//declare var jquery: any;
declare var $: any;

interface personTeam {
  employeeId: string;
  employName: string;
}

@Component({
  selector: "app-create-idea",
  templateUrl: "./create-idea.component.html",
  styleUrls: ["./create-idea.component.scss"]
})

export class CreateIdeaComponent implements OnInit {
  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms));
  }

  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjust(textArea);
  }

  @Input('autoresize') maxHeight: number;

  owner: any;
  email: any;

  public hiddenProjectId;
  // Create From Data
  public createProjectIdea: FormGroup;
  public person: personTeam;

  public now: Date = new Date();

  public TabStep = "step1";
  public newAttribute: any = { email: "", name: "", description: "" };
  public fieldInputArray: Array<any> = [this.newAttribute];
  public lengthField = 0;

  public url = "../../../../../../assets/images/Group 2934.png";

  // set default file
  public fileToUpload: File = null;
  public fileToUploadRD: File = null;
  public fileSize: string = "";
  public fileNameBlob: string = "";

  public submitted = false;

  // set data auto save
  public valueSave: any;
  public dataFromSave: any = {
    projectName: "",
    teamName: "",
    targetGroup: "",
    projectDescription: "",
    projectObjectives: "",
    projectExpectedResults: "",
    //category: "",
    coverPic: null,
    url: "",
    developer: false,
    developerDescription: "",
    graphicDesign: false,
    graphicDesignDescription: "",
    engineering: false,
    engineeringDescription: "",
    marketing: false,
    marketingDescription: "",
    sales: false,
    salesDescription: "",
    other: false,
    otherDescription: "",
    team: "",
    ImageName: "",
    categoryList: "",
  };

  public categorySelectcount = 0;
  public categoryListAll = [];
  public categoryListMaster = [];
  public categoryListMasterNgFor = [];
  public categoryListEvent = [];
  public categoryListEventNgFor = [];
  public categoryListEvent2Select = [];
  public categorySelectedList = [];
  // categoryList = [
  //   { id: 1, formControl: 'Agriculture', name: 'Agriculture(การเกษตร)' },
  //   { id: 2, formControl: 'Education', name: 'Education(การศึกษา)' },
  //   { id: 3, formControl: 'E-Commerce', name: 'E-Commerce' },
  //   { id: 4, formControl: 'Energy', name: 'Energy & Utilities (พลังงานและสาธารณูปโภค)' },
  //   { id: 5, formControl: 'Transportation', name: 'Transportation & Logistics (ขนส่งและโลจิสติกส์)' },
  //   { id: 6, formControl: 'Tourisms', name: 'Tourisms & Leisure (การท่องเที่ยวและสันทนาการ)' },
  //   { id: 7, formControl: 'Home', name: 'Home & Office' },
  //   { id: 8, formControl: 'Health', name: 'Health Care Services (การแพทย์)' },
  //   { id: 9, formControl: 'Other', name: 'Other' }
  // ];

  // set data field input
  // public inputTeamAttribute: any = { empEmail: "", empName: "", empDescription: ""};
  // public inputTeam: any = [this.inputTeamAttribute];
  // public inputTeamTem: any = [this.inputTeamAttribute];

  public inputTeam: any = [
    { empEmail: "", empName: "", empDescription: "", empId: "" },
    { empEmail: "", empName: "", empDescription: "", empId: "" },
    { empEmail: "", empName: "", empDescription: "", empId: "" },
    { empEmail: "", empName: "", empDescription: "", empId: "" },
    { empEmail: "", empName: "", empDescription: "", empId: "" },
    { empEmail: "", empName: "", empDescription: "", empId: "" },
    { empEmail: "", empName: "", empDescription: "", empId: "" },
    { empEmail: "", empName: "", empDescription: "", empId: "" }
  ];
  public inputTeamTem: any = [
    { empEmail: "", empName: "", empDescription: "", empId: "" },
    { empEmail: "", empName: "", empDescription: "", empId: "" },
    { empEmail: "", empName: "", empDescription: "", empId: "" },
    { empEmail: "", empName: "", empDescription: "", empId: "" },
    { empEmail: "", empName: "", empDescription: "", empId: "" },
    { empEmail: "", empName: "", empDescription: "", empId: "" },
    { empEmail: "", empName: "", empDescription: "", empId: "" },
    { empEmail: "", empName: "", empDescription: "", empId: "" }
  ];

  constructor(
    private router: Router,
    private projectSer: ProjectideaServiceService,
    private formBuilder: FormBuilder,
    public spinner: NgxSpinnerService,
    public element: ElementRef
  ) { }

  ngOnInit() {
    $("#mySidenav-panel").hide();
    document.getElementById("mySidenav").style.width = "0";

    window.scroll(0, 0);
    this.getInit();
    this.onCheckValue();

    this.owner = sessionStorage.getItem("displayName");
    this.email = sessionStorage.getItem("email");
  }

  public countRowsInText(text) {
    let numerOfRowsInText = text.split(/\n/).length;
    let rows = numerOfRowsInText < 3 ? 3 : numerOfRowsInText;
    //
    return rows;
  }

  adjust(ta): void {
    //let ta = this.element.nativeElement.querySelector("textarea"),newHeight;
    let newHeight;
    if (ta) {
      ta.style.overflow = "hidden";
      ta.style.height = "auto";
      if (this.maxHeight) {
        
        newHeight = Math.min(ta.scrollHeight, this.maxHeight);
        
      } else {
        newHeight = ta.scrollHeight;
      }
      ta.style.height = newHeight + "px";
    }
  }

  public onCheckValue() {
    this.valueSave = JSON.parse(localStorage.getItem("valueSave"));

    if (this.valueSave) {
      if (this.valueSave.projectName && this.valueSave.projectName.length > 0) {
        this.createProjectIdea.controls["projectName"].setValue(
          this.valueSave.projectName
        );
      }
      if (this.valueSave.teamName && this.valueSave.teamName.length > 0) {
        this.createProjectIdea.controls["teamName"].setValue(
          this.valueSave.teamName
        );
      }
      if (this.valueSave.targetGroup && this.valueSave.targetGroup.length > 0) {
        this.createProjectIdea.controls["targetGroup"].setValue(
          this.valueSave.targetGroup
        );
      }
      if (
        this.valueSave.projectDescription &&
        this.valueSave.projectDescription.length > 0
      ) {
        this.createProjectIdea.controls["projectDescription"].setValue(
          this.valueSave.projectDescription
        );
      }
      if (this.valueSave.projectObjectives && this.valueSave.projectObjectives.length > 0) {
        this.createProjectIdea.controls["projectObjectives"].setValue(
          this.valueSave.projectObjectives
        );
      }
      if (this.valueSave.projectExpectedResults && this.valueSave.projectExpectedResults.length > 0) {
        this.createProjectIdea.controls["projectExpectedResults"].setValue(
          this.valueSave.projectExpectedResults
        );
      }
      // if (this.valueSave.category && this.valueSave.category.length > 0) {
      //   this.createProjectIdea.controls["category"].setValue(
      //     this.valueSave.category
      //   );
      // }
      // if (this.valueSave.coverPic) {
      //   this.createProjectIdea.controls["coverPic"].setValue(
      //     this.valueSave.coverPic
      //   );
      //   this.fileNameBlob = this.valueSave.coverPic.name;
      //   
      // }
      // if (this.valueSave.url && this.valueSave.url.length > 0) {
      //   this.url = this.valueSave.url;
      // }
      if (this.valueSave.developer) {
        this.createProjectIdea.controls["developer"].setValue(
          this.valueSave.developer
        );
      }
      if (this.valueSave.developerDescription && this.valueSave.developerDescription.length > 0) {
        this.createProjectIdea.controls["developerDescription"].setValue(this.valueSave.developerDescription);
      }
      if (this.valueSave.graphicDesign) {
        this.createProjectIdea.controls["graphicDesign"].setValue(
          this.valueSave.graphicDesign
        );
      }
      if (this.valueSave.graphicDesignDescription && this.valueSave.graphicDesignDescription.length > 0) {
        this.createProjectIdea.controls["graphicDesignDescription"].setValue(this.valueSave.graphicDesignDescription);
      }
      if (this.valueSave.engineering) {
        this.createProjectIdea.controls["engineering"].setValue(
          this.valueSave.engineering
        );
      }
      if (this.valueSave.engineeringDescription && this.valueSave.engineeringDescription.length > 0) {
        this.createProjectIdea.controls["engineeringDescription"].setValue(this.valueSave.engineeringDescription);
      }
      if (this.valueSave.marketing) {
        this.createProjectIdea.controls["marketing"].setValue(
          this.valueSave.marketing
        );
      }
      if (this.valueSave.marketingDescription && this.valueSave.marketingDescription.length > 0) {
        this.createProjectIdea.controls["marketingDescription"].setValue(this.valueSave.marketingDescription);
      }
      if (this.valueSave.sales) {
        this.createProjectIdea.controls["sales"].setValue(
          this.valueSave.sales
        );
      }
      if (this.valueSave.salesDescription && this.valueSave.salesDescription.length > 0) {
        this.createProjectIdea.controls["salesDescription"].setValue(this.valueSave.salesDescription);
      }
      if (this.valueSave.other) {
        this.createProjectIdea.controls["other"].setValue(
          this.valueSave.other
        );
      }
      if (this.valueSave.otherDescription && this.valueSave.otherDescription.length > 0) {
        this.createProjectIdea.controls["otherDescription"].setValue(this.valueSave.otherDescription);
      }

      if (this.valueSave.categoryList && this.valueSave.categoryList.length > 0) {
        this.createProjectIdea.controls["categoryList"].setValue(this.valueSave.categoryList);
      }

      for (let i = 0; i < this.valueSave.team.length; i += 1) {
        if (this.valueSave.team[i].empEmail != "") {
          this.fieldInputArray.push(this.newAttribute);
          this.lengthField += 1;
        }
      }
      if (this.lengthField != 0) {
        this.fieldInputArray.pop();
        this.lengthField -= 1;
      }
      //if (this.valueSave.team && this.valueSave.team.length > 0) {
      this.inputTeam = this.valueSave.team;
      //
      // }
      //
    }
  }

  public onAutoSaveValue() {
    this.dataFromSave = {
      projectName: this.createProjectIdea.value.projectName,
      teamName: this.createProjectIdea.value.teamName,
      targetGroup: this.createProjectIdea.value.targetGroup,
      projectDescription: this.createProjectIdea.value.projectDescription,
      projectObjectives: this.createProjectIdea.value.projectObjectives,
      projectExpectedResults: this.createProjectIdea.value.projectExpectedResults,
      //category: this.createProjectIdea.value.category,
      coverPic: this.createProjectIdea.value.coverPic,
      url: this.url,
      developer: this.createProjectIdea.value.developer,
      graphicDesign: this.createProjectIdea.value.graphicDesign,
      engineering: this.createProjectIdea.value.engineering,
      marketing: this.createProjectIdea.value.marketing,
      sales: this.createProjectIdea.value.sales,
      other: this.createProjectIdea.value.other,
      developerDescription: this.createProjectIdea.value.developerDescription,
      graphicDesignDescription: this.createProjectIdea.value.graphicDesignDescription,
      engineeringDescription: this.createProjectIdea.value.engineeringDescription,
      marketingDescription: this.createProjectIdea.value.marketingDescription,
      salesDescription: this.createProjectIdea.value.salesDescription,
      otherDescription: this.createProjectIdea.value.otherDescription,
      team: this.inputTeam,
      categoryList: this.categorySelectedList,
    };
    //
    localStorage.setItem("valueSave", JSON.stringify(this.dataFromSave));
  }

  public getInit() {

    // SET ACTIVER DELETE HOME
    sessionStorage.setItem("activeTabs", "");
    sessionStorage.setItem("barActive", "createidea");
    this.getCategoryList();

    // const formControls = this.categoryListMaster.map(control => new FormControl(false));

    this.createProjectIdea = this.formBuilder.group({
      projectName: ["", Validators.required],
      teamName: ["", Validators.required],
      targetGroup: ["", Validators.required],
      projectDescription: ["", Validators.required],
      projectObjectives: ["", Validators.required],
      projectExpectedResults: ["", Validators.required],
      //category: [""],
      coverPic: [File],
      developer: [Boolean],
      graphicDesign: [Boolean],
      engineering: [Boolean],
      marketing: [Boolean],
      sales: [Boolean],
      other: [Boolean],
      developerDescription: [""],
      graphicDesignDescription: [""],
      engineeringDescription: [""],
      marketingDescription: [""],
      salesDescription: [""],
      otherDescription: [""],
      userId: [""],
      type: [""],
      fileProject: [File],
      fileSize: [""],
      categoryList: [""],
    });

    // this.createProjectIdea.patchValue({
    //   categoryList: new FormArray(formControls)
    // });

    this.createProjectIdea.patchValue({
      developer: false
    });
    this.createProjectIdea.patchValue({
      graphicDesign: false
    });
    this.createProjectIdea.patchValue({
      engineering: false
    });
    this.createProjectIdea.patchValue({
      marketing: false
    });
    this.createProjectIdea.patchValue({
      sales: false
    });
    this.createProjectIdea.patchValue({
      other: false
    });

    this.checkValidationCategory();

  }

  get f() {
    return this.createProjectIdea.controls;
  }
  ckClick() {
    //
    //this.onAutoSaveValue();
  }

  public getCategoryList() {
    let resCategory = this.projectSer.getCategoryWithoutExit();
    let resEvent = this.projectSer.getEvent();
    resCategory.then(data => {
      if (data.data.data != "ไม่พบข้อมูล") {
        data.data.data.forEach(element => {
          if (element.group == "master" && element.status != 'false') {
            this.categoryListMaster.push(element);
            this.categoryListMasterNgFor.push(element);
            this.categoryListAll.push(element);
          }
          //else if (element.group == "event") {
          //this.categoryListEvent.push(element);
          //this.categoryListEventNgFor.push(element);
          //}

        });

        //////// set value save to selectlist
        //
        this.categorySelectedList = this.createProjectIdea.controls.categoryList.value;

        resEvent.then(data => {
          if (data.data.data != "ไม่พบข้อมูล") {
            // 
            data.data.data.forEach(element => {
              if (element.group == "event") {
                this.categoryListMaster.push(element);
                this.categoryListMasterNgFor.push(element);
                this.categoryListAll.push(element);
              }
            });
            
            

            this.categorySelectedList.forEach(element => {
              if (element.group == "master" && element.status == true) {
                let categoryTrue = this.categoryListMasterNgFor.filter(x => x.code == element.code)[0];
                let event2push = this.categoryListMasterNgFor.filter(x => x.projectCategoryId == categoryTrue._id);
                if (event2push) {
                  event2push.forEach(element => {
                    let o = this.categorySelectedList.filter(x => x.code == element.code);
                    this.categoryListEvent2Select.push({
                      _id: element._id,
                      code: element.code,
                      group: element.group,
                      status: o[0].status
                    });
                  });
                }
              }
            });
          }
        });
      }
    });


  }

  public selectMaster(item, index) {
    if (this.categorySelectedList) {
      if (this.categorySelectedList[index].status == false) {
        this.categorySelectedList[index] = ({
          _id: this.categoryListAll[index]._id,
          code: this.categoryListAll[index].code,
          group: this.categoryListAll[index].group,
          status: true
        });
        this.categoryListMasterNgFor.forEach(element => {
          
          if (element.projectCategoryId == item._id && element.group == "event") {
            
            if (this.categoryListEvent2Select.findIndex((y => y.code == element.code)) == -1) {
              this.categoryListEvent2Select.push({
                _id: element._id,
                code: element.code,
                group: element.group,
                status: false
              });
            }
          }
        });
      }
      else {
        this.categorySelectedList[index] = ({
          _id: this.categoryListAll[index]._id,
          code: this.categoryListAll[index].code,
          group: this.categoryListAll[index].group,
          status: false
        });
        this.categoryListMasterNgFor.forEach(element => {
          if (element.projectCategoryId == item._id && element.group == "event") {
          }
        });
        this.categoryListAll.filter(x => x.projectCategoryId == this.categoryListAll[index]._id).forEach(element => {
          let indexselectEvent = this.categorySelectedList.findIndex(x => x.code == element.code);
          this.categorySelectedList[indexselectEvent] = ({
            _id: this.categoryListAll[indexselectEvent]._id,
            code: this.categoryListAll[indexselectEvent].code,
            group: this.categoryListAll[indexselectEvent].group,
            status: false
          });
          this.categoryListEvent2Select.splice(this.categoryListEvent2Select.findIndex(x => x.code == element.code), 1);
        });
        //this.categoryListAll.filter(x => x.projectCategoryId == this.categoryListAll[index]._id)
      }
    }
    else {
      this.categorySelectedList = [];
      for (let i = 0; i < this.categoryListAll.length; i++) {
        if (this.categoryListAll[i].code == item.code) {
          this.categorySelectedList.push({
            _id: this.categoryListAll[i]._id,
            code: this.categoryListAll[i].code,
            group: this.categoryListAll[i].group,
            status: true
          });
          this.categoryListMasterNgFor.forEach(element => {
            if (element.projectCategoryId == item._id && element.group == "event") {
              if (this.categoryListEvent2Select.findIndex((y => y.code == element.code)) == -1) {
                this.categoryListEvent2Select.push({
                  _id: element._id,
                  code: element.code,
                  group: element.group,
                  status: false
                });
              }
            }
          });
        }
        else {
          this.categorySelectedList.push({
            _id: this.categoryListAll[i]._id,
            code: this.categoryListAll[i].code,
            group: this.categoryListAll[i].group,
            status: false
          });
        }
      }
    }



    this.onAutoSaveValue();
    this.createProjectIdea.controls["categoryList"].setValue(
      this.categorySelectedList
    );
    
    
    
    
    
    

  }

  public selectEvent(item, index) {
    if (this.categorySelectedList) {
      if (this.categorySelectedList[index].status == false) {
        this.categorySelectedList[index] = ({
          _id: this.categoryListAll[index]._id,
          code: this.categoryListAll[index].code,
          group: this.categoryListAll[index].group,
          status: true
        });
      }
      else {
        this.categorySelectedList[index] = ({
          _id: this.categoryListAll[index]._id,
          code: this.categoryListAll[index].code,
          group: this.categoryListAll[index].group,
          status: false
        });
      }
    }
    else {
      this.categorySelectedList = [];
      for (let i = 0; i < this.categoryListAll.length; i++) {
        if (this.categoryListAll[i].code == item.code) {
          this.categorySelectedList.push({
            _id: this.categoryListAll[i]._id,
            code: this.categoryListAll[i].code,
            group: this.categoryListAll[i].group,
            status: true
          });
        }
        else {
          this.categorySelectedList.push({
            _id: this.categoryListAll[i]._id,
            code: this.categoryListAll[i].code,
            group: this.categoryListAll[i].group,
            status: false
          });
        }
      }
    }
    
    this.onAutoSaveValue();
    this.createProjectIdea.controls["categoryList"].setValue(
      this.categorySelectedList
    );
  }

  checkIfExists(item) {
    if (this.categoryListEvent2Select.findIndex(x => x.code == item.code) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

  checkValidationCategory() {
    for (let i = 0; i < this.categorySelectedList.length; i++) {
      if (this.categorySelectedList[i].status == true) {
        this.categorySelectcount = 0;
        break;
      }
      else {
        this.categorySelectcount += 1;
      }
    }
  }

  public checkProjectName() {
    let res = this.projectSer.checkProjectName(this.createProjectIdea.value.projectName.trim());
    res.then(data => {
      if (data.data.data == "ไม่พบข้อมูล") {
        //$("#projectNameSuccessModal").modal("show");
      } else {
        $("#projectFailNameModal").modal("show");
        this.TabStep = "step1";
        $("#step2").removeClass("active_line");
        $("#step3").removeClass("active_line");
        return window.scroll(0, 0);
      }
    });
  }

  public findName(email, index) {
    let EmployeeId = localStorage.getItem("EmployeeId");
    let res = this.projectSer.findNameByEmail(email);
    res.then(data => {
      if (data.data.data != "ไม่พบข้อมูล") {
        if (data.data.data.employeeId != EmployeeId) {
          this.inputTeam[index].empName = data.data.data.displayName;
          this.inputTeam[index].empId = data.data.data.employeeId;
        }
        else {
          this.inputTeam[index].empEmail = "";
          $("#inviteMemberFail").modal("show");
        }
      } else {
        this.inputTeam[index].empName = "";
        this.inputTeam[index].empId = "";
      }

      this.delay(500);
      this.onAutoSaveValue()
    });
  }

  public back(): void {
    this.router.navigate([""]);
    sessionStorage.setItem("barActive", "home");
  }

  public addFieldValue(index): void {
    if (this.fieldInputArray.length < 8 && this.fieldInputArray) {
      this.fieldInputArray.push(this.newAttribute);
      //this.inputTeam.push(this.inputTeamAttribute);
      this.lengthField += 1;
    }
  }

  public onSuccess(Id): void {
    
    $("#CreateSuccess").modal("hide");
    // set bar active navbar
    sessionStorage.setItem("barActive", "userprefile");
    this.router.navigate(["/userProfile/myidea/detailupdateidea/" + Id]);
  }

  public activeStep1() {
    this.TabStep = "step1";
    $("#step2").removeClass("active_line");
    $("#step3").removeClass("active_line");
  }
  public activeStep2() {
    this.TabStep = "step2";
    $("#step2").addClass("active_line");
    $("#step3").removeClass("active_line");
  }
  public activeStep3() {
    this.TabStep = "step3";
    $("#step2").addClass("active_line");
    $("#step3").addClass("active_line");
  }

  // Change Step Create Idea
  public changeStep(step): void {
    this.submitted = true;
    this.checkProjectName();
    // if (this.createProjectIdea.invalid) {
    //   if (this.createProjectIdea.controls.teamName.valid) {
    //     this.TabStep = "step1";
    //     $("#step2").removeClass("active_line");
    //     $("#step3").removeClass("active_line");
    //     return window.scroll(0, 0);
    //   }
    // }
    this.checkValidationCategory();

    if (this.createProjectIdea.controls.projectName.invalid) {
      this.activeStep1();
      return window.scroll(0, 0);
    } else if (this.createProjectIdea.controls.targetGroup.invalid) {
      this.activeStep1();
      return window.scroll(0, 100);
    } else if (this.createProjectIdea.controls.projectDescription.invalid) {
      this.activeStep1();
      return window.scroll(0, 200);
    } else if (this.createProjectIdea.controls.projectObjectives.invalid) {
      this.activeStep1();
      return window.scroll(0, 300);
    } else if (this.createProjectIdea.controls.projectExpectedResults.invalid) {
      this.activeStep1();
      return window.scroll(0, 500);
    }
    // else if (this.createProjectIdea.controls.category.invalid) {
    //   this.activeStep1();
    //   return window.scroll(0, 600);
    // }
    else if (this.categorySelectcount > 0) {
      this.activeStep1();
      return window.scroll(0, 600);
    } else if (this.createProjectIdea.controls.teamName.invalid) {
      if (this.TabStep == 'step3') {
        this.activeStep3();
        return window.scroll(0, 0);
      }
    }
    if (this.fileNameBlob == "") {
      if (this.TabStep == 'step2') {

        if (this.categorySelectcount > 0) {
          this.activeStep1();
          return window.scroll(0, 600);
        } else {
          this.activeStep2();
          var imageVa = document.getElementById("imageVa");
          var errimg = document.getElementById("errimg");
          imageVa.classList.add("Imagevalidate");
          errimg.classList.remove("co-hid");
          return window.scroll(0, 0);
        }
      }
    }


    this.TabStep = step;
    window.scroll(0, 0);

    switch (this.TabStep) {
      case "step1": {
        $("#step2").removeClass("active_line");
        $("#step3").removeClass("active_line");
        // this.valueSave = JSON.parse(localStorage.getItem("valueSave"));
        // this.createProjectIdea.removeControl("teamName");
        // this.createProjectIdea.addControl("teamName", this.formBuilder.control(this.valueSave.teamName));
        break;
      }
      case "step2": {
        $("#step2").addClass("active_line");
        $("#step3").removeClass("active_line");
        break;
      }
      case "step3": {
        $("#step2").addClass("active_line");
        $("#step3").addClass("active_line");
        break;
      }
    }
  }
  public getFileNameBlob(event) {
    this.fileNameBlob = event;
  }
  public handleFileInput(event) {
    if (
      event.type == "image/png" ||
      event.type == "image/jpg" ||
      event.type == "image/jpeg" ||
      event.type == "image/gif"
    ) {
      var size = (event.size / Math.pow(1024, 2)).toFixed(2);
      if (parseInt(size) <= 3) {
        this.createProjectIdea.patchValue({
          coverPic: event
        });
        if (event) {
          this.fileToUpload = event;
          // var reader = new FileReader();
          const reader: FileReader = new FileReader();
          reader.readAsDataURL(event);

          reader.onload = (event: any) => {
            this.url = event.target.result;
            // set Auto Save Function
            this.onAutoSaveValue();
          };
        }
      }
    } else {
      $("#CreateFail").modal("show");
    }
  }
  public handleFileInputRD(event) {
    if (
      event.target.files[0].type == "application/pdf"
      //|| event.target.files[0].type ==
      // "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      var size = (event.target.files[0].size / Math.pow(1024, 2)).toFixed(2);
      
      if (parseInt(size) < 5) {
        this.fileSize = size;
        this.fileToUploadRD = event.target.files[0];
        this.createProjectIdea.patchValue({
          fileProject: event.target.files[0],
          fileSize: size
        });
      } else {
        $('#File').val('');
        this.fileToUploadRD = null;
        $("#fileSizeExceededPDF").modal("show");
      }
    } else {
      $('#File').val('');
      this.fileToUploadRD = null;
      $("#CreateFailpdf").modal("show");
    }
  }
  public removeFile() {
    $('#File').val('');
    this.fileToUploadRD = null;
    this.createProjectIdea.patchValue({
      fileProject: null,
      fileSize: ""
    });
  }

  public deleteSlotNewMember(index) {
    this.fieldInputArray.splice(index, 1);
    this.inputTeam[index] = { empEmail: "", empName: "", empDescription: "", empId: "" };
    this.lengthField -= 1;
    this.onAutoSaveValue();
  }

  public checkInvalid() {
    if (this.createProjectIdea.controls.projectName.invalid) {
      this.activeStep1();
      return window.scroll(0, 0);
    } else if (this.createProjectIdea.controls.targetGroup.invalid) {
      this.activeStep1();
      return window.scroll(0, 100);
    } else if (this.createProjectIdea.controls.projectDescription.invalid) {
      this.activeStep1();
      return window.scroll(0, 200);
    } else if (this.createProjectIdea.controls.projectObjectives.invalid) {
      this.activeStep1();
      return window.scroll(0, 300);
    } else if (this.createProjectIdea.controls.projectExpectedResults.invalid) {
      this.activeStep1();
      return window.scroll(0, 500);
    }
    //  else if (this.createProjectIdea.controls.category.invalid) {
    //   this.activeStep1();
    //   return window.scroll(0, 600);
    // }
    else if (this.categorySelectcount > 0) {
      this.activeStep1();
      return window.scroll(0, 600);
    } else if (this.createProjectIdea.controls.teamName.invalid) {
      this.activeStep3();
      return window.scroll(0, 0);
    } else if (!this.fileNameBlob) {
      this.activeStep2();
      var imageVa = document.getElementById("imageVa");
      var errimg = document.getElementById("errimg");
      imageVa.classList.add("Imagevalidate");
      errimg.classList.remove("co-hid");
      return window.scroll(0, 0);
    }
    else {
      $("#ConfirmModal").modal("show");
    }
  }

  public onSubmitIdea() {
    this.submitted = true;
    // stop here if form is invalid
    // this.valueSave = JSON.parse(localStorage.getItem("valueSave"));
    // this.createProjectIdea.removeControl("teamName");
    // this.createProjectIdea.addControl("teamName", this.formBuilder.control(this.valueSave.teamName, Validators.required));
    
    if (this.createProjectIdea.controls.projectName.invalid) {
      this.activeStep1();
      return window.scroll(0, 0);
    } else if (this.createProjectIdea.controls.targetGroup.invalid) {
      this.activeStep1();
      return window.scroll(0, 100);
    } else if (this.createProjectIdea.controls.projectDescription.invalid) {
      this.activeStep1();
      return window.scroll(0, 200);
    } else if (this.createProjectIdea.controls.projectObjectives.invalid) {
      this.activeStep1();
      return window.scroll(0, 300);
    } else if (this.createProjectIdea.controls.projectExpectedResults.invalid) {
      this.activeStep1();
      return window.scroll(0, 500);
    }
    // else if (this.createProjectIdea.controls.category.invalid) {
    //   this.activeStep1();
    //   return window.scroll(0, 600);
    // }
    else if (this.categorySelectcount > 0) {
      this.activeStep1();
      return window.scroll(0, 600);
    } else if (this.createProjectIdea.controls.teamName.invalid) {
      this.activeStep3();
      return window.scroll(0, 0);
    } else if (!this.fileNameBlob) {
      this.activeStep2();
      var imageVa = document.getElementById("imageVa");
      var errimg = document.getElementById("errimg");
      imageVa.classList.add("Imagevalidate");
      errimg.classList.remove("co-hid");
      return window.scroll(0, 0);
    }
    //if (this.createProjectIdea.invalid) {
    // if (this.createProjectIdea.controls.teamName.value != "") {
    //   this.TabStep = "step1";
    //   return window.scroll(0, 0);
    // }
    //}
    else {
      let tempUrl = this.now + this.fileNameBlob
      this.spinner.show();
      this.projectSer
        .createIdea(this.createProjectIdea.value, this.inputTeam, this.fileNameBlob)
        .then(response => {
          setTimeout(() => {
            this.spinner.hide();
            if (response.status === 413) {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
              this.TabStep = "step1";
            } else if (response.status === 403) {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            } else if (response.status === 200) {

              localStorage.removeItem("valueSave");
              this.createProjectIdea.controls["coverPic"].setValue("");
              // reset Image
              this.fileToUploadRD = null;
              this.url = "../../../../../../assets/images/Group 2934.png";
              
              this.hiddenProjectId = response.data.data[0]._id;
              $("#ConfirmModal").modal("hide");
              $("#CreateSuccess").modal("show");

            } else {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            }
          }, 900);
        });
    }
  }

  // reset form create Idea projects
  public resetForm() {
    this.createProjectIdea.controls["coverPic"].setValue("");
    // reset Image
    this.fileToUploadRD = null;
    this.url = "../../../../../../assets/images/Group 2934.png";
    localStorage.removeItem("valueSave");

    window.scroll(0, 0);
    window.location.reload();
  }


}
