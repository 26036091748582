import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";

declare var jquery: any;
declare var $: any;

export interface CropperPosition {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}
export interface ImageCroppedEvent {
  base64?: string | null;
  file?: File | null;
  width: number;
  height: number;
  cropperPosition: CropperPosition;
  imagePosition: CropperPosition;
}

@Component({
  selector: "app-cropimage",
  templateUrl: "./cropimage.component.html",
  styleUrls: ["./cropimage.component.scss"]
})
export class CropimageComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  @Output() valueFilename = new EventEmitter();
  @Output() valueMutiple = new EventEmitter<any>();
  @Input() pageComponent: any;

  constructor() { }

  ngOnInit() { }
  imageChangedEvent: any = "";
  croppedImage: any = "";
  croppedImadeFile: any = null;
  fileNameCrop: any = "";

  fileChangeEvent(event: any): void {

    let fixfileSize, modelFileSizeExceeded, CreateFailImage;
    if (this.pageComponent == "admin") {
      fixfileSize = 5;
      modelFileSizeExceeded = "fileSizeExceededAdmin";
      CreateFailImage = "CreateFailImageAdmin";
    }
    else {
      fixfileSize = 3;
      modelFileSizeExceeded = "fileSizeExceededUser";
      CreateFailImage = "CreateFailImageUser";
    }

    if (
      event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/gif"
    ) {
      
      var size = (event.target.files[0].size / Math.pow(1024, 2)).toFixed(2);
      
      if (parseInt(size) < fixfileSize) {
        this.imageChangedEvent = event;
        this.fileNameCrop = event;
      } else {
        this.imageChangedEvent = "";
        this.fileNameCrop = "";
        $('#FileImage').val('');
        $("#modalCover").modal("hide");
        $("#" + modelFileSizeExceeded).modal("show");
      }
    } else {
      this.imageChangedEvent = "";
      this.fileNameCrop = "";
      $('#FileImage').val('');
      $("#modalCover").modal("hide");
      $("#" + CreateFailImage).modal("show");
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.croppedImadeFile = event.file;
    this.croppedImadeFile.name = this.fileNameCrop.target.files[0].name;
  }
  imageLoaded() {
    // show cropper
  }
  loadImageFailed() {
    // show message
  }
  valueChanged() {
    let filename = this.fileNameCrop.target.files[0].name
    let file = this.croppedImadeFile
    // You can give any function name
    this.valueChange.emit(this.croppedImadeFile);
    this.valueFilename.emit(this.fileNameCrop.target.files[0].name);
    this.valueMutiple.emit({ file, filename });

    $("#modalCover").modal("hide");
  }
}
