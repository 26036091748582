import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class RoleAdminGuard implements CanActivate {
  constructor(private Route: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      let role = localStorage.getItem("role");
      let Graud = localStorage.getItem("role");
      if (
        (role != undefined &&
        role != "undefined" &&
        role != null &&
        role != "null" &&
        role != "") && sessionStorage.getItem("authen")
      ) {
        return true;
      } else {
        sessionStorage.clear();
        localStorage.clear();
        this.Route.navigate(["/login"]);
        return false;
      }
  }
}
