import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

// Import Service
import { ProjectideaServiceService } from "../../Services/projectidea-service.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  itemCategory: any;

  constructor(
    private router: Router,
    private projectSer: ProjectideaServiceService,
    ) { }

  ngOnInit() {
    this.getInit();
  }

  async getInit(){

    let arrCat = await this.projectSer.getCategory();
    this.itemCategory = arrCat.data.data;
  }

  async onChangeType(value) {
    if (value == "AllIdeas") {
      this.router.navigate(['']);
    } else {
      this.router.navigate(['/morelist/Allregion' , value]);
    }
  }
}
