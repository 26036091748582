import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

// Set const Jquery
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-edit-success-modal',
  templateUrl: './edit-success-modal.component.html',
  styleUrls: ['./edit-success-modal.component.scss']
})
export class EditSuccessModalComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  public onSuccess(): void {
    $("#CreateSuccess").modal("hide");
    this.router.navigate(["/userProfile/profileUser"]);
  }

}
