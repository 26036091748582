import { Component, OnInit ,Input} from '@angular/core';

import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";

// Import Service
import { FillterService } from "../../../Services/fillter.service";
import { ProjectideaServiceService } from "../../../Services/projectidea-service.service";
import { UserServiceService } from "../../../Services/user-service.service"

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-incubator-component',
  templateUrl: './incubator-component.component.html',
  styleUrls: ['./incubator-component.component.scss']
})
export class IncubatorComponentComponent implements OnInit {
  
  incubatData = [];
  dtOptions: DataTables.Settings = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
    private fillterServ: FillterService,
    public spinner: NgxSpinnerService,
    private userSer: UserServiceService,

  ) { }

  public searchDataIdea: any = [];
  dataTimeline: any;
  allData: any;
  urlHostImage = environment.URLUPLOAD;
  itemCard: any = [];
  cateName: any;
  search: string = "";
  tabsIncu: any;
  myincubator: any;
  regionName: any;
  public textSearch: string;
  @Input() myVal: string;
  page = 1;
  pageSize = 12;
  public checkBack = false;

  

  async ngOnInit() {

    sessionStorage.setItem("barActive", "incubator");
    this.spinner.show();
      setTimeout(async () => {
        this.route.params.subscribe(async routeParams => {
          window.scroll(0, 0);
          this.myincubator = routeParams["myincubator"];
          await this.onDataall();
          await this.searchFun(this.search);         
          
        });
      this.spinner.hide();
    }, 1000);    
  }

  async searchFun(search) {
    
    if (search !== "") {
      let searchText = search.target.value.toLowerCase();
      this.searchDataIdea = this.itemCard.filter(items => {
        let display = items.owner.displayName.toLowerCase().includes((searchText)) == true;
        let email = items.owner.email != undefined ? items.owner.email.toLowerCase().includes((searchText)) == true : false;
        let project = items.projectName != undefined ? items.projectName.toLowerCase().includes((searchText)) == true : false;

        return display || email || project;
      });
    }
    else {
      this.searchDataIdea = this.itemCard;
    }
  }

  

  async onDataall() {
      let tem = await this.projectSer.getIdeaINNObyYear('all');
      this.itemCard = tem.data.data;    
  }

  public sort(field,icon){
    
    if (document.getElementById(icon).className == "fa fa-sort-up font-12") {
      this.spinner.show();
      setTimeout(() => {

        if(field == "displayName" || field == "email" || field == "tel"){
          this.searchDataIdea.sort(function(a,b){
            // 
            if(a.user[field] === undefined){
              a.user[field] = " ";
              b.user[field] = " ";
            }
              return a.user[field].localeCompare(b.user[field]);
          })
        }else if(field == "lastUpdate"){
          this.searchDataIdea.sort(function(a,b){
            if(a.project[field] === undefined){
              a.project[field] = a.project.createDate;
              b.project[field] = a.project.createDate;
            }

            return a.project[field].localeCompare(b.project[field]);
          })

        }else if(field == "statusLevel"){ 
          this.searchDataIdea.sort(function(a,b){
            return a[field]-b[field];
          })

        }else if(field == "incubator"){
          this.searchDataIdea.sort(function(a,b){
            if(a.project.incubator === undefined){
              a[field] = " ";
              b[field] = " ";
            }else if(a.project.incubator.length == 0){
              a[field] = " ";
              b[field] = " ";
            }else if(a.project.incubator.length < 0){
              a[field] = a.project.incubator.displayName;
              b[field] = b.project.incubator.displayName;
            }else{
              if(a.project.incubator.displayName){
                a[field] = a.project.incubator.displayName;
                b[field] = b.project.incubator.displayName;
              }else{
                a[field] = "";
                b[field] = "";
              }
            }
            
          })
        }else{
          this.searchDataIdea.sort(function(a,b){
              return a.project[field].trimStart().localeCompare(b.project[field].trimStart());
          })
        }

        document.getElementById(icon).className = "fa fa-sort-down font-12";

      this.spinner.hide();
      },500);

    }else{
      this.spinner.show();
      setTimeout(() => {

        if(field == "displayName" || field == "email" || field == "tel"){
          this.searchDataIdea.sort(function(a,b){
            
            if(a.user[field] === undefined){
              a.user[field] = " ";
              b.user[field] = " ";
            }
            
            return b.user[field].localeCompare(a.user[field]);
          })
        }else if(field == "lastUpdate"){
          this.searchDataIdea.sort(function(a,b){
            if(a.project[field] === undefined){
              a.project[field] = "0";
              b.project[field] = "0";
            }

            return b.project[field].localeCompare(a.project[field]);
          })
        

        }else if(field == "statusLevel"){
          this.searchDataIdea.sort(function(a,b){
              return b[field]-a[field];
          })
        }else if(field == "incubator"){
          this.searchDataIdea.sort(function(a,b){
 

            if(a.project.incubator === undefined){
                a[field] = " ";
                b[field] = " ";
            }else if(a.project.incubator.length == 0){
              a[field] = " ";
              b[field] = " ";
            }else if(a.project.incubator.length < 0){
              a[field] = a.project.incubator.displayName;
              b[field] = b.project.incubator.displayName;
            }else{
              if(a.project.incubator.displayName){
                a[field] = a.project.incubator.displayName;
                b[field] = b.project.incubator.displayName;
              }else{
                a[field] = "";
                b[field] = "";
              }
            }
            
            // return b.incubator[0].displayName.trimStart().localeCompare(a.incubator[0].displayName.trimStart());
          })
        }else{
          this.searchDataIdea.sort(function(a,b){
              return b.project[field].trimStart().localeCompare(a.project[field].trimStart());
          })
        }
         
        
        document.getElementById(icon).className = "fa fa-sort-up font-12";
        
      this.spinner.hide();
      },500);
    }
  }

  
}
