import { Component, OnInit, Input, ViewChild  } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";

// Import Service
import { FillterService } from "../../../Services/fillter.service";
import { ProjectideaServiceService } from "../../../Services/projectidea-service.service";

//declare var test: any;
declare var $: any;


@Component({
  selector: 'app-all-project-component',
  templateUrl: './all-project-component.component.html',
  styleUrls: ['./all-project-component.component.scss']
})
export class AllProjectComponentComponent implements OnInit {

  urlHostImage = environment.URLUPLOAD;
  itemCard: any;
  cateName: any;
  regionName: any;
  public textSearch: string;
  @Input() myVal: string;
  page = 1;
  pageSize = 12;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
    private fillterServ: FillterService,
  ) { }

  ngOnInit() {
    this.getInit()
  }

  async getInit() {

    $("#mySidenav-panel").hide();
    document.getElementById("mySidenav").style.width = "0";


    window.onscroll = function () { myFunction() };
    function myFunction() {
      var ontop = document.getElementById("ontop");

      if (window.scrollY > 100) {
        ontop.classList.remove("co-hid");

      }else if(window.scrollY === 0){
        ontop.classList.add("co-hid"); 
      }
    }


    window.scroll(0, 0);
    // Set Bar active header
    sessionStorage.setItem("barActive", "allproject");
    this.route.params.subscribe(routeParams => {
      window.scroll(0, 0);
      //window.scroll(0, 1000);
      this.regionName = routeParams["region"];
      this.cateName = routeParams["cate"];
      this.onChangeType(this.regionName, this.cateName);
    });
  }

  ngDoCheck() {
    this.textSearch = this.fillterServ.getFilter();
  }

  async onChangeType(region, cate) {
    if (cate == "AllIdeas") {
      if(region == "Allregion"){
        let tem = await this.projectSer.getIdeaAllsearch();
        if (tem) {
          this.itemCard = tem.sort(function (a, b) {
            if (a.createDate < b.createDate) { return -1; }
            if (a.createDate > b.createDate) { return 1; }
            return 0;
          });
  
        } else {
          this.itemCard = [];
        }
      } else {
        let tem = await this.projectSer.getIdeaByRegion(region, cate);
        if (tem) {
          this.itemCard = tem.sort(function (a, b) {
            if (a.createDate < b.createDate) { return -1; }
            if (a.createDate > b.createDate) { return 1; }
            return 0;
          });
  
        } else {
          this.itemCard = [];
        }
      }
    }
    else if (cate == "NewIdeas") {
      if (region == "Allregion") {
        let tem = await this.projectSer.getNewProject("");
        if (tem) {
          this.itemCard = tem.data.data.sort(function (a, b) {
            if (a.createDate > b.createDate) { return -1; }
            if (a.createDate < b.createDate) { return 1; }
            return 0;
          });
        }
        else {
          this.itemCard = [];
        }
      }
      else {
        let tem = await this.projectSer.getNewProject(region);
        if (tem) {
          this.itemCard = tem.data.data.sort(function (a, b) {
            if (a.createDate > b.createDate) { return -1; }
            if (a.createDate < b.createDate) { return 1; }
            return 0;
          });
        }
        else {
          this.itemCard = [];
        }
      }
    }
    else if (cate != "AllIdeas" || cate != "NewIdeas" && region != "Allregion") {
      let tem = await this.projectSer.getIdeaByRegion(region, cate);
      if (tem) {
        this.itemCard = tem.sort(function (a, b) {
          if (a.createDate > b.createDate) { return -1; }
          if (a.createDate < b.createDate) { return 1; }
          return 0;
        });
      } else {
        this.itemCard = [];
      }
    }
  }

  public percentage(state) {
    let percent = "0%";
    switch (state) {
      case "New Project": percent = "14%";
        break;
      case "Problem Validation": percent = "28%";
        break;
      case "Problem / Solution fit ready for pitch": percent = "42%";
        break;
      case "Product / MKT Validation": percent = "57%";
        break;
      case "Product / MKT Validation ready for pitch": percent = "71%";
        break;
      case "Commercialization / Preparation": percent = "85%";
        break;
      case "Commercialization / Ready to Launch": percent = "100%";
        break;
    }
    return percent;
  }

  public setPage() {

  }

  public onTop(){
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
  }
}
