import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profileuser',
  template: `
  <app-nav-tabs valueTypeTab="isShow"></app-nav-tabs>
  <div class='navpofile-response'>
    <router-outlet></router-outlet>
  </div>
  `,
  styleUrls:['./mainproile.scss']
})
export class MainProfile implements OnInit {

  constructor() {

  }

  ngOnInit() {

  }

}
