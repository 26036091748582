import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listjointokenidea',
  templateUrl: './listjointokenidea.component.html',
  styleUrls: ['./listjointokenidea.component.scss']
})
export class ListjointokenideaComponent implements OnInit {

  item;
  constructor() { }

  ngOnInit() {
    window.scroll(0,0)
    this.item = [
      {
        region: 'ROS',
        name: 'Name Project',
        team: 'wanpenet',
        date: '10/10/2018',
        status: 'Wait to update'
      },
      {
        region: 'ROS',
        name: 'Name Project',
        team: 'wanpenet',
        date: '10/10/2018',
        status: 'watching by admin'
      },
      {
        region: 'ROS',
        name: 'Name Project',
        team: 'wanpenet',
        date: '10/10/2018',
        status: 'watching by admin'
      },
      {
        region: 'ROS',
        name: 'Name Project',
        team: 'wanpenet',
        date: '10/10/2018',
        status: 'watching by admin'
      },
    ]
  }

}
