import { Component, OnInit, Input, EventEmitter, ViewChild, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Http, ResponseContentType } from '@angular/http';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { environment } from "../../../../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { saveAs } from "file-saver";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MentionList } from 'ng-mentions';

// Import Service
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";
import { UserServiceService } from "../../../../Services/user-service.service";
import { CommentServiceService } from "../../../../Services/comment-service.service";
import { flatten } from '@angular/compiler';
import { modalConfigDefaults } from 'ngx-bootstrap/modal/modal-options.class';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-tab-comment',
  templateUrl: './tab-comment.component.html',
  styleUrls: ['./tab-comment.component.scss']
})

export class TabCommentComponent implements AfterViewInit {

  // @Input() typeNameInput: any;
  // @Input() regionNameInput: any;
  // cate: string;
  // region: string;
  @Input() projectId: any;

  public model: any = {
    value: '',
    mentions: []
  };
  public boolTrue = true;

  public selectMentionCommentValue = [];
  public selectMentionCommentEditValue = [];
  public selectMentionReplyValue = [];
  public selectMentionReplyEditValue = [];
  public selectMentionReplyValue2r = [];

  public userList;
  public commentFormGroup: FormGroup;
  public commentEditFormGroup: FormGroup;
  public replyFormGroup: FormGroup;
  public replyEditFormGroup: FormGroup;
  public urlHostImage = environment.URLUPLOAD;
  public descriptionValue: any = "";
  public comment = false;
  public createReply = false;
  public ownerProfile: any;
  public checkRemoveFileClick = false;
  public commentList: any = null;
  public replyList: any;

  public createReplyValue = "";
  public createReplyToReplyValue = "";
  public EditReplyValue = "";
  public EditCommentValue = "";

  //public filePDFToUpload: File = null;
  public filePDFToUploadRD: [File];
  public filePDFSize: string = "";
  public filePDFNameBlob: string = "";
  public filePDFCreate = [];

  public fileImageToUpload: [File];
  public fileImageNameCrop;
  public fileImageChangedEvent;
  public fileImageSize;
  //public fileImage = [{url:"../../assets/images/action-plan-brainstorming-complex-212286@2x.png"},{url:"../../assets/images/adult-architect-architectural-design-1260309@2x.png"},{url:"../../assets/images/test2.png"}];
  public fileImageCreate = [];

  public newAttribute: any = { link: "", input: true, label: false };
  public fieldInputLinkcreateArray: Array<any> = [];
  public lengthField = 0;
  public link = [];
  public labelLinkindex;
  public indexOldEdit;
  public comment2Delete: any = "";
  public reply2Delete: any = "";

  public DivCreateComment = "";
  public DivEditComment = "";


  //============================ edit comment ================================
  public descriptionValueEdit: any = "";
  //public newAttributeEdit: any = { IdComment: "", link: "", input: true, label: false };
  public fieldInputLinkEdit: Array<any> = [];
  public lengthFieldEdit = 0;

  public filePDFEdit = [];
  public filePDFEditToUploadRD = null;

  public fileImageEdit = [];
  public fileImageEditToUpload = null;

  //============================ create reply ================================
  public descriptionValueReply: any = "";

  public filePDFToUploadRDReply: [File];
  public filePDFSizeReply: string = "";
  public filePDFNameBlobReply: string = "";
  public filePDFReply = [];

  public fileImageToUploadReply: [File];
  public fileImageNameCropReply;
  public fileImageChangedEventReply;
  public fileImageSizeReply;
  public fileImageReply = [];

  public newAttributeReply: any = { link: "", input: true, label: false };
  public fieldInputLinkArrayReply: Array<any> = [];
  public lengthFieldReply = 0;
  showIdNoti: any;

  //============================ edit reply ================================
  public descriptionValueReplyEdit: any = "";

  public filePDFToUploadRDReplyEdit = null;
  public filePDFSizeReplyEdit: string = "";
  public filePDFNameBlobReplyEdit: string = "";
  public filePDFReplyEdit = [];

  public fileImageToUploadReplyEdit = null;
  public fileImageNameCropReplyEdit;
  public fileImageChangedEventReplyEdit;
  public fileImageSizeReplyEdit;
  public fileImageReplyEdit = [];

  public newAttributeReplyEdit: any = { link: "", input: true, label: false };
  public fieldInputLinkArrayReplyEdit: Array<any> = [];
  public lengthFieldReplyEdit = 0;


  //============================ create reply To reply ================================

  public descriptionValueReply2r: any = "";

  public filePDFToUploadRDReply2r: [File];
  public filePDFSizeReply2r: string = "";
  public filePDFNameBlobReply2r: string = "";
  public filePDFReply2r = [];

  public fileImageToUploadReply2r: [File];
  public fileImageNameCropReply2r;
  public fileImageChangedEventReply2r;
  public fileImageSizeReply2r;
  public fileImageReply2r = [];

  public newAttributeReply2r: any = { link: "", input: true, label: false };
  public fieldInputLinkArrayReply2r: Array<any> = [];
  public lengthFieldReply2r = 0;



  //////////// Fillter Mention ////////////
  // @ViewChild('userMentions', {read: MentionList}) userMentions: MentionList;
  // @ViewChild('userMentionsReply', {read: MentionList}) userMentionsReply: MentionList;

  @ViewChild(MentionList) userMentions: MentionList;
  @ViewChild(MentionList) userMentionsReply: QueryList<MentionList>;
  public matches$;
  //  public matches2$//: Observable<{  }[]>;
  ////////////////////////////////////////


  constructor(private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
    private userSer: UserServiceService,
    private commentSer: CommentServiceService,
    private _location: Location,
    public spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private http: Http
  ) { }

  ngAfterViewInit() {
    //  ngOnInit() {
    this.getMentionList();
    this.getComment();

    
    

    //////////// Fillter Mention ////////////
    // this.matches$ = this.userMentions.mentionTerm$.pipe(
    //   map(t => this.model.mentions.filter(p => p.display.toLowerCase().indexOf(t.toLowerCase()) > -1 && this.selectMentionCommentValue.findIndex(y => y.displayName == p.display) === -1)
    //   )
    // );
    // for (let i = 0; i < this.userMentionsReply.length; i++) {
    //   this.matches$ = this.userMentionsReply[i].mentionTerm$.pipe(
    //     map(t => this.model.mentions.filter(p => p.display.toLowerCase().indexOf(t[i].toLowerCase()) > -1  && this.selectMentionCommentValue.findIndex(y => y.displayName == p.display) === -1 )
    //     )
    //   );
    // }

    // 
    // 
    ////////////////////////////////////////

    this.getInit();
  }

  async getInit() {



    setTimeout(() => {
      sessionStorage.setItem("TabsDetail", "");
    }, 300);

    var element = document.getElementById("section-comment");
    element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });

    // this.cate = this.typeNameInput
    // this.region = this.regionNameInput
    //const id = this.route.snapshot.paramMap.get("id");
    let detailTabs = localStorage.getItem("EmployeeId");
    let tempData;
    tempData = await this.userSer.getProfileByEmpId(detailTabs);
    this.ownerProfile = tempData.data.data;
    
    

    this.commentFormGroup = this.formBuilder.group({
      projectId: [""],
      description: [""],
      attachFile: [File],
      attachFileSize: [''],
      attachPic: [File],
      attachLink: [""],
      createBy: [""],
    });
    this.commentEditFormGroup = this.formBuilder.group({
      commentId: [""],
      projectId: [""],
      description: [""],
      attachFile: [File],
      attachFileSize: [''],
      attachPic: [File],
      attachLink: [""],
      createBy: [""],
    });
    this.replyFormGroup = this.formBuilder.group({
      projectId: [""],
      projectCommentId: [""],
      description: [""],
      attachFile: [File],
      attachFileSize: [''],
      attachPic: [File],
      attachLink: [""],
      replyTo: [""],
      createBy: [""],
    });
    this.replyEditFormGroup = this.formBuilder.group({
      replyId: [""],
      projectId: [""],
      projectCommentId: [""],
      description: [""],
      attachFile: [File],
      attachFileSize: [''],
      attachPic: [File],
      attachLink: [""],
    });


    // let commentIdnoti = sessionStorage.getItem("CommentId");
    // this.showIdNoti = commentIdnoti;

    // var elementcomment = document.getElementById("commentNoti");
    // 
    // 
    // elementcomment.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  }


  // scroll(el: HTMLElement) {
  //   el.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  // }

  async getMentionList() {
    let user = await this.userSer.getAllUserforMention();
    this.userList = user.data.data;
    for (let i = 0; i < this.userList.length; i++) {
      this.model.mentions.push({
        display: this.userList[i].displayName,
        name: this.userList[i].firstName,
        employeeId: this.userList[i].employeeId,
        email: this.userList[i].email,
        type: "user",
        icon: this.userList[i].imgProfile
      });
    }
  }

  selectMention(mention) {
    var text = $("#inputCreateComment").val();
    this.selectMentionCommentValue.push({
      displayName: mention.display,
      name: mention.name,
      employeeId: mention.employeeId,
      email: mention.email,
      indexS: text.indexOf("@" + mention.name),
      indexE: text.lastIndexOf("@" + mention.name) + mention.name.length
    });


    
  }

  checkMention(event) {
    //
    var text = $("#inputCreateComment").val();
    var cutText = text.split("@");
    for (let i = 0; i < this.selectMentionCommentValue.length; i++) {
      if (!text.includes(this.selectMentionCommentValue[i].name)) {
        var txtSlice = text.slice(this.selectMentionCommentValue[i].indexS, this.selectMentionCommentValue[i].indexE);
        var txtReplace = text.replace(txtSlice, '');
        $("#inputCreateComment").val(txtReplace);

        this.selectMentionCommentValue.splice(i, 1);
      }
      else { // Update Index Mention
        this.selectMentionCommentValue[i] = {
          displayName: this.selectMentionCommentValue[i].displayName,
          name: this.selectMentionCommentValue[i].name,
          employeeId: this.selectMentionCommentValue[i].employeeId,
          email: this.selectMentionCommentValue[i].mentionEmail,
          indexS: text.indexOf("@" + this.selectMentionCommentValue[i].name),
          indexE: text.lastIndexOf("@" + this.selectMentionCommentValue[i].name) + this.selectMentionCommentValue[i].name.length
        }
      }
    }
    //
  }

  async getComment() {
    let tempDataComment;
    const id = this.route.snapshot.paramMap.get("id");
    if (!this.projectId) {
      this.projectId = id;
    }

    //alert(this.projectId);
    tempDataComment = await this.commentSer.getCommentByProjectId(this.projectId);
    this.commentList = tempDataComment.data.data;
    
    


    // var myLineBreak = this.commentList[0].description.replace(/\r\n|\r|\n/g,"</br>");

    // 


  }

  public countRowsInText(text) {

    let numerOfRowsInText = text != null ? text.split(/\n/).length : 2;
    let rows = numerOfRowsInText < 2 ? 2 : numerOfRowsInText;
    //
    return rows;
  }

  commentClick() {
    this.comment = true;

    this.DivCreateComment = "createComment";
    this.DivEditComment = 'editComment';
  }
  outsidecommentClick() {
    //
    if (!this.checkRemoveFileClick) {
      if (!this.descriptionValue && this.filePDFCreate.length == 0 && this.fileImageCreate.length == 0 && this.fieldInputLinkcreateArray.length == 0) {
        this.comment = false;
      }
    } else {
      this.checkRemoveFileClick = false;
    }
  }

  public handleFilePDFInputRD(event) {
    if (true) {//(this.filePDFCreate.length < 1) {
      if (true) {//(event.target.files.length <= (1 - this.filePDFCreate.length)) {
        for (let i = 0; i < event.target.files.length; i++) {
          if (
            event.target.files[i].type == "application/pdf"
            //|| event.target.files[0].type ==
            // "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          ) {
            //
            var size = (event.target.files[i].size / Math.pow(1024, 2)).toFixed(2);
            //
            if (parseInt(size) < 5) {
              this.filePDFSize = size;
              this.filePDFToUploadRD = event.target.files[i];

              if (this.filePDFCreate.length != 0) {
                this.filePDFCreate = [];
              }
              this.filePDFCreate.push({
                file: event.target.files[i],
                name: event.target.files[i].name,
                size: event.target.files[i].size,
              });


            } else {
              $('#File').val('');
              this.filePDFToUploadRD = null;
              $("#filePDFSizeExceeded").modal("show");
            }
          } else {
            $('#File').val('');
            this.filePDFToUploadRD = null;
            $("#CreateFailpdf").modal("show");
          }
        }
        
      }
      else {
        
      }
    } else {
      
    }
    //
  }

  removefilePDFCreate(index) {
    this.checkRemoveFileClick = true;
    this.filePDFCreate.splice(index, 1);
    this.filePDFToUploadRD = null;
  }

  handleImageInputRD(event: any): void {//true){//
    if (true) {//this.fileImage.length < 3) {
      if (true) {//event.target.files.length <= (3 - this.fileImage.length)) {
        for (let i = 0; i < event.target.files.length; i++) {
          if (event.target.files.length <= (1 - this.fileImageCreate.length)) { }
          //
          if (
            event.target.files[i].type == "image/png" || event.target.files[i].type == "image/jpeg" || event.target.files[i].type == "image/gif"
          ) {
            //
            var size = (event.target.files[i].size / Math.pow(1024, 2)).toFixed(2);
            //
            if (parseInt(size) < 3) {
              this.fileImageSize = size;
              this.fileImageChangedEvent = event;
              this.fileImageNameCrop = event;
              //
              //

              if (event.target.files[i]) {
                this.fileImageToUpload = event.target.files[i];
                const reader: FileReader = new FileReader();
                reader.readAsDataURL(event.target.files[i]);

                reader.onload = (eventReader: any) => {
                  //
                  //this.url = eventReader.target.result;

                  if (this.fileImageCreate.length != 0) {
                    this.fileImageCreate = [];
                  }
                  this.fileImageCreate.push({
                    file: event.target.files[i],
                    url: eventReader.target.result
                  });
                };
              }

              //
            } else {
              this.fileImageChangedEvent = "";
              this.fileImageNameCrop = "";
              $('#imgFile').val('');
              $("#modalCover").modal("hide");
              $("#fileImageSizeExceeded").modal("show");
            }
          } else {
            this.fileImageChangedEvent = "";
            this.fileImageNameCrop = "";
            $('#imgFile').val('');
            $("#modalCover").modal("hide");
            $("#CreateFailImage").modal("show");
          }

        }
      }
      else {
        
      }
    } else {
      
    }
  }

  removeImageCreate(index) {
    this.fileImageCreate.splice(index, 1);
    this.fileImageToUpload = null;
  }

  addFieldLinkCreate() {
    var fieldid = this.lengthField - 1;
    if (this.lengthField == 0) {
      this.fieldInputLinkcreateArray.push(this.newAttribute);
      this.lengthField += 1;
    }
    else {
      let linklabel = $('#inputLink' + (this.lengthField - 1)).val();
      //let valueLink = linklabel == "" ? "" : linklabel.substr(0, 4) == "http" ? linklabel : "http://" + linklabel;
      for (let i = 0; i < this.lengthField - 1; i++) {
        if (this.lengthField - 1 != i) {
          this.fieldInputLinkcreateArray[i] = {
            link: this.fieldInputLinkcreateArray[i].link == "" ? $('#inputLink' + i).val() : this.fieldInputLinkcreateArray[i].link,//$('#inputLink' + i).val() == "" ? $('#LabelLink' + i).text() : $('#inputLink' + i).val() ,
            input: false,
            label: true
          };
        }
      }
      this.fieldInputLinkcreateArray[this.lengthField - 1] = {
        link: linklabel,
        input: false,
        label: true
      };

      this.fieldInputLinkcreateArray.push(this.newAttribute);
      $('#inputLink' + (this.lengthField - 1)).val("");
      this.lengthField += 1;

    }
  }

  editLinkCreate(index) {
    // let fieldid = this.fieldInputLinkcreateArray.length - 1;
    // this.labelLinkindex = index;
    // 
    // let labelOld = $('#inputLink' + (this.indexOldEdit)).val();
    // //let valueOld = labelOld == "" ? "" : labelOld.substr(0, 4) == "http" ? labelOld : "http://" + labelOld;
    // this.fieldInputLinkcreateArray[this.indexOldEdit] = {
    //   link: labelOld,
    //   input: false,
    //   label: true
    // };
    // let labelCur = $('#LabelLink' + (index)).text();
    // //let valueCur =labelCur == "" ? "" : labelCur.substr(0, 4) == "http" ? labelCur : "http://" + labelCur;
    // this.fieldInputLinkcreateArray[index] = {
    //   link: labelCur,
    //   input: true,
    //   label: false
    // };
    // if (index != fieldid) {
    //   let labelLast = $('#LabelLink' + (fieldid)).text();
    //   //let valueLast = labelLast == "" ? "" : labelLast.substr(0, 4) == "http" ? labelLast : "http://" + labelLast;
    //   this.fieldInputLinkcreateArray[fieldid] = {
    //     link: labelLast,
    //     input: false,
    //     label: true
    //   };
    // }
    // this.indexOldEdit = index;
  }

  SaveLinkCreate(index) {
    if ($('#inputLink' + index).val() != "") {
      this.fieldInputLinkcreateArray[index] = {
        link: $('#inputLink' + index).val(),
        input: true,
        label: false
      };
    }
    
  }

  hideLinkTextbox() {

  }
  addLinkCreate() {
    //this.link.push();

  }

  removeLinkCreate(index) {
    this.fieldInputLinkcreateArray.splice(index, 1);
    this.lengthField -= 1;
    $('#inputLink' + (index)).hide();
    $('#LabelLink' + (index)).hide();
  }

  submitComment() {

    const id = this.route.snapshot.paramMap.get("id");

    let linkNew = [];
    for (let i = 0; i < this.fieldInputLinkcreateArray.length; i++) {
      if (this.fieldInputLinkcreateArray[i].link != "") {
        linkNew.push(this.fieldInputLinkcreateArray[i].link)
      }
    }

    if (!this.projectId) {
      this.projectId = id;
    }

    //alert(this.projectId);

    this.commentFormGroup.patchValue({
      projectId: this.projectId,
      description: this.descriptionValue,
      attachFile: this.filePDFCreate.length == 0 ? null : this.filePDFCreate[0].file,
      attachFileSize: this.filePDFCreate.length == 0 ? null : this.filePDFCreate[0].size,
      attachPic: this.fileImageCreate.length == 0 ? null : this.fileImageCreate[0].file,
      attachLink: linkNew,
      createBy: {
        Id: sessionStorage.getItem("_id"),
        displayName: sessionStorage.getItem("displayName"),
      }
    });

    

    if (!(this.descriptionValue == "" && this.filePDFCreate.length == 0 && this.fileImageCreate.length == 0 && linkNew.length == 0)) {
      this.spinner.show();
      this.commentSer.createComment(this.commentFormGroup.value, this.selectMentionCommentValue)
        .then(response => {
          setTimeout(() => {
            this.spinner.hide();
            if (response.status === 413) {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            } else if (response.status === 403) {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");

            } else if (response.status === 200) {

              let res = response.data.data[0]._id;
              
              this.getComment();

              //$('#inputCreateComment').val("");
              this.descriptionValue = "";
              this.fileImageCreate = [];
              this.fieldInputLinkcreateArray = [];
              this.filePDFCreate = [];
              this.fileImageToUpload = null;
              this.filePDFToUploadRD = null;
              this.lengthField = 0;
              $("#ConfirmModal").modal("hide");
              $("#CreateSuccess").modal("show");

              this.comment = false;

            } else {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            }
          }, 300);
        });

    }
  }

  openDeletemodal(type, id) {
    if (type == 'comment') {
      $("#ConfirmDeleteCommentModal").modal("show");
      this.comment2Delete = id;
      this.reply2Delete = "";


    }
    else if (type == 'reply') {
      $("#ConfirmDeleteCommentModal").modal("show");
      this.reply2Delete = id;
      this.comment2Delete = "";
    }


  }
  confirmDelete() {
    if (this.comment2Delete != "") {
      this.deleteComment(this.comment2Delete);
    }
    else if (this.reply2Delete != "") {
      this.deleteReply(this.reply2Delete);
    }
  }

  deleteComment(id) {
    this.commentSer.deleteComment(id)
      .then(response => {
        setTimeout(() => {
          this.spinner.hide();
          
          if (response.status === 413) {
            $("#ConfirmModal").modal("hide");
            $("#CreateFail").modal("show");
          } else if (response.status === 403) {
            $("#ConfirmModal").modal("hide");
            $("#CreateFail").modal("show");
          } else if (response.status === 200) {
            let res = response.data;
            
            this.getComment();

            $("#ConfirmDeleteCommentModal").modal("hide");

          } else {
            $("#ConfirmModal").modal("hide");
            $("#CreateFail").modal("show");
          }
        }, 300);
      });
    this.comment2Delete = "";
  }

  deleteReply(id) {
    this.commentSer.deleteReply(id)
      .then(response => {
        setTimeout(() => {
          this.spinner.hide();
          if (response.status === 413) {
            $("#ConfirmModal").modal("hide");
            $("#CreateFail").modal("show");
          } else if (response.status === 403) {
            $("#ConfirmModal").modal("hide");
            $("#CreateFail").modal("show");
          } else if (response.status === 200) {
            let res = response.data;
            
            this.getComment();

            $("#ConfirmDeleteCommentModal").modal("hide");

          } else {
            $("#ConfirmModal").modal("hide");
            $("#CreateFail").modal("show");
          }
        }, 300);
      });
    this.reply2Delete = "";
  }

  openCreateReply(id) {
    //this.createReply = true;
    // $('#createReply' + id).show();
    // $('#reply' + id).show();
    //this.createReplyValue = id;
    // $('#createReplyToReply' + this.createReplyToReplyValue).hide();
    // $('#replyToReply' + this.createReplyToReplyValue).hide();
    //this.createReplyToReplyValue = "";
    this.DivCreateComment = "createReply" + id;
    
  }


  //============================================================================ edit comment ===============================================================================
  editComment(itemComment) {
    

    this.DivEditComment = "editComment" + itemComment._id;
    this.DivCreateComment = "";
    console.clear()
    
  }

  cancelEditComment(id) {
    this.DivEditComment = "";
    
  }

  selectMentionEdit(commentId, mention) {
    var text = $("#inputEditComment" + commentId).val();
    this.selectMentionCommentEditValue.push({
      displayName: mention.displayName,
      name: mention.name,
      employeeId: mention.employeeId,
      email: mention.email,
      indexS: text.indexOf("@" + mention.name),
      indexE: text.lastIndexOf("@" + mention.name) + mention.name.length
    });
    //this.getMentionList();
    // 
  }

  checkMentionEdit(commentId, event) {
    //
    var text = $("#inputEditComment" + commentId).val();
    var cutText = text.split("@");
    for (let i = 0; i < this.selectMentionCommentEditValue.length; i++) {
      if (!text.includes(this.selectMentionCommentEditValue[i].name)) {
        var txtSlice = text.slice(this.selectMentionCommentEditValue[i].indexS, this.selectMentionCommentEditValue[i].indexE);
        var txtReplace = text.replace(txtSlice, '');
        $("#inputEditComment" + commentId).val(txtReplace);

        this.selectMentionCommentEditValue.splice(i, 1);
      }
      else { // Update Index Mention
        this.selectMentionCommentEditValue[i] = {
          displayName: this.selectMentionCommentEditValue[i].displayName,
          name: this.selectMentionCommentEditValue[i].name,
          employeeId: this.selectMentionCommentEditValue[i].employeeId,
          email: this.selectMentionCommentEditValue[i].mentionEmail,
          indexS: text.indexOf("@" + this.selectMentionCommentEditValue[i].name),
          indexE: text.lastIndexOf("@" + this.selectMentionCommentEditValue[i].name) + this.selectMentionCommentEditValue[i].name.length
        }
      }
    }
    //
  }

  getDataEditComment(itemComment) {
    //link
    this.fieldInputLinkEdit = [];
    for (let i = 0; i < itemComment.attachLink.length; i++) {
      if (itemComment.attachLink[i].url) {
        this.fieldInputLinkEdit.push({
          IdComment: itemComment._id,
          link: itemComment.attachLink[i].url,
          input: false,
          label: true
        });
      }
    }
    //description
    $('#inputEditComment' + itemComment._id).val(itemComment.description);
    //image
    if (itemComment.attachPic.path != null) {
      this.fileImageEdit = [];
      this.fileImageEdit.push({
        IdComment: itemComment._id,
        file: null,
        url: itemComment.attachPic.path
      });
      this.fileImageEditToUpload = itemComment.attachPic.path;
    }
    //file
    if (itemComment.attachFile.path != null) {
      this.filePDFEdit = [];
      this.filePDFEdit.push({
        IdComment: itemComment._id,
        file: null,
        name: itemComment.attachFile.name,
        size: itemComment.attachFile.fileSize,
      });
      this.filePDFEditToUploadRD = itemComment.attachFile.name;
    }
  }

  handleFilePDFInputRDEdit(event, id) {
    if (true) {
      if (true) {
        for (let i = 0; i < event.target.files.length; i++) {
          if (
            event.target.files[i].type == "application/pdf"
            //|| event.target.files[0].type ==
            // "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          ) {
            //
            var size = (event.target.files[i].size / Math.pow(1024, 2)).toFixed(2);
            //
            if (parseInt(size) < 5) {
              //this.filePDFEditToUploadRD = event.target.files[i];

              let pdfck = this.filePDFEdit.filter(x => x.IdComment === id);
              if (pdfck.length == 0) {
                this.filePDFEdit.push({
                  IdComment: id,
                  file: event.target.files[i],
                  name: event.target.files[i].name,
                  size: event.target.files[i].size,
                });
              } else {
                this.filePDFEdit = [];
                this.filePDFEdit.push({
                  IdComment: id,
                  file: event.target.files[i],
                  name: event.target.files[i].name,
                  size: event.target.files[i].size,
                });
              }
            } else {
              $('#File').val('');
              this.filePDFEditToUploadRD = null;
              $("#filePDFSizeExceeded").modal("show");
            }
          } else {
            $('#File').val('');
            this.filePDFEditToUploadRD = null;
            $("#CreateFailpdf").modal("show");
          }
        }
      }
      else {
        
      }
    } else {
      
    }
  }
  removefilePDFEdit(id) {
    let index = this.filePDFEdit.findIndex(x => x.IdComment === id)
    this.filePDFEdit.splice(index, 1);
    this.filePDFEditToUploadRD = null;
  }

  addFieldLinkEdit(id) {
    var fieldid = this.lengthFieldEdit - 1;
    if (this.lengthFieldEdit == 0) {
      this.fieldInputLinkEdit.push({ IdComment: id, link: "", input: true, label: false });
      this.lengthFieldEdit += 1;
    }
    else {
      let linklabel = $('#inputLinkEdit' + id + "_" + (this.lengthFieldEdit - 1)).val();
      //let valueLink = linklabel == "" ? "" : linklabel.substr(0, 4) == "http" ? linklabel : "http://" + linklabel;
      for (let i = 0; i < this.lengthFieldEdit - 1; i++) {
        if (this.lengthFieldEdit - 1 != i) {
          this.fieldInputLinkEdit[i] = {
            IdComment: id,
            link: this.fieldInputLinkEdit[i].link == "" ? $('#inputLinkEdit' + id + "_" + i).val() : this.fieldInputLinkEdit[i].link,//$('#inputLink' + i).val() == "" ? $('#LabelLink' + i).text() : $('#inputLink' + i).val() ,
            input: false,
            label: true
          };
        }
      }
      this.fieldInputLinkEdit[this.lengthFieldEdit - 1] = {
        IdComment: id,
        link: linklabel,
        input: false,
        label: true
      };

      this.fieldInputLinkEdit.push({ IdComment: id, link: "", input: true, label: false });
      $('#inputLinkEdit' + id + "_" + (this.lengthFieldEdit - 1)).val("");
      this.lengthFieldEdit += 1;

    }
  }
  removeLinkEdit(id, index) {
    this.fieldInputLinkEdit.splice(index, 1);
    this.lengthFieldEdit -= 1;
    $('inputLinkEdit' + id + "_" + (index)).hide();
    $('#LabelLinkEdit' + id + "_" + + (index)).hide();
  }

  handleImageInputRDEdit(event: any, id): void {
    if (true) {//this.fileImage.length < 3) {
      if (true) {//event.target.files.length <= (3 - this.fileImage.length)) {
        for (let i = 0; i < event.target.files.length; i++) {
          //
          if (
            event.target.files[i].type == "image/png" || event.target.files[i].type == "image/jpeg" || event.target.files[i].type == "image/gif"
          ) {

            //
            var size = (event.target.files[i].size / Math.pow(1024, 2)).toFixed(2);
            //
            if (parseInt(size) < 3) {
              if (event.target.files[i]) {
                const reader: FileReader = new FileReader();
                reader.readAsDataURL(event.target.files[i]);

                reader.onload = (eventReader: any) => {
                  //
                  //this.url = eventReader.target.result;
                  let imgck = this.fileImageEdit.filter(x => x.IdComment === id);
                  if (imgck.length == 0) {
                    this.fileImageEdit.push({
                      IdComment: id,
                      file: event.target.files[i],
                      url: eventReader.target.result
                    });
                  } else {
                    this.fileImageEdit = [];
                    this.fileImageEdit.push({
                      IdComment: id,
                      file: event.target.files[i],
                      url: eventReader.target.result
                    });
                  }

                };
              }

              //
            } else {
              this.fileImageChangedEvent = "";
              this.fileImageNameCrop = "";
              $('#imgFile').val('');
              $("#modalCover").modal("hide");
              $("#fileImageSizeExceeded").modal("show");
            }
          } else {
            this.fileImageChangedEvent = "";
            this.fileImageNameCrop = "";
            $('#imgFile').val('');
            $("#modalCover").modal("hide");
            $("#CreateFailImage").modal("show");
          }

        }
      }
      else {
        
      }
    } else {
      
    }
  }
  removeImageEdit(id) {
    let index = this.fileImageEdit.findIndex(x => x.IdComment === id);
    this.fileImageEdit.splice(index, 1);
    this.fileImageEditToUpload = null;
  }

  SaveLinkEdit(id, index) {
    let indexv = this.fieldInputLinkEdit.findIndex(x => x.IdComment === id);
    if ($('#inputLinkEdit' + id + "_" + index).val() != "") {
      this.fieldInputLinkEdit[index] = {
        IdComment: id,
        link: $('#inputLinkEdit' + id + "_" + index).val(),
        input: true,
        label: false
      };
    }
  }

  submitEditComment(itemComment) {
    let linkNew = [];
    for (let i = 0; i < this.fieldInputLinkEdit.length; i++) {
      if (this.fieldInputLinkEdit[i].link != "") {
        linkNew.push(this.fieldInputLinkEdit[i].link);
      }
    }
    this.commentEditFormGroup.patchValue({
      commentId: itemComment._id,
      projectId: this.projectId,
      description: $('#inputEditComment' + itemComment._id).val(),
      // attachFile: this.filePDFEditToUploadRD == null
      //   ? null
      //   : this.filePDFEditToUploadRD,

      // attachFileSize: this.filePDFEdit.length == 0
      //   ? null
      //   : this.filePDFEdit[0].size,

      // attachPic: this.fileImageEditToUpload == null
      //   ? null
      //   : this.fileImageEditToUpload,
      attachFile: this.filePDFEditToUploadRD == null && this.filePDFEdit.length == 0
        ? 'NoFile'
        : this.filePDFEditToUploadRD != null && this.filePDFEdit.length == 0
          ? this.filePDFEditToUploadRD
          : this.filePDFEditToUploadRD == null && this.filePDFEdit.length != 0
            ? this.filePDFEdit[0].file
            : this.filePDFEditToUploadRD != null && this.filePDFEdit.length != 0
              ? this.filePDFEdit[0].file
              : 'NoFile',

      attachFileSize: this.filePDFEditToUploadRD == null && this.filePDFEdit.length == 0
        ? 'NoFile'
        : this.filePDFEditToUploadRD != null && this.filePDFEdit.length == 0
          ? this.filePDFEditToUploadRD
          : this.filePDFEditToUploadRD == null && this.filePDFEdit.length != 0
            ? this.filePDFEdit[0].size
            : this.filePDFEditToUploadRD != null && this.filePDFEdit.length != 0
              ? this.filePDFEdit[0].size
              : 'NoFile',

      attachPic: this.fileImageEditToUpload == null && this.fileImageEdit.length == 0
        ? 'NoImage'
        : this.fileImageEditToUpload != null && this.fileImageEdit.length == 0
          ? this.fileImageEditToUpload
          : this.fileImageEditToUpload == null && this.fileImageEdit.length != 0
            ? this.fileImageEdit[0].file
            : this.fileImageEditToUpload != null && this.fileImageEdit.length != 0
              ? this.fileImageEdit[0].file
              : 'NoImage',

      attachLink: linkNew,
      createBy: {
        Id: sessionStorage.getItem("_id"),
        displayName: sessionStorage.getItem("displayName"),
      }
    });

    

    if (!($('#inputEditComment' + itemComment._id).val() == "" && this.filePDFEdit.length == 0 && this.fileImageEdit.length == 0 && linkNew.length == 0)) {
      this.spinner.show();
      this.commentSer.editComment(this.commentEditFormGroup.value, this.selectMentionCommentEditValue)
        .then(response => {
          setTimeout(() => {
            this.spinner.hide();
            if (response.status === 413) {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            } else if (response.status === 403) {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            } else if (response.status === 200) {

              let res = response.data;
              
              this.getComment();

              //$('#inputCreateComment').val("");
              $("#ConfirmModal").modal("hide");
              $("#CreateSuccess").modal("show");

            } else {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            }
          }, 300);
        });
    }
  }


  //============================================================================ Create Reply ===============================================================================
  openCreateReplyToReply(id) {
    this.DivCreateComment = "CreateReplyToReply" + id;
    
    // // Reset ค่า create reply to reply
    // if (this.createReplyToReplyValue != id) {
    //   this.descriptionValueReply2r = "";
    //   this.fileImageReply2r = [];
    //   this.fieldInputLinkArrayReply2r = [];
    //   this.filePDFReply2r = [];
    //   this.fileImageToUploadReply2r = null;
    //   this.filePDFToUploadRDReply2r = null;
    //   this.lengthFieldReply2r = 0;
    // }

    // // ปิด createReplyToReply เดิม
    // $('#createReplyToReply' + this.createReplyToReplyValue).hide();
    // $('#replyToReply' + this.createReplyToReplyValue).hide();

    // // เปิด createReplyToReply ที่กดตอบกลับ
    // $('#createReplyToReply' + id).show();
    // $('#replyToReply' + id).show();

    // // เซ็ตค่า replyId
    // this.createReplyToReplyValue = id;

    // // ปิด create reply
    // $('#createReply' + this.createReplyValue).hide();
    // $('#reply' + this.createReplyValue).hide();
    // this.createReplyValue = "";

    // // Reset ค่า create reply
    // this.descriptionValueReply = "";
    // this.fileImageReply = [];
    // this.fieldInputLinkArrayReply = [];
    // this.filePDFReply = [];
    // this.fileImageToUploadReply = null;
    // this.filePDFToUploadRDReply = null;
    // this.lengthFieldReply = 0;
  }

  outsideReplyClick(id) {
    // 
    // //$('#createReply' + id).show();
    // var allListElements = $(".findReply");
    // for (let i = 0; i < allListElements.length; i++) {
    //   
    // }


    // if (false) {
    //   for (let i = 0; i < this.commentList.length; i++) {
    //     if (this.commentList._id != id) {
    //       $('#createReply' + this.commentList[i]._id).hide();
    //     }
    //   }
    // }
  }

  selectMentionReply(commentId, mention) {
    //
    var text = $("#inputCommentReply" + commentId).val();
    this.selectMentionReplyValue.push({
      displayName: mention.display,
      name: mention.name,
      employeeId: mention.employeeId,
      email: mention.email,
      indexS: text.indexOf("@" + mention.name),
      indexE: text.lastIndexOf("@" + mention.name) + mention.name.length
    });
    //this.getMentionList();
  }

  checkMentionReply(commentId, event) {
    //
    var text = $("#inputCommentReply" + commentId).val();
    var cutText = text.split("@");
    for (let i = 0; i < this.selectMentionReplyValue.length; i++) {
      if (!text.includes(this.selectMentionReplyValue[i].name)) {
        var txtSlice = text.slice(this.selectMentionReplyValue[i].indexS, this.selectMentionReplyValue[i].indexE);
        var txtReplace = text.replace(txtSlice, '');
        $("#inputCommentReply" + commentId).val(txtReplace);

        this.selectMentionReplyValue.splice(i, 1);
      }
      else { // Update Index Mention
        this.selectMentionReplyValue[i] = {
          displayName: this.selectMentionReplyValue[i].displayName,
          name: this.selectMentionReplyValue[i].name,
          employeeId: this.selectMentionReplyValue[i].employeeId,
          indexS: text.indexOf("@" + this.selectMentionReplyValue[i].name),
          indexE: text.lastIndexOf("@" + this.selectMentionReplyValue[i].name) + this.selectMentionReplyValue[i].name.length
        }
      }
    }
    //
  }

  handleFilePDFInputRDReply(event) {
    if (this.filePDFReply.length < 1) {
      if (event.target.files.length <= (1 - this.filePDFReply.length)) {
        for (let i = 0; i < event.target.files.length; i++) {
          if (
            event.target.files[i].type == "application/pdf"
            //|| event.target.files[0].type ==
            // "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          ) {
            //
            var size = (event.target.files[i].size / Math.pow(1024, 2)).toFixed(2);
            //
            if (parseInt(size) < 5) {
              this.filePDFSizeReply = size;
              this.filePDFToUploadRDReply = event.target.files[i];

              if (this.filePDFReply.length != 0) {
                this.filePDFReply = [];
              }
              this.filePDFReply.push({
                file: event.target.files[i],
                name: event.target.files[i].name,
                size: event.target.files[i].size,
              });

            } else {
              $('#File').val('');
              this.filePDFToUploadRDReply = null;
              $("#filePDFSizeExceeded").modal("show");
            }
          } else {
            $('#File').val('');
            this.filePDFToUploadRDReply = null;
            $("#CreateFailpdf").modal("show");
          }
        }
        
      }
      else {
        
      }
    } else {
      
    }
  }

  removeFilePDFReply(index) {
    this.checkRemoveFileClick = true;
    this.filePDFReply.splice(index, 1);
    this.filePDFToUploadRDReply = null;
  }

  addFieldLinkReply() {
    var fieldid = this.lengthFieldReply - 1;
    if (this.lengthFieldReply == 0) {
      this.fieldInputLinkArrayReply.push(this.newAttributeReply);
      this.lengthFieldReply += 1;
    }
    else {
      let linklabel = $('#inputLinkReply' + (this.lengthFieldReply - 1)).val();
      //let valueLink = linklabel == "" ? "" : linklabel.substr(0, 4) == "http" ? linklabel : "http://" + linklabel;
      for (let i = 0; i < this.lengthFieldReply - 1; i++) {
        if (this.lengthFieldReply - 1 != i) {
          this.fieldInputLinkArrayReply[i] = {
            link: this.fieldInputLinkArrayReply[i].link == "" ? $('#inputLinkReply' + i).val() : this.fieldInputLinkArrayReply[i].link,//$('#inputLinkReply' + i).val() == "" ? $('#inputLinkReply' + i).text() : $('#inputLinkReply' + i).val() ,
            input: false,
            label: true
          };
        }
      }
      this.fieldInputLinkArrayReply[this.lengthFieldReply - 1] = {
        link: linklabel,
        input: false,
        label: true
      };

      this.fieldInputLinkArrayReply.push(this.newAttributeReply);
      $('#inputLinkReply' + (this.lengthFieldReply - 1)).val("");
      this.lengthFieldReply += 1;

    }
  }

  removeLinkReply(index) {
    this.fieldInputLinkArrayReply.splice(index, 1);
    this.lengthFieldReply -= 1;
    $('#inputLinkReply' + (index)).hide();
    $('#LabelLinkReply' + (index)).hide();
  }

  SaveLinkReply(index) {
    this.fieldInputLinkArrayReply[index] = {
      link: $('#inputLinkReply' + index).val(),
      input: true,
      label: false
    };
    
  }

  handleImageInputRDReply(event: any, id): void {
    if (true) {//this.fileImage.length < 3) {
      if (true) {//event.target.files.length <= (3 - this.fileImage.length)) {
        for (let i = 0; i < event.target.files.length; i++) {
          if (event.target.files.length <= (1 - this.fileImageReply.length)) { }
          //
          if (
            event.target.files[i].type == "image/png" || event.target.files[i].type == "image/jpeg" || event.target.files[i].type == "image/gif"
          ) {
            //
            var size = (event.target.files[i].size / Math.pow(1024, 2)).toFixed(2);
            //
            if (parseInt(size) < 3) {
              this.fileImageSizeReply = size;
              this.fileImageChangedEventReply = event;
              this.fileImageNameCropReply = event;
              //
              //

              if (event.target.files[i]) {
                this.fileImageToUploadReply = event.target.files[i];
                const reader: FileReader = new FileReader();
                reader.readAsDataURL(event.target.files[i]);

                reader.onload = (eventReader: any) => {
                  //
                  //this.url = eventReader.target.result;

                  if (this.fileImageReply.length != 0) {
                    this.fileImageReply = [];
                  }
                  this.fileImageReply.push({
                    file: event.target.files[i],
                    url: eventReader.target.result
                  });

                };
              }

              //
            } else {
              this.fileImageChangedEventReply = "";
              this.fileImageNameCropReply = "";
              $('#imgFile').val('');
              $("#modalCover").modal("hide");
              $("#fileImageSizeExceeded").modal("show");
            }
          } else {
            this.fileImageChangedEventReply = "";
            this.fileImageNameCropReply = "";
            $('#imgFile').val('');
            $("#modalCover").modal("hide");
            $("#CreateFailImage").modal("show");
          }

        }
      }
      else {
        
      }
    } else {
      
    }
  }
  removeImageReply(index) {
    this.fileImageReply.splice(index, 1);
    this.fileImageToUploadReply = null;
  }
  submitReplyComment(itemComment) {
    let linkNew = [];
    for (let i = 0; i < this.fieldInputLinkArrayReply.length; i++) {
      if (this.fieldInputLinkArrayReply[i].link != "") {
        linkNew.push(this.fieldInputLinkArrayReply[i].link);
      }
    }
    this.replyFormGroup.patchValue({
      projectId: this.projectId,
      projectCommentId: itemComment._id,
      description: this.descriptionValueReply,
      attachFile: this.filePDFReply.length == 0 ? null : this.filePDFReply[0].file,
      attachFileSize: this.filePDFReply.length == 0 ? null : this.filePDFReply[0].size,
      attachPic: this.fileImageReply.length == 0 ? null : this.fileImageReply[0].file,
      attachLink: linkNew,
      replyTo: {
        _id: itemComment.createBy._id,
        displayName: itemComment.createBy.displayName
      },
      createBy: {
        Id: sessionStorage.getItem("_id"),
        displayName: sessionStorage.getItem("displayName"),
      }
    });

    

    if (!(this.descriptionValueReply == "" && this.filePDFReply.length == 0 && this.fileImageReply.length == 0 && linkNew.length == 0)) {
      this.spinner.show();
      this.commentSer.createReply(this.replyFormGroup.value, this.selectMentionReplyValue)
        .then(response => {
          setTimeout(() => {
            this.spinner.hide();
            if (response.status === 413) {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            } else if (response.status === 403) {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            } else if (response.status === 200) {

              let res = response.data.data[0]._id;
              
              this.getComment();

              //$('#inputCreateComment').val("");
              this.descriptionValueReply = "";
              this.fileImageReply = [];
              this.fieldInputLinkArrayReply = [];
              this.filePDFReply = [];
              this.fileImageToUploadReply = null;
              this.filePDFToUploadRDReply = null;
              this.lengthFieldReply = 0;
              $("#ConfirmModal").modal("hide");
              $("#CreateSuccess").modal("show");


            } else {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            }
          }, 300);

          // let resReply = response.data.data[0]._id;
          // var element = document.getElementById(resReply);
          // alert(resReply);
          // element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});

        });
    }
  }

  //============================================================================ Edit Reply ===============================================================================
  editReply(itemReply) {
    this.DivEditComment = "editComment" + itemReply._id;
    this.DivCreateComment = "";
    
  }

  cancelEditReply(id) {
    this.DivEditComment = "";
  }

  getDataEditReply(itemReply) {
    //link
    this.fieldInputLinkArrayReplyEdit = [];
    for (let i = 0; i < itemReply.attachLink.length; i++) {
      if (itemReply.attachLink[i].url) {
        this.fieldInputLinkArrayReplyEdit.push({
          IdReply: itemReply._id,
          link: itemReply.attachLink[i].url,
          input: false,
          label: true
        });
      }
    }
    //description
    $('#inputEditReply' + itemReply._id).val(itemReply.description);
    //image
    if (itemReply.attachPic.path != null) {
      this.fileImageReplyEdit = [];
      this.fileImageReplyEdit.push({
        IdReply: itemReply._id,
        file: null,
        url: itemReply.attachPic.path
      });
    }
    //file
    if (itemReply.attachFile.path != null) {
      this.filePDFReplyEdit = [];
      this.filePDFReplyEdit.push({
        IdReply: itemReply._id,
        file: null,
        name: itemReply.attachFile.name,
        size: itemReply.attachFile.fileSize,
      });
    }
  }

  handleFilePDFInputRDReplyEdit(event, id) {
    if (true) {
      if (true) {
        for (let i = 0; i < event.target.files.length; i++) {
          if (
            event.target.files[i].type == "application/pdf"
            //|| event.target.files[0].type ==
            // "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          ) {
            //
            var size = (event.target.files[i].size / Math.pow(1024, 2)).toFixed(2);
            //
            if (parseInt(size) < 5) {
              //this.filePDFToUploadRDReplyEdit = event.target.files[i];

              let pdfck = this.filePDFReplyEdit.filter(x => x.IdReply === id);
              if (pdfck.length == 0) {
                this.filePDFReplyEdit.push({
                  IdReply: id,
                  file: event.target.files[i],
                  name: event.target.files[i].name,
                  size: event.target.files[i].size,
                });
              } else {
                this.filePDFReplyEdit = [];
                this.filePDFReplyEdit.push({
                  IdReply: id,
                  file: event.target.files[i],
                  name: event.target.files[i].name,
                  size: event.target.files[i].size,
                });
              }
            } else {
              $('#File').val('');
              this.filePDFToUploadRDReplyEdit = null;
              $("#filePDFSizeExceeded").modal("show");
            }
          } else {
            $('#File').val('');
            this.filePDFToUploadRDReplyEdit = null;
            $("#CreateFailpdf").modal("show");
          }
        }
      }
      else {
        
      }
    } else {
      
    }
  }
  removeFilePDFReplyEdit(id) {
    let index = this.filePDFReplyEdit.findIndex(x => x.IdReply === id)
    this.filePDFReplyEdit.splice(index, 1);
    this.filePDFToUploadRDReplyEdit = null;
  }
  addFieldLinkReplyEdit(id) {
    var fieldid = this.lengthFieldReplyEdit - 1;
    if (this.lengthFieldReplyEdit == 0) {
      this.fieldInputLinkArrayReplyEdit.push({ IdReply: id, link: "", input: true, label: false });
      this.lengthFieldReplyEdit += 1;
    }
    else {
      let linklabel = $('#inputLinkReplyEdit' + id + "_" + (this.lengthFieldReplyEdit - 1)).val();
      //let valueLink = linklabel == "" ? "" : linklabel.substr(0, 4) == "http" ? linklabel : "http://" + linklabel;
      for (let i = 0; i < this.lengthFieldReplyEdit - 1; i++) {
        if (this.lengthFieldReplyEdit - 1 != i) {
          this.fieldInputLinkArrayReplyEdit[i] = {
            IdReply: id,
            link: this.fieldInputLinkArrayReplyEdit[i].link == "" ? $('#inputLinkReplyEdit' + id + "_" + i).val() : this.fieldInputLinkArrayReplyEdit[i].link,//$('#inputLink' + i).val() == "" ? $('#LabelLink' + i).text() : $('#inputLink' + i).val() ,
            input: false,
            label: true
          };
        }
      }
      this.fieldInputLinkArrayReplyEdit[this.lengthFieldReplyEdit - 1] = {
        IdReply: id,
        link: linklabel,
        input: false,
        label: true
      };

      this.fieldInputLinkArrayReplyEdit.push({ IdReply: id, link: "", input: true, label: false });
      $('#inputLinkReplyEdit' + id + "_" + (this.lengthFieldReplyEdit - 1)).val("");
      this.lengthFieldReplyEdit += 1;

    }
  }
  removeLinkReplyEdit(id, index) {
    this.fieldInputLinkArrayReplyEdit.splice(index, 1);
    this.lengthFieldReplyEdit -= 1;
    $('inputLinkReplyEdit' + id + "_" + (index)).hide();
    $('#LabelLinkReplyEdit' + id + "_" + + (index)).hide();
  }

  SaveLinkReplyEdit(id, index) {
    let indexv = this.fieldInputLinkArrayReplyEdit.findIndex(x => x.IdReply === id);
    if ($('#inputLinkReplyEdit' + id + "_" + index).val() != "") {
      this.fieldInputLinkArrayReplyEdit[index] = {
        IdReply: id,
        link: $('#inputLinkReplyEdit' + id + "_" + index).val(),
        input: true,
        label: false
      };
    }
  }
  handleImageInputRDReplyEdit(event: any, id): void {
    if (true) {//this.fileImage.length < 3) {
      if (true) {//event.target.files.length <= (3 - this.fileImage.length)) {
        for (let i = 0; i < event.target.files.length; i++) {
          //
          if (
            event.target.files[i].type == "image/png" || event.target.files[i].type == "image/jpeg" || event.target.files[i].type == "image/gif"
          ) {

            //
            var size = (event.target.files[i].size / Math.pow(1024, 2)).toFixed(2);
            //
            if (parseInt(size) < 3) {
              if (event.target.files[i]) {
                //this.fileImageToUploadReplyEdit = event.target.files[i];
                const reader: FileReader = new FileReader();
                reader.readAsDataURL(event.target.files[i]);

                reader.onload = (eventReader: any) => {
                  //
                  //this.url = eventReader.target.result;
                  let imgck = this.fileImageReplyEdit.filter(x => x.IdReply === id);
                  if (imgck.length == 0) {
                    this.fileImageReplyEdit.push({
                      IdReply: id,
                      file: event.target.files[i],
                      url: eventReader.target.result
                    });
                  } else {
                    this.fileImageReplyEdit = [];
                    this.fileImageReplyEdit.push({
                      IdReply: id,
                      file: event.target.files[i],
                      url: eventReader.target.result
                    });
                  }

                };
              }

              //
            } else {
              this.fileImageChangedEvent = "";
              this.fileImageNameCrop = "";
              $('#imgFile').val('');
              $("#modalCover").modal("hide");
              $("#fileImageSizeExceeded").modal("show");
            }
          } else {
            this.fileImageChangedEvent = "";
            this.fileImageNameCrop = "";
            $('#imgFile').val('');
            $("#modalCover").modal("hide");
            $("#CreateFailImage").modal("show");
          }

        }
      }
      else {
        
      }
    } else {
      
    }
  }
  removeImageReplyEdit(id) {
    let index = this.fileImageReplyEdit.findIndex(x => x.IdReply === id);
    this.fileImageReplyEdit.splice(index, 1);
    this.fileImageToUploadReplyEdit = null;
  }
  submitReplyCommentEdit(itemReply) {
    let linkNew = [];
    for (let i = 0; i < this.fieldInputLinkArrayReplyEdit.length; i++) {
      if (this.fieldInputLinkArrayReplyEdit[i].link != "") {
        linkNew.push(this.fieldInputLinkArrayReplyEdit[i].link);
      }
    }
    
    this.replyEditFormGroup.patchValue({
      replyId: itemReply._id,
      projectId: this.projectId,
      projectCommentId: itemReply.projectCommentId,
      description: $('#inputEditReply' + itemReply._id).val(),

      attachFile: this.filePDFToUploadRDReplyEdit == null && this.filePDFReplyEdit.length == 0
        ? 'NoFile'
        : this.filePDFToUploadRDReplyEdit != null && this.filePDFReplyEdit.length == 0
          ? this.filePDFToUploadRDReplyEdit
          : this.filePDFToUploadRDReplyEdit == null && this.filePDFReplyEdit.length != 0
            ? this.filePDFReplyEdit[0].file
            : this.filePDFToUploadRDReplyEdit != null && this.filePDFReplyEdit.length != 0
              ? this.filePDFReplyEdit[0].file
              : 'NoFile',

      attachFileSize: this.filePDFToUploadRDReplyEdit == null && this.filePDFReplyEdit.length == 0
        ? 'NoFile'
        : this.filePDFToUploadRDReplyEdit != null && this.filePDFReplyEdit.length == 0
          ? this.filePDFToUploadRDReplyEdit
          : this.filePDFToUploadRDReplyEdit == null && this.filePDFReplyEdit.length != 0
            ? this.filePDFReplyEdit[0].size
            : this.filePDFToUploadRDReplyEdit != null && this.filePDFReplyEdit.length != 0
              ? this.filePDFReplyEdit[0].size
              : 'NoFile',

      attachPic: this.fileImageToUploadReplyEdit == null && this.fileImageReplyEdit.length == 0
        ? 'NoImage'
        : this.fileImageToUploadReplyEdit != null && this.fileImageReplyEdit.length == 0
          ? this.fileImageToUploadReplyEdit
          : this.fileImageToUploadReplyEdit == null && this.fileImageReplyEdit.length != 0
            ? this.fileImageReplyEdit[0].file
            : this.fileImageToUploadReplyEdit != null && this.fileImageReplyEdit.length != 0
              ? this.fileImageReplyEdit[0].file
              : 'NoImage',

      attachLink: linkNew,


    });
    
    

    if (!($('#inputLinkReplyEdit' + itemReply._id).val() == "" && this.filePDFReplyEdit.length == 0 && this.fileImageReplyEdit.length == 0 && linkNew.length == 0)) {
      this.spinner.show();
      this.commentSer.editReply(this.replyEditFormGroup.value)
        .then(response => {
          setTimeout(() => {
            this.spinner.hide();
            if (response.status === 413) {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            } else if (response.status === 403) {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            } else if (response.status === 200) {

              let res = response.data;
              
              this.descriptionValueReplyEdit = "";
              this.filePDFToUploadRDReplyEdit = null;
              this.filePDFReplyEdit = [];
              this.fileImageToUploadReplyEdit = null;
              this.fileImageReplyEdit = [];
              this.fieldInputLinkArrayReplyEdit = [];
              this.lengthFieldReplyEdit = 0;
              this.getComment();

              //$('#inputCreateComment').val("");
              $("#ConfirmModal").modal("hide");
              $("#CreateSuccess").modal("show");

            } else {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            }
          }, 300);
        });
    }
  }

  //============================================================================ Create Reply To Reply ===============================================================================

  selectMentionReply2r(replyId, mention) {
    //
    var text = $("#inputReply2r" + replyId).val();
    this.selectMentionReplyValue2r.push({
      displayName: mention.displayName,
      name: mention.name,
      employeeId: mention.employeeId,
      email: mention.email,
      indexS: text.indexOf("@" + mention.name),
      indexE: text.lastIndexOf("@" + mention.name) + mention.name.length
    });
    //this.getMentionList();
  }

  checkMentionReply2r(replyId, event) {
    //
    var text = $("#inputReply2r" + replyId).val();
    var cutText = text.split("@");
    for (let i = 0; i < this.selectMentionReplyValue2r.length; i++) {
      if (!text.includes(this.selectMentionReplyValue2r[i].name)) {
        var txtSlice = text.slice(this.selectMentionReplyValue2r[i].indexS, this.selectMentionReplyValue2r[i].indexE);
        var txtReplace = text.replace(txtSlice, '');
        $("#inputReply2r" + replyId).val(txtReplace);

        this.selectMentionReplyValue2r.splice(i, 1);
      }
      else { // Update Index Mention
        this.selectMentionReplyValue2r[i] = {
          displayName: this.selectMentionReplyValue2r[i].displayName,
          name: this.selectMentionReplyValue2r[i].name,
          employeeId: this.selectMentionReplyValue2r[i].employeeId,
          indexS: text.indexOf("@" + this.selectMentionReplyValue2r[i].name),
          indexE: text.lastIndexOf("@" + this.selectMentionReplyValue2r[i].name) + this.selectMentionReplyValue2r[i].name.length
        }
      }
    }
    
  }

  handleFilePDFInputRDReply2r(event) {
    if (this.filePDFReply2r.length < 1) {
      if (event.target.files.length <= (1 - this.filePDFReply2r.length)) {
        for (let i = 0; i < event.target.files.length; i++) {
          if (
            event.target.files[i].type == "application/pdf"
            //|| event.target.files[0].type ==
            // "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          ) {
            //
            var size = (event.target.files[i].size / Math.pow(1024, 2)).toFixed(2);
            //
            if (parseInt(size) < 5) {
              this.filePDFSizeReply2r = size;
              this.filePDFToUploadRDReply2r = event.target.files[i];

              if (this.filePDFReply2r.length != 0) {
                this.filePDFReply2r = [];
              }
              this.filePDFReply2r.push({
                file: event.target.files[i],
                name: event.target.files[i].name,
                size: event.target.files[i].size,
              });

            } else {
              $('#File').val('');
              this.filePDFToUploadRDReply2r = null;
              $("#filePDFSizeExceeded").modal("show");
            }
          } else {
            $('#File').val('');
            this.filePDFToUploadRDReply2r = null;
            $("#CreateFailpdf").modal("show");
          }
        }
        
      }
      else {
        
      }
    } else {
      
    }
  }
  removeFilePDFReply2r(index) {
    this.filePDFReply2r.splice(index, 1);
    this.filePDFToUploadRDReply2r = null;
  }
  addFieldLinkReply2r() {
    var fieldid = this.lengthFieldReply2r - 1;
    if (this.lengthFieldReply2r == 0) {
      this.fieldInputLinkArrayReply2r.push(this.newAttributeReply2r);
      this.lengthFieldReply2r += 1;
    }
    else {
      let linklabel = $('#inputLinkReply2r' + (this.lengthFieldReply2r - 1)).val();
      //let valueLink = linklabel == "" ? "" : linklabel.substr(0, 4) == "http" ? linklabel : "http://" + linklabel;
      for (let i = 0; i < this.lengthFieldReply2r - 1; i++) {
        if (this.lengthFieldReply2r - 1 != i) {
          this.fieldInputLinkArrayReply2r[i] = {
            link: this.fieldInputLinkArrayReply2r[i].link == "" ? $('#inputLinkReply2r' + i).val() : this.fieldInputLinkArrayReply2r[i].link,//$('#inputLinkReply' + i).val() == "" ? $('#inputLinkReply' + i).text() : $('#inputLinkReply' + i).val() ,
            input: false,
            label: true
          };
        }
      }
      this.fieldInputLinkArrayReply2r[this.lengthFieldReply2r - 1] = {
        link: linklabel,
        input: false,
        label: true
      };

      this.fieldInputLinkArrayReply2r.push(this.newAttributeReply2r);
      $('#inputLinkReply2r' + (this.lengthFieldReply2r - 1)).val("");
      this.lengthFieldReply2r += 1;

    }
    
  }
  SaveLinkReply2r(index) {
    this.fieldInputLinkArrayReply2r[index] = {
      link: $('#inputLinkReply2r' + index).val(),
      input: true,
      label: false
    };
    
  }
  removeLinkReply2r(index) {
    this.fieldInputLinkArrayReply2r.splice(index, 1);
    this.lengthFieldReply2r -= 1;
    $('#inputLinkReply2r' + (index)).hide();
    $('#LabelLinkReply2r' + (index)).hide();
  }

  handleImageInputRDReply2r(event: any): void {
    if (true) {//this.fileImage.length < 3) {
      if (true) {//event.target.files.length <= (3 - this.fileImage.length)) {
        for (let i = 0; i < event.target.files.length; i++) {
          if (event.target.files.length <= (1 - this.fileImageReply2r.length)) { }
          //
          if (
            event.target.files[i].type == "image/png" || event.target.files[i].type == "image/jpeg" || event.target.files[i].type == "image/gif"
          ) {
            //
            var size = (event.target.files[i].size / Math.pow(1024, 2)).toFixed(2);
            //
            if (parseInt(size) < 3) {
              this.fileImageSizeReply2r = size;
              this.fileImageChangedEventReply2r = event;
              this.fileImageNameCropReply2r = event;
              //
              //

              if (event.target.files[i]) {
                this.fileImageToUploadReply2r = event.target.files[i];
                const reader: FileReader = new FileReader();
                reader.readAsDataURL(event.target.files[i]);

                reader.onload = (eventReader: any) => {
                  //
                  //this.url = eventReader.target.result;

                  if (this.fileImageReply2r.length != 0) {
                    this.fileImageReply2r = [];
                  }
                  this.fileImageReply2r.push({
                    file: event.target.files[i],
                    url: eventReader.target.result
                  });

                };
              }

              //
            } else {
              this.fileImageChangedEventReply2r = "";
              this.fileImageNameCropReply2r = "";
              $('#imgFile').val('');
              $("#modalCover").modal("hide");
              $("#fileImageSizeExceeded").modal("show");
            }
          } else {
            this.fileImageChangedEventReply2r = "";
            this.fileImageNameCropReply2r = "";
            $('#imgFile').val('');
            $("#modalCover").modal("hide");
            $("#CreateFailImage").modal("show");
          }

        }
      }
      else {
        
      }
    } else {
      
    }
  }
  removeImageReply2r(index) {
    this.fileImageReply2r.splice(index, 1);
    this.fileImageToUploadReply2r = null;
  }

  submitReplyComment2r(itemReply) {
    let linkNew = [];
    for (let i = 0; i < this.fieldInputLinkArrayReply2r.length; i++) {
      if (this.fieldInputLinkArrayReply2r[i].link != "") {
        linkNew.push(this.fieldInputLinkArrayReply2r[i].link);
      }
    }
    this.replyFormGroup.patchValue({
      projectId: this.projectId,
      projectCommentId: itemReply.projectCommentId,
      description: this.descriptionValueReply2r,
      attachFile: this.filePDFReply2r.length == 0 ? null : this.filePDFReply2r[0].file,
      attachFileSize: this.filePDFReply2r.length == 0 ? null : this.filePDFReply2r[0].size,
      attachPic: this.fileImageReply2r.length == 0 ? null : this.fileImageReply2r[0].file,
      attachLink: linkNew,
      replyTo: {
        _id: itemReply.createBy._id,
        displayName: itemReply.createBy.displayName
      },
      createBy: {
        Id: sessionStorage.getItem("_id"),
        displayName: sessionStorage.getItem("displayName"),
      }
    });

    

    if (!(this.descriptionValueReply2r == "" && this.filePDFReply2r.length == 0 && this.fileImageReply2r.length == 0 && linkNew.length == 0)) {
      this.spinner.show();
      this.commentSer.createReply(this.replyFormGroup.value, this.selectMentionReplyValue2r)
        .then(response => {
          setTimeout(() => {
            this.spinner.hide();
            if (response.status === 413) {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            } else if (response.status === 403) {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            } else if (response.status === 200) {

              let res = response.data.data[0]._id;
              
              this.getComment();

              //$('#inputCreateComment').val("");
              this.descriptionValueReply2r = "";
              this.fileImageReply2r = [];
              this.fieldInputLinkArrayReply2r = [];
              this.filePDFReply2r = [];
              this.fileImageToUploadReply2r = null;
              this.filePDFToUploadRDReply2r = null;
              this.lengthFieldReply2r = 0;
              $("#ConfirmModal").modal("hide");
              $("#CreateSuccess").modal("show");

            } else {
              $("#ConfirmModal").modal("hide");
              $("#CreateFail").modal("show");
            }
          }, 300);
        });
    }
  }

  download(filename) {
    this.commentSer.downloadfile(filename).subscribe(
      data => {
        console.error(data);
        saveAs(data, filename);
      },
      err => {
        console.error(err);
      }
    );
  }

  bindCommentlist(value) {
    if (value) {
      this.getComment();
      this.DivCreateComment = "";
      this.DivEditComment = "";
    }
  }

  closeEditPanel(value) {
    
    if (value.type == "comment") {
      this.cancelEditComment(value.id)
    }
    else if (value.type == "reply") {
      this.cancelEditReply(value.id);
    }
  }
}
