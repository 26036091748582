import { Injectable } from "@angular/core";
import * as jwt_decode from "jwt-decode";

import axios from "axios";

import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class UserServiceService {

  public authenEmployeeId: any = localStorage.getItem("EmployeeId")
  public authenRegion: any = localStorage.getItem("Region")

  /* Get Profile By Id */
  public async getProfileEmId() {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      // context.res.header('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS');
      let data = await setting.get(environment.URL_PROFILE, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      

      this.authenEmployeeId = data.data.data.employeeId;
      this.authenRegion = data.data.data.region;
      localStorage.setItem("EmployeeId", data.data.data.employeeId);
      localStorage.setItem("Region", data.data.data.region);
    } catch (err) {
      
    }
  }

  /* Get Profile By Id */
  public async getProfileUser() {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      // context.res.header('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS');
      let data = await setting.get(environment.URL_PROFILE, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      sessionStorage.setItem("displayName", data.data.data.displayName);
      sessionStorage.setItem("role", data.data.data.role);
      sessionStorage.setItem("position", data.data.data.position);
      sessionStorage.setItem("email", data.data.data.email);
      sessionStorage.setItem("_id", data.data.data._id);

      //
      return data.data.data;
    } catch (err) {
      
    }
  }

  public async getProfileByEmpId(employeeId: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        employeeId: employeeId,
      };
      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      // context.res.header('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS');
      let data = await setting.post("user/getProfileByEmpId", dataUser, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });
      return data;
    } catch (err) {
      
    }
  }

  /* Get User By Email and Projectid */
  public async getUser(projectId: string, email: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });


      let dataUser = {
        email: email,
        projectId: projectId
      };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      
      let data = await setting.post(environment.URL_USER, dataUser, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }

  /* Get User By Email and Projectid */
  public async getUpdateUser(email: string, employeeId: string, displayName: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });


      let dataUser = {
        email: email,
        employeeId: employeeId,
        displayName: displayName
      };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      
      let data = await setting.post(environment.URL_UPDATEUSER, dataUser, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }

  /* Get update By Id */
  public async updateProfileUser(dataUser: any) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      // context.res.header('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS');
      let data = await setting.post(environment.URL_USER_UPDATE, dataUser, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }


  public async getMemberByEmail(searchTxt: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let data = {
        searchTxt: searchTxt,
      };

      // let token = sessionStorage.getItem("authen");
      let token = sessionStorage.getItem("authen");

      let result = await setting.post(environment.URL_USER_GET_USER_BY_EMAIL, data, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });

      return result;
    } catch (err) {
      return err.response
    }
  }

  public async getUserRecommend(projectId) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let data = {
        projectId: projectId
      };

      let token = sessionStorage.getItem("authen");

      let result = await setting.post(environment.URL_USER_GET_USER_RECOMMEND, data, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });

      return result;
    } catch (err) {
      return err.response
    }
  }

  /* Get User By Email and Projectid */
  public async getUserById(id: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });


      let dataUser = {
        userId: id
      };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      //
      let data = await setting.post(environment.URL_USERBYID, dataUser, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }

  public async getAllUserforMention() {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let token = sessionStorage.getItem("authen");

      let result = await setting.post(environment.URL_GET_ALL_USER, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });

      return result;
    } catch (err) {
      return err.response
    }
  }

  public async sendEmail(emailData) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let token = sessionStorage.getItem("authen");

      let formData = new FormData();
      formData.append("from", emailData.from);
      formData.append("to", emailData.to);
      formData.append("subject", emailData.subject);
      formData.append("detail", emailData.detail);
      formData.append("attachments", emailData.attachments);

      let result = await setting.post(environment.URL_SEND_EMAIL, formData, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });

      return result;
    } catch (err) {
      return err.response
    }
  }

  public async updateRoleUser(data) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let token = sessionStorage.getItem("authen");

      let formData = new FormData();
      formData.append("userId", data.userId);
      formData.append("role", data.role);
      formData.append("isUser", data.isUser);
      formData.append("isAdmin", data.isAdmin);
      formData.append("isIncubator", data.isIncubator);
      formData.append("isSuperAdmin", data.isSuperAdmin);

      let result = await setting.post(environment.URL_UPDATE_ROLE_USER, formData, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });

      return result;
    } catch (err) {
      return err.response
    }
  }

  public async deleteUserById(userId) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let token = sessionStorage.getItem("authen");

      let data = {
        userId: userId,
      };

      let result = await setting.post(environment.URL_DELETE_USER_BY_ID, data, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });

      return result;
    } catch (err) {
      return err.response
    }
  }

  public async loginLog(userId, userType) {
    try {
      
      let setting = axios.create({
        baseURL: environment.URL
      });
      // let token = sessionStorage.getItem("authen")
      
      let token = sessionStorage.getItem("authen");
      let data = {
        userId: userId,
        userType: userType
      };
      
      let result = await setting.post(environment.URL_INSERT_LOGIN_LOG,data ,{
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });
      
      return result;
    } catch (err) {
      return err.response;
    }
  }
}
