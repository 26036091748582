import { Component, OnInit, Input } from "@angular/core";

// Import Service
import { UserServiceService } from "../../Services/user-service.service";
import { environment } from "../../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";

declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-slideprofile",
  templateUrl: "./slideprofile.component.html",
  styleUrls: ["./slideprofile.component.scss"]
})
export class SlideprofileComponent implements OnInit {
  @Input() data: any;

  public dataUser: any;
  public displayName: any;
  public email: any;
  public imageProfile: any;
  public imageCover: any;
  public fileName: any;
  public ownerProfile: any;
  fileToUploadProfile: File = null;
  dataProfile;
  urlHostImage = environment.URLUPLOAD;
  route: any;

  public filePDFToUploadRD: [File];
  public filePDFSize: string = "";
  public filePDFNameBlob: string = "";
  public filePDFCreate = [];

  constructor(
    private userSer: UserServiceService,
    public spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getInit();
  }

  async getInit() {

    var pageURL = window.location.href;
    var lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);
    ///

    //alert(lastURLSegment);
    let detailTabs = localStorage.getItem("EmployeeId");
    let tempData;
    tempData = await this.userSer.getProfileByEmpId(detailTabs);
    this.ownerProfile = tempData.data.data;

    if (lastURLSegment === "profileUser") {
      //alert("owner");
      let dataUser = await this.userSer.getProfileUser();
      this.dataProfile = dataUser;
      this.displayName = dataUser.displayName;
      this.imageProfile = dataUser.imgProfile;
      this.imageCover = this.urlHostImage + dataUser.imgCover;

      $('#paneluser').addClass('co-dis');
      $('#userimg').addClass('co-dis');
      $('#myimg').removeClass('co-dis');
      $('#panelowner').removeClass('co-dis');
      $('#myplus').removeClass('co-dis');
      $('#mydisplay').removeClass('co-dis');
    }else{
      //alert("user");
      let dataUser = await this.userSer.getUserById(lastURLSegment);
      console.clear();
      
      
      this.dataProfile = dataUser.data.data;
      this.displayName = dataUser.data.data.displayName;
      this.email = dataUser.data.data.email;
      this.imageProfile = dataUser.data.data.imgProfile;
      this.imageCover = this.urlHostImage + dataUser.data.data.imgCover;

      $('#panelowner').addClass('co-dis');
      $('#paneluser').removeClass('co-dis');
      $('#userimg').removeClass('co-dis');
      $('#myimg').addClass('co-dis');
      $('#myplus').addClass('co-dis');
      $('#mydisplay').addClass('co-dis');
    }

  }
  public getFileName(event) {
    this.fileName = event
  }
  public onChangeImage(event): void {
    if (
      event.file.type == "image/png" ||
      event.file.type == "image/jpeg" ||
      event.file.type == "image/jpeg" ||
      event.file.type == "image/gif"
    ) {
      this.spinner.show();

      const formData = new FormData();

      formData.append("displayName", this.dataProfile.displayName);

      formData.append("imgProfile", event.file, event.filename);

      this.userSer.updateProfileUser(formData).then(res => {
        setTimeout(() => {
          this.spinner.hide();
          if (res.status == 200) {
            window.location.reload();
          } else if (res.status == 413) {
            $("#CreateFail").modal("show");
          } else {
            $("#CreateFail").modal("show");
          }
        }, 600);
      });
    } else {
      $("#CreateFail").modal("show");
    }
  }
  public onChangeImageCover(event): void {
    if (
      event.target.files[0].type == "image/png" ||
      event.target.files[0].type == "image/jpeg" ||
      event.target.files[0].type == "image/jpeg" ||
      event.target.files[0].type == "image/gif"
    ) {
      this.spinner.show();
      const formData = new FormData();
      this.fileToUploadProfile = event.target.files[0];

      formData.append("displayName", this.dataProfile.displayName);
      formData.append("imgCover", this.fileToUploadProfile);

      this.userSer.updateProfileUser(formData).then(res => {
        setTimeout(() => {
          this.spinner.hide();
          if (res.status == 200) {
            window.location.reload();
          } else {
            $("#CreateFail").modal("show");
          }
        }, 900);
      });
    } else {
      $("#CreateFail").modal("show");
    }
  }

  public sendtUser(id,displayName){
    // alert(id);
    //  $('#ProjectModal').modal('show');
     $('#userid').val(id);
     $('#username').html(displayName);
   } 

  public openSendMessageModal() {
    $("#SendMessageModal").modal("show");
  }

  public countRowsInText(text) {

    let numerOfRowsInText = text != null ? text.split(/\n/).length : 2;
    let rows = numerOfRowsInText < 2 ? 2 : numerOfRowsInText;
    //
    return rows;
  }
  handleFilePDFInputRD(event) {
    if (
      event.target.files[0].type == "application/pdf"
      //|| event.target.files[0].type ==
      // "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      //
      var size = (event.target.files[0].size / Math.pow(1024, 2)).toFixed(2);
      //
      if (parseInt(size) < 5) {
        this.filePDFSize = size;
        this.filePDFToUploadRD = event.target.files[0];
        if (this.filePDFCreate.length != 0) {
          this.filePDFCreate = [];
        }
        this.filePDFCreate.push({
          file: event.target.files[0],
          name: event.target.files[0].name,
          size: event.target.files[0].size,
        });


      } else {
        $('#File').val('');
        this.filePDFToUploadRD = null;
        $("#filePDFSizeExceeded").modal("show");
      }
    } else {
      $('#File').val('');
      this.filePDFToUploadRD = null;
      $("#CreateFailpdf").modal("show");
    }
  }

  removefilePDFCreate(index) {
    //this.checkRemoveFileClick = true;
    this.filePDFCreate.splice(index, 1);
    this.filePDFToUploadRD = null;
  }

  public submitSendMessage() {

    let data = {
      from: this.ownerProfile.displayName,
      to: this.email,
      subject: $('#inputSubject').val(),
      detail: $('#inputDetail').val(),
      attachments: this.filePDFToUploadRD,
    }

    this.spinner.show();
    this.userSer.sendEmail(data)
      .then(response => {
        setTimeout(() => {
          this.spinner.hide();
          if (response.status === 413) {
            $("#SendMessageModal").modal("hide");
           // $("#CreateFail").modal("show");
          } else if (response.status === 403) {
            $("#SendMessageModal").modal("hide");
            //$("#CreateFail").modal("show");
          } else if (response.status === 200) {

            let res = response.data;
            

            $('#inputSubject').val('');
            $('#inputDetail').val('');
            this.filePDFToUploadRD=null;

            $("#SendMessageModal").modal("hide");
            $("#successSendEmailModal").modal("show");

          } else {
            $('#inputSubject').val('');
            $('#inputDetail').val('');
            this.filePDFToUploadRD=null;
            

            $("#SendMessageModal").modal("hide");
            //$("#CreateFail").modal("show");
          }
        }, 300);
      });
  }
}
