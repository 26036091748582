import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';

import { JoinServiceService } from '../../../Services/join-service.service'

// IMPORT SERVICES
import { ProjectideaServiceService } from '../../../Services/projectidea-service.service';

// use Jquery
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-join-team-modal',
  templateUrl: './join-team-modal.component.html',
  styleUrls: ['./join-team-modal.component.scss']
})
export class JoinTeamModalComponent implements OnInit {
  requestStatus = false;

  @Input() token : any;
  @Input() project : any;


  modalJoinToken: any;
  modalJoinTeam: any;
  subscription: Subscription;

  constructor(private join: JoinServiceService) { 

    this.subscription = this.join.getMessage().subscribe(message => { this.modalJoinToken = message; });
  }

  ngOnInit() {
    
    

    // this.modalJoinToken = this.join.getModalJoinToken()
    // this.modalJoinTeam = this.join.getModalJoinTeam()
  }

  public onJointoken(): void {
    this.requestStatus = true;
    $('#joinTeamModal').modal('hide')
  }

}
