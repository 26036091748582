import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";

// Import Service
import { FillterService } from "../../../../Services/fillter.service";
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";


declare var $: any;

@Component({
  selector: 'app-fillter-button',
  templateUrl: './fillter-button.component.html',
  styleUrls: ['./fillter-button.component.scss']
})
export class FillterButtonComponent implements OnInit {

  @Input() typeNameInput: any;
  @Input() regionNameInput: any;
  cate: string;
  region: string;
  itemCategory: any;
  

  public checkBack = false;

  public checkNext = true;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
    private fillterServ: FillterService,
    private _location: Location,
  ) { }

  ngOnInit() {
    this.getInit();
    this.dropdown();
  }

  async getInit(){

    this.cate = this.typeNameInput
    this.region = this.regionNameInput

    $('.noscroll').on( 'mousewheel DOMMouseScroll', function (e) { 
  
      var e0 = e.originalEvent;
      var delta = e0.wheelDelta || -e0.detail;
    
      this.scrollTop += ( delta < 0 ? 1 : -1 ) * 30;
      e.preventDefault();  
    });

    var pageURL = window.location.href;
    var lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);

    //alert(lastURLSegment);

    // if(lastURLSegment === "AllIdeas"){
    //   $('#searcdincubator').addClass('co-dis');
    //   $('#searchall').removeClass('co-dis');
    //   $('#dropallone').removeClass('co-dis');
    //   $('#dropalltwo').removeClass('co-dis');
    // }else if(lastURLSegment === "incubator"){
    //   $('#searchall').addClass('co-dis');
    //   $('#dropallone').addClass('co-dis');
    //   $('#dropalltwo').addClass('co-dis');
    //   $('#searcdincubator').removeClass('co-dis');
    // }

    let arrCat = await this.projectSer.getCategory();
    this.itemCategory = arrCat.data.data
    // 
    

  }
  onChangeType(cate) {
    this.cate = cate;
    
    this.router.navigate(['/AllProject', this.region, this.cate]);
  }

  onChangeRegion(region) {
    this.region = region;
    this.router.navigate(['/AllProject', this.region, this.cate]);
  }
  public onKey(event: any) {

    let searchData = "";

    searchData += event.target.value;

    this.fillterServ.setFilter(searchData);

    if (searchData.length > 0) {
      // set back 1 time
      this.checkBack = false;
      this.router.navigate(["/AllProject/Allregion/AllIdeas"]);
    }
    
    //  else if (searchData.length == 0 && this.checkBack == false) {
    //   this.checkBack = true;
    //   this._location.back();
    // }
  }

  public onKeyincubator(event: any) {

    let searchData = "";

    searchData += event.target.value;

    this.fillterServ.setFilter(searchData);

    if (searchData.length > 0) {
      // set back 1 time
      this.checkBack = false;
      this.router.navigate(["/incubator"]);
    }
    
    //  else if (searchData.length == 0 && this.checkBack == false) {
    //   this.checkBack = true;
    //   this._location.back();
    // }
  }

  // dropdown =========================
  public dropdown() {
    var $container = $('.dropdown-menu'),
      $list = $('.dropdown-menu ul'),
      listItem = $list.find('li');
      
    $(".dropdown .title").click(function () {
      //
      if ($container.height() > 58) {
        closeMenu(this);
      } else {
        openMenu(this);
      }
    });

    $(".dropdown-menu li").click(function () {
      closeMenu(this);
    });

    function closeMenu(el) {
      $(el).closest('.dropdown').toggleClass("closed").find(".title").text($(el).text());
      $container.css("height", 0);
      $list.css("top", 0);
      
    }

    function openMenu(el) {
      //
      $(el).parent().toggleClass("closed");
      
      $container.css({
        height: 200
      })
        .mousemove(function (e) {
          
          var heightDiff = $list.height() / $container.height(),
            offset = $container.offset(),
            relativeY = (e.pageY - offset.top),
            top = relativeY * heightDiff > $list.height() - $container.height() ?
              $list.height() - $container.height() : relativeY * heightDiff;

          $list.css("top", -top);
        });
    }
  }
}
