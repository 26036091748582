import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Http, ResponseContentType } from '@angular/http';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { environment } from "../../../../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { saveAs } from "file-saver";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MentionList } from 'ng-mentions';

// Import Service
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";
import { UserServiceService } from "../../../../Services/user-service.service";
import { CommentServiceService } from "../../../../Services/comment-service.service";
import { flatten } from '@angular/compiler';
import { modalConfigDefaults } from 'ngx-bootstrap/modal/modal-options.class';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-comment-box-incubator',
  templateUrl: './comment-box-incubator.component.html',
  styleUrls: ['./comment-box-incubator.component.scss']
})
export class CommentBoxIncubatorComponent implements OnInit {

  //@Input() comment: any;
  @Input() type: any;
  @Input() projectId: any;
  @Input() reply: any;
  @Input() ownerProfile: any;
  @Input() itemComment: any;
  @Input() itemReply: any;
  @Output() bindComment: EventEmitter<boolean> = new EventEmitter();
  @Output() closeEdit: EventEmitter<{}> = new EventEmitter();

  public urlHostImage = environment.URLUPLOAD;
  public userList;
  public model: any = {
      value: '',
      mentions: []
  };
  public selectMentionCommentValue = [];
  // public selectMentionCommentEditValue = [];
  // public selectMentionReplyValue = [];
  // public selectMentionReplyEditValue = [];
  // public selectMentionReplyValue2r = [];

  public commentFormGroup: FormGroup;
  public replyFormGroup: FormGroup;
  public commentEditFormGroup: FormGroup;
  public replyEditFormGroup: FormGroup;
  
  public fieldInputLinkcreateArray: Array<any> = [];
  public lengthField = 0;
  public link = [];
  public labelLinkindex;
  public indexOldEdit;
  public descriptionValue: any = "";

  public filePDFToUploadRD: [File];
  public filePDFSize: string = "";
  public filePDFNameBlob: string = "";
  public filePDFCreate = [];

  public fileImageToUpload: [File];
  public fileImageNameCrop;
  public fileImageChangedEvent;
  public fileImageSize;
  //public fileImage = [{url:"../../assets/images/action-plan-brainstorming-complex-212286@2x.png"},{url:"../../assets/images/adult-architect-architectural-design-1260309@2x.png"},{url:"../../assets/images/test2.png"}];
  public fileImageCreate = [];

  public newAttribute: any = { link: "", input: true, label: false };
  public checkRemoveFileClick = false;


  //////////// Fillter Mention ////////////
  //@ViewChild('userMentions', { read: MentionList }) userMentions: MentionList;
  // @ViewChild('userMentionsReply', {read: MentionList}) userMentionsReply: MentionList;

  @ViewChild(MentionList) userMentions: MentionList;
  public matches$;

  ////////////////////////////////////////

  constructor(private router: Router,
      private route: ActivatedRoute,
      private projectSer: ProjectideaServiceService,
      private userSer: UserServiceService,
      private commentSer: CommentServiceService,
      private _location: Location,
      public spinner: NgxSpinnerService,
      private formBuilder: FormBuilder,
      private http: Http
  ) { }

  //ngAfterViewInit() {
  ngOnInit() {
      this.getMentionList();
      //////////// Fillter Mention ////////////
      this.matches$ = this.userMentions.mentionTerm$.pipe(
          map(t => this.model.mentions.filter(p => p.display.toLowerCase().indexOf(t.toLowerCase()) > -1 && this.selectMentionCommentValue.findIndex(y => y.displayName == p.display) == -1 && p.display != this.ownerProfile.displayName)
          )
      );
      ////////////////////////////////////////


      this.getInit();
      this.getEditForm();
  }

  
  async getInit() {
      this.commentFormGroup = this.formBuilder.group({
          projectId: [""],
          description: [""],
          attachFile: [File],
          attachFileSize: [''],
          attachPic: [File],
          attachLink: [""],
          createBy: [""],
          category:[""],
      });

      this.replyFormGroup = this.formBuilder.group({
          projectId: [""],
          projectCommentId: [""],
          description: [""],
          attachFile: [File],
          attachFileSize: [''],
          attachPic: [File],
          attachLink: [""],
          replyTo: [""],
          createBy: [""],
          category:[""],
      });
      this.commentEditFormGroup = this.formBuilder.group({
          commentId: [""],
          projectId: [""],
          description: [""],
          attachFile: [File],
          attachFileSize: [''],
          attachPic: [File],
          attachLink: [""],
          createBy: [""],
          category:[""],
        });
        this.replyEditFormGroup = this.formBuilder.group({
          replyId: [""],
          projectId: [""],
          projectCommentId: [""],
          description: [""],
          attachFile: [File],
          attachFileSize: [''],
          attachPic: [File],
          attachLink: [""],
          category:[""],
        });
  }

  getEditForm() {

      if (this.type == "editComment") {
          this.getDataEditComment(this.itemComment);
      }
      if (this.type == "editReply") {
          this.getDataEditReply(this.itemReply);
      }
  }

  getDataEditComment(itemComment) {
      //link
      
      this.fieldInputLinkcreateArray = [];
      for (let i = 0; i < itemComment.attachLink.length; i++) {
          if (itemComment.attachLink[i].url) {
              this.fieldInputLinkcreateArray.push({
                  IdComment: itemComment._id,
                  link: itemComment.attachLink[i].url,
                  input: false,
                  label: true
              });
          }
      }
      //description
      this.descriptionValue = itemComment.description;
      //image
      if (itemComment.attachPic.path != null) {
          this.fileImageCreate = [];
          this.fileImageCreate.push({
              IdComment: itemComment._id,
              file: null,
              url: itemComment.attachPic.path
          });
          this.fileImageToUpload = itemComment.attachPic.path;
      }
      //file
      if (itemComment.attachFile.path != null) {
          this.filePDFCreate = [];
          this.filePDFCreate.push({
              IdComment: itemComment._id,
              file: null,
              name: itemComment.attachFile.name,
              size: itemComment.attachFile.fileSize,
          });
          this.filePDFToUploadRD = itemComment.attachFile.name;
      }
  }

  getDataEditReply(itemReply) {
      //link
      this.fieldInputLinkcreateArray = [];
      for (let i = 0; i < itemReply.attachLink.length; i++) {
          if (itemReply.attachLink[i].url) {
              this.fieldInputLinkcreateArray.push({
                  IdReply: itemReply._id,
                  link: itemReply.attachLink[i].url,
                  input: false,
                  label: true
              });
          }
      }
      //description
      this.descriptionValue = itemReply.description;
      //image
      if (itemReply.attachPic.path != null) {
          this.fileImageCreate = [];
          this.fileImageCreate.push({
              IdReply: itemReply._id,
              file: null,
              url: itemReply.attachPic.path
          });
      }
      //file
      if (itemReply.attachFile.path != null) {
          this.filePDFCreate = [];
          this.filePDFCreate.push({
              IdReply: itemReply._id,
              file: null,
              name: itemReply.attachFile.name,
              size: itemReply.attachFile.fileSize,
          });
      }
  }

  cancelEditComment(commentId) {
      
      this.closeEdit.emit({
          id: commentId,
          type :"reply"
      });
  }

  cancelEditReply(replyId) {
      
      this.closeEdit.emit({
          id: replyId,
          type :"reply"
      });
  }

  async getMentionList() {
      let user = await this.userSer.getAllUserforMention();
      this.userList = user.data.data;
      for (let i = 0; i < this.userList.length; i++) {
          this.model.mentions.push({
              display: this.userList[i].displayName,
              name: this.userList[i].firstName,
              employeeId: this.userList[i].employeeId,
              email: this.userList[i].email,
              type: "user",
              icon: this.userList[i].imgProfile
          });
      }
  }

  selectMention(mention) {
      var text = $("#inputDescription").val();
      this.selectMentionCommentValue.push({
          displayName: mention.display,
          name: mention.name,
          employeeId: mention.employeeId,
          email: mention.email,
          indexS: text.indexOf("@" + mention.name),
          indexE: text.lastIndexOf("@" + mention.name) + mention.name.length
      });


      
  }

  checkMention(event) {
      //
      var text = $("#inputDescription").val();
      var cutText = text.split("@");
      for (let i = 0; i < this.selectMentionCommentValue.length; i++) {
          if (!text.includes(this.selectMentionCommentValue[i].name)) {
              var txtSlice = text.slice(this.selectMentionCommentValue[i].indexS, this.selectMentionCommentValue[i].indexE);
              var txtReplace = text.replace(txtSlice, '');
              $("#inputDescription").val(txtReplace);

              this.selectMentionCommentValue.splice(i, 1);
          }
          else { // Update Index Mention
              this.selectMentionCommentValue[i] = {
                  displayName: this.selectMentionCommentValue[i].displayName,
                  name: this.selectMentionCommentValue[i].name,
                  employeeId: this.selectMentionCommentValue[i].employeeId,
                  email: this.selectMentionCommentValue[i].mentionEmail,
                  indexS: text.indexOf("@" + this.selectMentionCommentValue[i].name),
                  indexE: text.lastIndexOf("@" + this.selectMentionCommentValue[i].name) + this.selectMentionCommentValue[i].name.length
              }
          }
      }
      //
  }

  public countRowsInText(text) {

      let numerOfRowsInText = text != null ? text.split(/\n/).length : 2;
      let rows = numerOfRowsInText < 2 ? 2 : numerOfRowsInText;
      //
      return rows;
  }

  public handleFilePDFInputRD(event) {
      if (true) {//(this.filePDFCreate.length < 1) {
          if (true) {//(event.target.files.length <= (1 - this.filePDFCreate.length)) {
              for (let i = 0; i < event.target.files.length; i++) {
                  if (
                      event.target.files[i].type == "application/pdf"
                      //|| event.target.files[0].type ==
                      // "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                  ) {
                      //
                      var size = (event.target.files[i].size / Math.pow(1024, 2)).toFixed(2);
                      //
                      if (parseInt(size) < 5) {
                          this.filePDFSize = size;
                          this.filePDFToUploadRD = event.target.files[i];

                          if (this.filePDFCreate.length != 0) {
                              this.filePDFCreate = [];
                          }
                          this.filePDFCreate.push({
                              file: event.target.files[i],
                              name: event.target.files[i].name,
                              size: event.target.files[i].size,
                          });


                      } else {
                          $('#File').val('');
                          this.filePDFToUploadRD = null;
                          $("#filePDFSizeExceeded").modal("show");
                      }
                  } else {
                      $('#File').val('');
                      this.filePDFToUploadRD = null;
                      $("#CreateFailpdf").modal("show");
                  }
              }
              
          }
          else {
              
          }
      } else {
          
      }
      //
  }

  removefilePDFCreate(index) {
      this.checkRemoveFileClick = true;
      this.filePDFCreate.splice(index, 1);
      this.filePDFToUploadRD = null;
  }

  handleImageInputRD(event: any): void {//true){//
      if (true) {//this.fileImage.length < 3) {
          if (true) {//event.target.files.length <= (3 - this.fileImage.length)) {
              for (let i = 0; i < event.target.files.length; i++) {
                  if (event.target.files.length <= (1 - this.fileImageCreate.length)) { }
                  //
                  if (
                      event.target.files[i].type == "image/png" || event.target.files[i].type == "image/jpeg" || event.target.files[i].type == "image/gif"
                  ) {
                      //
                      var size = (event.target.files[i].size / Math.pow(1024, 2)).toFixed(2);
                      //
                      if (parseInt(size) < 3) {
                          this.fileImageSize = size;
                          this.fileImageChangedEvent = event;
                          this.fileImageNameCrop = event;
                          //
                          //

                          if (event.target.files[i]) {
                              this.fileImageToUpload = event.target.files[i];
                              const reader: FileReader = new FileReader();
                              reader.readAsDataURL(event.target.files[i]);

                              reader.onload = (eventReader: any) => {
                                  //
                                  //this.url = eventReader.target.result;

                                  if (this.fileImageCreate.length != 0) {
                                      this.fileImageCreate = [];
                                  }
                                  this.fileImageCreate.push({
                                      file: event.target.files[i],
                                      url: eventReader.target.result
                                  });
                              };
                          }

                          //
                      } else {
                          this.fileImageChangedEvent = "";
                          this.fileImageNameCrop = "";
                          $('#imgFile').val('');
                          $("#modalCover").modal("hide");
                          $("#fileImageSizeExceeded").modal("show");
                      }
                  } else {
                      this.fileImageChangedEvent = "";
                      this.fileImageNameCrop = "";
                      $('#imgFile').val('');
                      $("#modalCover").modal("hide");
                      $("#CreateFailImage").modal("show");
                  }

              }
          }
          else {
              
          }
      } else {
          
      }
  }

  removeImageCreate(index) {
      this.fileImageCreate.splice(index, 1);
      this.fileImageToUpload = null;
  }

  addFieldLinkCreate() {
      var fieldid = this.lengthField - 1;
      if (this.lengthField == 0) {
          this.fieldInputLinkcreateArray.push(this.newAttribute);
          this.lengthField += 1;
      }
      else {
          let linklabel = $('#inputLink' + (this.lengthField - 1)).val();
          //let valueLink = linklabel == "" ? "" : linklabel.substr(0, 4) == "http" ? linklabel : "http://" + linklabel;
          for (let i = 0; i < this.lengthField - 1; i++) {
              if (this.lengthField - 1 != i) {
                  this.fieldInputLinkcreateArray[i] = {
                      link: this.fieldInputLinkcreateArray[i].link == "" ? $('#inputLink' + i).val() : this.fieldInputLinkcreateArray[i].link,//$('#inputLink' + i).val() == "" ? $('#LabelLink' + i).text() : $('#inputLink' + i).val() ,
                      input: false,
                      label: true
                  };
              }
          }
          this.fieldInputLinkcreateArray[this.lengthField - 1] = {
              link: linklabel,
              input: false,
              label: true
          };

          this.fieldInputLinkcreateArray.push(this.newAttribute);
          $('#inputLink' + (this.lengthField - 1)).val("");
          this.lengthField += 1;

      }
  }

  editLinkCreate(index) {

  }

  SaveLinkCreate(index) {
      if ($('#inputLink' + index).val() != "") {
          this.fieldInputLinkcreateArray[index] = {
              link: $('#inputLink' + index).val(),
              input: true,
              label: false
          };
      }
      
  }

  hideLinkTextbox() {

  }
  addLinkCreate() {
      //this.link.push();

  }

  removeLinkCreate(index) {
      this.fieldInputLinkcreateArray.splice(index, 1);
      this.lengthField -= 1;
      $('#inputLink' + (index)).hide();
      $('#LabelLink' + (index)).hide();
  }

  submit() {
      if (this.type == "createComment") {
          this.createcomment();
      }
      else if (this.type == "createReply") {
          this.replyComment();
      }
      else if (this.type == "createReplyToReply") {
          this.replyToReplyComment();
      }
      else if (this.type == "editComment") {
          this.editComment();
      }
      else if (this.type == "editReply") {
          this.editReply();
      }
  }

  createcomment() {
      const id = this.route.snapshot.paramMap.get("id");

      let linkNew = [];
      for (let i = 0; i < this.fieldInputLinkcreateArray.length; i++) {
          if (this.fieldInputLinkcreateArray[i].link != "") {
              linkNew.push(this.fieldInputLinkcreateArray[i].link)
          }
      }

      if (!this.projectId) {
          this.projectId = id;
      }

      //alert(this.projectId);

      this.commentFormGroup.patchValue({
          projectId: this.projectId,
          description: this.descriptionValue,
        //   description: this.descriptionValue.replace(/\r\n|\r|\n/g,"<br>"), // comment with html <br>
          attachFile: this.filePDFCreate.length == 0 ? null : this.filePDFCreate[0].file,
          attachFileSize: this.filePDFCreate.length == 0 ? null : this.filePDFCreate[0].size,
          attachPic: this.fileImageCreate.length == 0 ? null : this.fileImageCreate[0].file,
          attachLink: linkNew,
          createBy: {
              Id: sessionStorage.getItem("_id"),
              displayName: sessionStorage.getItem("displayName"),
          },
          category:"incubator"
      });

      if (!(this.descriptionValue == "" && this.filePDFCreate.length == 0 && this.fileImageCreate.length == 0 && linkNew.length == 0)) {
          this.spinner.show();
          this.commentSer.createCommentIncubator(this.commentFormGroup.value, this.selectMentionCommentValue)
              .then(response => {
                  setTimeout(() => {
                      this.spinner.hide();
                      if (response.status === 413) {
                          $("#ConfirmModal").modal("hide");
                          $("#CreateFail").modal("show");
                      } else if (response.status === 403) {
                          $("#ConfirmModal").modal("hide");
                          $("#CreateFail").modal("show");

                      } else if (response.status === 200) {

                          let res = response.data.data[0]._id;
                          
                          this.bindComment.emit(true);
                          //$('#inputDescription').val("");
                          this.descriptionValue = "";
                          this.fileImageCreate = [];
                          this.fieldInputLinkcreateArray = [];
                          this.filePDFCreate = [];
                          this.fileImageToUpload = null;
                          this.filePDFToUploadRD = null;
                          this.lengthField = 0;
                          $("#ConfirmModal").modal("hide");
                          $("#CreateSuccess").modal("show");

                      } else {
                          $("#ConfirmModal").modal("hide");
                          $("#CreateFail").modal("show");
                      }
                  }, 300);
              });

      }
  }

  replyComment() {

      let linkNew = [];
      for (let i = 0; i < this.fieldInputLinkcreateArray.length; i++) {
          if (this.fieldInputLinkcreateArray[i].link != "") {
              linkNew.push(this.fieldInputLinkcreateArray[i].link);
          }
      }
      this.replyFormGroup.patchValue({
          projectId: this.projectId,
          projectCommentId: this.itemComment._id,
          description: this.descriptionValue,
        //   description: this.descriptionValue.replace(/\r\n|\r|\n/g,"<br>"), // comment with html <br>
          attachFile: this.filePDFCreate.length == 0 ? null : this.filePDFCreate[0].file,
          attachFileSize: this.filePDFCreate.length == 0 ? null : this.filePDFCreate[0].size,
          attachPic: this.fileImageCreate.length == 0 ? null : this.fileImageCreate[0].file,
          attachLink: linkNew,
          replyTo: {
              _id: this.itemComment.createBy._id,
              displayName: this.itemComment.createBy.displayName
          },
          createBy: {
              Id: sessionStorage.getItem("_id"),
              displayName: sessionStorage.getItem("displayName"),
          },
          category:"incubator"
      });

      

      if (!(this.descriptionValue == "" && this.filePDFCreate.length == 0 && this.fileImageCreate.length == 0 && linkNew.length == 0)) {
          this.spinner.show();
          this.commentSer.createReplyIncubator(this.replyFormGroup.value, this.selectMentionCommentValue)
              .then(response => {
                  setTimeout(() => {
                      this.spinner.hide();
                      if (response.status === 413) {

                      } else if (response.status === 403) {

                      } else if (response.status === 200) {

                          let res = response.data.data[0]._id;
                          
                          this.bindComment.emit(true);

                          //$('#inputDescription').val("");
                          this.descriptionValue = "";
                          this.fileImageCreate = [];
                          this.fieldInputLinkcreateArray = [];
                          this.filePDFCreate = [];
                          this.fileImageToUpload = null;
                          this.filePDFToUploadRD = null;
                          this.lengthField = 0;

                      } else {

                      }
                  }, 300);

                  // let resReply = response.data.data[0]._id;
                  // var element = document.getElementById(resReply);
                  // alert(resReply);
                  // element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});

              });
      }
  }

  replyToReplyComment() {
      let linkNew = [];
      for (let i = 0; i < this.fieldInputLinkcreateArray.length; i++) {
          if (this.fieldInputLinkcreateArray[i].link != "") {
              linkNew.push(this.fieldInputLinkcreateArray[i].link);
          }
      }
      this.replyFormGroup.patchValue({
          projectId: this.projectId,
          projectCommentId: this.itemReply.projectCommentId,
        //   description: this.descriptionValue.replace(/\r\n|\r|\n/g,"<br>"), // comment with html <br>
          description: this.descriptionValue,
          attachFile: this.filePDFCreate.length == 0 ? null : this.filePDFCreate[0].file,
          attachFileSize: this.filePDFCreate.length == 0 ? null : this.filePDFCreate[0].size,
          attachPic: this.fileImageCreate.length == 0 ? null : this.fileImageCreate[0].file,
          attachLink: linkNew,
          replyTo: {
              _id: this.itemReply.createBy._id,
              displayName: this.itemReply.createBy.displayName
          },
          createBy: {
              Id: sessionStorage.getItem("_id"),
              displayName: sessionStorage.getItem("displayName"),
          },
          category:"incubator"
      });

      

      if (!(this.descriptionValue == "" && this.filePDFCreate.length == 0 && this.fileImageCreate.length == 0 && linkNew.length == 0)) {
          this.spinner.show();
          this.commentSer.createReplyIncubator(this.replyFormGroup.value, this.selectMentionCommentValue)
              .then(response => {
                  setTimeout(() => {
                      this.spinner.hide();
                      if (response.status === 413) {
                          $("#ConfirmModal").modal("hide");
                          $("#CreateFail").modal("show");
                      } else if (response.status === 403) {
                          $("#ConfirmModal").modal("hide");
                          $("#CreateFail").modal("show");
                      } else if (response.status === 200) {

                          let res = response.data.data[0]._id;
                          
                          this.bindComment.emit(true);

                          //$('#inputDescription').val("");
                          this.descriptionValue = "";
                          this.fileImageCreate = [];
                          this.fieldInputLinkcreateArray = [];
                          this.filePDFCreate = [];
                          this.fileImageToUpload = null;
                          this.filePDFToUploadRD = null;
                          this.lengthField = 0;
                          $("#ConfirmModal").modal("hide");
                          $("#CreateSuccess").modal("show");

                      } else {
                          $("#ConfirmModal").modal("hide");
                          $("#CreateFail").modal("show");
                      }
                  }, 300);
              });
      }
  }

  editComment(){
      let linkNew = [];
      for (let i = 0; i < this.fieldInputLinkcreateArray.length; i++) {
        if (this.fieldInputLinkcreateArray[i].link != "") {
          linkNew.push(this.fieldInputLinkcreateArray[i].link);
        }
      }
      this.commentEditFormGroup.patchValue({
        commentId: this.itemComment._id,
        projectId: this.projectId,
        description: $('#inputDescription').val(),
        // attachFile: this.filePDFEditToUploadRD == null
        //   ? null
        //   : this.filePDFEditToUploadRD,
  
        // attachFileSize: this.filePDFEdit.length == 0
        //   ? null
        //   : this.filePDFEdit[0].size,
  
        // attachPic: this.fileImageEditToUpload == null
        //   ? null
        //   : this.fileImageEditToUpload,
        attachFile: this.filePDFToUploadRD == null && this.filePDFCreate.length == 0
          ? 'NoFile'
          : this.filePDFToUploadRD != null && this.filePDFCreate.length == 0
            ? this.filePDFToUploadRD
            : this.filePDFToUploadRD == null && this.filePDFCreate.length != 0
              ? this.filePDFCreate[0].file
              : this.filePDFToUploadRD != null && this.filePDFCreate.length != 0
                ? this.filePDFCreate[0].file
                : 'NoFile',
  
        attachFileSize: this.filePDFToUploadRD == null && this.filePDFCreate.length == 0
          ? 'NoFile'
          : this.filePDFToUploadRD != null && this.filePDFCreate.length == 0
            ? this.filePDFToUploadRD
            : this.filePDFToUploadRD == null && this.filePDFCreate.length != 0
              ? this.filePDFCreate[0].size
              : this.filePDFToUploadRD != null && this.filePDFCreate.length != 0
                ? this.filePDFCreate[0].size
                : 'NoFile',
  
        attachPic: this.fileImageToUpload == null && this.fileImageCreate.length == 0
          ? 'NoImage'
          : this.fileImageToUpload != null && this.fileImageCreate.length == 0
            ? this.fileImageToUpload
            : this.fileImageToUpload == null && this.fileImageCreate.length != 0
              ? this.fileImageCreate[0].file
              : this.fileImageToUpload != null && this.fileImageCreate.length != 0
                ? this.fileImageCreate[0].file
                : 'NoImage',
  
        attachLink: linkNew,
        createBy: {
          Id: sessionStorage.getItem("_id"),
          displayName: sessionStorage.getItem("displayName"),
        }
      });
  
      
  
      if (!($('#inputDescription').val() == "" && this.filePDFCreate.length == 0 && this.fileImageCreate.length == 0 && linkNew.length == 0)) {
        this.spinner.show();
        this.commentSer.editComment(this.commentEditFormGroup.value, this.selectMentionCommentValue)
          .then(response => {
            setTimeout(() => {
              this.spinner.hide();
              if (response.status === 413) {
                $("#ConfirmModal").modal("hide");
                $("#CreateFail").modal("show");
              } else if (response.status === 403) {
                $("#ConfirmModal").modal("hide");
                $("#CreateFail").modal("show");
              } else if (response.status === 200) {
  
                let res = response.data;
                
                this.descriptionValue = "";
                this.filePDFToUploadRD = null;
                this.filePDFCreate = [];
                this.fileImageToUpload = null;
                this.fileImageCreate = [];
                this.fieldInputLinkcreateArray = [];
                this.lengthField = 0;

                this.bindComment.emit(true);
  
                //$('#inputDescription').val("");
                $("#ConfirmModal").modal("hide");
                $("#CreateSuccess").modal("show");
  
              } else {
                $("#ConfirmModal").modal("hide");
                $("#CreateFail").modal("show");
              }
            }, 300);
          });
      }
  }

  editReply(){
      let linkNew = [];
      for (let i = 0; i < this.fieldInputLinkcreateArray.length; i++) {
        if (this.fieldInputLinkcreateArray[i].link != "") {
          linkNew.push(this.fieldInputLinkcreateArray[i].link);
        }
      }
      
      this.replyEditFormGroup.patchValue({
        replyId: this.itemReply._id,
        projectId: this.projectId,
        projectCommentId: this.itemReply.projectCommentId,
        description: $('#inputDescription').val(),
  
        attachFile: this.filePDFToUploadRD == null && this.filePDFCreate.length == 0
          ? 'NoFile'
          : this.filePDFToUploadRD != null && this.filePDFCreate.length == 0
            ? this.filePDFToUploadRD
            : this.filePDFToUploadRD == null && this.filePDFCreate.length != 0
              ? this.filePDFCreate[0].file
              : this.filePDFToUploadRD != null && this.filePDFCreate.length != 0
                ? this.filePDFCreate[0].file
                : 'NoFile',
  
        attachFileSize: this.filePDFToUploadRD == null && this.filePDFCreate.length == 0
          ? 'NoFile'
          : this.filePDFToUploadRD != null && this.filePDFCreate.length == 0
            ? this.filePDFToUploadRD
            : this.filePDFToUploadRD == null && this.filePDFCreate.length != 0
              ? this.filePDFCreate[0].size
              : this.filePDFToUploadRD != null && this.filePDFCreate.length != 0
                ? this.filePDFCreate[0].size
                : 'NoFile',
  
        attachPic: this.fileImageToUpload == null && this.fileImageCreate.length == 0
          ? 'NoImage'
          : this.fileImageToUpload != null && this.fileImageCreate.length == 0
            ? this.fileImageToUpload
            : this.fileImageToUpload == null && this.fileImageCreate.length != 0
              ? this.fileImageCreate[0].file
              : this.fileImageToUpload != null && this.fileImageCreate.length != 0
                ? this.fileImageCreate[0].file
                : 'NoImage',
  
        attachLink: linkNew,
  
  
      });
      
      
  
      if (!($('#inputDescription').val() == "" && this.filePDFCreate.length == 0 && this.fileImageCreate.length == 0 && linkNew.length == 0)) {
        this.spinner.show();
        this.commentSer.editReply(this.replyEditFormGroup.value)
          .then(response => {
            setTimeout(() => {
              this.spinner.hide();
              if (response.status === 413) {
                $("#ConfirmModal").modal("hide");
                $("#CreateFail").modal("show");
              } else if (response.status === 403) {
                $("#ConfirmModal").modal("hide");
                $("#CreateFail").modal("show");
              } else if (response.status === 200) {
  
                let res = response.data;
                
                this.descriptionValue = "";
                this.filePDFToUploadRD = null;
                this.filePDFCreate = [];
                this.fileImageToUpload = null;
                this.fileImageCreate = [];
                this.fieldInputLinkcreateArray = [];
                this.lengthField = 0;

                this.bindComment.emit(true);
  
                //$('#inputDescription').val("");
                $("#ConfirmModal").modal("hide");
                $("#CreateSuccess").modal("show");
  
              } else {
                $("#ConfirmModal").modal("hide");
                $("#CreateFail").modal("show");
              }
            }, 300);
          });
      }
  }

}
