import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";

// Import Service
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";



declare var $: any;
declare var jQuery: any;
declare var $scope: any;


@Component({
  selector: 'app-mension',
  templateUrl: './mension.component.html',
  styleUrls: ['./mension.component.scss'],
})

export class MensionComponent implements OnInit {

  choices: any;
  mentionConfig: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
    private _location: Location,
    
  ) { }

  ngOnInit() {

    // this.choices = [{ first: 'bob', last: 'barker', id: 11123 }, { first: 'kenny', last: 'logins', id: '123ab-123' }, { first: 'kyle', last: 'corn', id: '123' }, { first: 'steve', last: 'rodriguez', id: 'hi' }, { first: 'steve', last: 'holt', id: '0-9' }, { first: 'megan', last: 'burgerpants', id: 'ab-' }];
    // 

    let user = [];
    let arr = "";
    this.projectSer.getUserMension().then(res => {
      
        for(let i =0; i < res.data.data.length; i++){
          user[i] = res.data.data[i].displayName+".";
        }

        this.mentionConfig = {
          items: user,
          triggerChar: "@",
          maxItems:15
        }
    });
  
  }

  public checkPin(){
    var text = $("#mensionText").val();

    $('#textShow').html(text);

    var cutText = text.split("@");

    

    $('#textCutShow').html(cutText);

    
  }

}
