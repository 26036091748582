import { Component, OnInit, Input } from '@angular/core';
import { environment } from "../../../../../environments/environment";
import { ActivatedRoute } from "@angular/router";

// IMPORT SERVICES
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";
import { empty } from 'rxjs';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  urlHostImage = environment.URLUPLOAD;
  arrTimline: any;
  FinalWidth: any;
  maxTrue: any;
  percent:any;

  constructor(
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
  ) { }

  ngOnInit() {
    this.getInit();
  }

  async getInit(){

    const id = this.route.snapshot.paramMap.get("id");

    let arr = await this.projectSer.getProjectStatusForTimeline(id);
    this.arrTimline = arr.data.data[0];
    
    let lengthCh = this.arrTimline.checkList.length;

    let minWidth = 100/lengthCh;
    let rsWidth = (minWidth * 10)+15;
    let FinalWidth = rsWidth.toFixed(0);
    this.FinalWidth = FinalWidth+"px";

    this.percent = minWidth.toFixed(0);
    let arrMax = [];
    let arrSorta = [];

    for(let i=0; i<lengthCh;  i++){
      let no = i+1;
      if(this.arrTimline.checkList[i].isAchieve === true){
        arrMax.push({
          arr : this.arrTimline.checkList[i]
        })

        arrSorta.push({
          arr : this.arrTimline.checkList[i]
        })
      }

      // for(let y =0; y<arrSorta.length; y++){
      //   if(this.arrTimline.checkList[i]._id === arrSorta[i][y].arr._id){
      //     
      //   }
      // }
    }

    
    let dsd = arrMax.reverse();
    
    this.maxTrue =  dsd[0].arr.seq ;
    //this.maxTrue = dsd.length!= 0 ? dsd[0].arr.seq : '';

    

    
    
 
    //  
    //  

    // let FinalArray = [];

    // this.arrTimline.checkList.forEach(element => {
      
    //   FinalArray.push({
    //     data:element
    //   });
    //   // arrSorta.forEach((elementSort,index) => {
    //   //   if(element.Id === elementSort.arr._id){
    //   //     FinalArray.push({
    //   //       no:element
    //   //     });
    //   //   }else{
    //   //     FinalArray.push({
    //   //       no:"0"
    //   //     });
    //   //   }
    //   // });
    // });

    // 
      


  }

}
