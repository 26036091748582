import { Component, OnInit } from '@angular/core';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-slidebrand',
  templateUrl: './slidebrand.component.html',
  styleUrls: ['./slidebrand.component.scss']
})
export class SlidebrandComponent implements OnInit {

  
  constructor() { }

  ngOnInit() {
    $(".carousel").carousel({
      interval: 4000
    });
    
  }
  
}
