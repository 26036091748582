import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { environment } from "../../../../../environments/environment";

// Import Service
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";

@Component({
  selector: 'app-deletemember',
  templateUrl: './deletemember.component.html',
  styleUrls: ['./deletemember.component.scss']
})


export class DeletememberComponent implements OnInit {

  @Input() typeNameInput: any;
  @Input() regionNameInput: any;
  urlHostImage = environment.URLUPLOAD;
  cateName: string;
  regionName: string;
  projectid: string;
  list : any;
  category : any;


  constructor(private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
    private _location: Location,
  ) { }

  ngOnInit() {
    this.getInit();
  }

  async getInit(){
    
    this.cateName = this.typeNameInput
    this.regionName = this.regionNameInput

    
  }

}
