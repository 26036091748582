import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { environment } from "../../../../../environments/environment";

// Import Service
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";


@Component({
  selector: 'app-footer-detail',
  templateUrl: './footer-detail.component.html',
  styleUrls: ['./footer-detail.component.scss']
})
export class FooterDetailComponent implements OnInit {

  @Input() typeNameInput: any;
  @Input() regionNameInput: any;
  urlHostImage = environment.URLUPLOAD;
  cate: string;
  region: string;
  projectid: string;
  list : any;
  category : any;
  projectId: any;
  id: any;


  constructor(private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
    private _location: Location,
  ) { }

  ngOnInit() {
    this.getInit();
  }

  async getInit(){
    
    this.cate = this.typeNameInput
    this.region = this.regionNameInput

    
    
    const id = this.route.snapshot.paramMap.get("id");
    this.projectId = id;
    let listtarr = [];

    this.projectSer.getProjectTop4Detail(id).then(res => {
  
      // for(let i =0; i < res.data.data.length; i++){
      //   if(res.data.data[i]._id != id){
          this.list = res.data.data;
      //     listtarr[i] = res.data.data[i];         
          this.category = "AllIdeas";
      //   }
      // }

      //this.list = listtarr;
      
      
    });
  }
}
