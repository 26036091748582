import { Component, OnInit, Input, ViewChild  } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { environment } from "../../../../../environments/environment";

// Import Service
import { FillterService } from "../../../../Services/fillter.service";
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";

declare var $: any;

@Component({
  selector: 'app-rd-allproject-components',
  templateUrl: './rd-allproject-components.component.html',
  styleUrls: ['./rd-allproject-components.component.scss']
})
export class RdAllprojectComponentsComponent implements OnInit {

  urlHostImage = environment.URLUPLOAD;
  itemCard: any;
  cateName: any;
  yearName: any;
  public textSearch: string;
  @Input() myVal: string;
  page = 1;
  pageSize = 12;
  region: string;
  @Input() yearNameInput: any;
  yearList: any = [];
  year: any = '';

  public checkBack = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
    private fillterServ: FillterService,
  ) { }

  ngOnInit() {
    this.getInit()
    $('#create_inno').addClass('co-dis');
  }

  async getInit() {
    $('#create_inno').addClass('co-dis');
    let year = new Date().getFullYear();
    for (let i = 2000; i <= year; i++) {
      this.yearList.push(i);
    }
    this.year = new Date().getFullYear();

    $("#mySidenav-panel").hide();
    document.getElementById("mySidenav").style.width = "0";


    window.onscroll = function () { myFunction() };
    function myFunction() {
      var ontop = document.getElementById("ontop");

      if (window.scrollY > 100) {
        ontop.classList.remove("co-hid");

      }else if(window.scrollY === 0){
        ontop.classList.add("co-hid");
      }
    }

    window.scroll(0, 0);
    // Set Bar active header
    sessionStorage.setItem("barActive", "rdproject");
    this.route.params.subscribe(routeParams => {
      window.scroll(0, 0);
      //window.scroll(0, 1000);
      this.yearName = routeParams["year"];
      // this.cateName = routeParams["cate"];
      this.onChangeType(this.yearName);
      $('#create_inno').addClass('co-dis');
    });
  }

  onChangeRegion(yearName) {
    this.yearName = yearName;
    this.router.navigate(['/rdproject', this.yearName]);
  }

  public onKey(event: any) {

    let searchData = "";

    searchData += event.target.value;

    this.fillterServ.setFilter(searchData);

    if (searchData.length > 0) {
      // set back 1 time
      this.checkBack = false;
      this.router.navigate(["/rdproject",this.yearName]);
    }
  }

  ngDoCheck() {
    this.textSearch = this.fillterServ.getFilter();

  }

  async onChangeType(year) {
    if (year == "all") {
      // alert(year);
      let tem = await this.projectSer.getProjectRDbyYear(year);
      if (tem) {
        this.itemCard = tem.data.data;
        this.itemCard.forEach(element => {
          //
        });

      } else {
        this.itemCard = [];
      }
    }else if (year != "all") {
      // alert(year);
      let tem = await this.projectSer.getProjectRDbyYear(year);
      if (tem) {
        this.itemCard = tem.data.data;

        this.itemCard.forEach(element => {
          //var descriptionview = element.projectDescription;
          // var vass = descriptionview.append();

          var text = "";

          // var ca = text.html(element.projectDescription);
         //
        });

      } else {
        this.itemCard = [];
      }
    }


  }


  public onTop(){
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  RdOpen(){
    window.open("https://apps.powerapps.com/play/fd33a343-d67c-4bba-abc5-c51fb20b0c3c?tenantId=833df664-61c8-4af0-bcce-b9eed5f10e5a&hidenavbar=true",'_blank');
  }


}
