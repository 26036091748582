import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";


// Import Service
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";


@Component({
  selector: 'app-tab-proguess',
  templateUrl: './tab-proguess.component.html',
  styleUrls: ['./tab-proguess.component.scss']
})
export class TabProguessComponent implements OnInit {

  @Input() typeNameInput: any;
  @Input() regionNameInput: any;
  cate: string;
  region: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
    private _location: Location,
  ) { }

  ngOnInit() {
    this.getInit();
  }

  async getInit(){
    
    this.cate = this.typeNameInput
    this.region = this.regionNameInput

  }

}
