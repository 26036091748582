import { Component, OnInit, Input } from '@angular/core';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-project-team-modal',
  templateUrl: './project-team-modal.component.html',
  styleUrls: ['./project-team-modal.component.scss']
})
export class ProjectTeamModalComponent implements OnInit {

  itemTeamName: any;

  @Input() data : any;

  constructor() { }

  ngOnInit() {
    this.itemTeamName = [
      {
        Name: '1'
      }, {
        Name: '1'
      }, {
        Name: '1'
      }, {
        Name: '1'
      }
    ]
    
  }

  public onPitching(): void {

    $('#Pitching').modal('hide')

    $('#pitchingSuccess').modal('show')

  }

  public onTeamSuccess(): void {

    $('#Team').modal('hide')

    $('#TeamSuccess').modal('show')

  }

  public onTeamCancel(): void {

    $('#Team').modal('hide')

    $('#TeamCancel').modal('show')

  }


}
