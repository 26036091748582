import { Component, OnInit, Input } from '@angular/core';
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";
import { UserServiceService } from "../../../../Services/user-service.service";
import { environment } from "../../../../../environments/environment";

declare var $: any;

@Component({
  selector: 'app-detail-project',
  templateUrl: './detail-project-component.html',
  styleUrls: ['./detail-project-component.scss']
})
export class DetailProjectComponent implements OnInit {
  [x: string]: any;

  @Input() projectId: any;
  urlHostImage = environment.URLUPLOAD;
  activeProfile: string;
  imagePath: any;
  project: any;
  itemCard: any;
  member: any;
  developerDescription: string;
  graphicDesignDescription: string;
  marketingDescription: string;
  engineeringDescription: string;
  salesDescription: string;
  otherDescription: string;
  developerReadMore = false;
  graphicDesignReadMore = false;
  marketingReadMore = false;
  engineeringReadMore = false;
  salesReadMore = false;
  otherReadMore = false;
  resultskill: any;
  countlike:any;
  userProfiles = [];
  arrTimline: any;
  score: any;
  arrProguess:any;

  constructor(
    private projectSer: ProjectideaServiceService,
    private userSer: UserServiceService
  ) { }

  ngOnInit() {
    this.getInit();
  }

  async getInit() {
    this.imagePath = "";
    this.project = this.projectId;
    //const id = this.route.snapshot.paramMap.get("id");
    this.projectSer.viewLog();
    // 
    let tempData;
    tempData = await this.projectSer.getIdeaById(this.projectId);

    this.itemCard = tempData.data.data[0];


    let skill = this.itemCard.teamData.requireSkill;
    // 

    let dev = this.itemCard.teamData.requireSkill.developer;
    let engineering = this.itemCard.teamData.requireSkill.engineering;
    let graphicDesign = this.itemCard.teamData.requireSkill.graphicDesign;
    let marketing = this.itemCard.teamData.requireSkill.marketing;
    let sales = this.itemCard.teamData.requireSkill.sales;
    let other = this.itemCard.teamData.requireSkill.other;

    if( (dev === false) && (engineering === false) && (graphicDesign === false) && (marketing === false) && (sales === false) && (other === false) ){
      this.resultskill = false;
    }

    //alert(skill.length);

    
    if(this.itemCard.teamData.requireSkill.developerDescription.length > 40){
      this.developerDescription = this.itemCard.teamData.requireSkill.developerDescription.substr(0, 40) + "...";
    }else{
      this.developerDescription = this.itemCard.teamData.requireSkill.developerDescription;
    }

    if(this.itemCard.teamData.requireSkill.graphicDesignDescription.length > 40){
      this.graphicDesignDescription = this.itemCard.teamData.requireSkill.graphicDesignDescription.substr(0, 40) + "...";
    }else{
      this.graphicDesignDescription = this.itemCard.teamData.requireSkill.graphicDesignDescription;
    }

    if(this.itemCard.teamData.requireSkill.marketingDescription.length > 40){
      this.marketingDescription = this.itemCard.teamData.requireSkill.marketingDescription.substr(0, 40) + "...";
    }else{
      this.marketingDescription = this.itemCard.teamData.requireSkill.marketingDescription;
    }

    if(this.itemCard.teamData.requireSkill.engineeringDescription.length > 40){
      this.engineeringDescription = this.itemCard.teamData.requireSkill.engineeringDescription.substr(0, 40) + "...";
    }else{
      this.engineeringDescription = this.itemCard.teamData.requireSkill.engineeringDescription;
    }

    if(this.itemCard.teamData.requireSkill.salesDescription.length > 40){
      this.salesDescription = this.itemCard.teamData.requireSkill.salesDescription.substr(0, 40) + "...";
    }else{
      this.salesDescription = this.itemCard.teamData.requireSkill.salesDescription;
    }

    if(this.itemCard.teamData.requireSkill.otherDescription.length > 40){
      this.otherDescription = this.itemCard.teamData.requireSkill.otherDescription.substr(0, 40) + "...";
    }else{
      this.otherDescription = this.itemCard.teamData.requireSkill.otherDescription;
    }


    //

    this.itemCard.teamData.member.forEach(element => {
      if (element.status == "สมาชิกโครงการ") {
        let res = this.userSer.getProfileByEmpId(element.employeeId);
        res.then(data => {
          this.userProfiles.push({
            user : data.data.data,
            teamData : element
          });
        });
      }
    });
    // 
    // 

    let userid = sessionStorage.getItem("_id");

    this.projectSer.getLikeStatus(this.project,userid).then(res => {
      this.countlike = res.data.rs.like;

    });

    // const id = this.route.snapshot.paramMap.get("id");

    let arrPG = await this.projectSer.getProjectStatusForTimeline(this.projectId);
    this.arrProguess = arrPG.data.data[0];
    

    this.score = this.arrProguess.score+"%";
  }

  readMore(text) {
    switch (text) {
      case "developer": { this.developerReadMore = true; break; }
      case "graphicDesign": { this.graphicDesignReadMore = true; break; }
      case "marketing": { this.marketingReadMore = true; break; }
      case "engineering": { this.engineeringReadMore = true; break; }
      case "sales": { this.salesReadMore = true; break; }
      case "other": { this.otherReadMore = true; break; }
    }
  }
  Hide(text) {
    switch (text) {
      case "developer": { this.developerReadMore = false; break; }
      case "graphicDesign": { this.graphicDesignReadMore = false; break; }
      case "marketing": { this.marketingReadMore = false; break; }
      case "engineering": { this.engineeringReadMore = false; break; }
      case "sales": { this.salesReadMore = false; break; }
      case "other": { this.otherReadMore = false; break; }

    }
  }
  openMemberModal(){
    $("#memberModal").modal("show");
    //$("#ConfirmModal").modal("hide");
  }

  changeActive(activeName) {

    if (sessionStorage.getItem("activeTabs")) {
      sessionStorage.setItem("activeTabs", activeName)
      this.activeProfile = sessionStorage.getItem("activeTabs")
    } else {
      sessionStorage.setItem("activeTabs", activeName)
      this.activeProfile = sessionStorage.getItem("activeTabs")
    }
  }

  async ngDoCheck() {
    this.activeProfile = sessionStorage.getItem("activeTabs")
  }

  // public processBar(state) {
  //   let percent = this.percentage(state);
  //   return percent;
  // }

  // public percentage(state) {
  //   // let percent = "0%";
  //   // //for (let i = 0; i < 7; i++) {
  //   // switch (state) {
  //   //   case "New Project": percent = "14%";
  //   //     break;
  //   //   case "Problem Validation": percent = "28%";
  //   //     break;
  //   //   case "Problem / Solution fit ready for pitch": percent = "42%";
  //   //     break;
  //   //   case "Product / MKT Validation": percent = "57%";
  //   //     break;
  //   //   case "Product / MKT Validation ready for pitch": percent = "71%";
  //   //     break;
  //   //   case "Commercialization / Preparation": percent = "85%";
  //   //     break;
  //   //   case "Commercialization / Ready to Launch": percent = "100%";
  //   //     break;
  //   // }
  //   return percent;
  // }
}
