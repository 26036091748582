import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-btn-back',
  template: '<button class="btn btn-outline-green text-green px-4 w-12" (click)="onBackPage()"><i class="fas fa-caret-left btn-back"></i>&nbsp;&nbsp; BACK</button>',
})
export class BtnBackComponent implements OnInit {

  constructor(private _location: Location) { }

  ngOnInit() {

  }

  public onBackPage(): void {
    this._location.back();
  }
}
