import { Component, OnInit } from '@angular/core';


declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  data: any;
  itemTeamName: any;


  constructor() { }

  ngOnInit() {
    this.data = '111111'

    this.itemTeamName = [
      {
        Name:'1'
      },{
        Name:'1'
      },{
        Name:'1'
      },{
        Name:'1'
      }
    ]
  }

  public onNotAuthen(): void {

    $('#Team').modal('hide')

    $('#modalNotAuthen').modal('show')

  }

}
