import { Component, OnInit, Input, ViewChild  } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { environment } from "../../../../../environments/environment";

// Import Service
import { FillterService } from "../../../../Services/fillter.service";
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";

declare var $: any;

@Component({
  selector: 'app-ip-allproject-components',
  templateUrl: './ip-allproject-components.component.html',
  styleUrls: ['./ip-allproject-components.component.scss']
})
export class IpAllprojectComponentsComponent implements OnInit {

  urlHostImage = environment.URLUPLOAD;
  itemCard: any;
  cateName: any;
  yearName: any;
  public textSearch: string;
  @Input() myVal: string;
  page = 1;
  pageSize = 12;
  region: string;
  @Input() yearNameInput: any;
  @Input() categoryInput: any;
  yearList: any = [];
  year: any = '';
  category: any = '';
  typeIPList: any;
  arrTypeColr = [];
  dataRS = [];

  public checkBack = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
    private fillterServ: FillterService,
  ) { }

  ngOnInit() {
    this.getInit()
  }

  async getInit() {
    $('#create_inno').addClass('co-dis');
    let tempTypeIp = await this.projectSer.getTypeIP();
    this.typeIPList = tempTypeIp.data.data;

    this.typeIPList.forEach(element => {
      if(element.typeName == "ลิขสิทธิ์"){
        this.arrTypeColr[element.typeName] = "one";
      }else if(element.typeName == "อนุสิทธิบัตร"){
        this.arrTypeColr[element.typeName] = "two";
      }else if(element.typeName == "สิทธิบัตรการออกแบบผลิตภัณฑ์"){
        this.arrTypeColr[element.typeName] = "three";
      }else if(element.typeName == "สิทธิบัตรการประดิษฐ์"){
        this.arrTypeColr[element.typeName] = "four";
      }else{
        this.arrTypeColr[element.typeName] = "other";
      }
    });

    
    let year = new Date().getFullYear();
    for (let i = 2000; i <= year; i++) {
      this.yearList.push(i);
    }
    this.year = new Date().getFullYear();

    $("#mySidenav-panel").hide();
    document.getElementById("mySidenav").style.width = "0";


    window.onscroll = function () { myFunction() };
    function myFunction() {
      var ontop = document.getElementById("ontop");

      if (window.scrollY > 100) {
        ontop.classList.remove("co-hid");

      }else if(window.scrollY === 0){
        ontop.classList.add("co-hid"); 
      }
    }


    window.scroll(0, 0);
    // Set Bar active header
    sessionStorage.setItem("barActive", "ipproject");
    this.route.params.subscribe(routeParams => {
      window.scroll(0, 0);
      //window.scroll(0, 1000);
      this.yearName = routeParams["year"];
      this.category = routeParams["category"];
      // this.cateName = routeParams["cate"];
      this.onChangeType(this.yearName,this.category);
      $('#create_inno').addClass('co-dis');
    });
  }

  onChangeRegion(yearName) {
    this.yearName = yearName;
    this.router.navigate(['/ipproject', this.yearName,this.category]);
  }

  onChangeCategory(category) {
    this.category = category;
    this.router.navigate(['/ipproject', this.yearName,this.category]);
  }

  public onKey(event: any) {

    let searchData = "";

    searchData += event.target.value;

    this.fillterServ.setFilter(searchData);

    if (searchData.length > 0) {
      // set back 1 time
      this.checkBack = false;
      this.router.navigate(['/ipproject', this.yearName,this.category]);
    }
  }

  ngDoCheck() {
    this.textSearch = this.fillterServ.getFilter();
  }

  async onChangeType(year,type) {
    // let arrInno = await this.projectSer.getProjectIPbyYear(yearName,type);
    // this.mockdata = arrInno.data.data;
    if (year == "all") {
      // alert(year);
      let tem = await this.projectSer.getProjectIPbyYear(year,type);
      
      
      if (tem) {
        this.itemCard = tem.data.data; 
        this.itemCard.forEach(element => {
          
          
        });

      } else {
        this.itemCard = [];
      }

      

      

    }else if (year != "all" || type != "all") {
      // alert(year);
      let tem = await this.projectSer.getProjectIPbyYear(year,type);
      if (tem) {
        this.itemCard = tem.data.data; 

        this.itemCard.forEach(element => {
          //var descriptionview = element.projectDescription;
          // var vass = descriptionview.append();
          
          var text = "";

          // var ca = text.html(element.projectDescription);
         // 
        });
       
      } else {
        this.itemCard = [];
      }
    }

    // 
  }
  

  public onTop(){
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
  }
} 
