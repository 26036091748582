import { Component, OnInit } from "@angular/core";

// Import Service
import { UserServiceService } from "../../../Services/user-service.service";
import { ProjectideaServiceService } from "../../../Services/projectidea-service.service";
import { environment } from "../../../../environments/environment";

declare var $: any;

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent implements OnInit {
  urlHostImage = environment.URLUPLOAD;
  public itemCard: any;
  public dataUser: any;
  public idProject: any;

  public Email: any;
  public skill: any = [];
  public region: any;
  public phone: any;
  userProfiles = [];
  dataSearch = [];
  userProfilesRecommend = [];
  inviteList = [];
  waitForAcceptList = [];
  databackupPush = [];
  route: any;
  itemData: any;
  itemConfirm: any;
  itemCardRec: any;
  itemonSubmit: any;
  itemWait = [];
  itemcardWait: any;
  itemMem = [];
  itemJoin = [];
  itemModal: any;
  itemCencel: any;
  itemCardrs = [];
  itemMy = [];
  searchValue: any;
  CkEmailFormat = false;
  constructor(
    private userService: UserServiceService,
    private projectSer: ProjectideaServiceService
  ) { }

  async ngOnInit() {

    $("#mySidenav-panel").hide();
    document.getElementById("mySidenav").style.width = "0";

    // SET ACTIVER DELETE HOME
    sessionStorage.setItem("activeTabs", "")
    // Set Bar active header 
    sessionStorage.setItem("barActive", "userprefile");

    window.scroll(0, 0);
    this.dataUser = await this.userService.getProfileUser();

    this.Email = this.dataUser.email;
    this.skill = this.dataUser.skill;

    this.region = this.dataUser.region;
    this.phone = this.dataUser.tel;
    let tempData;
    tempData = await this.projectSer.getIdeaBytoken();
    this.itemCard = tempData.data.data

    let myData;
    myData = await this.projectSer.getIdeaByUserId(this.dataUser._id);
    this.itemMy = myData.data.data


    if (myData.data.data === 'ไม่พบข้อมูล') {
      $('#mycreate').html("0");
    } else {
      $('#mycreate').html(this.itemMy.length);
    }

    if (myData.data.data != 'ไม่พบข้อมูล') {
      for (let index = 0; index < this.itemMy.length; index++) {
        this.itemCardrs.push({
          project: this.itemMy[index],
          member: this.itemMy[index].teamData.member,
          user: this.itemMy[index].user
        });
      }
    }

    //
    if (this.itemCard != 'ไม่พบข้อมูล')
      this.itemCard.sort((val1, val2) => {
        return new Date(val2.createDate).getTime() - new Date(val1.createDate).getTime()
      })


    let waitData;
    let MemData;
    let JoinData;
    let myemp = localStorage.getItem("EmployeeId");
    let myemail = sessionStorage.getItem("email");


  //ststus wait
    let wait = "รอการตอบรับ";
    waitData = await this.projectSer.getIdeaByTeamEmployeeIdAndStatus(myemp, wait,myemail);
    let reWaitdata = waitData.data.data

    // //

    if (waitData.data.data != 'ไม่พบข้อมูล') {
      for (let index = 0; index < reWaitdata.length; index++) {
        this.itemWait.push({
          project: reWaitdata[index],
          member: reWaitdata[index].teamData.member,
          user: reWaitdata[index].user
        });
      }
    }
    //



  //ststus member
    let mem = "สมาชิกโครงการ";
    MemData = await this.projectSer.getIdeaByTeamEmployeeIdAndStatus(myemp, mem,myemail);
    let reMemdata = MemData.data.data

    if (MemData.data.data != 'ไม่พบข้อมูล') {
      for (let index = 0; index < reMemdata.length; index++) {
        this.itemMem.push({
          project: reMemdata[index],
          member: reMemdata[index].teamData.member,
          user: reMemdata[index].user
        });
      }
    }
    //

    if (MemData.data.data === 'ไม่พบข้อมูล') {
      $('#myjoin').html("0");
    } else {
      $('#myjoin').html(reMemdata.length);
    }


  //ststus invite
    let join = "สนใจเข้าร่วมโครงการ";
    JoinData = await this.projectSer.getIdeaByTeamEmployeeIdAndStatus(myemp, join,myemail);

    let reJoindata = JoinData.data.data

    if (JoinData.data.data != 'ไม่พบข้อมูล') {
      for (let index = 0; index < reJoindata.length; index++) {
        this.itemJoin.push({
          project: reJoindata[index],
          member: reJoindata[index].teamData.member,
          user: reJoindata[index].user
        });
      }
    }
    //

  }

  async ngOnChanges() {
    // GET DATA USER TOKEN
    this.dataUser = await this.userService.getProfileUser();
  }

  sortFunc(a?: any, b?: any) {
    return new Date(b.createDate).getTime() - new Date(a.createDate).getTime()
  }

  async memberRecommended(id) {
    this.idProject = id;
    let tempData;
    tempData = await this.projectSer.getIdeaById(id);
    this.itemCardRec = tempData.data.data[0];

    this.userProfilesRecommend = [];
    this.itemCardRec.teamData.member.forEach(element => {

      if (element.status == "สนใจเข้าร่วมโครงการ") {
        let res = this.userService.getProfileByEmpId(element.employeeId);
        res.then(data => {
          //
          //
          let filterrecommend = this.inviteList.filter(x => x.employeeId == element.employeeId);
          ////
          ////
          //
          if (filterrecommend.length == 0) {
            //
            this.userProfilesRecommend.push(
              //{ user : data.data.data,
              // teamdata : element}
              {
                displayName: element.displayName,
                email: element.email,
                employeeId: element.employeeId,
                status: element.status,
                imgProfile: data.data.data.imgProfile,
                _id: data.data.data._id,
                position: data.data.data.position,
                _idTeam: element._id
              }
            );
            //
          }
        });
      }
    });
    this.waitForAcceptList = [];


    tempData.data.data[0].teamData.member.forEach(element => {
      if (element.status == "รอการตอบรับ") {
        let res = this.userService.getProfileByEmpId(element.employeeId);
        res.then(data => {
          this.waitForAcceptList.push({
            user: data.data.data,
            teamdata: element
          });
        });
      }
    });

    $('#idProj').val(id);

  }

  async searchMember() {

    let searchTxt = $('#searchBox').val();

    if (searchTxt != "") {

      $('#userDropdown').addClass("display");
      let users = await this.userService.getMemberByEmail(searchTxt);
      this.dataSearch = users.data.data;
      ////
      ////
      ////
      let leftUsers = this.dataSearch;
      if (this.inviteList.length > 0) {
        leftUsers = this.dataSearch.filter(x => this.inviteList.findIndex(y => y.employeeId == x.employeeId) === -1);
      }
      ////
      if (this.waitForAcceptList.length > 0) {
        leftUsers = leftUsers.filter(x => this.waitForAcceptList.findIndex(y => (y.user != undefined ? y.user.employeeId : y.teamdata.email) == (y.user != undefined ? x.employeeId : x.email )) === -1 );
        //leftUsers = leftUsers.filter(x => this.waitForAcceptList.findIndex(y => (y.user != undefined ? y.user.employeeId : y.teamdata.employeeId) == (y.user != undefined ? x.email : x.employeeId)) === -1);
      }
      ////

      for (let index = 0; index < this.itemCard.length; index++) {
        var leftUsers3 = this.itemCard[index].teamData.member.filter(x => x.status == "สนใจเข้าร่วมโครงการ");
        ////
      }
      if (leftUsers3.length > 0) {
        leftUsers = leftUsers.filter(x => leftUsers3.findIndex(y => y.employeeId == x.employeeId) === -1);
      }
      ////

      for (let i = 0; i < this.itemCard.length; i++) {
        if (this.itemCard[i]._id ==  this.idProject) {
          leftUsers = leftUsers.filter(x => x.employeeId != this.itemCard[i].owner[0].employeeId);

          ////
          if (this.itemCard[i].incubator != undefined) {
            leftUsers = leftUsers.filter(x => x._id != this.itemCard[i].incubator._id);
          }
        }

      }



      this.dataSearch = leftUsers;
      if (this.dataSearch.length != 0) {
        //$('#userDropdown').show();
        $('#userDropdown').addClass("display");
      } else {
        //$('#userDropdown').hide();
        $('#userDropdown').removeClass("display");
      }

      //////////////////  Check Email Format  ////////////////// this.CkEmailFormat = 
      if (this.searchValue.indexOf('@') != -1 && this.searchValue.substr(this.searchValue.indexOf('@')).indexOf('.') != -1) {
        if (this.waitForAcceptList.length === 0) {
          this.CkEmailFormat = true;
        } else {
          for (let i = 0; i <= this.waitForAcceptList.length; i++) {
            if (this.searchValue.indexOf(this.waitForAcceptList[i].teamdata.email) != -1) {
              this.CkEmailFormat = false;
              break;
            } else {
              this.CkEmailFormat = true;
            }
          }
        }
      }

      //////////////////////////////////////////////////////////
    }
    else {
      this.dataSearch = null;
      $('#userDropdown').hide();
    }
  }

  hidedropdown() {
    $('#userDropdown').hide();
  }

  selectUser(value) {

    // alert(value);
    $('#userDropdown').removeClass("display");

    this.inviteList.push(this.dataSearch[value]);

    let leftUsers = this.dataSearch;
    if (this.inviteList.length > 0) {
      leftUsers = this.dataSearch.filter(x => this.inviteList.findIndex(y => y.employeeId == x.employeeId) === -1);
    }
    //
    if (this.waitForAcceptList.length > 0) {
      leftUsers = leftUsers.filter(x => this.waitForAcceptList.findIndex(y => (y.user != undefined ? y.user.employeeId : y.teamdata.employeeId) == (y.user != undefined ? x.email : x.employeeId)) === -1);
    }
    //

    for (let index = 0; index < this.itemCard.length; index++) {
      var leftUsers3 = this.itemCard[index].teamData.member.filter(x => x.status == "สนใจเข้าร่วมโครงการ");
      //
    }
    if (leftUsers3.length > 0) {
      leftUsers = leftUsers.filter(x => leftUsers3.findIndex(y => y.employeeId == x.employeeId) === -1);
    }

    //


    for (let index = 0; index < this.itemCard.length; index++) {
      leftUsers = leftUsers.filter(x => x.employeeId != this.itemCard[index].owner.employeeId);
    }
    //
    this.dataSearch = leftUsers;
    //



  }

  selectRecommendUser(value) {
    // let tempData;
    // tempData = this.projectSer.getIdeaById(id);
    // this.itemCard = tempData.data.data[0];
    let ck = false;
    let keepGoing = true;
    //
    //
    this.inviteList.forEach(element => {
      if (keepGoing) {
        //
        if (element._id == this.userProfilesRecommend[value]._id) {
          ck = true;
          keepGoing = false;
        }
        else {

        }
      }
    });
    if (!ck) {
      this.inviteList.push(this.userProfilesRecommend[value]);
      this.databackupPush.push(this.userProfilesRecommend[value]);
      this.userProfilesRecommend.splice(value, 1);
    }
  }

  addMemberNotUser(email) {
    //
    this.inviteList.push({
      displayName: null,
      email: email,
      employeeId: null,
      status: null,
      imgProfile: null,
      _id: null,
      position: null,
      _idTeam: null

    });
  }

  deleteUser(value, employeeId) {
    ////
    this.inviteList.splice(value, 1);
    let index = this.databackupPush.findIndex(y => y._id === employeeId);
    if (this.databackupPush[index]) {
      this.userProfilesRecommend.push(this.databackupPush[index]);
      this.databackupPush.splice(index, 1);
    }
  }

  async confirmInvite() {
    //alert();
    let projectid = $('#idProj').val();

    let tempData;
    tempData = await this.projectSer.getIdeaById(projectid);
    this.itemConfirm = tempData.data.data[0];

    $("#InviteMemberModal").modal("hide");
    $("#ConfirmInviteModal").modal("show");
  }

  async onSubmitconfirm(id) {
    //alert(id);
    //const id = this.route.snapshot.paramMap.get("id");
    let OwnerUserId = sessionStorage.getItem("_id");
    let tempData;
    tempData = await this.projectSer.getIdeaById(id);
    this.itemonSubmit = tempData.data.data[0];

    this.inviteList.forEach(element => {
      const userData = {
        _id: element._id,
        jobDescription: $('#jobDescription' + element._id).val(),
        position: element.position,
        employeeId: element.employeeId == "" || element.employeeId == null ? element.email : element.employeeId,
        displayName: element.displayName,
        email: element.email,
        status: "รอการตอบรับ",
        oldStatus: element.status ? element.status : null,
        _idTeam: element._idTeam ? element._idTeam : null
      }

      //

      this.projectSer.InsertMember(this.itemonSubmit, userData).then(
        res => {
          ////
          if (res.data.status == 200) {
            let res2 = this.projectSer.InsertToStory(element.displayName, OwnerUserId, id);
            // //
            $("#ConfirmInviteModal").modal("hide");
            $("#sendinviteComplete").modal("show");
            this.inviteList = [];
            $('#searchBox').val("");
          }
        }
      );
    });
  }

  public secdComplete() {
    sessionStorage.setItem("TabsDetail", "คำขอเข้าร่วมทีม");
    window.location.reload();
  }

  public conFirm(id, projectname) {

    //alert(projectname);
    $('#cf-projectname').html(projectname);
    $('#projectid').val(id);
    $("#ConfirmModal").modal("show");

  }

  async onconfirm() {

    let id = $('#projectid').val();
    let tempData;
    tempData = await this.projectSer.getIdeaById(id);
    this.itemModal = tempData.data.data[0];

    ////

    const userData = {
      _id: sessionStorage.getItem("_id"),
      email: sessionStorage.getItem("email"),
      position: sessionStorage.getItem("position"),
      employeeId: localStorage.getItem("EmployeeId"),
      displayName: sessionStorage.getItem("displayName"),
      status: "สมาชิกโครงการ",
    }

    this.projectSer.getUpdateStatusJoinTeam(this.itemModal, userData).then(res => {
      if (res.status == 200) {
        $("#ConfirmModal").modal("hide");
        $("#CompleteModal").modal("show");
        //window.location.reload();
      }

    });
  }

  public Cancel(id, projectname) {
    $('#cancel-projectname').html(projectname);
    $('#projectidcancel').val(id);
    $('#CancelModal').modal("show");
  }

  async onSubmitCancel() {

    let id = $('#projectidcancel').val();
    let tempData;
    tempData = await this.projectSer.getIdeaById(id);
    this.itemCencel = tempData.data.data[0];

    const userData = {
      _id: sessionStorage.getItem("_id"),
      email: sessionStorage.getItem("email"),
      position: sessionStorage.getItem("position"),
      employeeId: localStorage.getItem("EmployeeId"),
      displayName: sessionStorage.getItem("displayName"),
      status: "ปฏิเสธคำเชิญ",
    }

    this.projectSer.getUpdateStatusJoinTeam(this.itemCencel, userData).then(res => {
      if (res.status == 200) {
        $('#CancelModal').modal("hide");
        $('#CompleteModal').modal("show");
        //window.location.reload();
      }

    });
  }

  public onSubmitComplete() {
    $('#CompleteModal').modal("hide");
    $('.btn-invite').prop( "disabled", true );
    window.location.reload();
  }

}
