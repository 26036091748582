import { Component, OnInit } from "@angular/core";

// IMPORT SERVICES
import { FillterService } from "../../../Services/fillter.service";
import { ProjectideaServiceService } from '../../../Services/projectidea-service.service';
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"]
})
export class SearchComponent implements OnInit {
  
  public urlHostImage = environment.URLUPLOAD;
  public mockdata: any;
  public textSearch: string;
  public itemCard: any;
  
  constructor(private fillterServ: FillterService,private projectSer: ProjectideaServiceService) {}

  ngOnInit() {
    this.getInit()
  }

  ngDoCheck() {
    this.textSearch = this.fillterServ.getFilter();
  }

  async getInit(){

    window.scroll(0,0)
    // GET DATA ALL PROJECT IDEAS
    this.mockdata = await this.projectSer.getIdeaAllsearch()

  }
}
