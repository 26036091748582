import { Component, OnInit } from '@angular/core';

// Import Service
import { UserServiceService } from "../../../Services/user-service.service";
import { ProjectideaServiceService } from "../../../Services/projectidea-service.service";
import { environment } from "../../../../environments/environment";

declare var $: any;

@Component({
  selector: 'app-userprofile-component',
  templateUrl: './userprofile-component.component.html',
  styleUrls: ['./userprofile-component.component.scss']
})
export class UserprofileComponentComponent implements OnInit {

  urlHostImage = environment.URLUPLOAD;
  public itemCard: any;
  public dataUser: any;

  public Email: any;
  public skill: any = [];
  public region: any;
  public phone: any;
  userProfiles = [];
  dataSearch= [];
  userProfilesRecommend = [];
  inviteList= [];
  waitForAcceptList= [];
  databackupPush = [];
  route: any;
  itemData: any;
  itemConfirm: any;
  itemCardRec: any;
  itemonSubmit: any;
  itemMem= [];
  itemWait: any;
  itemJoin: any;
  myAllData: any;
  coverPic: any;
  public userproject: any =[];
  otherproject = [];
  checkData = [];
  dataList = [];
  myMember: any;
  countlist: any;
  itemCardrs = [];
  modalData = [];
  modalimg : any;
  modalName : any;
  modalPosition : any;
  modalEmail : any;
  memberprofile =[];
  employeeid:any;
  

  constructor(
    private userService: UserServiceService,
    private projectSer: ProjectideaServiceService
  ) { }

  async ngOnInit() {

    $('.noscroll').on( 'mousewheel DOMMouseScroll', function (e) { 
  
      var e0 = e.originalEvent;
      var delta = e0.wheelDelta || -e0.detail;
    
      this.scrollTop += ( delta < 0 ? 1 : -1 ) * 30;
      e.preventDefault();  
    });

    $('#materialIndeterminate2').prop('indeterminate', true);

    var pageURL = window.location.href;
    var lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);
    let userId = lastURLSegment;

    if(userId === sessionStorage.getItem("_id")){
      var urlDetail = "";
      if (window.location.hostname === "localhost") {
        urlDetail = "http://localhost:4200/userProfile/profileUser/";
      } else if (window.location.hostname === "inning.dld-test.com") {
        urlDetail = "https://" + window.location.host + "/userProfile/profileUser";
      } else {
        urlDetail = "https://" + window.location.host + "/userProfile/profileUser";
      }
      window.location.href = urlDetail;
    }

    // SET ACTIVER DELETE HOME
    sessionStorage.setItem("activeTabs", "")
    // Set Bar active header 
    sessionStorage.setItem("barActive", "userprefile");

    
    window.scroll(0, 0);
    this.dataUser = await this.userService.getUserById(userId);

    
    

    this.Email = this.dataUser.data.data.email;
    this.skill = this.dataUser.data.data.skill;
    this.coverPic = this.dataUser.data.data.imgProfile;
    let empid = this.dataUser.data.data.employeeId;
    this.employeeid = empid;
    
    // 
    
    this.region = this.dataUser.data.data.region;
    this.phone = this.dataUser.data.data.tel;
    let tempData;
    tempData = await this.projectSer.getIdeaByUserId(userId);
    // 
    // 
    this.itemCard = tempData.data.data

    //
    
    if(this.itemCard != 'ไม่พบข้อมูล'){
      for (let index = 0; index < this.itemCard.length; index++) {
        this.itemCardrs.push({
          project: this.itemCard[index],
          member: this.itemCard[index].teamData.member,
          user: this.itemCard[index].user
        });
      }
    }

    // 
    // 
    // 
    
    
    if(this.itemCard != 'ไม่พบข้อมูล')
    this.itemCard.sort((val1, val2)=> {
      return new Date(val2.createDate).getTime() - new Date(val1.createDate).getTime()
    })

    $('#mycreate').html(this.itemCard.length);

    let myemail = sessionStorage.getItem("email");

    let mem = "สมาชิกโครงการ";
    let MemData;
    MemData = await this.projectSer.getIdeaByTeamEmployeeIdAndStatus(empid,mem,myemail);
    let reMemdata = MemData.data.data
    
    
    
    if(MemData.data.data != 'ไม่พบข้อมูล'){
      for (let index = 0; index < reMemdata.length; index++) {
        this.itemMem.push({
          project: reMemdata[index],
          member: reMemdata[index].teamData.member,
          user: reMemdata[index].user
        });
      }
    }
      

    // 
    // 
    // 

    // let wait = "รอการตอบรับ";
    // let waitData;
    // waitData = await this.projectSer.getIdeaByTeamEmployeeIdAndStatus(empid,wait);
    // this.itemWait = waitData.data.data
    // 
    // 

    // let join = "สนใจเข้าร่วมโครงการ";
    // let JoinData;
    // JoinData = await this.projectSer.getIdeaByTeamEmployeeIdAndStatus(empid,join);
    // this.itemJoin = JoinData.data.data
    // 
    // 

    let countjoin = this.itemMem.length;
    $('#myjoin').html(countjoin);

    let allData;
    allData = await this.projectSer.getIdeaByUserId(sessionStorage.getItem("_id"));
    this.myAllData = allData.data.data;

    //
  

    for (let index = 0; index < this.myAllData.length; index++) {
      if(this.myAllData[index].status == "active"){
        let member = this.myAllData[index].teamData.member.reverse((a,b) => b.createDate - a.createDate);
        member = member.find(x => x.employeeId === empid);

        if(member){
          if(member.status && member.status != "รอการตอบรับ"){
            this.userproject.push({
              project: this.myAllData[index],
              member: member
            });
          }
        } else{
          this.userproject.push({
            project: this.myAllData[index],
            member: member
          });
        }
      }
    }

    
    
  
  }

  async ngOnChanges() {
    // GET DATA USER TOKEN
    this.dataUser = await this.userService.getProfileUser();
  }

  sortFunc (a? :any, b? :any) {
    return new Date(b.createDate).getTime() - new Date(a.createDate).getTime()
  }


  onSend(){
    //alert();
    let type = [];
    $("input[name='project-check']:checked").each(function (i) {
        type[i] = $(this).val();
    });

    type.forEach(element => {
      let res = this.projectSer.getIdeaById(element);
        res.then(data => {
          this.checkData.push({
            project : data.data.data
          });
        });
    });

    
    
    

    this.countlist = type.length;


    $('#ProjectModal').modal('hide');
    $('#SubmitModal').modal('show');

    $('#show-displayname').html(this.dataUser.data.data.displayName);
    $('#show-email').html(this.dataUser.data.data.email);

  }

  async onComplete(length){

    $('#lastCompleteModal').modal('show');
    $('#SubmitModal').modal('hide');
    var pageURL = window.location.href;
    var lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);
    let userId = lastURLSegment;
    //alert(length);

    let dataList = [];

    var inputs = document.getElementsByClassName( 'projectid' ),
    names  = [].map.call(inputs, function( input ) {
        return input.value;
    }).join( ',' );

    var jobs = document.getElementsByClassName( 'job' ),
    jobdes  = [].map.call(jobs, function( input ) {
        return input.value;
    }).join( ',' );
  
    //let projectData = [] ;
    var resname = names.split(",");
    var resjob = jobdes.split(",");

    let userowner = await this.userService.getUserById(sessionStorage.getItem("_id"));
    let member = await this.userService.getUserById(userId);


    const ownerData = {   
      _id: userowner.data.data._id,
      employeeId: userowner.data.data.employeeId,
      displayName: userowner.data.data.displayName,
      email: userowner.data.data.email,              
      }

      const memberData = {
      _id: member.data.data._id,
      email: member.data.data.email,
      status: "รอการตอบรับ",
      employeeId: member.data.data.employeeId,
      position: member.data.data.position,
      displayName: member.data.data.displayName
      }

    for(let i=0; i < resname.length; i++){
      let res = this.projectSer.getIdeaById(resname[i]);
      res.then(async data => {
        let memberrs = data.data.data[0].teamData.member.sort((a,b) => b.createDate - a.createDate);
        memberrs = memberrs.find(x => x.employeeId === member.data.data.employeeId);
       
        if(memberrs){
         const projectData = {
            _id: resname[i],
            projectName: data.data.data[0].projectName,
            status: memberrs.status,
            jobDescription: resjob[i]
           }           
          let resinvite = await this.projectSer.getInvitMultiProject( ownerData,  memberData, projectData);
        }else{
          const projectData =  {
            _id: resname[i],
            projectName: data.data.data[0].projectName,
            status: "",
            jobDescription: resjob[i]
           }
           let resinvite = await this.projectSer.getInvitMultiProject( ownerData,  memberData, projectData);
        }

      });
    }
    
    

  }

  public onlastComplete(){
    window.location.reload()
  }

  public onCancel(){
    $("input:checkbox").attr('checked', false);

    this.checkData = [];
    $('#SubmitModal').modal('hide');

  }

  openMemberModal(id){

    let res = this.projectSer.getIdeaById(id);
    
    res.then(data => {
      this.modalimg = data.data.data[0].owner.imgProfile;
      this.modalName = data.data.data[0].owner.displayName;
      this.modalPosition = data.data.data[0].owner.displayName;
      this.modalEmail = data.data.data[0].owner.displayName;
      
      data.data.data[0].teamData.member.forEach(element => {
        if (element.status == "สมาชิกโครงการ") {
          let res = this.userService.getProfileByEmpId(element.employeeId);
          res.then(data => {
            this.modalData.push({
              user : data.data.data,
              teamData : element
            });
          });
        }
      });
    });

    $("#memberModal").modal("show");
   
  }
  

}
