import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { Subscription } from "rxjs";


// IMPORT SERVICES
import { ProjectideaServiceService } from "../../../Services/projectidea-service.service";

// use Jquery
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-detailmember-component',
  templateUrl: './detailmember-component.component.html',
  styleUrls: ['./detailmember-component.component.scss']
})
export class DetailmemberComponentComponent implements OnInit {

  public itemCard: any;
  public url = environment.API;
  urlHostImage = environment.URLUPLOAD;

  public coverPicture = "";
  public projectDescript = "";
  public imgProfile = "";
  public email = "";
  public tel = "";
  public team = [];
  
  teamSkill: any;
  tabsDetail: any;
  URLID:any
  member: any;
  showInvite;
  scrollone: any;
  countlike: any;
  statuslike: any;
  subscription: Subscription;
  requestStatus = false;
  private token;
  private project;
  public inviteJobDescription = null;

  constructor(private projectSer: ProjectideaServiceService, private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.getInit()
    this.getURL();

  }

  async getInit() {

    sessionStorage.setItem("incubatestatus","member");
    window.onscroll = function () { myFunction() };

    var navbar = document.getElementById("navbarnoti");
    var content = document.getElementById("content");
    var projectnamenoti = document.getElementById("projectname");
    var contain = document.getElementById("contain");
    var sticky = navbar.offsetTop;

    //header
    var header = document.getElementById("header-panel");
    var namehd = document.getElementById("name-header")
    var like = document.getElementById("like");
    var add = document.getElementById("add");
    var btnlike = document.getElementById('btnlike');
    var btnadd = document.getElementById('btnadd');

    //btn on top
    var ontop = document.getElementById("ontop");

    //tab panel
    var tabpanel = document.getElementById("tab-panel");
    var stickytab = tabpanel.offsetTop;
   

    function myFunction() {
      //navbar.classList.add("sh-nonnoti");
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
        content.classList.add("co-hid");
        projectnamenoti.classList.remove("co-hid");
        contain.classList.add("con-ma");

      } else {
        navbar.classList.remove("sticky");
        content.classList.remove("co-hid");
        projectnamenoti.classList.add("co-hid");
        contain.classList.remove("con-ma");

      }

      //header  
      if (window.scrollY > 50) {
        //alert("1");
        header.classList.add("sticky-header");
        namehd.classList.add("co-hid");
        //like.classList.add("disnone");
        //btnlike.classList.add("resize-like");
        ontop.classList.remove("co-hid");
       
        

      }else if(window.scrollY <= 50){

        header.classList.remove("sticky-header");
        namehd.classList.remove("co-hid");
        //like.classList.remove("disnone");
        //btnlike.classList.remove("resize-like");
        ontop.classList.add("co-hid");
        
      }

      //tab
      if (window.pageYOffset >= stickytab) {
        
        tabpanel.classList.add("sticky-tab");

      }else{

        tabpanel.classList.remove("sticky-tab");

      }
    }
    
    


    window.scroll(0, 0)
    this.token = {
      Title_token: "Support Project",
      Text_token: "Message from Home Component to App Component!",
      Token: "50"
    };
    this.project = {
      Title_join: "Join Project",
      Project_name: "NAME PROJECT...",
      Text_join: "* คุณต้องรอการอนุมัติจากเจ้าของโครงการ *"
    };

    this.token = { title: "Support Project", message: ' Would you like support project by providing a token', token: '50' }
    this.project = { title: "Join Project", name: 'NAME PROJECT...', message: '* คุณต้องรอการอนุมัติจากเจ้าของโครงการ *' }

    const id = this.route.snapshot.paramMap.get("id");
    let tempData;
    tempData = await this.projectSer.getIdeaById(id);
    this.itemCard = tempData.data.data[0];
    
    this.coverPicture = this.urlHostImage + this.itemCard.coverPicture;
    this.projectDescript = this.itemCard.projectDescription;
    this.imgProfile = tempData.data.data[0].owner.imgProfile;
    this.email = tempData.data.data[0].owner.email;
    this.tel = tempData.data.data[0].owner.tel;
    this.teamSkill = tempData.data.data[0].teamData.requireSkill;
    this.member = tempData.data.data[0].teamData.member;

    if(this.itemCard.status === "inactive"){
      $('#deleteStatus').modal('show');
    }else{
      $('#deleteStatus').modal('hide');
    }


    let userid = sessionStorage.getItem("_id");
    let detailTabs = sessionStorage.getItem("TabsDetail");
   

    if (detailTabs === "แสดงความคิดเห็น") {
      this.tabsDetail = "comment";
    }

    this.projectSer.getLikeStatus(id,userid).then(res => {
      

      if(res.data.data === null || res.data.data.action === "unlike"){
        
        this.countlike = res.data.rs.like;
        this.statuslike = "like";

        $("#like").html("ถูกใจ");
        $("#btnlike").removeClass("btn-like");
        $("#btnlike").addClass("btn-unlike");
        //$("#fly-heart").addClass("co-dis");
        
      }else if(res.data.data.action === "like"){
        
        this.countlike = res.data.rs.like;
        this.statuslike = "unlike";
        
        $("#like").html(this.countlike);
        $("#btnlike").addClass("btn-like");
        $("#btnlike").removeClass("btn-unlike");
        //$("#fly-heart").removeClass("co-dis");
        
      }
    });
   
  }

  public onTop(){
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
  }

  public tabscroll(){
    window.scroll({
      top: 400, 
      left: 0, 
      behavior: 'smooth'
    });
  }

  public likeAction(action){
    //alert(status);
    var str = document.getElementById("like").innerHTML; 
    const id = this.route.snapshot.paramMap.get("id");
    let userid = sessionStorage.getItem("_id");

    this.projectSer.getUpdateLikeStatus(id,userid,action).then(res => {
      //

      this.projectSer.getLikeStatus(id,userid).then(res => {
        
  
        if(res.data.data.action === "unlike"){
          
          this.countlike = res.data.rs.like;
          $('#count-like').html(this.countlike);
          this.statuslike = "like";
  
          $("#like").html("ถูกใจ");
          $("#btnlike").removeClass("btn-like");
          $("#btnlike").addClass("btn-unlike");
          $("#fly-heart").addClass("co-dis");
          
        }else if(res.data.data.action === "like"){
          
          this.countlike = res.data.rs.like;
          $('#count-like').html(this.countlike);
          this.statuslike = "unlike";
          
          $("#like").html(this.countlike);
          $("#btnlike").addClass("btn-like");
          $("#btnlike").removeClass("btn-unlike");
          $("#fly-heart").removeClass("co-dis");

          this.projectSer.getIdeaById(id).then(res => {
            //
            let itemCard = res.data.data[0];
            let senderid = sessionStorage.getItem("_id");
            let displayName = sessionStorage.getItem("displayName");
            let employeeId = localStorage.getItem("EmployeeId");
            let teamMember = res.data.data[0].teamData.member;


            const userDataNoti = {
              projectId: itemCard._id,
              projectName: itemCard.projectName,
              empIdowner: itemCard.owner.employeeId,
              sender: {
                id: senderid,
                displayName: displayName,
                employeeId: employeeId
              },
              teamMember
            };

            //

            this.projectSer.getNotiLike(userDataNoti).then(res => {
              //
            });
            
        });

        }
      });
    });
      
  }

  public DeleteComplete(){
    var urlDetail = "";
    if (window.location.hostname === "localhost") {
      urlDetail = "http://localhost:4200/";
    } else if (window.location.hostname === "inning.dld-test.com") {
      urlDetail = "https://" + window.location.host;
    } else {
      urlDetail = "https://" + window.location.host;
    }
    window.location.href = urlDetail;
  }

  copyText(val: string){
    
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    // alert("ddf");
    $('#copyPanel').modal("show");
    setTimeout(() => {

      $('#copyPanel').modal("hide");
    }, 1000);
    
  }

  public getURL(){
    const id = this.route.snapshot.paramMap.get("id");
    

    var urlDetail = "";
    if (window.location.hostname === "localhost") {
      urlDetail = "http://localhost:4200/login?pid="+id;
    } else if (window.location.hostname === "inning.dld-test.com") {
      urlDetail = "https://" + window.location.host+"/login?pid="+id;
    } else {
      urlDetail = "https://" + window.location.host+"/login?pid="+id;
    }
    this.URLID = urlDetail;
  }


}
