import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-historymodify',
  templateUrl: './historymodify.component.html',
  styleUrls: ['./historymodify.component.scss']
})
export class HistorymodifyComponent implements OnInit {

  data: any;
  tabCheck:string = 'tab1';
  constructor() { }

  ngOnInit() {
    
    window.scroll(0,0)

    this.data = [{
      id: 1
    }, {
      id: 1
    }, {
      id: 1
    }, {
      id: 1
    }, {
      id: 1
    }, {
      id: 1
    }]
  }
  tabChange(value) : void {
    this.tabCheck = value;
  }

}
