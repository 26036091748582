import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { environment } from "../../../../../environments/environment";

// IMPORT SERVICES
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";
import { UserServiceService } from "../../../../Services/user-service.service";
import { element } from '@angular/core/src/render3';

// USE JQUERY
declare var jquery: any;
declare var $: any;



@Component({
  selector: 'app-tab-incubate-status',
  templateUrl: './tab-incubate-status.component.html',
  styleUrls: ['./tab-incubate-status.component.scss']
})
export class TabIncubateStatusComponent implements OnInit {
  [x: string]: any;

  allstatus: any;
  itemCard: any;
  che: any;
  itemstatus: any;
  rsLv= [];
  statuschecked = [];
  arrTimline: any;


  constructor(
    private router: Router,
    private projectSer: ProjectideaServiceService,
    private userService: UserServiceService

  ) { }

  ngOnInit() {

    this.getInit()

  }

  async getInit() {

    let statusData = await this.projectSer.getSatusProject();

    this.allstatus = statusData.data.data;

    // 

    // const id = this.route.snapshot.paramMap.get("id");

    var pageURL = window.location.href;
    var lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);
    let id = lastURLSegment;
    let statusproj = await this.projectSer.getProjectStatusById(id);

    if(statusproj != undefined){
      this.itemstatus = statusproj.data.data;
    }
    
    // console.clear();
    // 
    // 

    let arrMergechecklist = [];

    for(let i=0; i<this.allstatus.length; i++){
      for(let e=0; e<this.allstatus[i].state.length; e++){
        if(this.allstatus[i].state[e]._id == this.itemstatus.level[i].checkList[e]._id){
          arrMergechecklist.push({
            state:this.allstatus[i].state[e],
            checklist:this.itemstatus.level[i].checkList[e],
            level:this.itemstatus.level[i]._id
          })
        }
      }
    }

    // 


    this.allstatus.forEach( (element,index) => {
        this.itemstatus.level.forEach( (elementstatus,indexy) => {

          let one = 1;
          // 
            
            if(element._id === elementstatus._id){
              if(this.itemstatus.level[index-1] === undefined){
                this.rsLv.push({
                    idLv : element._id,
                    code : element.code,
                    createBy : element.createBy,
                    createDate :element.createDate,
                    description: element.description,
                    group: element.group,
                    lastUpdate: element.lastUpdate,
                    seq: element.seq,
                    updateBy: element.updateBy,
                    score: elementstatus.score,
                    checklist: elementstatus.checkList,
                    prevscore: "100",
                    nextscore: this.itemstatus.level[index+(+one)].score
                });
              }else if(this.itemstatus.level[index+(+one)] === undefined){
                  this.rsLv.push({
                      idLv : element._id,
                      code : element.code,
                      createBy : element.createBy,
                      createDate :element.createDate,
                      description: element.description,
                      group: element.group,
                      lastUpdate: element.lastUpdate,
                      seq: element.seq,
                      updateBy: element.updateBy,
                      score: elementstatus.score,
                      checklist: elementstatus.checkList,
                      prevscore: this.itemstatus.level[index-1].score,
                      nextscore: "0"
                  });
              }else{
                // 
                this.rsLv.push({
                    idLv : element._id,
                    code : element.code,
                    createBy : element.createBy,
                    createDate :element.createDate,
                    description: element.description,
                    group: element.group,
                    lastUpdate: element.lastUpdate,
                    seq: element.seq,
                    updateBy: element.updateBy,
                    score: elementstatus.score,
                    checklist: elementstatus.checkList,
                    prevscore: this.itemstatus.level[index-1].score,
                    nextscore: this.itemstatus.level[index+(+one)].score
                });
              }
            }
        });
    });

    // 

    let arr = await this.projectSer.getProjectStatusForTimeline(id);
    this.arrTimline = arr.data.data[0];
    // 

    

  }

  public saveStatus(no,idlevel,count){

      var checked = [];
      $("input[name='statuslist-"+no+"']:checked").each(function () {
        checked.push($(this).val());
      });

      
      

    
      if(count === checked.length){

        checked.forEach(element => {
          this.statuschecked.push({
            status:element
          })
        });
        
        // var backOfferButton = document.getElementById('btn-sub'+no);
        // backOfferButton.dataset.target = "#errorSubmit";

        $('#errorSubmit').modal('show');
        $('#ERRlevelid').val(idlevel);
       

      }else{

        checked.forEach(element => {
          this.statuschecked.push({
            status:element
          })
        });

        // var backOfferButton = document.getElementById('btn-sub'+no);
        // backOfferButton.dataset.target = "#saveModal";
        // 
        $('#levelid').val(idlevel);
        $('#saveModal').modal('show');

      }
        
  }

  public confirmAfterErrsubmitStatus(){

    var levelid = $('#ERRlevelid').val();

    // var inputs = document.getElementsByClassName( 'statuschecked' ),
    // check  = [].map.call(inputs, function( input ) {
    //     return input.value;
    // }).join( ',' );

    // var rescheck = check.split(",");
    // 


    $('#levelid').val(levelid);
    $('#errorSubmit').modal('hide');
    // $('#saveModal').modal('show');
      

  }

  public submitStatus(){
    var pageURL = window.location.href;
    var lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);
    let id = lastURLSegment;

    var levelid = $('#levelid').val();

    var inputs = document.getElementsByClassName( 'statuschecked' ),
    check  = [].map.call(inputs, function( input ) {
        return input.value;
    }).join( ',' );

    var rescheck = check.split(",");
    

    let data = []
    rescheck.forEach(element => {
      data.push({
        checkListId:element
      })
    });


    let dataStatus = {
      data
    }

    

    let role = sessionStorage.getItem("Pathrole");

    //this.spinner.show();
    this.projectSer.updateStatusByIdea(id,JSON.stringify(dataStatus.data),levelid,role)
      .then(Response => {
        
        // setTimeout(() => {
        //   this.spinner.hide();
          $('#saveModal').modal('hide');
          // $("#saveModal").click()
          $('#CompleteModal').modal('show');

          sessionStorage.setItem("TabsDetail","updatestatus");
        // }, 900);
      });
 

  }

  public cancel(){
    this.statuschecked = [];
  }

  public onSubmitComplete(){
    window.location.reload();
  }

  public collapse(no){
   
    $( "#collapse"+no ).toggle();
    
    var stylers = $("#collapse"+no).attr("style");
    if(stylers === "display: block;"){
      $("#down-"+no).addClass("co-dis");
      $("#selectall-"+no).removeClass("co-dis");

    }else if(stylers === "display: none;"){

      $("#down-"+no).removeClass("co-dis");
      $("#selectall-"+no).addClass("co-dis");
    }
    
  }

  public collapseview(no){

    $( "#collapseview"+no ).toggle();
    
    var stylers = $("#collapseview"+no).attr("style");
    if(stylers === "display: block;"){
      $("#down-"+no).addClass("co-dis");
      $("#selectall-"+no).removeClass("co-dis");

    }else if(stylers === "display: none;"){

      $("#down-"+no).removeClass("co-dis");
      $("#selectall-"+no).addClass("co-dis");
    }

  }

  selectall(event,i) {
    //
    if(event.target.checked){
      //alert("checked");
      //$('#statuslist-'+i).prop('checked', true);
      $('input[name="statuslist-'+i+'"]').prop('checked' , true);
    }
    else{
      //alert("unchecked");
      $('input[name="statuslist-'+i+'"]').prop('checked' , false);
    }
  }

}
