import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss']
})
export class NavTabsComponent implements OnInit {

  @Input() valueTypeTab : string;
  activeProfile: string;
  constructor() { }

  ngOnInit() {
  }
  changeActive(activeName) {

    if (sessionStorage.getItem("activeTabs")) {
      sessionStorage.setItem("activeTabs", activeName)
      this.activeProfile = sessionStorage.getItem("activeTabs")
    } else {
      sessionStorage.setItem("activeTabs", activeName)
      this.activeProfile = sessionStorage.getItem("activeTabs")
    }
  }

  async ngDoCheck(){
    this.activeProfile = sessionStorage.getItem("activeTabs")
  }
}
