// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    URL: 'https://aisideaportal.ais.co.th/v1/api/',
    API: 'https://aisideaportal.ais.co.th',
    URLUPLOAD: 'https://aisideaportal.ais.co.th',
    URL_WEB_PLAYGROUND: 'https://aisplayground.ais.co.th/',
    production: false,

    ADMA_GET_AUTH_CODE : 'adma/getAuthorizationUrl',
    ADMA_AUTH: 'adma/authorizationCode',
    URL_AUTH_LOGOUT: 'adma/logout',
    URL_USER_LOGINCALLBACK: 'user/login/callback',
    URL_USER_UPDATE: 'user/updateProfile',
    URL_NOTI_LIST_ID: 'user/findNotificationDetailByEmpId',
    URL_ALLNOTI_LIST_ID: 'user/findAllNotificationDetailByEmpId',
    URL_READNOTI_LIST_ID: 'user/updateNotificationStatus',
    URL_UNREAD_LIST_ID: 'user/findUnreadNotification',
    URL_UPDATE_JOIN_TEAM: 'user/updateStatusByEmpId',
    URL_USER_GET_USER_BY_EMAIL: 'user/getMemberByEmail',
    URL_USER_GET_USER_RECOMMEND: 'user/getUserRecommend',
    URL_PROFILE: 'user/getProfileByToken',
    URL_USER: 'user/checkUserByProjectId',
    URL_UPDATEUSER: 'user/updateNotificationEmpIdByEmail',
    URL_GET_OWNER: 'user/getOwnerDetail/',
    URL_UDATE_VIEWDELETEMEMBER: 'user/updateViewDeleteMember',
    URL_USERBYID: 'user/getProfileByUserId',
    URL_USER_MENSION: 'user/getAllUserforMention',
    URL_GET_ALL_USER: 'user/getAllUserforMention',
    URL_SEND_EMAIL: 'user/sendEmail',
    URL_UPDATE_ROLE_USER: 'user/updateRoleUser',
    URL_DELETE_USER_BY_ID: 'user/deleteUserById',
    URL_INSERT_LOGIN_LOG: 'user/loginInsertLog',
  
    URL_PROJECT_LIST: 'project/findProjectList',
    URL_PROJECT_INNO_LIST: 'project/findProjectInnoList',
    URL_PROJECT_RD_LIST: 'project/findProjectRDList',
    URL_PROJECT_NEW_LIST: 'project/findNewProjectList',
    URL_PROJECT_TOP_8: 'project/findProjectTop8',
    URL_PROJECT_CREATE: 'project/createProject',
    URL_PROJECT_LIST_ID: 'project/findProjectById',
    URL_PROJECT_RD_LIST_ID: 'project/findProjectRDById',
    URL_PROJECT_FINDBY_OWN: 'project/findProjectByToken',
    URL_PROJECT_UPDATE: 'project/editProject',
    URL_PROJECT_UPDATE_CHANGE_STATUS: 'project/changeStatus',
    URL_PROJECT_UPDATE_CHANGE_STAGE: 'project/markState',
    URL_TOP4_DETAIL: 'project/findProjectDetailTop4',
    URL_PROJECT_HISTORY: 'project/findProjectHistoryById',
    URL_INVITE_USER_JOIN: 'project/insertUserJoinProject',
    URL_PROJECT_INSERT_MEMBER: 'project/insertMember',
    URL_LIKE_STATUS: 'project/checkLikeorUnlike',
    URL_UPDATE_LIKE_STATUS: 'project/updateLike',
    URL_COMMENT_CREATE: 'project/createComment',
    URL_COMMENT_CREATE_INCUBATOR: 'project/createCommentIncubator',
    URL_COMMENT_CREATE_INCUBATOR_FOR_USER: 'project/createCommentIncubatorforUser',
    URL_GET_COMMENT_BY_PROJECTID: 'project/getCommentByProjectId',
    URL_GET_COMMENT_INCUBATOR_BY_PROJECTID: 'project/getCommentIncubatorByProjectId',
    URL_REPLY_CREATE: 'project/createReply',
    URL_REPLY_CREATE_INCUBATOR: 'project/createReplyIncubator',
    URL_REPLY_CREATE_INCUBATOR_FOR_USER: 'project/createReplyIncubatorForUser',
    URL_EDIT_COMMENT: 'project/updateComment',
    URL_EDIT_REPLY: 'project/updateReply',
    URL_DELETE_COMMENT: 'project/deleteComment',
    URL_DELETE_REPLY: 'project/deleteReply',
    URL_IDEA_BY_USERID: 'project/getProjectByOwnerUserId',
    URL_IDEA_BY_TEAM_AND_STATUS: 'project/getProjectByTeamEmployeeIdAndStatus',
    URL_MULTI_PROJECT: 'project/inviteMemberMultiProject',
    URL_GET_CATEGORY: 'project/getCategory',
    URL_GET_CATEGORY_WITHOUT_EXIT: 'project/getCategoryWithoutExit',
    URL_GET_EVENT: 'project/getEvent',
    URL_GET_NEW_PROJECT_FORMB: 'project/getProjectTop4',
    URL_ACCEPT_INCUBATOR: 'project/incubatorAccept',
    URL_PROJECT_STATUS_BY_ID: 'project/getProjectStatus',
    URL_PROJECT_UPDATE_STATUS_BY_IDEA: 'project/updateProjectStatus',
    URL_PROJECT_STATUS_FOR_TIMELINE: 'project/getProjectCheckList',
    URL_CREATE_PROJECT_RD: 'project/createProjectRD',
    URL_EDIT_PROJECT_RD: 'project/editProjectRD',
    URL_PROJECT_RD_BY_YEAR: 'project/getProjectRDByYear',
    URL_PROJECT_INNO_BY_YEAR: 'project/getProjectINNOByYear',
    URL_PROJECT_INNO_BY_INCUBATOR: 'project/getProjectINNOByIncubator',
    URL_PROJECT_IP_BY_YEAR: 'project/getProjectIPByYear',
    URL_CATEGORY_EDIT: 'project/updateCategory',
    URL_CATEGORY_ADD: 'project/createCategory',
    URL_INCUBATOR_ADD: 'project/addIncubator',
    URL_INCUBATOR_DELETE: 'project/deleteIncubator',
    URL_MEMBER: 'project/getAllMemberByProjectId/',
    URL_UPDATE_STATUS_USER: 'project/updateStatusInviteUser/',
    URL_INSERT_STORY: 'project/insertToProjectStory/',
    URL_PROJECT_DELETE: 'project/deleteProjectInno',
    URL_PROJECT_RESTORE: 'project/restoreProjectInno',
    URL_PROJECT_EXIT: 'project/exitProjectInno',
    URL_INSERT_VIEW_LOG: 'project/viewInsertLog/',
    URL_PROJECT_INNO_BY_INACTIVE: 'project/getProjectINNOInactive',
    URL_CREATE_PROJECT_IP:'project/createProjectIP',
    URL_GET_TYPE_IP:'project/getTypeIP',
    URL_CREATE_TYPE_IP:'project/createTypeIP',
    URL_UPDATE_TYPE_IP:'project/updateTypeIP',
    URL_DELETE_TYPE_IP:'project/deleteTypeIP',
    URL_GET_PROJECT_IP:'project/getProjectIP',
    URL_PROJECT_IP_BY_ID: 'project/findProjectIPById',
    URL_UPDATE_PROJECT_IP:'project/updateProjectIP',
  
    URL_NOTI_LIKE_STATUS: 'notification/likeNotification',
    URL_SEND_EMAIL_NOTI: 'notification/sendEmailToMember/',
    URL_INSERT_NOTI_ONE: 'notification/notiInviteOne/',
  
    URL_DASHBOARD_TOTAL_USER: 'dashboard/dashboardTotalActiveUser',
    URL_DASHBOARD_STATUS_RANGE: 'dashboard/dashboardStatusRange',
    URL_EXPORT_CREATE_PROJECT: 'dashboard/exportProject',
    URL_EXPORT_STATUS_RANGE: 'dashboard/dashboardStatusRange',
    URL_DASHBOARD_TOTAL_USER_BY_YEAR: 'dashboard/dashboardTotalActiveUserByYear',
    URL_EXPORT_PROJECT_BY_YEAR: 'dashboard/exportProjectByYear',
  
    URL_PROJECT_STATUS: 'admin/getProjectStatus',
    URL_PROJECT_STATE: 'admin/getProjectState',
    URL_STATUS_UPDATE: 'admin/updateStatus',
    URL_STATE_UPDATE: 'admin/updateState',
    URL_STATE_REMOVE: 'admin/removeState',
    URL_STATE_EDITNAME: 'admin/editNameState',
    URL_STATUS_EDITNAME: 'admin/editNameStatus',
    URL_STATE_ADD: 'admin/addState',
    URL_STATUS_ADD: 'admin/addStatus',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
