import { Component, OnInit } from '@angular/core';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-pitching-modal',
  templateUrl: './pitching-modal.component.html',
  styleUrls: ['./pitching-modal.component.scss']
})
export class PitchingModalComponent implements OnInit {

  show: any
  message: any
  status = true
  constructor() { }

  ngOnInit() {

  }


  public onPitching(): void {
    this.message= 'คุณต้องการเข้าร่วมในวันที่ 19/06/2018'
    this.status= true 

    $('#Pitching').modal('hide')
    
    $('#pitchingSuccess').modal('show')

  }

  public onCancle(): void {
    this.message= 'คุณต้องการยกเลิกในวันที่ 19/06/2018'
    this.status= false 
    // this.show = { message: 'คุณต้องการยกเลิกในวันที่ 19/06/2018', status: false }

    $('#Pitching').modal('hide')

    $('#pitchingSuccess').modal('show')

  }
}
