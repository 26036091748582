import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import axios from "axios";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthenServiceService {

  public isloggedIn: boolean;

  constructor() { }

  async admaGetAuthUrl() {
    try {
      let setting = axios.create({
        baseURL: environment.URL,
      });
      let data = await setting.get(environment.ADMA_GET_AUTH_CODE);

      return data;

    } catch (err) {
      
    }
  }

  async loginCallback() {
    try {
      let setting = axios.create({
        baseURL: environment.URL,
      });
      let token = sessionStorage.getItem("authen");
      let data = await setting.post(environment.URL_USER_LOGINCALLBACK, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      return data;

    } catch (err) {
      
    }
  }

  async logoutClearToken() {
    try {
      let setting = axios.create({
        baseURL: environment.URL,
      });

      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");

      let data = await setting.get(environment.URL_AUTH_LOGOUT, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      return data;

    } catch (err) {
      
    }
  }



  /* Get Top 10 Notification By Id */

  public async getNotiById(email: string, employeeId: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });


      let dataUser = {
        email: email,
        employeeId: employeeId

      };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      // 

      let data = await setting.post(environment.URL_NOTI_LIST_ID, dataUser, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }

  /* Get All Notification By Id */
  public async getAllNotiById(email: string, employeeId: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        email: email,
        employeeId: employeeId

      };
      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      

      let data = await setting.post(environment.URL_ALLNOTI_LIST_ID, dataUser, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }

  /* Get Read Notification By Id */

  public async getReadNotiById(Id: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });


      let dataUser = {
        id: Id

      };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      

      let data = await setting.post(environment.URL_READNOTI_LIST_ID, dataUser, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }

  /* Get All Notification By Id */
  public async getUnReadById(email: string, employeeId: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });


      let dataUser = {
        email: email,
        employeeId: employeeId

      };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      //

      let data = await setting.post(environment.URL_UNREAD_LIST_ID, dataUser, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }
}
