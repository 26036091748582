import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "../../../../../environments/environment";
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";
import { UserServiceService } from "../../../../Services/user-service.service";

declare var $: any;

@Component({
  selector: 'app-rd-detail-components',
  templateUrl: './rd-detail-components.component.html',
  styleUrls: ['./rd-detail-components.component.scss']
})
export class RdDetailComponentsComponent implements OnInit {

  tempData: any;
  dataUser: any;
  arrItemCard = [];
  itemCard: any;
  public urlHostImage = environment.URLUPLOAD;
  fileName: any;
  fileSize: any;
  filePath: any;

  constructor(
    private projectSer: ProjectideaServiceService,
    public spinner: NgxSpinnerService,
    private userSer: UserServiceService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    sessionStorage.setItem("barActive", "rdproject");
    this.getinit()
    

  }

  async getinit() {

    window.scroll(0, 0)
    

    const id = this.route.snapshot.paramMap.get("id");
    this.tempData = await this.projectSer.getIdeaRDById(id);
    let projectRD = this.tempData.data.data[0];

    this.fileName = projectRD.fileProject == undefined ? "" : projectRD.fileProject.name
    this.fileSize = projectRD.fileProject == undefined ? "" : projectRD.fileProject.fileSize
    this.filePath = projectRD.fileProject == undefined ? "" : projectRD.fileProject.path

    $('#projectDes').append(projectRD.projectDescription);

    this.dataUser = await this.userSer.getUserById(projectRD.owner._id);

    let userAll = await this.userSer.getAllUserforMention();
    let userData = userAll.data.data;

    if(this.dataUser.data.data != undefined){
      this.arrItemCard.push({
        project:projectRD,
        owner:this.dataUser.data.data
      });

      //
    }else{
      userData.forEach(element => {

        //
        if(projectRD.owner.employeeId){
          if(element.employeeId === projectRD.owner.employeeId){
            this.arrItemCard.push({
              project:projectRD,
              owner:element
            });
          }
        }else{
          if(element.email === projectRD.owner.email){
            this.arrItemCard.push({
              project:projectRD,
              owner:element
            });
          }else if(element.email != projectRD.owner.email){
            this.arrItemCard.push({
              project:projectRD,
              owner:undefined
            });
          }
        }
      }); 
    }

    
    this.itemCard = this.arrItemCard[0];
    
    
  }

  download() {
    this.projectSer.downloadfileProject(this.filePath).subscribe(
      data => {
        console.error(data);
        saveAs(data, this.filePath);
      },
      err => {
        //console.error(err);
      }
    );
  }

}
