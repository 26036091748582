import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";

// Import Service
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";


@Component({
  selector: 'app-tab-detail',
  templateUrl: './tab-detail.component.html',
  styleUrls: ['./tab-detail.component.scss']
})
export class TabDetailComponent implements OnInit {

  // @Input() typeNameInput: any;
  // @Input() regionNameInput: any;
  cate: string;
  region: string;
  itemCard: any;
  category: any;
  teamname: any;
  targetGroup: any;
  projectDescription: any;
  projectObjectives: any;
  projectExpectedResults: any;
  itemCategory: any = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
    private _location: Location,
  ) { }

  ngOnInit() {
    this.getInit();
  }

  async getInit(){
    
    // this.cate = this.typeNameInput
    // this.region = this.regionNameInput

    const id = this.route.snapshot.paramMap.get("id");
    let tempData;
    tempData = await this.projectSer.getIdeaById(id);

    this.itemCard = tempData.data.data[0];
    this.category = tempData.data.data[0].category;
    this.teamname = tempData.data.data[0].teamData.teamName;
    this.targetGroup = tempData.data.data[0].targetGroup;
    this.projectDescription = tempData.data.data[0].projectDescription;
    this.projectObjectives = tempData.data.data[0].projectObjectives;
    this.projectExpectedResults = tempData.data.data[0].projectExpectedResults;

    // 
    // console.clear()
    let arrCategory = this.itemCard.projectCategory;
    
    for (let index = 0; index < arrCategory.length; index++) {
      
      // 
      // const element = arrCategory[index];
        this.itemCategory += arrCategory[index].category+", ";
    }
    if(this.itemCategory.includes(",")){
      this.itemCategory = this.itemCategory.substr(0, this.itemCategory.length - 2)
    }
    
    // 
    

    if(this.category === null){
      this.category = "-";
    }else{
      this.category = this.category;
    }

    if(this.teamname === null){
      this.teamname = "-";
    }else{
      this.teamname = this.teamname;
    }

    if(this.targetGroup == null){
      this.targetGroup = "-";
    }else{
      this.targetGroup = this.targetGroup;
    }

    if(this.projectDescription == null){
      this.projectDescription = "-";
    }else{
      this.projectDescription = this.projectDescription;
    }

    if(this.projectObjectives == null){
      this.projectObjectives = "-";
    }else{
      this.projectObjectives = this.projectObjectives;
    }

    if(this.projectExpectedResults == null){
      this.projectExpectedResults = "-";
    }else{
      this.projectExpectedResults = this.projectExpectedResults;
    }

    
   
    


  }

  public countRowsInText(text) {

    let numerOfRowsInText = text != null ? text.split(/\n/).length : 2;
    let rows = numerOfRowsInText < 2 ? 2 : numerOfRowsInText;
    //
    return rows;
  }

}
