import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpModule } from '@angular/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { UserRoutingModule } from './Components/user-components/user.routing.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './Authen/login/login.component';
import { UserComponentsComponent } from './Components/user-components/user-components.component';
import { HeaderComponent } from './Shared/header/header.component';
import { FooterComponent } from './Shared/footer/footer.component';
import { HomeuserComponent } from './Components/user-components/homeuser-component/homeuser.component';

import { CreateIdeaComponent } from './Components/user-components/create-idea-component/create-idea.component';
import { ListideaComponent } from './Components/user-components/navTabprofile-component/myidea/listidea/listidea.component';
import { AllProjectComponentComponent } from './Components/user-components/all-project-component/all-project-component.component';
import { ListjoinideaComponent } from './Components/user-components/navTabprofile-component/joinidea/listjoinidea/listjoinidea.component';
import { ListjointokenideaComponent } from './Components/user-components/navTabprofile-component/jointokenidea/listjointokenidea/listjointokenidea.component';

import { DetailideaComponent } from './Components/user-components/detailidea-component/detailidea.component';
import { ProfileComponent } from './Components/user-components/profile-component/profile.component';

import  { MainProfile } from './Components/user-components/navTabprofile-component/mainprofile.component';

import { HttpClientModule } from  '@angular/common/http';
import { SlidebrandComponent } from './Shared/slidebrand/slidebrand.component';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';
import { SlideprofileComponent } from './Shared/slideprofile/slideprofile.component';
import { SearchComponent } from './Components/user-components/search-component/search.component';

import { NavTabsComponent } from './Components/user-components/Shared/nav-tabs/nav-tabs.component';
import { MorelistComponentComponent } from './Components/user-components/morelist-component/morelist-component.component';
import { FillterButtonComponent } from './Components/user-components/Shared/fillter-button/fillter-button.component';
import { DetailProjectComponent } from "./Components/user-components/Shared/detail-project-component/detail-project-component";

import { DetailComponent } from './Components/user-components/navTabprofile-component/detail/detail.component';
import { HistorymodifyComponent } from './Components/user-components/navTabprofile-component/historymodify/historymodify.component';
import { JoinideaComponent } from './Components/user-components/navTabprofile-component/joinidea/joinidea.component';
import { JointokenideaComponent } from './Components/user-components/navTabprofile-component/jointokenidea/jointokenidea.component';
import { MyideaComponent } from './Components/user-components/navTabprofile-component/myidea/myidea.component';
import { UpdateprofileComponent } from './Components/user-components/updateprofile/updateprofile.component';
import { CommentComponentComponent } from './Components/user-components/Shared/comment-component/comment-component.component';
import { DetailupdateideaComponent } from './Components/user-components/navTabprofile-component/myidea/detailupdateidea/detailupdateidea.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

// Guard Authen 
import { AuthGuard } from './_Guard/auth.guard';
import { EmploGuardGuard } from './_Guard/emplo-guard.guard';
import { RoleAdminGuard } from './_Guard/role-admin.guard';

// Services
import { AuthenServiceService } from './Services/authen-service.service';
import { FillterService } from './Services/fillter.service';
import { ProjectideaServiceService } from './Services/projectidea-service.service';
import { CommentServiceService } from './Services/comment-service.service';
import { JoinTeamModalComponent } from './Components/modal-components/join-team-modal/join-team-modal.component';
import { ProjectTeamModalComponent } from './Components/modal-components/project-team-modal/project-team-modal.component';
import { PitchingModalComponent } from './Components/modal-components/pitching-modal/pitching-modal.component';
import { UserServiceService } from './Services/user-service.service';
import { ReversePipe } from './Pipes/reverse.pipe';
import { EditIdeaComponentComponent } from './Components/user-components/edit-idea-component/edit-idea-component.component';
import { ModalFailComponent } from './Components/modal-components/modal-fail/modal-fail.component';
import { EditSuccessModalComponent } from './Components/modal-components/edit-success-modal/edit-success-modal.component';
import { CropimageComponent } from './Shared/cropimage/cropimage.component';
import { ExcelService } from './Services/excel.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { from } from 'rxjs';

import { MainBothModule } from '../app/Shared/main.both.module';

import { ModalModule } from 'ngx-bootstrap';
import { AllNotiComponentComponent } from './Components/user-components/all-noti-component/all-noti-component.component';
import { TabDetailComponent } from './Components/user-components/Shared/tab-detail/tab-detail.component';
import { TabCommentComponent } from './Components/user-components/Shared/tab-comment/tab-comment.component';
import { TabProguessComponent } from './Components/user-components/Shared/tab-proguess/tab-proguess.component';
import { TabHistoryComponent } from './Components/user-components/Shared/tab-history/tab-history.component';
import { TabMemberComponent } from './Components/user-components/Shared/tab-member/tab-member.component';
import { FooterDetailComponent } from './Components/user-components/Shared/footer-detail/footer-detail.component';
import { DetailmemberComponentComponent } from './Components/user-components/detailmember-component/detailmember-component.component';
import { DeletememberComponent } from './Components/user-components/Shared/deletemember/deletemember.component';
import { CommentBoxComponent } from "./Components/user-components/Shared/comment-box/comment-box.component";

import { ClickOutsideDirective } from './Components/user-components/Shared/directive/click-outside.directive';
import { MensionComponent } from './Components/user-components/Shared/mension/mension.component';
import { MentionModule } from 'angular-mentions';
import { UserprofileComponentComponent } from './Components/user-components/userprofile-component/userprofile-component.component';
// import { MentionUIModule } from 'angular-ui-mention';
//import {NgMentionsModule} from "@nth-cloud/ng-mentions";
// import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { NgMentionsModule } from 'ng-mentions';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragulaModule } from 'ng2-dragula';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';


import { ChecklistModule } from "angular-checklist";
import { IncubatorComponentComponent } from './Components/user-components/incubator-component/incubator-component.component';
import { DetailIncubateComponentComponent } from './Components/user-components/incubator/detail-incubate-component/detail-incubate-component.component';
import { TabIncubateStatusComponent } from './Components/user-components/Shared/tab-incubate-status/tab-incubate-status.component';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { TabCommentIncubatorComponent } from './Components/user-components/Shared/tab-comment-incubator/tab-comment-incubator.component';
import { CommentBoxIncubatorComponent } from './Components/user-components/Shared/comment-box-incubator/comment-box-incubator.component';
import { MyincubatorComponentComponent } from './Components/user-components/incubator/myincubator-component/myincubator-component.component';
import { TimelineComponent } from './Components/user-components/Shared/timeline/timeline.component';
import { RdAllprojectComponentsComponent } from './Components/user-components/rd-components/rd-allproject-components/rd-allproject-components.component';
import { RdDetailComponentsComponent } from './Components/user-components/rd-components/rd-detail-components/rd-detail-components.component';

//datatable
import { DataTablesModule } from 'angular-datatables';
import { IpAllprojectComponentsComponent } from './Components/user-components/ip-components/ip-allproject-components/ip-allproject-components.component';
import { IpDetialComponentsComponent } from './Components/user-components/ip-components/ip-detial-components/ip-detial-components.component';
// import { IdeaInactiveComponent } from './Components/admin-components/idea-inactive/idea-inactive.component';







//graph components
// import { GraphTotalMemberComponent } from './Shared/graph-total-member/graph-total-member.component';



const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};


@NgModule({
  declarations: [
    AppComponent,LoginComponent,UserComponentsComponent,HeaderComponent,
    FooterComponent,HomeuserComponent,CreateIdeaComponent,
    ListideaComponent,ListjoinideaComponent,ListjointokenideaComponent,
    SlidebrandComponent,DetailideaComponent, SlideprofileComponent, ProfileComponent,
    SearchComponent, DetailupdateideaComponent,
    MainProfile,DetailComponent,MyideaComponent,CommentComponentComponent,
    NavTabsComponent,HistorymodifyComponent,JoinideaComponent,JointokenideaComponent,
    MorelistComponentComponent,UpdateprofileComponent,
    FillterButtonComponent,
    DetailProjectComponent,
    JoinTeamModalComponent,
    ProjectTeamModalComponent,
    PitchingModalComponent,
    ReversePipe,ModalFailComponent,
    EditIdeaComponentComponent,
    EditSuccessModalComponent,
    //CropimageComponent,
    AllProjectComponentComponent,
    AllNotiComponentComponent,
    TabDetailComponent,
    TabCommentComponent,
    TabProguessComponent,
    TabHistoryComponent,
    TabMemberComponent,
    FooterDetailComponent,
    DetailmemberComponentComponent,
    DeletememberComponent,
    ClickOutsideDirective,
    MensionComponent,
    UserprofileComponentComponent,
    CommentBoxComponent,
    IncubatorComponentComponent,
    DetailIncubateComponentComponent,
    TabIncubateStatusComponent,
    TabCommentIncubatorComponent,
    CommentBoxIncubatorComponent,
    MyincubatorComponentComponent,
    TimelineComponent,
    RdAllprojectComponentsComponent,
    RdDetailComponentsComponent,
    IpAllprojectComponentsComponent,
    IpDetialComponentsComponent,
    // IdeaInactiveComponent,
    
    
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    NgxSpinnerModule,
    UserRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    ImageCropperModule,
    MainBothModule,
    ModalModule.forRoot(),
    NgbModule.forRoot(),
    MentionModule,
    //NgZorroAntdModule
    NgMentionsModule,
    ChecklistModule,
    SwiperModule,
    DragDropModule,
    DragulaModule.forRoot(),
    DataTablesModule,
    CKEditorModule,
  ],
  providers: [
    EmploGuardGuard,
    AuthenServiceService,
    RoleAdminGuard,
    FillterService,
    AuthGuard,
    ProjectideaServiceService,
    UserServiceService,
    ExcelService,
    CommentServiceService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
    //{ provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent],
  //exports: [CropimageComponent]
})


export class AppModule { }
