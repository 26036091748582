import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "../../../../../environments/environment";
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";
import { UserServiceService } from "../../../../Services/user-service.service";

@Component({
  selector: 'app-ip-detial-components',
  templateUrl: './ip-detial-components.component.html',
  styleUrls: ['./ip-detial-components.component.scss']
})
export class IpDetialComponentsComponent implements OnInit {

  projectIP: any = [];
  urlHostImage = environment.URLUPLOAD;
  arrOwner = [];

  constructor(
    private projectSer: ProjectideaServiceService,
    public spinner: NgxSpinnerService,
    private userSer: UserServiceService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    sessionStorage.setItem("barActive", "ipproject");
    this.getInit()
  }

  async getInit() {
    $('#create_inno').addClass('co-dis');
    window.scroll(0, 0)
    const id = this.route.snapshot.paramMap.get("id");
    let tempData = await this.projectSer.getIdeaIPById(id);
    
    this.projectIP = tempData.data.data[0];

    


    this.projectIP.owner.forEach(element => {
      let res = this.userSer.getProfileByEmpId(element.employeeId);
      res.then(data => {
        
        this.arrOwner.push({
          data: data.data.data
        })
      });

    });

    

  }

  download(filePath) {
    this.projectSer.downloadfileProject(filePath).subscribe(
      data => {
        console.error(data);
        saveAs(data, filePath);
      },
      err => {
        //console.error(err);
      }
    );
  }
  

}
