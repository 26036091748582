import { Pipe, PipeTransform } from "@angular/core";
import { IfStmt } from "@angular/compiler";

@Pipe({
  name: "search"
})
export class SearchPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!args) {
      return value;
    } else {
      args = args.toLowerCase();
    }

    if (value != null) {
      return value.filter(items => {
        return items.projectName.toLowerCase().includes((args)) == true;
      });
    }
  }
}
