import { Component, OnInit } from '@angular/core';

// Import Services
import { ProjectideaServiceService } from '../../../../../Services/projectidea-service.service';
import { environment } from "../../../../../../environments/environment";
@Component({
  selector: 'app-listidea',
  templateUrl: './listidea.component.html',
  styleUrls: ['./listidea.component.scss']
})
export class ListideaComponent implements OnInit {

  public item;
  urlHostImage = environment.URLUPLOAD;
  constructor(private projectSer : ProjectideaServiceService) { }

  ngOnInit() {
   
    this.getInit();
    
  }
  async getInit(){
    window.scroll(0,0)
    // Get Data Project List
    let tempData;
    tempData = await this.projectSer.getIdeaBytoken();
    this.item = tempData.data.data

    this.item.sort((val1, val2)=> {
      return new Date(val2.createDate).getTime() - new Date(val1.createDate).getTime()
    })
  }
  sortFunc (a, b) {
    return new Date(b.createDate).getTime() - new Date(a.createDate).getTime()
  }
}
