import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";

import { ProjectideaServiceService } from "../../../Services/projectidea-service.service";

@Component({
  selector: "app-morelist-component",
  templateUrl: "./morelist-component.component.html",
  styleUrls: ["./morelist-component.component.scss"]
})
export class MorelistComponentComponent implements OnInit {
  
  urlHostImage = environment.URLUPLOAD;
  itemCard: any;
  cateName: any;
  regionName: any;
  @Input() myVal: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService
  ) {}

  ngOnInit() {

    this.getInit()

  }

  async getInit(){
    window.scroll(0, 0);
    // Set Bar active header
    sessionStorage.setItem("barActive", "home");
    this.route.params.subscribe(routeParams => {
      window.scroll(0, 1000);
      this.regionName = routeParams["region"];
      this.cateName = routeParams["cate"];
      this.onChangeType(this.regionName, this.cateName);
    });
  }
  
  async onChangeType(region, cate) {
    if (cate == "AllIdeas" && region == "Allregion") {
      this.router.navigate([""]);
    } else {
      this.router.navigate(["/morelist", region, cate]);
      let tem = await this.projectSer.getIdeaByRegion(region, cate);
      if (tem) {
        this.itemCard = tem;
        this.itemCard.sort((val1, val2) => {
          return (
            new Date(val1.createDate).getTime() -
            new Date(val2.createDate).getTime()
          );
        });
      } else {
        this.itemCard = [];
      }
    }
  }
}
