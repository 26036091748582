import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './Authen/login/login.component';

const routes: Routes = [

  {
    path: 'login', component: LoginComponent,
  },
  {
    path: 'login/:token', component: LoginComponent,
  },
  {
    path: 'admin', 
    loadChildren: "./Components/admin-components/admin.module.main#AdminModuleMain",
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
