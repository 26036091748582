import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http } from "@angular/http";
import axios from "axios";

import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class ProjectideaServiceService {
  public tempDataFloderScore = {
    tempAll: 0,
    tempSolu: 0,
    tempMark: 0,
    tempOnSh: 0,
    tempDrop: 0
  };

  constructor(private _http: Http, private http: HttpClient) { }

  public async sessionTimeout(){
    sessionStorage.removeItem('authen');
  }

  /* Get Project Idea ALL */
  public async getIdea() {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_LIST);
      return data;
    } catch (err) {
      
    }
  }
  public async getIdeaRD() {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_RD_LIST);
      return data;
    } catch (err) {
      
    }
  }


  public async getNewProject(region) {
    try {
      let dataTemp = {
        region: region
      };
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.post(environment.URL_PROJECT_NEW_LIST, dataTemp);
      return data;
    } catch (err) {
      
    }
  }
  public async getProjectTop8() {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_TOP_8);
      return data;
    } catch (err) {
      
    }
  }
  /* Get Project Idea Region */
  public async getIdeaRegion(region, category) {
    let tempdata: any = [];
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(
        environment.URL_PROJECT_LIST + "/?region=" + region
      );

      data.data.data.forEach(ele => {
        ele.data.forEach(ele2 => {
          tempdata.push(ele2);
        });
      });

      return tempdata;
    } catch (err) {
      
    }
  }
  /* Get Project Idea ALL */
  public async getIdeaAllsearch() {
    let tempdata: any = [];
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_LIST);

      data.data.data.forEach(ele => {
        ele.data.forEach(ele2 => {
          tempdata.push(ele2);
        });
      });

      return tempdata;
    } catch (err) {
      
    }
  }

  /* Get Project Idea by token own ALL */
  public async getIdeaBytoken() {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      let data = await setting.get(environment.URL_PROJECT_FINDBY_OWN, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      if(data.status === 401){
       await this.sessionTimeout();
      }

      return data;
    } catch (err) {
      
    }
  }

  /* Get Project Idea ALL */
  public async getIdeaByRegion(region: string, cate: string) {
    if (region == "Allregion") {
      region = "";
    }
    if (cate == "AllIdeas") {
      cate = "";
    }

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataTemp = {
        cate: cate,
        region: region
      };
      let itemPush: any = [];
      let data = await setting.post(environment.URL_PROJECT_LIST, dataTemp);
      let dataReturn: any = null;
      data.data.data.forEach(element => {
        element.data.forEach(element2 => {
          itemPush.push(element2);
        });
      });
      return itemPush;
    } catch (err) {
      
    }
  }

  /* Get Project Innojump Idea ALL */
  public async getIdeaInnoByRegion(region: string, cate: string, year: string) {
    if (region == "Allregion") {
      region = "";
    }
    if (cate == "AllIdeas") {
      cate = "";
    }
    if (year == "all") {
      year = "";
    }

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataTemp = {
        cate: cate,
        region: region,
        year: year
      };
      let itemPush: any = [];
      let data = await setting.post(environment.URL_PROJECT_INNO_LIST, dataTemp);
      let dataReturn: any = null;
      data.data.data.forEach(element => {
        element.data.forEach(element2 => {
          itemPush.push(element2);
        });
      });
      return itemPush;
    } catch (err) {
      
    }
  }


  /* Get Project R&D Idea ALL */
  public async getIdeaRDByRegion(region: string, cate: string, year: string) {
    if (region == "Allregion") {
      region = "";
    }
    if (cate == "AllIdeas") {
      cate = "";
    }
    if (year == "all") {
      year = "";
    }

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataTemp = {
        cate: cate,
        region: region,
        year: year
      };
      let itemPush: any = [];
      let data = await setting.post(environment.URL_PROJECT_RD_LIST, dataTemp);
      let dataReturn: any = null;
      data.data.data.forEach(element => {
        element.data.forEach(element2 => {
          itemPush.push(element2);
        });
      });
      return itemPush;
    } catch (err) {
      
    }
  }

  /* Get Project Idea By Id */
  public async getIdeaById(Id: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_LIST_ID + "/" + Id);
      return data;
    } catch (err) {
      
    }
  }

  /* Get Project Idea By Id */
  public async getIdeaRDById(Id: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_RD_LIST_ID + "/" + Id);
      return data;
    } catch (err) {
      
    }
  }

  public async changeStatusIdea(Id: string, status: string) {
    const token = sessionStorage.getItem("authen");
    const dataSent = new FormData();

    dataSent.append("projectId", Id);
    dataSent.append("status", status);

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.post(
        environment.URL_PROJECT_UPDATE_CHANGE_STATUS,
        dataSent,
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
        }
      );
      this.getDashboardScore();
      return data;
    } catch (err) {
      
    }
  }
  public async changeStageIdea(Id: string, status: string, state: string) {
    const token = sessionStorage.getItem("authen");
    const dataSent = {
      id: Id,
      status: status,
      state: state
    };

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.post(
        environment.URL_PROJECT_UPDATE_CHANGE_STAGE,
        dataSent,
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
        }
      );
      this.getDashboardScore();
      return data;
    } catch (err) {
      
    }
  }

  /* Get Project Idea By Id */
  public async updateIdeaById(Id: string, dataPatch, inputTeam, imageName, changeStory, deleteMemberData, role) {
    const token = sessionStorage.getItem("authen");
    const formDataUpdate = new FormData();

    for (var i = 0; i < dataPatch.categoryList.length; i++) {
      if (dataPatch.categoryList[i].status == true) {
        formDataUpdate.append("categoryId", dataPatch.categoryList[i]._id);
        formDataUpdate.append("categoryCode", dataPatch.categoryList[i].code);
        formDataUpdate.append("categoryGroup", dataPatch.categoryList[i].group);
      }
    }


    if (imageName != null) {
      //
      formDataUpdate.append("_id", dataPatch._id);
      formDataUpdate.append("coverPic", dataPatch.coverPic, imageName);
      formDataUpdate.append("projectName", dataPatch.projectName);
      formDataUpdate.append("targetGroup", dataPatch.targetGroup);
      formDataUpdate.append("projectDescription", dataPatch.projectDescription);
      formDataUpdate.append("projectObjectives", dataPatch.projectObjectives);
      formDataUpdate.append("projectExpectedResults", dataPatch.projectExpectedResults);
      formDataUpdate.append("developer", dataPatch.developer);
      formDataUpdate.append("graphicDesign", dataPatch.graphicDesign);
      formDataUpdate.append("engineering", dataPatch.engineering);
      formDataUpdate.append("marketing", dataPatch.marketing);
      formDataUpdate.append("sales", dataPatch.sales);
      formDataUpdate.append("other", dataPatch.other);
      formDataUpdate.append("developerDescription", dataPatch.developerDescription);
      formDataUpdate.append("graphicDesignDescription", dataPatch.graphicDesignDescription);
      formDataUpdate.append("engineeringDescription", dataPatch.engineeringDescription);
      formDataUpdate.append("marketingDescription", dataPatch.marketingDescription);
      formDataUpdate.append("salesDescription", dataPatch.salesDescription);
      formDataUpdate.append("otherDescription", dataPatch.otherDescription);
      //formDataUpdate.append("category", dataPatch.category);
      formDataUpdate.append("type", "2");
      formDataUpdate.append("team", inputTeam);
      formDataUpdate.append("changeStory", changeStory);
      formDataUpdate.append("deleteMemberData", deleteMemberData);
      formDataUpdate.append("role", role);
    }
    else {
      //
      formDataUpdate.append("_id", dataPatch._id);
      formDataUpdate.append("coverPic", "");
      formDataUpdate.append("projectName", dataPatch.projectName);
      formDataUpdate.append("targetGroup", dataPatch.targetGroup);
      formDataUpdate.append("projectDescription", dataPatch.projectDescription);
      formDataUpdate.append("projectObjectives", dataPatch.projectObjectives);
      formDataUpdate.append("projectExpectedResults", dataPatch.projectExpectedResults);
      formDataUpdate.append("developer", dataPatch.developer);
      formDataUpdate.append("graphicDesign", dataPatch.graphicDesign);
      formDataUpdate.append("engineering", dataPatch.engineering);
      formDataUpdate.append("marketing", dataPatch.marketing);
      formDataUpdate.append("sales", dataPatch.sales);
      formDataUpdate.append("other", dataPatch.other);
      formDataUpdate.append("developerDescription", dataPatch.developerDescription);
      formDataUpdate.append("graphicDesignDescription", dataPatch.graphicDesignDescription);
      formDataUpdate.append("engineeringDescription", dataPatch.engineeringDescription);
      formDataUpdate.append("marketingDescription", dataPatch.marketingDescription);
      formDataUpdate.append("salesDescription", dataPatch.salesDescription);
      formDataUpdate.append("otherDescription", dataPatch.otherDescription);
      //formDataUpdate.append("category", dataPatch.category);
      formDataUpdate.append("type", "2");
      formDataUpdate.append("team", inputTeam);
      formDataUpdate.append("changeStory", changeStory);
      formDataUpdate.append("deleteMemberData", deleteMemberData);
      formDataUpdate.append("role", role);
    }
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.put(
        environment.URL_PROJECT_UPDATE + "/" + Id,
        formDataUpdate,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      return err.response;
    }
  }

  /* Create Project */
  public async createIdea(dataCreateIdea, inputTeam, imageName) {
    const formData = new FormData();

    
    for (var i = 0; i < dataCreateIdea.categoryList.length; i++) {
      if (dataCreateIdea.categoryList[i].status == true) {
        formData.append("categoryId", dataCreateIdea.categoryList[i]._id);
        formData.append("categoryCode", dataCreateIdea.categoryList[i].code);
        formData.append("categoryGroup", dataCreateIdea.categoryList[i].group);
      }
    }

    for (var i = 0; i < inputTeam.length; i++) {
      formData.append("empId", inputTeam[i].empId);
      formData.append("email", inputTeam[i].empEmail);
      formData.append("name", inputTeam[i].empName);
      formData.append("jobDescription", inputTeam[i].empDescription);
      formData.append("status", "รอการตอบรับ");
    }

    if (imageName != null) {
      formData.append("teamName", dataCreateIdea.teamName);
      formData.append("developer", dataCreateIdea.developer);
      formData.append("graphicDesign", dataCreateIdea.graphicDesign);
      formData.append("engineering", dataCreateIdea.engineering);
      formData.append("marketing", dataCreateIdea.marketing);
      formData.append("sales", dataCreateIdea.sales);
      formData.append("other", dataCreateIdea.other);
      formData.append("developerDescription", dataCreateIdea.developerDescription);
      formData.append("graphicDesignDescription", dataCreateIdea.graphicDesignDescription);
      formData.append("engineeringDescription", dataCreateIdea.engineeringDescription);
      formData.append("marketingDescription", dataCreateIdea.marketingDescription);
      formData.append("salesDescription", dataCreateIdea.salesDescription);
      formData.append("otherDescription", dataCreateIdea.otherDescription);
      formData.append("coverPic", dataCreateIdea.coverPic, imageName);
      formData.append("projectName", dataCreateIdea.projectName);
      formData.append("targetGroup", dataCreateIdea.targetGroup);
      formData.append("projectDescription", dataCreateIdea.projectDescription);
      formData.append("projectObjectives", dataCreateIdea.projectObjectives);
      formData.append("projectExpectedResults", dataCreateIdea.projectExpectedResults);
      //formData.append("category", dataCreateIdea.category);
      formData.append("fileProject", dataCreateIdea.fileProject);
      formData.append("fileSize", dataCreateIdea.fileSize);
    } else {
      formData.append("teamName", dataCreateIdea.teamName);
      formData.append("developer", dataCreateIdea.developer);
      formData.append("graphicDesign", dataCreateIdea.graphicDesign);
      formData.append("engineering", dataCreateIdea.engineering);
      formData.append("marketing", dataCreateIdea.marketing);
      formData.append("sales", dataCreateIdea.sales);
      formData.append("other", dataCreateIdea.other);
      formData.append("developerDescription", dataCreateIdea.developerDescription);
      formData.append("graphicDesignDescription", dataCreateIdea.graphicDesignDescription);
      formData.append("engineeringDescription", dataCreateIdea.engineeringDescription);
      formData.append("marketingDescription", dataCreateIdea.marketingDescription);
      formData.append("salesDescription", dataCreateIdea.salesDescription);
      formData.append("otherDescription", dataCreateIdea.otherDescription);
      formData.append("coverPic", "");
      formData.append("projectName", dataCreateIdea.projectName);
      formData.append("targetGroup", dataCreateIdea.targetGroup);
      formData.append("projectDescription", dataCreateIdea.projectDescription);
      formData.append("projectObjectives", dataCreateIdea.projectObjectives);
      formData.append("projectExpectedResults", dataCreateIdea.projectExpectedResults);
      //formData.append("category", dataCreateIdea.category);
      formData.append("fileProject", dataCreateIdea.fileProject);
      formData.append("fileSize", dataCreateIdea.fileSize);
    }

    let token = sessionStorage.getItem("authen");

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let data = await setting.post(environment.URL_PROJECT_CREATE, formData, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }
  public async getIdeaExport(region) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get("project/exportToCsv/" + region);
      return data;
    } catch (err) {
      
    }
  }

  public async getLastHistory(id: String) {
    let token = sessionStorage.getItem("authen");

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get("project/findLastHistory/" + id, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });
      return data;
    } catch (err) {
      
    }
  }

  public async checkProjectName(name: String) {
    let token = sessionStorage.getItem("authen");
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get("project/checkProjectName/" + name, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });
      return data;
    } catch (err) {
      
    }
  }

  public async viewLog() {
    let token = sessionStorage.getItem("authen");
    
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      
      let data = await setting.post(environment.URL_INSERT_VIEW_LOG, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });
      
      return data;
    } catch (err) {
      
    }
  }
  // public async deleteMember(project: any, deleteMember: any, role) {
  //   try {
  //     const token = sessionStorage.getItem("authen");

  //     
  //     
  //     const formData = {
  //       status: "ถูกเชิญออกจากโครงการ",
  //       changeStory: {
  //         projectId: project._id,
  //         changeBy: project.owner._id,
  //         message: "คุณ " + deleteMember.displayName + " ถูกเชิญออกจากโครงการ",
  //         userType: role
  //       },
  //       notification: {
  //         projectId: project._id,
  //         projectName: project.projectName,
  //         sender: project.owner.employeeId,
  //         senderName: project.owner.displayName,
  //         recipient: { _id: deleteMember._id, displayName: deleteMember.displayName, employeeId: deleteMember.employeeId },
  //       },
  //     };

  //     let setting = axios.create({
  //       baseURL: environment.URL
  //     });
  //     let data = await setting.put("project/deleteMember/" + deleteMember._id,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: token, "Access-Control-Allow-Origin": "*",
  //           //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
  //         }
  //       }
  //     );
  //     return data;
  //   } catch (err) {
  //     
  //   }
  // }

  public async findNameByEmail(email: String) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get("project/findNameByEmail/" + email, {
      });
      return data;
    } catch (err) {
      
    }
  }
  public async getDashboardScore() {
    let tempdata: any = [];
    let dataScore = {
      all: {
        allIdea: 0,
        solutonfit: 0,
        marketfit: 0,
        onshelf: 0,
        Drop: 0
      },
      bkk: {
        allIdea: 0,
        solutonfit: 0,
        marketfit: 0,
        onshelf: 0,
        Drop: 0
      },
      roc: {
        allIdea: 0,
        solutonfit: 0,
        marketfit: 0,
        onshelf: 0,
        Drop: 0
      },
      roe: {
        allIdea: 0,
        solutonfit: 0,
        marketfit: 0,
        onshelf: 0,
        Drop: 0
      },
      ron: {
        allIdea: 0,
        solutonfit: 0,
        marketfit: 0,
        onshelf: 0,
        Drop: 0
      },
      ros: {
        allIdea: 0,
        solutonfit: 0,
        marketfit: 0,
        onshelf: 0,
        Drop: 0
      },
      rone: {
        allIdea: 0,
        solutonfit: 0,
        marketfit: 0,
        onshelf: 0,
        Drop: 0
      }
    };
    try {
      let bkkLeng = await this.getIdeaByRegion("BKK", "AllIdeas");
      let roc = await this.getIdeaByRegion("ROC", "AllIdeas");
      let roe = await this.getIdeaByRegion("ROE", "AllIdeas");
      let ron = await this.getIdeaByRegion("RON", "AllIdeas");
      let ros = await this.getIdeaByRegion("ROS", "AllIdeas");
      let rone = await this.getIdeaByRegion("RONE", "AllIdeas");

      dataScore.bkk.allIdea = bkkLeng.length;
      dataScore.roc.allIdea = roc.length;
      dataScore.roe.allIdea = roe.length;
      dataScore.ron.allIdea = ron.length;
      dataScore.ros.allIdea = ros.length;
      dataScore.rone.allIdea = rone.length;

      dataScore.bkk.solutonfit = bkkLeng.filter(
        snap => snap.status === "Problem / Solution Fit"
      ).length;
      dataScore.bkk.marketfit = bkkLeng.filter(
        snap => snap.status === "Product / Market Fit"
      ).length;
      dataScore.bkk.onshelf = bkkLeng.filter(
        snap => snap.status === "On Shelf"
      ).length;
      dataScore.bkk.Drop = bkkLeng.filter(
        snap => snap.status === "Drop"
      ).length;

      dataScore.roc.solutonfit = roc.filter(
        snap => snap.status === "Problem / Solution Fit"
      ).length;
      dataScore.roc.marketfit = roc.filter(
        snap => snap.status === "Product / Market Fit"
      ).length;
      dataScore.roc.onshelf = roc.filter(
        snap => snap.status === "On Shelf"
      ).length;
      dataScore.roc.Drop = roc.filter(snap => snap.status === "Drop").length;

      dataScore.roe.solutonfit = roe.filter(
        snap => snap.status === "Problem / Solution Fit"
      ).length;
      dataScore.roe.marketfit = roe.filter(
        snap => snap.status === "Product / Market Fit"
      ).length;
      dataScore.roe.onshelf = roe.filter(
        snap => snap.status === "On Shelf"
      ).length;
      dataScore.roe.Drop = roe.filter(snap => snap.status === "Drop").length;

      dataScore.ron.solutonfit = ron.filter(
        snap => snap.status === "Problem / Solution Fit"
      ).length;
      dataScore.ron.marketfit = ron.filter(
        snap => snap.status === "Product / Market Fit"
      ).length;
      dataScore.ron.onshelf = ron.filter(
        snap => snap.status === "On Shelf"
      ).length;
      dataScore.ron.Drop = ron.filter(snap => snap.status === "Drop").length;

      dataScore.ros.solutonfit = ros.filter(
        snap => snap.status === "Problem / Solution Fit"
      ).length;
      dataScore.ros.marketfit = ros.filter(
        snap => snap.status === "Product / Market Fit"
      ).length;
      dataScore.ros.onshelf = ros.filter(
        snap => snap.status === "On Shelf"
      ).length;
      dataScore.ros.Drop = ros.filter(snap => snap.status === "Drop").length;

      dataScore.rone.solutonfit = rone.filter(
        snap => snap.status === "Problem / Solution Fit"
      ).length;
      dataScore.rone.marketfit = rone.filter(
        snap => snap.status === "Product / Market Fit"
      ).length;
      dataScore.rone.onshelf = rone.filter(
        snap => snap.status === "On Shelf"
      ).length;
      dataScore.rone.Drop = rone.filter(snap => snap.status === "Drop").length;

      dataScore.all.Drop =
        dataScore.bkk.Drop +
        dataScore.roc.Drop +
        dataScore.roe.Drop +
        dataScore.ron.Drop +
        dataScore.ros.Drop +
        dataScore.rone.Drop;
      dataScore.all.onshelf =
        dataScore.bkk.onshelf +
        dataScore.roc.onshelf +
        dataScore.roe.onshelf +
        dataScore.ron.onshelf +
        dataScore.ros.onshelf +
        dataScore.rone.onshelf;
      dataScore.all.marketfit =
        dataScore.bkk.marketfit +
        dataScore.roc.marketfit +
        dataScore.roe.marketfit +
        dataScore.ron.marketfit +
        dataScore.ros.marketfit +
        dataScore.rone.marketfit;
      dataScore.all.solutonfit =
        dataScore.bkk.solutonfit +
        dataScore.roc.solutonfit +
        dataScore.roe.solutonfit +
        dataScore.ron.solutonfit +
        dataScore.ros.solutonfit +
        dataScore.rone.solutonfit;
      dataScore.all.allIdea =
        bkkLeng.length +
        roc.length +
        roe.length +
        ron.length +
        ros.length +
        rone.length; //fix here

      return dataScore;
    } catch (err) {
      
    }
  }

  public async getDashboardScoreInno(year) {
    let tempdata: any = [];
    let dataScore = {
      all: {
        allIdea: 0,
      },
      bkk: {
        allIdea: 0,
      },
      roc: {
        allIdea: 0,
      },
      roe: {
        allIdea: 0,
      },
      ron: {
        allIdea: 0,
      },
      ros: {
        allIdea: 0,
      },
      rone: {
        allIdea: 0,
      }
    };
    try {
      let bkkLeng = await this.getIdeaInnoByRegion("BKK", "AllIdeas", year);
      // 
      let roc = await this.getIdeaInnoByRegion("ROC", "AllIdeas", year);
      let roe = await this.getIdeaInnoByRegion("ROE", "AllIdeas", year);
      let ron = await this.getIdeaInnoByRegion("RON", "AllIdeas", year);
      let ros = await this.getIdeaInnoByRegion("ROS", "AllIdeas", year);
      let rone = await this.getIdeaInnoByRegion("RONE", "AllIdeas", year);

      dataScore.bkk.allIdea = bkkLeng.length;
      dataScore.roc.allIdea = roc.length;
      dataScore.roe.allIdea = roe.length;
      dataScore.ron.allIdea = ron.length;
      dataScore.ros.allIdea = ros.length;
      dataScore.rone.allIdea = rone.length;

      dataScore.all.allIdea =
        bkkLeng.length +
        roc.length +
        roe.length +
        ron.length +
        ros.length +
        rone.length; //fix here

      return dataScore;
    } catch (err) {
      
    }
  }

  public async getDashboardScoreRD(year) {
    let tempdata: any = [];
    let dataScore = {
      all: {
        allIdea: 0,
      },
      bkk: {
        allIdea: 0,
      },
      roc: {
        allIdea: 0,
      },
      roe: {
        allIdea: 0,
      },
      ron: {
        allIdea: 0,
      },
      ros: {
        allIdea: 0,
      },
      rone: {
        allIdea: 0,
      }
    };
    try {
      let bkkLeng = await this.getIdeaRDByRegion("BKK", "AllIdeas", year);
      
      let roc = await this.getIdeaRDByRegion("ROC", "AllIdeas", year);
      let roe = await this.getIdeaRDByRegion("ROE", "AllIdeas", year);
      let ron = await this.getIdeaRDByRegion("RON", "AllIdeas", year);
      let ros = await this.getIdeaRDByRegion("ROS", "AllIdeas", year);
      let rone = await this.getIdeaRDByRegion("RONE", "AllIdeas", year);

      dataScore.bkk.allIdea = bkkLeng.length;
      dataScore.roc.allIdea = roc.length;
      dataScore.roe.allIdea = roe.length;
      dataScore.ron.allIdea = ron.length;
      dataScore.ros.allIdea = ros.length;
      dataScore.rone.allIdea = rone.length;

      dataScore.all.allIdea =
        bkkLeng.length +
        roc.length +
        roe.length +
        ron.length +
        ros.length +
        rone.length; //fix here

      return dataScore;
    } catch (err) {
      
    }
  }

  public async getFloderScore(region) {
    try {
      let itemScore = await this.getDashboardScore();
      if (region == "BKK") {
        this.tempDataFloderScore.tempAll = itemScore.bkk.allIdea;
        this.tempDataFloderScore.tempSolu = itemScore.bkk.solutonfit;
        this.tempDataFloderScore.tempMark = itemScore.bkk.marketfit;
        this.tempDataFloderScore.tempOnSh = itemScore.bkk.onshelf;
        this.tempDataFloderScore.tempDrop = itemScore.bkk.Drop;
      } else if (region == "ROC") {
        this.tempDataFloderScore.tempAll = itemScore.roc.allIdea;
        this.tempDataFloderScore.tempSolu = itemScore.roc.solutonfit;
        this.tempDataFloderScore.tempMark = itemScore.roc.marketfit;
        this.tempDataFloderScore.tempOnSh = itemScore.roc.onshelf;
        this.tempDataFloderScore.tempDrop = itemScore.roc.Drop;
      } else if (region == "ROE") {
        this.tempDataFloderScore.tempAll = itemScore.roe.allIdea;
        this.tempDataFloderScore.tempSolu = itemScore.roe.solutonfit;
        this.tempDataFloderScore.tempMark = itemScore.roe.marketfit;
        this.tempDataFloderScore.tempOnSh = itemScore.roe.onshelf;
        this.tempDataFloderScore.tempDrop = itemScore.roe.Drop;
      } else if (region == "RON") {
        this.tempDataFloderScore.tempAll = itemScore.ron.allIdea;
        this.tempDataFloderScore.tempSolu = itemScore.ron.solutonfit;
        this.tempDataFloderScore.tempMark = itemScore.ron.marketfit;
        this.tempDataFloderScore.tempOnSh = itemScore.ron.onshelf;
        this.tempDataFloderScore.tempDrop = itemScore.ron.Drop;
      } else if (region == "ROS") {
        this.tempDataFloderScore.tempAll = itemScore.ros.allIdea;
        this.tempDataFloderScore.tempSolu = itemScore.ros.solutonfit;
        this.tempDataFloderScore.tempMark = itemScore.ros.marketfit;
        this.tempDataFloderScore.tempOnSh = itemScore.ros.onshelf;
        this.tempDataFloderScore.tempDrop = itemScore.ros.Drop;
      } else if (region == "RONE") {
        this.tempDataFloderScore.tempAll = itemScore.rone.allIdea;
        this.tempDataFloderScore.tempSolu = itemScore.rone.solutonfit;
        this.tempDataFloderScore.tempMark = itemScore.rone.marketfit;
        this.tempDataFloderScore.tempOnSh = itemScore.rone.onshelf;
        this.tempDataFloderScore.tempDrop = itemScore.rone.Drop;
      }
    } catch (err) {
      
    }
  }


  /* Get All Notification By Id */
  public async getUpdateStatusJoinTeam(project: any, member: any) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      const fromData = {
        project: {
          _id: project._id,
          projectName: project.projectName,
          owner: {
            _id: project.owner._id,
            employeeId: project.owner.employeeId,
            displayName: project.owner.displayName,
            email: project.owner.email,
          }
        },
        member: {
          _id: member._id,
          email: member.email,
          status: member.status,
          employeeId: member.employeeId,
          position: member.position,
          displayName: member.displayName,
        },
      }

      // let dataUser = {
      //   projectId: project._id,
      //   employeeId: member.employeeId,
      //   status: member.status,
      //   email: member.email
      // };
      // //
      // const dataNoti = {
      //   projectId: project._id,
      //   projectName: project.projectName,
      //   sender: member.employeeId,
      //   senderName: member.displayName,
      //   recipient: { id: project.owner._id, name: project.owner.displayName , employeeId: project.owner.employeeId },
      // };

      let token = sessionStorage.getItem("authen")

      let data = await setting.post(environment.URL_UPDATE_JOIN_TEAM, fromData, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      // if (member.status == "สมาชิกโครงการ") {
      //   let datainvite = await setting.post("notification/notiAcceptInvite", dataNoti, {
      //     // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      //   });
      // } else if (member.status == "ปฏิเสธคำเชิญ") {
      //   let datainvite = await setting.post("notification/notiDeclineInvite", dataNoti, {
      //     // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      //   });
      //   //
      // } else { }

      return data;
    } catch (err) {
      return err.response;
    }
  }
  /* Get tab member */
  public async updateViewDeleteMember(viewDeleteMember: any) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let data = await setting.post(environment.URL_UDATE_VIEWDELETEMEMBER, viewDeleteMember, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });
      return data;
    } catch (err) {
      return err.response;
    }
  }
  /* Get tab member */
  public async getMemberForTabMember(projectid: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        projectId: projectid,

      };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      //



      let data = await setting.post(environment.URL_MEMBER, dataUser, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });



      return data;
    } catch (err) {
      return err.response;
    }
  }
  /* Get Project Tp4 footer detail */
  public async getProjectTop4Detail(projectid: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        projectId: projectid,

      };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      

      let data = await setting.post(environment.URL_TOP4_DETAIL, dataUser, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });



      return data;
    } catch (err) {
      return err.response;
    }
  }
  /* Get Project history */
  public async getProjectHistory(projectid: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        projectId: projectid,

      };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      

      let data = await setting.post(environment.URL_PROJECT_HISTORY, dataUser, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });



      return data;
    } catch (err) {
      return err.response;
    }
  }

  /* Get Join Team */
  public async getInvitJoin(project: any, member: any) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      const fromData = {
        project: {
          _id: project._id,
          projectName: project.projectName,
          owner: {
            _id: project.owner._id,
            employeeId: project.owner.employeeId,
            displayName: project.owner.displayName,
            email: project.owner.email,
          }
        },
        member: {
          _id: member._id,
          email: member.email,
          status: member.status,
          employeeId: member.employeeId,
          position: member.position,
          displayName: member.displayName,
          jobDescription: member.jobDescription,
        },
      }

      let token = sessionStorage.getItem("authen");

      let data = await setting.post(environment.URL_INVITE_USER_JOIN, fromData, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });



      return data;
    } catch (err) {
      return err.response;
    }
  }

  public async UpdateStatusUser(JobDesc: string, MemberId: string, Projectid: string, Status: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        jobDescription: JobDesc,
        MemberId: MemberId,
        projectId: Projectid,
        status: Status

      };
      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      

      let data = await setting.post(environment.URL_UPDATE_STATUS_USER, dataUser, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });



      return data;
    } catch (err) {
      return err.response;
    }
  }

  public async SendEmailtoMember(Email: string, Name: string, Projectid: string, OwnerdisplayName: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        projectId: Projectid,
        email: Email,
        name: Name,
        displayName: OwnerdisplayName
      };
      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      

      let data = await setting.post(environment.URL_SEND_EMAIL_NOTI, dataUser, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });



      return data;
    } catch (err) {
      return err.response;
    }
  }

  public async InsertOneNoti(OwnerdisplayName: string, OwnerEmployeeId: string, Projectid: string, EmpId: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        displayName: OwnerdisplayName,
        OwnerEmployeeId: OwnerEmployeeId,
        projectId: Projectid,
        EmpId: EmpId,

      };
      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      

      let data = await setting.post(environment.URL_INSERT_NOTI_ONE, dataUser, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });



      return data;
    } catch (err) {
      return err.response;
    }
  }

  public async InsertToStory(Name: string, OwnerUserId: string, Projectid: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        displayName: Name,
        UserId: OwnerUserId,
        projectId: Projectid,

      };
      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      

      let data = await setting.post(environment.URL_INSERT_STORY, dataUser, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });



      return data;
    } catch (err) {
      return err.response;
    }
  }

  public async GetOwnerProject(projectid: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        projectId: projectid,
      };
      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      

      let data = await setting.post(environment.URL_GET_OWNER, dataUser, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });



      return data;
    } catch (err) {
      return err.response;
    }
  }

  public async InsertMember(project: any, member: any) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      const fromData = {
        project: {
          _id: project._id,
          projectName: project.projectName,
          owner: {
            _id: project.owner._id,
            employeeId: project.owner.employeeId,
            displayName: project.owner.displayName,
            email: project.owner.email,
          }
        },
        member: {
          _id: member._id,
          email: member.email,
          status: member.status,
          employeeId: member.employeeId,
          position: member.position,
          displayName: member.displayName,
          jobDescription: member.jobDescription,
          oldStatus: member.oldStatus,
          _idTeam: member._idTeam
        },
      }

      let token = sessionStorage.getItem("authen");

      let data = await setting.post(environment.URL_PROJECT_INSERT_MEMBER, fromData, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }

  /* Get Like status */
  public async getLikeStatus(projectId: string, userId: String) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        projectId: projectId,
        userId: userId

      };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      // 

      let data = await setting.post(environment.URL_LIKE_STATUS, dataUser, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });



      return data;
    } catch (err) {
      return err.response;
    }
  }

  /* Update Like status */
  public async getUpdateLikeStatus(projectId: string, userId: String, action: String) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        projectId: projectId,
        userId: userId,
        action: action

      };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      

      let data = await setting.post(environment.URL_UPDATE_LIKE_STATUS, dataUser, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });



      return data;
    } catch (err) {
      return err.response;
    }
  }

  /* Update Like status */
  public async getNotiLike(userDataNoti: any) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      // let dataUser = {
      //   projectId: projectId,
      //   userId: userId,
      //   action: action

      // };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      

      let data = await setting.post(environment.URL_NOTI_LIKE_STATUS, userDataNoti, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });



      return data;
    } catch (err) {
      return err.response;
    }
  }

  /* Get User for Mension */
  public async getUserMension() {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      //

      let data = await setting.post(environment.URL_USER_MENSION, '', {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });



      return data;
    } catch (err) {
      return err.response;
    }
  }

  /* get Project by Id user */
  public async getIdeaByUserId(userId: any) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        userId: userId
      };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      // 

      let data = await setting.post(environment.URL_IDEA_BY_USERID, dataUser, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });



      return data;
    } catch (err) {
      return err.response;
    }
  }

  /* get Project by Id user */
  public async getIdeaByTeamEmployeeIdAndStatus(employeeId: any, wait: any, email: any) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        employeeId: employeeId,
        status: wait,
        email: email
      };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      // 

      let data = await setting.post(environment.URL_IDEA_BY_TEAM_AND_STATUS, dataUser, {
        // headers: { Authorization: token, "Access-Control-Allow-Headers":"Access-Control-Allow-Headers" }
      });



      return data;
    } catch (err) {
      return err.response;
    }
  }

  /* get Insert user in multiproject */
  public async getInvitMultiProject(ownerData: any, memberData: any, projectData: any) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      const dataSent = {
        owner: {
          _id: ownerData._id,
          employeeId: ownerData.employeeId,
          displayName: ownerData.displayName,
          email: ownerData.email,
        },
        member: {
          _id: memberData._id,
          email: memberData.email,
          status: "รอการตอบรับ",
          employeeId: memberData.employeeId,
          position: memberData.position,
          displayName: memberData.displayName
        },
        project: {
          _id: projectData._id,
          projectName: projectData.projectName,
          status: projectData.status,
          jobDescription: projectData.jobDescription
        }
      }

      

      let data = await setting.post(environment.URL_MULTI_PROJECT, dataSent, {
        //headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }

  /* get all project category */
  public async getCategory() {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let data = await setting.post(environment.URL_GET_CATEGORY, {
        //headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });
      return data;
    } catch (err) {
      return err.response;
    }
  }

  public async getCategoryWithoutExit() {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let data = await setting.post(environment.URL_GET_CATEGORY_WITHOUT_EXIT, {
        //headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });
      return data;
    } catch (err) {
      return err.response;
    }
  }


  public async getEvent() {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let data = await setting.post(environment.URL_GET_EVENT, {
        //headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });
      return data;
    } catch (err) {
      return err.response;
    }
  }


  public async getForMobile(sort, limit) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        sort: sort,
        limit: limit
      };


      let data = await setting.post(environment.URL_GET_NEW_PROJECT_FORMB, dataUser, {
        //headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });
      return data;
    } catch (err) {
      return err.response;
    }
  }

  public async getAcceptIncubator(proid: string, userid: string, employeeId: string, displayName: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        project: {
          _id: proid
        },
        incubator: {
          _id: userid,
          displayName: displayName,
          employeeId: employeeId
        }
      };


      
      let token = sessionStorage.getItem("authen");
      let data = await setting.post(environment.URL_ACCEPT_INCUBATOR, dataUser, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });
      return data;
    } catch (err) {
      return err.response;
    }
  }


  public async getSatusProject() {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_STATUS);
      return data;
    } catch (err) {
      
    }
  }

  public async getStateProject(statusId) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_STATE + "/" + statusId);
      return data;
    } catch (err) {
      
    }
  }

  public async updateStatus(statusList) {
    const token = sessionStorage.getItem("authen");
    const formDataUpdate = new FormData();

    

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.post(
        environment.URL_STATUS_UPDATE, statusList,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }

  public async updateState(stateList) {
    const token = sessionStorage.getItem("authen");
    const formDataUpdate = new FormData();



    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.post(
        environment.URL_STATE_UPDATE, stateList,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }

  public async removeState(stateList) {
    const token = sessionStorage.getItem("authen");
    const formDataUpdate = new FormData();



    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.post(
        environment.URL_STATE_REMOVE, stateList,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }


  public async editNameStatus(statusList) {
    const token = sessionStorage.getItem("authen");
    const formDataUpdate = new FormData();



    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.post(
        environment.URL_STATUS_EDITNAME, statusList,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }


  public async editNameState(stateList) {
    const token = sessionStorage.getItem("authen");
    const formDataUpdate = new FormData();

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.post(
        environment.URL_STATE_EDITNAME, stateList,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }

  public async addState(stateList) {
    const token = sessionStorage.getItem("authen");
    const formDataUpdate = new FormData();

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.post(
        environment.URL_STATE_ADD, stateList,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }

  public async addStatus(statusList) {
    const token = sessionStorage.getItem("authen");


    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.post(
        environment.URL_STATUS_ADD, statusList,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }

  downloadfileProject(path: string) {
    var body = {
      path: path
    };
    
    let setting = axios.create({
      baseURL: environment.URL
    });
    return this.http.post(environment.URL + "project/downloadfileProject", body, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
  }


  public async getProjectStatusById(projectid) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_STATUS_BY_ID + "/" + projectid);

      // 
      return data;
    } catch (err) {
      
    }
  }

  /* Get Update Status Project Idea By Id */
  public async updateStatusByIdea(Id: string, dataPatch, levelid: string, role: string) {
    const token = sessionStorage.getItem("authen");
    
    const formDataUpdate = new FormData();


    formDataUpdate.append("data", dataPatch);


    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.put(
        environment.URL_PROJECT_UPDATE_STATUS_BY_IDEA + "/" + Id + "/" + levelid + "/" + role,
        formDataUpdate,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );

      

      return data;
    } catch (err) {
      return err.response;
    }
  }

  public async getProjectStatusForTimeline(projectid) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_STATUS_FOR_TIMELINE + "/" + projectid);

      // 
      return data;
    } catch (err) {
      
    }
  }



  public async createProjectRD(project: any) {
    
    const token = sessionStorage.getItem("authen");
    const formCreateProjectRD = new FormData();
    formCreateProjectRD.append("projectName", project.projectName);
    formCreateProjectRD.append("projectDescription", project.projectDescription);
    formCreateProjectRD.append("year", project.year);
    formCreateProjectRD.append("region", project.region);
    formCreateProjectRD.append("ownerDisplayName", project.ownerDisplayName);
    formCreateProjectRD.append("ownerEmployeeId", project.ownerEmployeeId);
    formCreateProjectRD.append("ownerEmail", project.ownerEmail);
    formCreateProjectRD.append("ownerTel", project.ownerTel);
    formCreateProjectRD.append("fileProject", project.fileProject);
    formCreateProjectRD.append("fileSize", project.fileSize);
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.post(
        environment.URL_CREATE_PROJECT_RD, formCreateProjectRD,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }

  public async createProjectIP(project, imageName) {
    
    const token = sessionStorage.getItem("authen");
    const formCreateProjectIP = new FormData();
    
    if (!(imageName == null || imageName == "")) {
      if (project.fileProject.length != 0) {
        for (let i = 0; i < project.fileProject.length; i++) {
          formCreateProjectIP.append("fileProject", project.fileProject[i].fileProject, project.fileProject[i].fileProject.name);
          formCreateProjectIP.append("fileStatus", project.fileProject[i].fileStatus);
          formCreateProjectIP.append("fileSize", project.fileProject[i].fileSize);
        }
      }
      if (project.ProjectOwner.length != 0) {
        for (let i = 0; i < project.ProjectOwner.length; i++) {
          formCreateProjectIP.append("ownerDisplayName", project.ProjectOwner[i].ownerDisplayName);
          formCreateProjectIP.append("ownerEmployeeId", project.ProjectOwner[i].ownerEmployeeId);
          formCreateProjectIP.append("ownerEmail", project.ProjectOwner[i].ownerEmail);
          formCreateProjectIP.append("ownerTel", project.ProjectOwner[i].ownerTel);
        }
      }

      formCreateProjectIP.append("projectName", project.projectName);
      formCreateProjectIP.append("projectDescription", project.projectDescription);
      formCreateProjectIP.append("year", project.year);
      formCreateProjectIP.append("applicationNo", project.applicationNo);
      formCreateProjectIP.append("typeId", project.typeId);
      formCreateProjectIP.append("coverPic", project.coverPic, imageName);

    } else {
      if (project.fileProject.length != 0) {
        for (let i = 0; i < project.fileProject.length; i++) {
          formCreateProjectIP.append("fileProject", project.fileProject[i].fileProject, project.fileProject[i].fileProject.name);
          formCreateProjectIP.append("fileStatus", project.fileProject[i].fileStatus);
          formCreateProjectIP.append("fileSize", project.fileProject[i].fileSize);
        }
      }

      if (project.ProjectOwner.length != 0) {
        for (let i = 0; i < project.ProjectOwner.length; i++) {
          formCreateProjectIP.append("ownerDisplayName", project.ProjectOwner[i].ownerDisplayName);
          formCreateProjectIP.append("ownerEmployeeId", project.ProjectOwner[i].ownerEmployeeId);
          formCreateProjectIP.append("ownerEmail", project.ProjectOwner[i].ownerEmail);
          formCreateProjectIP.append("ownerTel", project.ProjectOwner[i].ownerTel);
        }
      }

      formCreateProjectIP.append("projectName", project.projectName);
      formCreateProjectIP.append("projectDescription", project.projectDescription);
      formCreateProjectIP.append("year", project.year);
      formCreateProjectIP.append("applicationNo", project.applicationNo);
      formCreateProjectIP.append("typeId", project.typeId);
      formCreateProjectIP.append("coverPic", "");
    }
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.post(
        environment.URL_CREATE_PROJECT_IP, formCreateProjectIP,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }

  public async editProjectRD(project: any, fileDelete) {
    
    

    const token = sessionStorage.getItem("authen");
    const formUpdateProjectRD = new FormData();
    formUpdateProjectRD.append("_id", project._id);
    formUpdateProjectRD.append("projectName", project.projectName);
    formUpdateProjectRD.append("projectDescription", project.projectDescription);
    formUpdateProjectRD.append("year", project.year);
    formUpdateProjectRD.append("region", project.region);
    formUpdateProjectRD.append("ownerDisplayName", project.ownerDisplayName);
    formUpdateProjectRD.append("ownerEmployeeId", project.ownerEmployeeId);
    formUpdateProjectRD.append("ownerEmail", project.ownerEmail);
    formUpdateProjectRD.append("ownerTel", project.ownerTel);
    formUpdateProjectRD.append("fileProject", project.fileProject);
    formUpdateProjectRD.append("fileSize", project.fileSize);
    if (fileDelete.length != 0) {
      formUpdateProjectRD.append("fileDeleteName", fileDelete[0].name);

    }

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.post(
        environment.URL_EDIT_PROJECT_RD, formUpdateProjectRD,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }

  public async getIdeaINNObyYear(year) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_INNO_BY_YEAR + "/" + year);

      return data;
    } catch (err) {
      
    }
  }

  public async getProjectINNOByIncubator() {
    try {
      const token = sessionStorage.getItem("authen");
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_INNO_BY_INCUBATOR, {
        headers: {
          Authorization: token, "Access-Control-Allow-Origin": "*",
        }
      });

      return data;
    } catch (err) {
      
    }
  }

  /* Get Project R&D  ALL */
  public async getProjectRDbyYear(year) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_RD_BY_YEAR + "/" + year);
    
      return data;
    } catch (err) {
      
    }
  }

  public async getProjectIPbyYear(year, type) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_IP_BY_YEAR + "/" + year + "/" + type);
      
      
      return data;
    } catch (err) {
      
    }
  }
  public async updateCategory(category) {
    const token = sessionStorage.getItem("authen");
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.post(
        environment.URL_CATEGORY_EDIT, category,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }

  public async createCategory(category) {
    const token = sessionStorage.getItem("authen");
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.post(
        environment.URL_CATEGORY_ADD, category,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }

  public async addincubator(incubatorlist, projectId) {
    const token = sessionStorage.getItem("authen");
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let model = {
        projectId: projectId,
        incubatorlist: incubatorlist
      }

      let data = await setting.post(
        environment.URL_INCUBATOR_ADD, model,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }


  public async deleteIncubator(userId, projectId) {
    const token = sessionStorage.getItem("authen");
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let model = {
        projectId: projectId,
        userId: userId
      }

      let data = await setting.post(
        environment.URL_INCUBATOR_DELETE, model,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }

  public async getDashboardDailyUser(start: string, end: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_DASHBOARD_TOTAL_USER + "/" + start + "/" + end);
      return data;
    } catch (err) {
      
    }
  }



  public async getExportCreateProject(start: string, end: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_EXPORT_CREATE_PROJECT + "/" + start + "/" + end);
      return data;
    } catch (err) {
      
    }
  }

  public async getDashboardStatusRange(start: string, end: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_DASHBOARD_STATUS_RANGE + "/" + start + "/" + end);
      return data;
    } catch (err) {
      
    }
  }

  public async getExportStatusRange(start: string, end: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_EXPORT_STATUS_RANGE + "/" + start + "/" + end);
      return data;
    } catch (err) {
      
    }
  }

  public async getDashboardDailyUserbyYear(year: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_DASHBOARD_TOTAL_USER_BY_YEAR + "/" + year);
      return data;
    } catch (err) {
      
    }
  }

  public async getExportProjectByYear(year: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_EXPORT_PROJECT_BY_YEAR + "/" + year);
      return data;
    } catch (err) {
      
    }
  }



  public async deleteProjectInno(projectId) {
    const token = sessionStorage.getItem("authen");
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let model = {
        projectId: projectId,
      }

      let data = await setting.post(
        environment.URL_PROJECT_DELETE, model,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }

  public async restoreProjectInno(projectId) {
    const token = sessionStorage.getItem("authen");
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let model = {
        projectId: projectId,
      }

      let data = await setting.post(
        environment.URL_PROJECT_RESTORE, model,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }

  public async exitProjectInno(projectId) {
    const token = sessionStorage.getItem("authen");
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let model = {
        projectId: projectId,
      }

      let data = await setting.post(
        environment.URL_PROJECT_EXIT, model,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }

  public async getIdeaINNObyInactive() {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_INNO_BY_INACTIVE);
      return data;
    } catch (err) {
      
    }
  }

  public async getTypeIP() {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_GET_TYPE_IP);
      return data;
    } catch (err) {
      
    }
  }

  public async createTypeIP(typeName) {
    const token = sessionStorage.getItem("authen");
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let model = {
        typeName: typeName,
      }

      let data = await setting.post(
        environment.URL_CREATE_TYPE_IP, model,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );

      return data;
    } catch (err) {
      
    }
  }

  public async updateTypeIP(typeName, _id) {
    const token = sessionStorage.getItem("authen");
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let model = {
        _id: _id,
        typeName: typeName,
      }

      let data = await setting.post(
        environment.URL_UPDATE_TYPE_IP, model,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );

      return data;
    } catch (err) {
      
    }
  }

  public async deleteTypeIP(_id) {
    const token = sessionStorage.getItem("authen");
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let model = {
        _id: _id,
      }

      let data = await setting.post(
        environment.URL_DELETE_TYPE_IP, model,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );

      return data;
    } catch (err) {
      
    }
  }

  public async getProjectIP() {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_GET_PROJECT_IP);
      return data;
    } catch (err) {
      
    }
  }

  /* Get Project Idea By Id */
  public async getIdeaIPById(Id: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.get(environment.URL_PROJECT_IP_BY_ID + "/" + Id);
      return data;
    } catch (err) {
      
    }
  }

  public async updateProjectIP(Id, project, imageName) {
    
    const token = sessionStorage.getItem("authen");
    const formCreateProjectIP = new FormData();
    
    if (!(imageName == null || imageName == "")) { // มีรูป
      if (project.fileProject.length != 0) {
        for (let i = 0; i < project.fileProject.length; i++) {
          // formCreateProjectIP.append("fileProject", project.fileProject[i].fileProject, project.fileProject[i].fileProject.name);
          // formCreateProjectIP.append("fileStatus", project.fileProject[i].fileStatus);
          // formCreateProjectIP.append("fileSize", project.fileProject[i].fileSize);
          if (project.fileProject[i].fileProject == null) {
            //formCreateProjectIP.append("fileProject", project.fileProject[i].fileProject, project.fileProject[i].fileProject.name);
            formCreateProjectIP.append("fileStatus", project.fileProject[i].fileStatus);
            formCreateProjectIP.append("fileSize", project.fileProject[i].fileSize);
            formCreateProjectIP.append("fileName", project.fileProject[i].name);
            formCreateProjectIP.append("filePath", project.fileProject[i].path);
            formCreateProjectIP.append("file_id", project.fileProject[i]._id);
          }
          else {
            formCreateProjectIP.append("fileProject", project.fileProject[i].fileProject, project.fileProject[i].fileProject.name);
            formCreateProjectIP.append("fileStatus", project.fileProject[i].fileStatus);
            formCreateProjectIP.append("fileSize", project.fileProject[i].fileSize);
            formCreateProjectIP.append("fileName", project.fileProject[i].name);
            formCreateProjectIP.append("filePath", "");
            formCreateProjectIP.append("file_id", "");
          }
        }
      }
      if (project.ProjectOwner.length != 0) {
        for (let i = 0; i < project.ProjectOwner.length; i++) {
          formCreateProjectIP.append("ownerDisplayName", project.ProjectOwner[i].ownerDisplayName);
          formCreateProjectIP.append("ownerEmployeeId", project.ProjectOwner[i].ownerEmployeeId);
          formCreateProjectIP.append("ownerEmail", project.ProjectOwner[i].ownerEmail);
          formCreateProjectIP.append("ownerTel", project.ProjectOwner[i].ownerTel);
        }
      }

      formCreateProjectIP.append("projectName", project.projectName);
      formCreateProjectIP.append("projectDescription", project.projectDescription);
      formCreateProjectIP.append("year", project.year);
      formCreateProjectIP.append("applicationNo", project.applicationNo);
      formCreateProjectIP.append("typeId", project.typeId);
      formCreateProjectIP.append("coverPic", project.coverPic, imageName);

    } else {
      if (project.fileProject.length != 0) {
        for (let i = 0; i < project.fileProject.length; i++) {
          
          if (project.fileProject[i].fileProject == null) {
            //formCreateProjectIP.append("fileProject", project.fileProject[i].fileProject, project.fileProject[i].fileProject.name);
            formCreateProjectIP.append("fileStatus", project.fileProject[i].fileStatus);
            formCreateProjectIP.append("fileSize", project.fileProject[i].fileSize);
            formCreateProjectIP.append("fileName", project.fileProject[i].name);
            formCreateProjectIP.append("filePath", project.fileProject[i].path);
            formCreateProjectIP.append("file_id", project.fileProject[i]._id);
          }
          else {
            formCreateProjectIP.append("fileProject", project.fileProject[i].fileProject, project.fileProject[i].fileProject.name);
            formCreateProjectIP.append("fileStatus", project.fileProject[i].fileStatus);
            formCreateProjectIP.append("fileSize", project.fileProject[i].fileSize);
            formCreateProjectIP.append("fileName", project.fileProject[i].name);
            formCreateProjectIP.append("filePath", "");
            formCreateProjectIP.append("file_id", "");
          }

        }
      }

      if (project.ProjectOwner.length != 0) {
        for (let i = 0; i < project.ProjectOwner.length; i++) {
          formCreateProjectIP.append("ownerDisplayName", project.ProjectOwner[i].ownerDisplayName);
          formCreateProjectIP.append("ownerEmployeeId", project.ProjectOwner[i].ownerEmployeeId);
          formCreateProjectIP.append("ownerEmail", project.ProjectOwner[i].ownerEmail);
          formCreateProjectIP.append("ownerTel", project.ProjectOwner[i].ownerTel);
        }
      }

      formCreateProjectIP.append("projectName", project.projectName);
      formCreateProjectIP.append("projectDescription", project.projectDescription);
      formCreateProjectIP.append("year", project.year);
      formCreateProjectIP.append("applicationNo", project.applicationNo);
      formCreateProjectIP.append("typeId", project.typeId);
      //formCreateProjectIP.append("coverPic", "");
    }
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });
      let data = await setting.put(
        environment.URL_UPDATE_PROJECT_IP + "/" + Id, formCreateProjectIP,
        {
          headers: {
            Authorization: token, "Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Methods" : "POST, GET, PUT, OPTIONS, PATCH, DELETE",
          }
        }
      );
      return data;
    } catch (err) {
      
    }
  }



}



