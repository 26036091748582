import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";

// Import Service
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";
import { UserServiceService } from "../../../../Services/user-service.service";
import { environment } from "../../../../../environments/environment";
//import { $ } from 'protractor';

declare var $: any;

@Component({
  selector: 'app-tab-member',
  templateUrl: './tab-member.component.html',
  styleUrls: ['./tab-member.component.scss']
})
export class TabMemberComponent implements OnInit {
  urlHostImage = environment.URLUPLOAD;
  public MemberType1: any;
  public MemberType2: any;
  public MemberType3: any;
  public MemberType4: any;
  public OwnerData: any;
  @Input() typeNameInput: any;
  @Input() regionNameInput: any;
  cate: string;
  region: string;
  EmployeeId: any;
  owner: any;
  dataUser: any;
  DataMember1= [];
  DataMember2= [];
  DataMember3= [];
  DataMember4= [];
  status:any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
    private _location: Location,
    private userService: UserServiceService,
  ) { }

  ngOnInit() {
    this.getInit();

    
  }

  async getInit(){
    
    this.cate = this.typeNameInput
    this.region = this.regionNameInput

    const projectid = this.route.snapshot.paramMap.get("id");

      this.projectSer.getMemberForTabMember(projectid).then(res => {
        //
        
        
        let member = res.data.data[0].member.length;

        
        let memberType1 = [];
        let memberType2 = [];
        let memberType3 = [];
        let memberType4 = [];


        let userData1 = [];
        let userData2 = [];
        let userData3 = [];
        let userData4 = [];
        

        for(let i =0; i < member; i++){
           if(res.data.data[0].member[i].status === "รอการตอบรับ" || res.data.data[0].member[i].status === "สนใจเข้าร่วมโครงการ"){
            memberType1[i] = res.data.data[0].member[i];  
            let empmem = memberType1[i].employeeId

            this.userService.getProfileByEmpId(empmem).then(resuser => {
              userData1[i]  =  resuser.data.data;
                this.DataMember1.push({
                  member: res.data.data[0].member[i],  
                  user: resuser.data.data
                });
            });   

            
           }          
           if(res.data.data[0].member[i].status === "ปฏิเสธคำเชิญ"){
            memberType2[i] = res.data.data[0].member[i]; 
            let empmem = memberType2[i].employeeId      
            
            this.userService.getProfileByEmpId(empmem).then(resuser => {
              userData2[i]  =  resuser.data.data;
              this.DataMember2.push({
                member: res.data.data[0].member[i],  
                user: resuser.data.data
              });
            });
           }
           if(res.data.data[0].member[i].status === "สมาชิกโครงการ"){
            memberType3[i] = res.data.data[0].member[i];  
            let empmem = memberType3[i].employeeId  

            this.userService.getProfileByEmpId(empmem).then(resuser => {
              userData3[i]  =  resuser.data.data;
              this.DataMember3.push({
                member: res.data.data[0].member[i],  
                user: resuser.data.data
              });
            });
                
           }
           if(res.data.data[0].member[i].status === "ถูกเชิญออกจากโครงการ"){
            memberType4[i] = res.data.data[0].member[i]; 
            let empmem = memberType4[i].employeeId      
            
            this.userService.getProfileByEmpId(empmem).then(resuser => {
              userData4[i]  =  resuser.data.data;
              this.DataMember4.push({
                member: res.data.data[0].member[i],  
                user: resuser.data.data
              });
            });
           }
          //
        }  
        this.MemberType1 = memberType1; 
        this.MemberType2 = memberType2; 
        this.MemberType3 = memberType3; 
        this.MemberType4 = memberType4;   

        
        
        
        
        
        
        
    });
    
    this.projectSer.GetOwnerProject(projectid).then(res => {
      

      this.OwnerData = res.data.data;

    });
    const id = this.route.snapshot.paramMap.get("id");
    let tempData;
    tempData = await this.projectSer.getIdeaById(id);
    this.status = tempData.data.data[0].status;
    let owner = tempData.data.data[0].owner.employeeId;

    this.EmployeeId = localStorage.getItem("EmployeeId");
    this.owner = owner;

    let tabsDetail = sessionStorage.getItem("TabsDetail");

    if(tabsDetail === "คำขอเข้าร่วมทีม"){

      var element = document.getElementById("waitsection");
      element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
      sessionStorage.setItem("TabsDetail","");

    }else if(tabsDetail === "คำตอบรับเข้าร่วมทีม"){

      var element = document.getElementById("membersection");
      //alert(element);
      element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
      sessionStorage.setItem("TabsDetail","");

    }else if(tabsDetail === "ปฏิเสธ"){

      var element = document.getElementById("declinesection");
      element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
      sessionStorage.setItem("TabsDetail","");

    }


  }

  public conFirm(displayname,empid,position,email,memberId){
    
    
    $("#ConfirmModal").modal("show");
    $('#sendname').html(displayname);
    $('#position').html(position);
    $('#Email').val(email);
    $('#empId').val(empid);
    $('#name').val(displayname);
    $('#memberId').val(memberId);
  }

  public onSubmitconfirm(){
    $("#ConfirmModal").modal("hide");
    $('#sendComplete').modal("show");
    //alert();
    let OwnerdisplayName = sessionStorage.getItem("displayName");
    let OwnerEmployeeId = localStorage.getItem("EmployeeId");
    let OwnerUserId = sessionStorage.getItem("_id");
    const Projectid = this.route.snapshot.paramMap.get("id");   
    let JobDesc = $('#jobDescription').val();
    let EmpId = $('#empId').val();
    let Status = "รอการตอบรับ";
    let Email = $('#Email').val();
    let Name = $('#name').val();
    let MemberId = $('#memberId').val();
    
     this.projectSer.UpdateStatusUser(JobDesc,MemberId,Projectid,Status).then(res => {});
     this.projectSer.SendEmailtoMember(Email,Name,Projectid,OwnerdisplayName).then(res => {});
     this.projectSer.InsertOneNoti(OwnerdisplayName,OwnerEmployeeId,Projectid,EmpId).then(res => {});
     this.projectSer.InsertToStory(Name,OwnerUserId,Projectid);
    
  }  

  public onSuccess(){
    location.reload();
    
  }

}
