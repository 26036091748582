import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FillterService {

  headerFilter: string;

  constructor() { }

  public getFilter() {
    return this.headerFilter;
  }

  public setFilter(filter): void {
    this.headerFilter = filter;
  }

  
}
