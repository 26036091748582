import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { BtnBackComponent } from '../Shared/btn-back/btn-back.component';
import { SearchPipe } from '../Pipes/search.pipe';
import { CropimageComponent } from '../Shared/cropimage/cropimage.component';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  imports: [
    CommonModule,
    ImageCropperModule
  ],
  declarations: [
    BtnBackComponent,
    SearchPipe,
    CropimageComponent,
    
  ],
  exports: [
    BtnBackComponent,
    SearchPipe,
    CropimageComponent
  ]
})
export class MainBothModule {}
