import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from "@angular/common";

// Import Service
import { FillterService } from "../../Services/fillter.service";
import { AuthenServiceService } from "../../Services/authen-service.service";
import { getHostElement } from "@angular/core/src/render3";
import { environment } from "../../../environments/environment";
import { UserServiceService } from "../../Services/user-service.service";
import { ProjectideaServiceService } from "../../Services/projectidea-service.service";
import { async } from "@angular/core/testing";
import { env } from "process";

declare var jquery: any;
declare var $: any;


@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  urlHostImage = environment.URLUPLOAD;
  public activeTab = "home";
  public mockDataNoti;
  public itemCard: any;
  public noti: any;
  public countUnread : any;
  displayName:any;
  email:any;
  incubate: any;
  dataUser: any;
  pageinc: any;
  itemCardNoti : any;

  public checkBack = false;

  public checkNext = true;

  constructor(
    private router: Router,
    public spinner: NgxSpinnerService,
    private _location: Location,
    private fillterServ: FillterService,
    private authSer: AuthenServiceService,
    private userService: UserServiceService,
    private projectSer: ProjectideaServiceService
  ) {
    if (sessionStorage.getItem("barActive"))
      this.activeTab = sessionStorage.getItem("barActive");
  }

  async ngOnInit() {
    sessionStorage.setItem("barActive", "home");

    this.incubate = sessionStorage.getItem("incubate");
    let atbar = sessionStorage.getItem("barActive");

    $("#mySidenav-panel").hide();
    document.getElementById("mySidenav").style.width = "0";

    //setTimeout(() => {
      this.manageBadge();
    //},500)
   
    $('.noscroll').on( 'mousewheel DOMMouseScroll', function (e) { 
  
      var e0 = e.originalEvent;
      var delta = e0.wheelDelta || -e0.detail;
    
      this.scrollTop += ( delta < 0 ? 1 : -1 ) * 30;
      e.preventDefault();  
    });

    this.displayName = sessionStorage.getItem("displayName");
    this.email = sessionStorage.getItem("email");
    let userId = sessionStorage.getItem("_id");


    let userRS = await this.userService.getUserById(userId);
    this.dataUser = userRS.data.data;

    // 
    // 

    var pageURL = window.location.href;
    var lastURLSegment = pageURL.substr(pageURL.lastIndexOf('/') + 1);
    let bar = sessionStorage.getItem("barActive");

    //alert(lastURLSegment);

    if(lastURLSegment === "incubator" || lastURLSegment === "myincubator" ){
      this.pageinc = "user";
      $('#allincu').removeClass('co-dis');
      $('#myincu').removeClass('co-dis');
      $('#homep').addClass('co-dis');
      $('#profilep').addClass('co-dis');
      $('#allpro').addClass('co-dis');
      $('#allrd').addClass('co-dis');
      $('#allip').addClass('co-dis');
    }else if(bar === "incubator" || bar === "myincubator"){
      this.pageinc = "user";
      $('#allincu').removeClass('co-dis');
      $('#myincu').removeClass('co-dis');
      $('#homep').addClass('co-dis');
      $('#profilep').addClass('co-dis');
      $('#allpro').addClass('co-dis');
      $('#allrd').addClass('co-dis');
      $('#allip').addClass('co-dis');
    }else{
      this.pageinc = "other";
      $('#allincu').addClass('co-dis');
      $('#myincu').addClass('co-dis');
      $('#homep').removeClass('co-dis');
      $('#profilep').removeClass('co-dis');
      $('#allpro').removeClass('co-dis');
      $('#allrd').removeClass('co-dis');
      $('#allip').removeClass('co-dis');
    }

  }

  /* Logout */
  logout() {
    this.spinner.show();
    setTimeout(() => {
      this.authSer.logoutClearToken().then(response => {
        localStorage.clear();
        sessionStorage.clear();
        this.spinner.hide();
        this.router.navigate(["/login"]);
      });
    },1000)
    
  }

  public goto(){
    window.location.href = environment.URL_WEB_PLAYGROUND;
  }

  public navBar(position) {
    this.spinner.show();
    setTimeout(() => {
      if (position === "home") {
        //this.router.navigate([""]);
        var urlDetail = "";
        if (window.location.hostname === "localhost") {
          urlDetail = "http://localhost:4200/";
        } else if (window.location.hostname === "inning.dld-test.com") {
          urlDetail = "https://" + window.location.host;
        } else {
          urlDetail = "https://" + window.location.host;
        }
        window.location.href = urlDetail;
        this.activeTab = "home";
        $("#navbarSupportedContentMobile").collapse("hide");
        sessionStorage.setItem("barActive", "home");
      } else if (position === "userprefile") {
        // window.scroll(0, 0);
        this.router.navigate(["userProfile/profileUser"]);
        this.activeTab = "userprefile";
        $("#navbarSupportedContentMobile").collapse("hide");
        sessionStorage.setItem("barActive", "userprefile");
      } else if (position === "createidea") {
        // window.scroll(0, 0);
        this.router.navigate(["createidea"]);
        this.activeTab = "createidea";
        $("#navbarSupportedContentMobile").collapse("hide");
        sessionStorage.setItem("barActive", "createidea");
      }
      else if(position === "allproject"){

        // this.router.navigate(["/AllProject/Allregion/AllIdeas"]);

        var urlDetail = "";
        if (window.location.hostname === "localhost") {
          urlDetail = "http://localhost:4200/AllProject/Allregion/AllIdeas";
        } else if (window.location.hostname === "inning.dld-test.com") {
          urlDetail = "https://" + window.location.host+"/AllProject/Allregion/AllIdeas";
        } else {
          urlDetail = "https://" + window.location.host+"/AllProject/Allregion/AllIdeas";
        }
        window.location.href = urlDetail;

        this.activeTab = "allproject";
        $("#navbarSupportedContentMobile").collapse("hide");
        sessionStorage.setItem("barActive", "allproject");


      }else if(position === "incubator"){

        // this.router.navigate(["incubator"]);
        var urlDetail = "";
        if (window.location.hostname === "localhost") {
          urlDetail = "http://localhost:4200/incubator";
        } else if (window.location.hostname === "inning.dld-test.com") {
          urlDetail = "https://" + window.location.host+"/incubator";
        } else {
          urlDetail = "https://" + window.location.host+"/incubator";
        }
        window.location.href = urlDetail;
        
        sessionStorage.setItem("barActive", "incubator");

      }else if(position === "myincubator"){

        // this.router.navigate(["myincubator"]);
        var urlDetail = "";
        if (window.location.hostname === "localhost") {
          urlDetail = "http://localhost:4200/myincubator";
        } else if (window.location.hostname === "inning.dld-test.com") {
          urlDetail = "https://" + window.location.host+"/myincubator";
        } else {
          urlDetail = "https://" + window.location.host+"/myincubator";
        }
        window.location.href = urlDetail;
        
        sessionStorage.setItem("barActive", "myincubator");
        
      }else if(position === "rdproject"){

        this.router.navigate(["rdproject/all"]);
        
        sessionStorage.setItem("barActive", "rdproject");

      }else if(position === "ipproject"){

        this.router.navigate(["ipproject/all/all"]);
        
        sessionStorage.setItem("barActive", "ipproject");
      }
      this.spinner.hide();
    }, 1000);
  }

  public onKey(event: any) {
    
    let searchData = "";

    searchData += event.target.value;

    this.fillterServ.setFilter(searchData);

    if (searchData.length > 0) {
      // set back 1 time
      this.checkBack = false;
      this.router.navigate(["/search"]);
    } else if (searchData.length == 0 && this.checkBack == false) {
      this.checkBack = true;
      this._location.back();
    }
  }

  ngDoCheck(){
    
    let checkActive = sessionStorage.getItem("barActive");
    this.activeTab = checkActive;
    
  }

  /* Notification */
  public async notification() {
      let empid = localStorage.getItem("EmployeeId");
      let email = sessionStorage.getItem("email");

      //alert(empid+email);

      this.noti = []
     
      let arrNoti = await this.authSer.getNotiById(email,empid);
      let rsNoti = arrNoti.data.data;


      for(let i=0; i < rsNoti.length; i++){
        let dtUser = await this.userService.getProfileByEmpId(rsNoti[i].sender);
        let rsUser = dtUser.data.data

        this.noti.push({
          notilist : rsNoti[i],
          user: rsUser

        });
       
      }

  }

  public Allnoti(){

    this.spinner.show();
    setTimeout(() => {
      this.router.navigate(["allnotification"]);
      this.spinner.hide();
    },1000)

  }

 public manageBadge() {

    let employeeId = localStorage.getItem("EmployeeId");
    let email = sessionStorage.getItem("email");
      this.authSer.getUnReadById(email, employeeId).then(res => {
        //

        let count = res.data.data;       
        
        var countnoti = document.getElementById("countnoti");
        var countnotibar = document.getElementById("countnoti-bar");

        if( count.length < 10){
          this.countUnread = count.length;
          countnoti.classList.add("badgenoti");
          countnotibar.classList.add("badgenoti");
          // countnoti.classList.remove("badgenotitwo");
          
          
        }else if(count.length >= 10 && count.length < 100){

          this.countUnread = count.length;
          countnoti.classList.remove("badgenoti");
          countnoti.classList.add("badgenotitwo");

          countnotibar.classList.remove("badgenoti");
          countnotibar.classList.add("badgenotitwo");
          
        }else if(count.length >= 100){

          this.countUnread = "99+";
          countnoti.classList.remove("badgenotitwo");
          countnoti.classList.add("badgenotithree");

          countnotibar.classList.remove("badgenotitwo");
          countnotibar.classList.add("badgenotithree");


        }
        
      });
    
  }

  public async getProject(id){

    let userid = sessionStorage.getItem("_id");
    
    let tempData
    tempData =  await this.projectSer.getIdeaById(id);
    this.itemCardNoti = tempData.data.data[0];
    
    
    


    if(this.itemCardNoti.incubator){
      this.itemCardNoti.incubator.forEach(element => {
        
        if(userid === element._id){
          status = "incubator";
        }else{
          status = "user";
        }
      });
      // 
    }else{
      status = "user";
    }
    
    return status;
  }

  public async onNotiDetail(id,projectId,category,title,commentId,recipient){

    let statusPathReply = await this.getProject(projectId);

    
    

    // let rsProject = this.getProject(projectId);
    // 
    
    this.authSer.getReadNotiById(id).then(res => {
      

      //alert(category+"|"+title+"//"+commentId);

      this.manageBadge();

      this.noti = res.data.data;
        if(category === "DeleteMember" || category === "CreateProject" || title === "คำเชิญ" || category === "LikeProject"){
            var urldetail = "";
            if (window.location.hostname === "localhost") {
              urldetail = "http://localhost:4200/detailhomeidea/"+projectId;
            } else if (window.location.hostname === "inning.dld-test.com") {
              urldetail = "https://" + window.location.host + "/detailhomeidea/"+projectId;
            } else {
              urldetail = "https://" + window.location.host + "/detailhomeidea/"+projectId;
            }
            window.location.href = urldetail;
            
        }
        else if(category === "CreateProjectRD"){
          var urldetail = "";
            if (window.location.hostname === "localhost") {
              urldetail = "http://localhost:4200/detailrd/"+projectId;
            } else if (window.location.hostname === "inning.dld-test.com") {
              urldetail = "https://" + window.location.host + "/detailrd/"+projectId;
            } else {
              urldetail = "https://" + window.location.host + "/detailrd/"+projectId;
            }
            window.location.href = urldetail;
            
        }
        else if(category === "InviteMember"){
          if(title != "คำเชิญ"){

            sessionStorage.setItem("TabsDetail", title);

            var urldetail = "";
              if (window.location.hostname === "localhost") {
                urldetail = "http://localhost:4200/userProfile/myidea/detailupdateidea/"+projectId;
              } else if (window.location.hostname === "inning.dld-test.com") {
                urldetail = "https://" + window.location.host + "/userProfile/myidea/detailupdateidea/"+projectId;
              } else {
                urldetail = "https://" + window.location.host + "/userProfile/myidea/detailupdateidea/"+projectId;
              }
              window.location.href = urldetail;
          }

        }else if(category === "Comment"){

          //alert(title);

          sessionStorage.setItem("TabsDetail", title);
          sessionStorage.setItem("CommentId",commentId);

          // console.clear();
          
          
          if(title === "แสดงความคิดเห็นตอบกลับIncubator"){

            if(statusPathReply === "incubator"){

              var urldetail = "";
              if (window.location.hostname === "localhost") {
                urldetail = "http://localhost:4200/myincubate/"+projectId;
              } else if (window.location.hostname === "inning.dld-test.com") {
                urldetail = "https://" + window.location.host + "/myincubate/"+projectId;
              } else {
                urldetail = "https://" + window.location.host + "/myincubate/"+projectId;
              }
              window.location.href = urldetail;

            }else{

              // alert("1");
              var urldetail = "";
              if (window.location.hostname === "localhost") {
                urldetail = "http://localhost:4200/detailhomeidea/"+projectId;
              } else if (window.location.hostname === "inning.dld-test.com") {
                urldetail = "https://" + window.location.host + "/detailhomeidea/"+projectId;
              } else {
                urldetail = "https://" + window.location.host + "/detailhomeidea/"+projectId;
              }
              window.location.href = urldetail;

            }

          }else{
            // alert("2");

            var urldetail = "";
            if (window.location.hostname === "localhost") {
              urldetail = "http://localhost:4200/detailhomeidea/"+projectId;
            } else if (window.location.hostname === "inning.dld-test.com") {
              urldetail = "https://" + window.location.host + "/detailhomeidea/"+projectId;
            } else {
              urldetail = "https://" + window.location.host + "/detailhomeidea/"+projectId;
            }
            window.location.href = urldetail;
          }

        }
       
    });

  }


  public openNav() {
    $("#mySidenav-panel").show();
    document.getElementById("mySidenav").style.width = "90%";
    
    
    let employeeId = localStorage.getItem("EmployeeId");
    let email = sessionStorage.getItem("email");
      this.authSer.getUnReadById(email, employeeId).then(res => {
        //

        let count = res.data.data;       
        
        var countnotibar = document.getElementById("countnoti-side");

        if( count.length < 10){
          this.countUnread = count.length;
          countnotibar.classList.add("badge-allnoti-one");
          // countnoti.classList.remove("badgenotitwo");
          
          
        }else if(count.length >= 10 && count.length < 100){

          this.countUnread = count.length;
          
          countnotibar.classList.remove("badge-allnoti-one");
          countnotibar.classList.add("badge-allnoti");
          
        }else if(count.length >= 100){

          this.countUnread = "99+";
         
          countnotibar.classList.remove("badge-allnoti");
          countnotibar.classList.add("badgenotithree");


        }
        
      });
    
  }
  
  public closeNav() {
    $("#mySidenav-panel").hide();
    document.getElementById("mySidenav").style.width = "0";
    // document.getElementById("mySidenav-panel").style.width = "0";
    //$("#mySidenav-panel").fadeOut(1000);
    
  }

 
}
