import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";

import { UserServiceService } from "../Services/user-service.service";
import { Observable } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class EmploGuardGuard implements CanActivate {
  data: any = "";
  check : boolean = false;

  constructor(private Route: Router, private userSer: UserServiceService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    
      let datacheckEmployee = localStorage.getItem("EmployeeId");
      if (
        this.userSer.authenEmployeeId != undefined &&
        this.userSer.authenEmployeeId != "undefined" &&
        this.userSer.authenEmployeeId != null &&
        this.userSer.authenEmployeeId != "null" &&
        this.userSer.authenEmployeeId != "" &&
        this.userSer.authenRegion != undefined &&
        this.userSer.authenRegion != "undefined" &&
        this.userSer.authenRegion != null &&
        this.userSer.authenRegion != "null"  &&
        this.userSer.authenRegion != "" 
      ) {
        return true;
      } else {
        // Set Bar active header
        sessionStorage.setItem("barActive", "userprefile");
        this.Route.navigate(["/userProfile/updateprofile"]);
        return false;
      }
  }
}
