import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainProfile } from "../../Components/user-components/navTabprofile-component/mainprofile.component";

import { UserComponentsComponent } from "../../Components/user-components/user-components.component";
import { HomeuserComponent } from "../../Components/user-components/homeuser-component/homeuser.component";
import { CreateIdeaComponent } from "../../Components/user-components/create-idea-component/create-idea.component";
import { MyideaComponent } from "../../Components/user-components/navTabprofile-component/myidea/myidea.component";
import { JoinideaComponent } from "../../Components/user-components/navTabprofile-component/joinidea/joinidea.component";
import { ListideaComponent } from "../../Components/user-components/navTabprofile-component/myidea/listidea/listidea.component";
import { AllProjectComponentComponent } from '../../Components/user-components/all-project-component/all-project-component.component';
import { AllNotiComponentComponent } from '../../Components/user-components/all-noti-component/all-noti-component.component';
import { UpdateprofileComponent } from "../../Components/user-components/updateprofile/updateprofile.component";
import { ListjoinideaComponent } from "../../Components/user-components/navTabprofile-component/joinidea/listjoinidea/listjoinidea.component";
import { JointokenideaComponent } from "../../Components/user-components/navTabprofile-component/jointokenidea/jointokenidea.component";
import { ListjointokenideaComponent } from "../../Components/user-components/navTabprofile-component/jointokenidea/listjointokenidea/listjointokenidea.component";
import { DetailComponent } from "../../Components/user-components/navTabprofile-component/detail/detail.component";
import { DetailupdateideaComponent } from "../../Components/user-components/navTabprofile-component/myidea/detailupdateidea/detailupdateidea.component";
import { DetailideaComponent } from "../../Components/user-components/detailidea-component/detailidea.component";
import { DetailmemberComponentComponent } from "../../Components/user-components/detailmember-component/detailmember-component.component";
import { HistorymodifyComponent } from "../../Components/user-components/navTabprofile-component/historymodify/historymodify.component";
import { SearchComponent } from "../../Components/user-components/search-component/search.component";
import { MorelistComponentComponent } from "../../Components/user-components/morelist-component/morelist-component.component";
import { EditIdeaComponentComponent } from "../../Components/user-components/edit-idea-component/edit-idea-component.component";
import { MensionComponent } from "../../Components/user-components/Shared/mension/mension.component";
import { UserprofileComponentComponent } from "../../Components/user-components/userprofile-component/userprofile-component.component";
import { IncubatorComponentComponent } from "../../Components/user-components/incubator-component/incubator-component.component";
import { DetailIncubateComponentComponent } from "../../Components/user-components/incubator/detail-incubate-component/detail-incubate-component.component";
import { MyincubatorComponentComponent } from "../../Components/user-components/incubator/myincubator-component/myincubator-component.component";
import { RdAllprojectComponentsComponent } from "../../Components/user-components/rd-components/rd-allproject-components/rd-allproject-components.component";
import { RdDetailComponentsComponent } from "../../Components/user-components/rd-components/rd-detail-components/rd-detail-components.component";
import { IpAllprojectComponentsComponent } from "../../Components/user-components/ip-components/ip-allproject-components/ip-allproject-components.component";
import { IpDetialComponentsComponent } from "../../Components/user-components/ip-components/ip-detial-components/ip-detial-components.component";


import { AuthGuard } from "../../_Guard/auth.guard";
import { EmploGuardGuard } from "../../_Guard/emplo-guard.guard";
import { ProfileComponent } from "../../Components/user-components/profile-component/profile.component";


const routesUser: Routes = [
  {
    path: "",
    component: UserComponentsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: HomeuserComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "AllProject/:region/:cate",
        component: AllProjectComponentComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "morelist/:region/:cate",
        component: MorelistComponentComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "detailhomeidea/:id",
        component: DetailideaComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "userProfile",
        component: MainProfile,
        children: [
          {
            path: "profileUser",
            component: ProfileComponent,
            canActivate: [EmploGuardGuard]
          },
          {
            path: "myidea",
            component: MyideaComponent,
            children: [
              { path: "listidea", component: ListideaComponent },
              {
                path: "detailupdateidea/:id",
                component: DetailupdateideaComponent
              }
            ],
            canActivate: [EmploGuardGuard]
          },
          {
            path: "joinidea",
            component: JoinideaComponent,
            children: [
              { path: "listjoinidea", component: ListjoinideaComponent }
            ],
            canActivate: [EmploGuardGuard]
          },
          {
            path: "jointokenidea",
            component: JointokenideaComponent,
            children: [
              {
                path: "listjointokenidea",
                component: ListjointokenideaComponent
              },
              { path: "detailTokenIdea/:id", component: DetailideaComponent }
            ],
            canActivate: [EmploGuardGuard]
          },
          { path: "updateprofile", component: UpdateprofileComponent },
          {
            path: "detailidea/:id",
            component: DetailComponent,
            canActivate: [EmploGuardGuard]
          },
          {
            path: "history",
            component: HistorymodifyComponent,
            canActivate: [EmploGuardGuard]
          }
        ]
      },
      {
        path: "createidea",
        component: CreateIdeaComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "allnotification",
        component: AllNotiComponentComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "detailimember/:id",
        component: DetailmemberComponentComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "editidea/:id",
        component: EditIdeaComponentComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "search",
        component: SearchComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "mension",
        component: MensionComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "profile/:id",
        component: UserprofileComponentComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "incubator",
        component: IncubatorComponentComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "myincubator",
        component: MyincubatorComponentComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "myincubate/:id",
        component: DetailIncubateComponentComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "rdproject/:year",
        component: RdAllprojectComponentsComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "detailrd/:id",
        component: RdDetailComponentsComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "ipproject/:year/:category",
        component: IpAllprojectComponentsComponent,
        canActivate: [EmploGuardGuard]
      },
      {
        path: "detailip/:id",
        component: IpDetialComponentsComponent,
        canActivate: [EmploGuardGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routesUser)],
  exports: [RouterModule],
  declarations: []
})
export class UserRoutingModule {}
