import { Component, OnInit} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";



// IMPORT SERVICES
import { ProjectideaServiceService } from "../../../../../Services/projectidea-service.service";
import { UserServiceService } from "../../../../../Services/user-service.service";
import { environment } from "../../../../../../environments/environment";

declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-detailupdateidea",
  templateUrl: "./detailupdateidea.component.html",
  styleUrls: ["./detailupdateidea.component.scss"]
})

export class DetailupdateideaComponent implements OnInit {
  urlHostImage = environment.URLUPLOAD;
  public itemCard: any;
  data: any;
  itemTeamName: any;
  team: any;
  teamSkill: any;
  email: any;
  tel: any;
  frontProjectName: any;
  frontDetail: any;
  frontDataCoverPic: any;
  frontDataToken: any;
  frontDataViewer: any;
  frontDataCreatedate: any;
  frontDatacategory: any;
  frontDataId: any;
  frontDataTagetGroup: any;
  frontNameOwn: any;
  frontTeamname: any;
  fromtImageProfile: any;
  tabsDetail: any;
  countlike: any;
  statuslike: any;

  dataSearch = [];
  userProfilesRecommend = [];
  inviteList = [];
  waitForAcceptList = [];
  searchBoxx: any;
  searchValue: any;
  CkEmailFormat = false;
  databackupPush = [];
  URLID: any;

  constructor(
    private projectSer: ProjectideaServiceService,
    private route: ActivatedRoute,
    private userSer: UserServiceService,
    private spinner: NgxSpinnerService
  ) { }

  async ngOnInit() {
    this.getURL();
    const id = this.route.snapshot.paramMap.get("id");
    let tempData;
    tempData = await this.projectSer.getIdeaById(id);
    this.itemCard = tempData.data.data[0];
    
    window.onscroll = function () { myFunction() };

    sessionStorage.setItem("incubatestatus","owner");


    //header
    var header = document.getElementById("header-panel");
    var namehd = document.getElementById("name-header")
    var like = document.getElementById("like");
    var edit = document.getElementById("edit");
    var join = document.getElementById("join");
    var btnlike = document.getElementById('btnlike');
    var btnedit = document.getElementById('btnedit');
    var btnjoin = document.getElementById('btnjoin');

    //btn ontop
    var ontop = document.getElementById("ontop");

    //tab panel
    var tabpanel = document.getElementById("tab-panel");
    var stickytab = tabpanel.offsetTop;

    

    function myFunction() {


      //header  
      if (window.scrollY > 50) {
        header.classList.add("sticky-header");
        namehd.classList.add("co-hid");
        $('#edit').addClass('disnone');
        $('#join').addClass('disnone');

        //if(this.itemCard.status == "active"){
          $('#panel-heart').removeClass("myheart");
          $('#panel-heart').addClass("re-myheart");
        //}
      
        $('#btnlike').addClass('resize-like');
        $('#btnedit').addClass('resize-other');
        $('#btnjoin').addClass('resize-other');
        ontop.classList.remove("co-hid");



      } else if (window.scrollY === 0) {

        header.classList.remove("sticky-header");
        namehd.classList.remove("co-hid");
        //like.classList.remove("disnone");
        $('#edit').removeClass('disnone');
        $('#join').removeClass('disnone');
        // edit.classList.remove("disnone");
        // join.classList.remove("disnone");
        //if(this.itemCard.status == "active"){
          $('#panel-heart').addClass("myheart");
          $('#panel-heart').removeClass("re-myheart");
        //}
        
        $('#btnlike').removeClass('resize-like');
        $('#btnedit').removeClass('resize-other');
        $('#btnjoin').removeClass('resize-other');

        // btnlike.classList.remove("resize-like");
        // btnedit.classList.remove("resize-other");
        // btnjoin.classList.remove("resize-other");
        ontop.classList.add("co-hid");
      }

      //tab
      if (window.pageYOffset >= stickytab) {

        tabpanel.classList.add("sticky-tab");

      } else {
        tabpanel.classList.remove("sticky-tab");

      }
    }

    sessionStorage.setItem("barActive", "userprefile");

    window.scroll(0, 0);
    

    this.frontDataCoverPic = this.itemCard.coverPicture;
    this.frontDataToken = this.itemCard.token;
    this.frontDataViewer = this.itemCard.viewer;
    this.frontDataCreatedate = this.itemCard.createDate;
    this.frontDatacategory = this.itemCard.category;
    this.frontDataId = this.itemCard._id;
    this.frontProjectName = this.itemCard.projectName;
    this.frontDetail = this.itemCard.projectDescription;
    this.frontDataTagetGroup = this.itemCard.targetGroup;
    this.frontNameOwn = this.itemCard.owner.displayName;
    this.frontTeamname = this.itemCard.teamData.teamName;
    this.fromtImageProfile = this.itemCard.owner.imgProfile;
    this.email = this.itemCard.owner.email;
    this.tel = this.itemCard.owner.tel;
    this.team = this.itemCard.teamData.member;
    this.teamSkill = this.itemCard.teamData.requireSkill;
    

    // 

    if(this.itemCard.status === "inactive"){
      $('#deleteStatus').modal('show');
    }else{
      $('#deleteStatus').modal('hide');
    }

    //
    //
    //
    //
    //
    this.waitForAcceptList = [];
    tempData.data.data[0].teamData.member.forEach(element => {
      if (element.status == "รอการตอบรับ") {
        let res = this.userSer.getProfileByEmpId(element.employeeId);
        res.then(data => {
          this.waitForAcceptList.push({
            user: data.data.data,
            teamdata: element
          });
        });
      }
    });

    this.data = {
      ProjectTeam: {
        name: "1111",
        name2: "2222",
        name3: "3333"
      }
    };

    let detailTabs = sessionStorage.getItem("TabsDetail");
    let userid = sessionStorage.getItem("_id");


    if (detailTabs === "คำขอเข้าร่วมทีม" || detailTabs === "คำตอบรับเข้าร่วมทีม" || detailTabs === "ปฏิเสธ") {
      this.tabsDetail = "member";
    } else if (detailTabs === "แสดงความคิดเห็น") {
      this.tabsDetail = "comment";
    }else if (detailTabs === "แสดงความคิดเห็นIncubator") {
      this.tabsDetail = "commentincubator";
    } else {
      this.tabsDetail = "detail";
    }

    var str = document.getElementById("like").innerHTML;

    this.projectSer.getLikeStatus(id, userid).then(res => {
      //

      if (res.data.data === null || res.data.data.action === "unlike") {

        this.countlike = res.data.rs.like;
        this.statuslike = "like";

        $("#like").html("ถูกใจ");
        $("#btnlike").removeClass("btn-like");
        $("#btnlike").addClass("btn-unlike");
        //$("#fly-heart").addClass("co-dis");

      } else if (res.data.data.action === "like") {

        this.countlike = res.data.rs.like;
        this.statuslike = "unlike";

        $("#like").html(this.countlike);
        $("#btnlike").addClass("btn-like");
        $("#btnlike").removeClass("btn-unlike");
        //$("#fly-heart").addClass("co-dis");
      }
    });
  }

  public onTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  public tabscroll() {
    window.scroll({
      top: 400,
      left: 0,
      behavior: 'smooth'
    });
  }

  async memberRecommended() {
    const id = this.route.snapshot.paramMap.get("id");
    let tempData;
    tempData = await this.projectSer.getIdeaById(id);
    this.itemCard = tempData.data.data[0];

    this.userProfilesRecommend = [];
    this.itemCard.teamData.member.forEach(element => {

      if (element.status == "สนใจเข้าร่วมโครงการ") {
        let res = this.userSer.getProfileByEmpId(element.employeeId);
        res.then(data => {
          //
          //
          let filterrecommend = this.inviteList.filter(x => x.employeeId == element.employeeId);
          ////
          ////
          //
          if (filterrecommend.length == 0) {
            //
            this.userProfilesRecommend.push(
              //{ user : data.data.data,
              // teamdata : element}
              {
                displayName: element.displayName,
                email: element.email,
                employeeId: element.employeeId,
                status: element.status,
                imgProfile: data.data.data.imgProfile,
                _id: data.data.data._id,
                position: data.data.data.position,
                _idTeam: element._id
              }
            );
          }
        });
      }
    });
    this.waitForAcceptList = [];


    tempData.data.data[0].teamData.member.forEach(element => {
      if (element.status == "รอการตอบรับ") {
        let res = this.userSer.getProfileByEmpId(element.employeeId);
        res.then(data => {
          this.waitForAcceptList.push({
            user: data.data.data,
            teamdata: element
          });
        });
      }
    });
  }

  async searchMember() {
    let searchTxt = $('#searchBox').val();
    if (searchTxt != "") {
      let users = await this.userSer.getMemberByEmail(searchTxt);
      this.dataSearch = users.data.data;
      ////
      //
      ////
      let leftUsers = this.dataSearch;
      if (this.inviteList.length > 0) {
        leftUsers = this.dataSearch.filter(x => this.inviteList.findIndex(y => y.employeeId == x.employeeId) === -1);
      }
      //
      if (this.waitForAcceptList.length > 0) {
        leftUsers = leftUsers.filter(x => this.waitForAcceptList.findIndex(y => (y.user != undefined ? y.user.employeeId : y.teamdata.email) == (y.user != undefined ? x.employeeId : x.email )) === -1 );
      }
      //

      var leftUsers3 = this.itemCard.teamData.member.filter(x => x.status == "สนใจเข้าร่วมโครงการ");
      ////
      if (leftUsers3.length > 0) {
        leftUsers = leftUsers.filter(x => leftUsers3.findIndex(y => y.employeeId == x.employeeId) === -1);
      }
      ////
      leftUsers = leftUsers.filter(x => x.employeeId != this.itemCard.owner.employeeId);
      ////
      ////
      if (this.itemCard.incubator != undefined) {
        leftUsers = leftUsers.filter(x => x._id != this.itemCard.incubator._id);
      }
      this.dataSearch = leftUsers;
      if (this.dataSearch.length != 0) {
        $('#userDropdown').show();
      } else {
        $('#userDropdown').hide();
      }

      //////////////////  Check Email Format  ////////////////// this.CkEmailFormat = 
      if (this.searchValue.indexOf('@') != -1 && this.searchValue.substr(this.searchValue.indexOf('@')).indexOf('.') != -1) {
        for (let i = 0; i < this.waitForAcceptList.length; i++) {
          if (this.searchValue.indexOf(this.waitForAcceptList[i].teamdata.email) != -1) {
            this.CkEmailFormat = false;
            break;
          } else {
            this.CkEmailFormat = true;
          }
        }
      }

      //////////////////////////////////////////////////////////
    }
    else {
      this.dataSearch = null;
      $('#userDropdown').hide();
    }
  }

  hidedropdown() {
    $('#userDropdown').hide();
  }

  selectUser(value) {
    this.inviteList.push(this.dataSearch[value]);

    let leftUsers = this.dataSearch;
    if (this.inviteList.length > 0) {
      leftUsers = this.dataSearch.filter(x => this.inviteList.findIndex(y => y.employeeId == x.employeeId) === -1);
    }
    //
    if (this.waitForAcceptList.length > 0) {
      leftUsers = leftUsers.filter(x => this.waitForAcceptList.findIndex(y => (y.user != undefined ? y.user.employeeId : y.teamdata.employeeId) == (y.user != undefined ? x.email : x.employeeId)) === -1);
    }
    //

    var leftUsers3 = this.itemCard.teamData.member.filter(x => x.status == "สนใจเข้าร่วมโครงการ");
    //
    if (leftUsers3.length > 0) {
      leftUsers = leftUsers.filter(x => leftUsers3.findIndex(y => y.employeeId == x.employeeId) === -1);
    }
    //
    leftUsers = leftUsers.filter(x => x.employeeId != this.itemCard.owner.employeeId);
    //
    this.dataSearch = leftUsers;
    //
  }
  addMemberNotUser(email) {
    //
    this.inviteList.push({
      displayName: null,
      email: email,
      employeeId: null,
      status: null,
      imgProfile: null,
      _id: null,
      position: null,
      _idTeam: null

    });
  }

  selectRecommendUser(value) {
    let ck = false;
    let keepGoing = true;
    this.inviteList.forEach(element => {
      if (keepGoing) {
        ////
        if (element._id == this.userProfilesRecommend[value]._id) {
          ck = true;
          keepGoing = false;
        }
        else {

        }
      }
    });
    if (!ck) {
      this.inviteList.push(this.userProfilesRecommend[value]);
      this.databackupPush.push(this.userProfilesRecommend[value]);
      this.userProfilesRecommend.splice(value, 1);
    }
  }

  deleteUser(value, employeeId) {
    ////
    this.inviteList.splice(value, 1);
    let index = this.databackupPush.findIndex(y => y._id === employeeId);
    if (this.databackupPush[index]) {
      this.userProfilesRecommend.push(this.databackupPush[index]);
      this.databackupPush.splice(index, 1);
    }
  }

  confirmInvite() {
    $("#InviteMemberModal").modal("hide");
    $("#ConfirmInviteModal").modal("show");
  }

  onSubmitconfirm() {
    const id = this.route.snapshot.paramMap.get("id");
    let OwnerUserId = sessionStorage.getItem("_id");

    this.inviteList.forEach(element => {
      const userData = {
        _id: element._id,
        jobDescription: $('#jobDescription' + element._id).val(),
        position: element.position,
        employeeId: element.employeeId == "" || element.employeeId == null ? element.email : element.employeeId,
        displayName: element.displayName,
        email: element.email,
        status: "รอการตอบรับ",
        oldStatus: element.status ? element.status : null,
        _idTeam: element._idTeam ? element._idTeam : null
      }

      this.projectSer.InsertMember(this.itemCard, userData).then(
        res => {
          ////
          if (res.data.status == 200) {
            let res2 = this.projectSer.InsertToStory(element.displayName, OwnerUserId, id);
            // //
            $("#ConfirmInviteModal").modal("hide");
            $("#sendinviteComplete").modal("show");
            this.inviteList = [];
            $('#searchBox').val("");
          }
        }
      );
    });
  }

  public secdComplete() {
    sessionStorage.setItem("TabsDetail", "คำขอเข้าร่วมทีม");
    window.location.reload();
  }

  public likeAction(action) {

    var str = document.getElementById("like").innerHTML;
    const id = this.route.snapshot.paramMap.get("id");
    let userid = sessionStorage.getItem("_id");

    this.projectSer.getUpdateLikeStatus(id, userid, action).then(res => {

      this.projectSer.getLikeStatus(id, userid).then(res => {
        //
        //
        let LikeActive = res.data.rs.likeActive;
        if (res.data.data.action === "unlike") {

          this.countlike = res.data.rs.like;
          $('#count-like').html(this.countlike);
          this.statuslike = "like";

          $("#like").html("ถูกใจ");
          $("#btnlike").removeClass("btn-like");
          $("#btnlike").addClass("btn-unlike");
          $("#fly-heart").addClass("co-dis");

        } else if (res.data.data.action === "like") {

          //let oldlike = $('#count-like').text();

          this.countlike = res.data.rs.like;
          $('#count-like').html(this.countlike);
          this.statuslike = "unlike";

          $("#like").html(this.countlike);
          $("#btnlike").addClass("btn-like");
          $("#btnlike").removeClass("btn-unlike");
          $("#fly-heart").removeClass("co-dis");

          this.projectSer.getIdeaById(id).then(res => {
            // //
            //   //
            //   //
            let itemCard = res.data.data[0];
            //let LikeActive = res.data.rs.likeActive;
            let senderid = sessionStorage.getItem("_id");
            let displayName = sessionStorage.getItem("displayName");
            let employeeId = localStorage.getItem("EmployeeId");
            let teamMember = res.data.data[0].teamData.member;
            // //

            const userDataNoti = {
              projectId: itemCard._id,
              projectName: itemCard.projectName,
              empIdowner: itemCard.owner.employeeId,
              sender: {
                id: senderid,
                displayName: displayName,
                employeeId: employeeId
              },
              teamMember,
              likeActive: LikeActive
            };
            // //
            // //
            // //
            this.projectSer.getNotiLike(userDataNoti).then(res => {
              ////
            });

          });
        }
      });
    });

  }

  public DeleteComplete(){
    var urlDetail = "";
    if (window.location.hostname === "localhost") {
      urlDetail = "http://localhost:4200/";
    } else if (window.location.hostname === "inning.dld-test.com") {
      urlDetail = "https://" + window.location.host;
    } else {
      urlDetail = "https://" + window.location.host;
    }
    window.location.href = urlDetail;
  }


  copyText(val: string){
    
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    // alert("ddf");
    $('#copyPanel').modal("show");
    setTimeout(() => {

      $('#copyPanel').modal("hide");
    }, 1000);
    
  }

  public getURL(){
    const id = this.route.snapshot.paramMap.get("id");
    

    var urlDetail = "";
    if (window.location.hostname === "localhost") {
      urlDetail = "http://localhost:4200/login?pid="+id;
    } else if (window.location.hostname === "inning.dld-test.com") {
      urlDetail = "https://" + window.location.host+"/login?pid="+id;
    } else {
      urlDetail = "https://" + window.location.host+"/login?pid="+id;
    }
    this.URLID = urlDetail;
  }


}
