import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { environment } from "../../../../environments/environment";



// IMPORT SERVICES
import { ProjectideaServiceService } from "../../../Services/projectidea-service.service";
import { UserServiceService } from "../../../Services/user-service.service";
// import '~swiper/dist/css/swiper.min.css';

// USE JQUERY
declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-homeuser",
  templateUrl: "./homeuser.component.html",
  styleUrls: ["./homeuser.component.scss"]
})
export class HomeuserComponent implements OnInit {
  

  public itemCard: any;
  public itemCardNew: any;
  //public itemCard8: any;
  public dataUser: any;
  public itemTop4Mb: any;
  urlHostImage = environment.URLUPLOAD;

  public percents: any;
  arrTop4 = [];
  arrTop8 = [];
  itemCard8 = [];

  statuslike: any;

  constructor(
    private router: Router,
    private projectSer: ProjectideaServiceService,
    private userService: UserServiceService
  ) { }

  ngOnInit() {

    this.getInit()

  }

  async getInit() {

    $("#mySidenav-panel").hide();
    document.getElementById("mySidenav").style.width = "0";

    // $('body > app-root > div > app-user-components > div > app-homeuser > div > div > div.slider.bl-mb > div.gallery.js-flickity.flickity-enabled.is-draggable > div').attr('style','height:380px !important');
    

    // SET ACTIVER DELETE HOME
    sessionStorage.setItem("activeTabs", "")
    window.scroll(0, 0)
    // 
    // GET DATA ALL PROJECT IDEAS
    let tempData, NewProjectList, ProjectTop8, NewProjectMB
    tempData = await this.projectSer.getIdea()
    NewProjectList = await this.projectSer.getNewProject("");
    ProjectTop8 = await this.projectSer.getProjectTop8();
    NewProjectMB = await this.projectSer.getForMobile("DESC",4);

    //
    this.itemCard = tempData.data.data;
    this.itemCardNew = NewProjectList.data.data.sort(function (a, b) {
      if (a.lastUpdate > b.lastUpdate) { return -1; }
      if (a.lastUpdate < b.lastUpdate) { return 1; }
      return 0;
    });
    let top8 = ProjectTop8.data.data;
    this.itemTop4Mb = NewProjectMB.data.data;
    // GET DATA USER TOKEN
    this.dataUser = await this.userService.getProfileUser();
    let userid = sessionStorage.getItem("_id");

    // this.itemCard8 = 
    top8.forEach(element => {

      if(element.owner){
        this.itemCard8.push({
          project: element
        });
      }     
    });

    for(let index=0; index < this.itemTop4Mb.length; index++){
      this.projectSer.getLikeStatus(this.itemTop4Mb[index]._id,userid).then(res => {

        if(res.data.data === null || res.data.data.action === "unlike"){
          this.arrTop4.push({
            project : this.itemTop4Mb[index],
            status: "unlike",
            like: res.data.data
          });

        }else if(res.data.data.action === "like"){

          this.arrTop4.push({
            project : this.itemTop4Mb[index],
            status: "like",
            like: res.data.data
          });

        }
      });
    }

    for(let i=0; i < top8.length; i++){
      this.projectSer.getLikeStatus(top8[i]._id,userid).then(res => {
        if(top8[i].owner){
          if(res.data.data === null || res.data.data.action === "unlike"){
            this.arrTop8.push({
              project : top8[i],
              status: "unlike",
              like: res.data.data
            });

          }else if(res.data.data.action === "like"){

            this.arrTop8.push({
              project : top8[i],
              status: "like",
              like: res.data.data
            });
          }
        }
      });
    }


    


    // 


  }

  public onChangeType(value) {

    if (value == "AllIdeas") {
      this.router.navigate(['']);
    } else {
      this.router.navigate(['/morelist/Allregion', value]);
    }

  }

  public processBar(state) {
    let percent = this.percentage(state);
    return percent;
  }

  public percentage(state) {
    let percent = "0%";
    //for (let i = 0; i < 7; i++) {
    switch (state) {
      case "New Project": percent = "14%";
        break;
      case "Problem Validation": percent = "28%";
        break;
      case "Problem / Solution fit ready for pitch": percent = "42%";
        break;
      case "Product / MKT Validation": percent = "57%";
        break;
      case "Product / MKT Validation ready for pitch": percent = "71%";
        break;
      case "Commercialization / Preparation": percent = "85%";
        break;
      case "Commercialization / Ready to Launch": percent = "100%";
        break;
    }

    return percent;
  }

  public likeAction(status,projid,no){

    var unlike = document.getElementById("unlike["+no+"]");
    var like = document.getElementById("like["+no+"]");
    
    let userid = sessionStorage.getItem("_id");

    this.projectSer.getUpdateLikeStatus(projid,userid,status).then(res => {
      this.projectSer.getLikeStatus(projid,userid).then(res => {

        if(res.data.data.action === "unlike"){
          like.classList.add("co-dis");
          unlike.classList.remove("co-dis");
        }else if(res.data.data.action === "like"){
          like.classList.remove("co-dis");
          unlike.classList.add("co-dis");
        }
      });
    });
  }

  public unlikeAction(status,projid,no){

    var unlike = document.getElementById("unlike["+no+"]");
    var like = document.getElementById("like["+no+"]");

    let userid = sessionStorage.getItem("_id");

    this.projectSer.getUpdateLikeStatus(projid,userid,status).then(res => {
      this.projectSer.getLikeStatus(projid,userid).then(res => {

        if(res.data.data.action === "unlike"){
          like.classList.add("co-dis");
          unlike.classList.remove("co-dis");
        }else if(res.data.data.action === "like"){
          like.classList.remove("co-dis");
          unlike.classList.add("co-dis");
        }
      });
    });
  }

  public likeAllAction(status,projid,no){

    //alert(status+projid+no);

    var unlike = document.getElementById("unlikeall["+no+"]");
    var like = document.getElementById("likeall["+no+"]");
    
    let userid = sessionStorage.getItem("_id");

    this.projectSer.getUpdateLikeStatus(projid,userid,status).then(res => {
      this.projectSer.getLikeStatus(projid,userid).then(res => {

        if(res.data.data.action === "unlike"){
          like.classList.add("co-dis");
          unlike.classList.remove("co-dis");
        }else if(res.data.data.action === "like"){
          like.classList.remove("co-dis");
          unlike.classList.add("co-dis");
        }
      });
    });
  }

  public unlikeAllAction(status,projid,no){

    //alert(status+projid+no);

    var unlike = document.getElementById("unlikeall["+no+"]");
    var like = document.getElementById("likeall["+no+"]");

    let userid = sessionStorage.getItem("_id");

    this.projectSer.getUpdateLikeStatus(projid,userid,status).then(res => {
      this.projectSer.getLikeStatus(projid,userid).then(res => {

        if(res.data.data.action === "unlike"){
          like.classList.add("co-dis");
          unlike.classList.remove("co-dis");
        }else if(res.data.data.action === "like"){
          like.classList.remove("co-dis");
          unlike.classList.add("co-dis");
        }
      });
    });
  }

}
