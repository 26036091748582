import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";

// Import Service
import { FillterService } from "../../../Services/fillter.service";
import { ProjectideaServiceService } from "../../../Services/projectidea-service.service";
import { AuthenServiceService } from "../../../Services/authen-service.service";
import { UserServiceService } from "../../../Services/user-service.service";

declare var $: any;

@Component({
  selector: 'app-all-noti-component',
  templateUrl: './all-noti-component.component.html',
  styleUrls: ['./all-noti-component.component.scss']
})
export class AllNotiComponentComponent implements OnInit {
  public noti: any;
  badgeCount: number;
  urlHostImage = environment.URLUPLOAD;
  itemCardNoti: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
    private fillterServ: FillterService,
    private authSer: AuthenServiceService,
    private userService: UserServiceService
  ) { 
    this.badgeCount = 5;
  }

  ngOnInit() {

    $("#mySidenav-panel").hide();
    document.getElementById("mySidenav").style.width = "0";

    let employeeId = localStorage.getItem("EmployeeId");
    let email = sessionStorage.getItem("email");

    this.noti=[];

    this.authSer.getAllNotiById(email, employeeId).then(res => {
        // this.noti = res.data.data;

        res.data.data.forEach(element => {

          this.userService.getProfileByEmpId(element.sender).then(resuser => {
            this.noti.push({
              notilist : element,
              user: resuser.data.data

            });
          });
          
        });
    });

  }

  clearCount() {
    this.badgeCount = 0;
  }

  public async getProject(id){

    let userid = sessionStorage.getItem("_id");
    
    let tempData
    tempData =  await this.projectSer.getIdeaById(id);
    this.itemCardNoti = tempData.data.data[0];
    

    if(this.itemCardNoti.incubator){
      if(userid === this.itemCardNoti.incubator._id){
        status = "incubator";
      }else{
        status = "user";
      }
    }else{
      status = "user";
    }
    
    return status;
  }

  public async onNotiDetail(id, projectId, category,title,commentId,recipient){
    //alert(category+title);

    let statusPathReply = await this.getProject(projectId);
    
    
    this.authSer.getReadNotiById(id).then(res => {
      this.noti = res.data.data;
        if(category === "DeleteMember" || category === "CreateProject" || title === "คำเชิญ" || category === "LikeProject"){
          var urldetail = "";
          if (window.location.hostname === "localhost") {
            urldetail = "http://localhost:4200/detailhomeidea/"+projectId;
          } else if (window.location.hostname === "inning.dld-test.com") {
            urldetail = "https://" + window.location.host + "/detailhomeidea/"+projectId;
          } else {
            urldetail = "https://" + window.location.host + "/detailhomeidea/"+projectId;
          }
          window.location.href = urldetail;
          
      }else if(category === "InviteMember"){
        if(title != "คำเชิญ"){

          sessionStorage.setItem("TabsDetail", title);

          var urldetail = "";
            if (window.location.hostname === "localhost") {
              urldetail = "http://localhost:4200/userProfile/myidea/detailupdateidea/"+projectId;
            } else if (window.location.hostname === "inning.dld-test.com") {
              urldetail = "https://" + window.location.host + "/userProfile/myidea/detailupdateidea/"+projectId;
            } else {
              urldetail = "https://" + window.location.host + "/userProfile/myidea/detailupdateidea/"+projectId;
            }
            window.location.href = urldetail;
        }

      }else if(category === "Comment"){

        //alert(title);

        sessionStorage.setItem("TabsDetail", title);
        sessionStorage.setItem("CommentId",commentId);

        
        
        if(title === "แสดงความคิดเห็นตอบกลับIncubator"){

          if(statusPathReply === "incubator"){

            var urldetail = "";
            if (window.location.hostname === "localhost") {
              urldetail = "http://localhost:4200/myincubate/"+projectId;
            } else if (window.location.hostname === "inning.dld-test.com") {
              urldetail = "https://" + window.location.host + "/myincubate/"+projectId;
            } else {
              urldetail = "https://" + window.location.host + "/myincubate/"+projectId;
            }
            window.location.href = urldetail;

          }else{

            var urldetail = "";
            if (window.location.hostname === "localhost") {
              urldetail = "http://localhost:4200/detailhomeidea/"+projectId;
            } else if (window.location.hostname === "inning.dld-test.com") {
              urldetail = "https://" + window.location.host + "/detailhomeidea/"+projectId;
            } else {
              urldetail = "https://" + window.location.host + "/detailhomeidea/"+projectId;
            }
            window.location.href = urldetail;

          }

        }else{

          var urldetail = "";
          if (window.location.hostname === "localhost") {
            urldetail = "http://localhost:4200/detailhomeidea/"+projectId;
          } else if (window.location.hostname === "inning.dld-test.com") {
            urldetail = "https://" + window.location.host + "/detailhomeidea/"+projectId;
          } else {
            urldetail = "https://" + window.location.host + "/detailhomeidea/"+projectId;
          }
          window.location.href = urldetail;
        }

      }
    });
  }

}
