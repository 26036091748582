import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-myidea',
  template: `<router-outlet></router-outlet>` ,
})
export class MyideaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
