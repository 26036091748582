import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup } from "@angular/forms";
import { HttpParams } from "@angular/common/http";

import { UserServiceService } from "../../Services/user-service.service";
import { AuthenServiceService } from "../../Services/authen-service.service";
import { environment } from '../../../environments/environment';
import { IfStmt } from "@angular/compiler";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  isLogin: boolean;

  Role: boolean = false;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private userSer: UserServiceService,
    private authSer: AuthenServiceService
  ) {}

  ngOnInit() {
    this.getInit();
  }

  async getInit() {

    if (this.getParamValueQueryString("pid"))
    {
      let pid = this.getParamValueQueryString("pid");
      localStorage.setItem("pid", pid);
    }

    if(this.getParamValueQueryString("pth")){
      let pth = this.getParamValueQueryString("pth");
      localStorage.setItem("pth", pth);
    }
    
    if (this.getParamValueQueryString("token")) {
      let token = this.getParamValueQueryString("token");
      sessionStorage.setItem("authen", token);
      
      await this.userSer.getProfileUser().then(res => {       
        this.userSer.getProfileEmId();
        this.spinner.show();
        let userId = res._id;
        let userType = res.role;
        setTimeout(() => {
          this.spinner.hide();
          
          
          if (res.role === "user") {
            sessionStorage.setItem("barActive", "home");
            sessionStorage.setItem("incubate", res.isIncubator);
            this.userSer.loginLog(userId, userType);
            let projectId = localStorage.getItem("pid");
            let incubatorPath = localStorage.getItem("pth");
            
            if(projectId != null) {
              
              let urlDetail = "", path = "detailhomeidea";
              if(incubatorPath != null){
                path = incubatorPath;
              }else{
              this.userSer.getUser(projectId, res.email).then(res => {
                if(res.data.rs == true){
                  
                  this.userSer.getUpdateUser(res.email, res.employeeId, res.displayName);
                }
              });}
              
              if (window.location.hostname === "localhost") {
                urlDetail = "http://localhost:4200/"+path+"/" + projectId;
              } else if (window.location.hostname === "inning.dld-test.com") {
                urlDetail = "https://" + window.location.host + "/"+path+"/" + projectId;
              } else {
                urlDetail = "https://" + window.location.host + "/"+path+"/" + projectId;
              }
              window.location.href = urlDetail;
              localStorage.removeItem("pid");
              localStorage.removeItem("pth"); 
            }else{
              this.router.navigate([""]);   
              localStorage.removeItem("pid"); 
              localStorage.removeItem("pth");         
            }
            
          } else if (res.role === "admin") {
            sessionStorage.setItem("incubate", res.isIncubator);
            localStorage.setItem("role", "admin");
           
            this.Role = true;
          } else this.router.navigate(["/login"]);

        }, 100);
      });
    }

    if(this.getParamValueQueryString("code")){
      let auth_code = this.getParamValueQueryString("code");
      let url = `${environment.URL}${environment.ADMA_AUTH}?code=${encodeURIComponent(auth_code)}`
      window.location.href = url;
    }
     
  }
  //redirect 
  public redirectpage(urlpage: string) {
    var urllogin = "";
      if (window.location.hostname === "localhost") {
        urllogin = "http://localhost:4200/"+urlpage;
      } else if (window.location.hostname === "inning.dld-test.com") {
        urllogin = "https://" + window.location.host + urlpage;
      } else {
        urllogin = "https://" + window.location.host + urlpage;
      }
      return urllogin;
  }
  // Get param Token
  public getParamValueQueryString(paramName) {
    const url = window.location.href;
    let paramValue;
    if (url.includes("?")) {
      const httpParams = new HttpParams({ fromString: url.split("?")[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  public async onLoginAuth() {
    this.spinner.show();
    await this.authSer.admaGetAuthUrl().then(res => {
      setTimeout(() => {
        // var urllogin = "";
        // if (window.location.hostname === "localhost") {
        //   urllogin = "http://inning.dld-test.com/v1/api/user/auth/";
        // } else if (window.location.hostname === "inning.dld-test.com") {
        //   urllogin = "https://" + window.location.host + "/v1/api/user/auth/";
        // } else {
        //   urllogin = "https://" + window.location.host + "/v1/api/user/auth/";
        // }
        window.location.href = res.data.data;
        this.spinner.hide();
      }, 500);
    });
  }

  public async onLoginAuthAdmin(path: string) {
    
    this.spinner.show();
    await this.userSer.getProfileUser().then(res => { 
    setTimeout(() => {
      this.spinner.hide();
      let userId = res._id;
      let userType = res.role;
      this.userSer.loginLog(userId, userType);
      if (path == "user") {
        let projectId = localStorage.getItem("pid");
        let incubatorPath = localStorage.getItem("pth");
        let employeeId = localStorage.getItem("EmployeeId");
        let email = sessionStorage.getItem("email");
        let displayName = sessionStorage.getItem("displayName");

        sessionStorage.setItem("Pathrole","incubator");
        
        //this.userSer.getUser(Projectid,Email).then(res => {
         // if(res.data.rs == true){
          if(projectId != null) {

            let urlDetail = "", path = "detailhomeidea";
            if(incubatorPath != null){
              path = incubatorPath;
            }else{
            
              this.userSer.getUser(projectId, email).then(res => {
                if(res.data.rs == true){
                  this.userSer.getUpdateUser(email, employeeId, displayName);
                }
              });}

            if (window.location.hostname === "localhost") {
              urlDetail = "http://localhost:4200/"+path+"/" + projectId;
            } else if (window.location.hostname === "inning.dld-test.com") {
              urlDetail = "https://" + window.location.host + "/"+path+"/" + projectId;
            } else {
              urlDetail = "https://" + window.location.host + "/"+path+"/" + projectId;
            }
            window.location.href = urlDetail;
            localStorage.removeItem("pid");
            localStorage.removeItem("pth");

          }else{
            var urlDetail = "";
            if (window.location.hostname === "localhost") {
              urlDetail = "http://localhost:4200/";
            } else if (window.location.hostname === "inning.dld-test.com") {
              urlDetail = "https://" + window.location.host;
            } else {
              urlDetail = "https://" + window.location.host;
            }
            window.location.href = urlDetail;
            
          }
        
        this.router.navigate([""]);
        
      } else if (path == "admin") {
        sessionStorage.setItem("Pathrole","admin");
        sessionStorage.setItem("regionActive", "HOME");
        
        this.router.navigate(["admin"]);
        localStorage.removeItem("pid");
        localStorage.removeItem("pth");
      }
    }, 1000);
  });
  }
}
