import { Component, OnInit } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { Subscription } from "rxjs";


// IMPORT SERVICES
import { ProjectideaServiceService } from "../../../Services/projectidea-service.service";

// use Jquery
declare var jquery: any;
declare var $: any;

@Component({
  template: "The href is: {{href}}",
  selector: "app-detailidea",
  templateUrl: "./detailidea.component.html",
  styleUrls: ["./detailidea.component.scss"]
})
export class DetailideaComponent implements OnInit {
  public itemCard: any;
  public url = environment.API;
  urlHostImage = environment.URLUPLOAD;

  public coverPicture = "";
  public projectDescript = "";
  public imgProfile = "";
  public email = "";
  public tel = "";
  public team = [];
  
  viewDeleteMember: any;
  teamSkill: any;
  member: any;
  showInvite;
  scrollone: any;
  statuslike: any;
  subscription: Subscription;
  countlike: any;
  tabsDetail: any;
  requestStatus = false;
  projectID: any;
  URLID:any;
  private token;
  private project;
  public inviteJobDescription = null;

  constructor(private projectSer: ProjectideaServiceService, private route: ActivatedRoute,private router: Router
  ) {this.router.routeReuseStrategy.shouldReuseRoute = () => false; }

  ngOnInit() {

    this.getInit()

  }

  onRefresh(){
    
  }

  async getInit() {

    this.getURL();

    var x = window.matchMedia("(max-width: 700px)")
    this.media(x) // Call listener function at run time
    x.addListener(this.media) // Attach listener function on state changes
    
    

    window.onscroll = function () { myFunction() };

    var navbar = document.getElementById("navbarnoti");
    var navbarmb = document.getElementById("navbarnoti-mb");
    var content = document.getElementById("content");
    var projectnamenoti = document.getElementById("projectname");
    var contain = document.getElementById("contain");
    var sticky = navbar.offsetTop;
    var btnone = document.getElementById("btnnulone");
    var btnnultwo = document.getElementById("btnnultwo");
    
    

    //header
    var header = document.getElementById("header-panel");
    var namehd = document.getElementById("name-header");
    var btnjoin = document.getElementById('btnjoin');
    var btnadd = document.getElementById('btnadd');

    //btn on top
    var ontop = document.getElementById("ontop");

    //tab panel
    var tabpanel = document.getElementById("tab-panel");
    var stickytab = tabpanel.offsetTop;

    //delete
    var deletealert = document.getElementById("deletealert");
   

    function myFunction() {
      //navbar.classList.add("sh-nonnoti");
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
        // navbarmb.classList.add("sticky");
        content.classList.add("co-dis");
        projectnamenoti.classList.remove("co-dis");
        contain.classList.add("con-ma");
        
      } else {
        navbar.classList.remove("sticky");
        // navbarmb.classList.remove("sticky");
        content.classList.remove("co-dis");
        projectnamenoti.classList.add("co-dis");
        contain.classList.remove("con-ma");
        
      }

      //header  
      if (window.scrollY > 50) {
        //alert("1");
        header.classList.add("sticky-header");
        namehd.classList.add("co-hid");
        ontop.classList.remove("co-hid");
       

      }else if(window.scrollY === 0){

        header.classList.remove("sticky-header");
        namehd.classList.remove("co-hid");
        ontop.classList.add("co-hid");
        
      }

      //tab
      if (window.pageYOffset >= stickytab) {
        
        tabpanel.classList.add("sticky-tab");

      }else{

        tabpanel.classList.remove("sticky-tab");

      }
    }
    
    window.scroll(0, 0)
    this.token = {
      Title_token: "Support Project",
      Text_token: "Message from Home Component to App Component!",
      Token: "50"
    };
    this.project = {
      Title_join: "Join Project",
      Project_name: "NAME PROJECT...",
      Text_join: "* คุณต้องรอการอนุมัติจากเจ้าของโครงการ *"
    };

    this.token = { title: "Support Project", message: ' Would you like support project by providing a token', token: '50' }
    this.project = { title: "Join Project", name: 'NAME PROJECT...', message: '* คุณต้องรอการอนุมัติจากเจ้าของโครงการ *' }

    const id = this.route.snapshot.paramMap.get("id");
    let tempData;
    tempData = await this.projectSer.getIdeaById(id);
    this.itemCard = tempData.data.data[0];
    
    this.coverPicture = this.urlHostImage + this.itemCard.coverPicture;
    this.projectDescript = this.itemCard.projectDescription;
    this.imgProfile = tempData.data.data[0].owner.imgProfile;
    this.email = tempData.data.data[0].owner.email;
    this.tel = tempData.data.data[0].owner.tel;
    this.teamSkill = tempData.data.data[0].teamData.requireSkill;
    this.member = tempData.data.data[0].teamData.member;
    this.projectID = id;

    let emailLocal = sessionStorage.getItem("email");
    let employeeIdLocal = localStorage.getItem("EmployeeId");

    

    if(this.itemCard.status === "inactive"){
      $('#deleteStatus').modal('show');
    }else if(this.itemCard.status != "active"){
      $('#deleteStatus').modal('hide');
      $('#btnadd').addClass('co-dis');
      $('#btnjoin').addClass('co-dis');
    
    }else{
      $('#deleteStatus').modal('hide');
    }

    
    //resirect//
    let EmployeeId = localStorage.getItem("EmployeeId");
    if(EmployeeId === tempData.data.data[0].owner.employeeId){
      //alert("owner");
      var urlDetail = "";
      if (window.location.hostname === "localhost") {
        urlDetail = "http://localhost:4200/userProfile/myidea/detailupdateidea/" + id;
      } else if (window.location.hostname === "inning.dld-test.com") {
        urlDetail = "https://" + window.location.host + "/userProfile/myidea/detailupdateidea/" + id;
      } else {
        urlDetail = "https://" + window.location.host + "/userProfile/myidea/detailupdateidea/" + id;
      }
      window.location.href = urlDetail;
    }else{
      if (this.member.length > 0) {
        for (let k = 0; k < this.member.length; k++) {
           if(EmployeeId === tempData.data.data[0].teamData.member[k].employeeId) {
              if (tempData.data.data[0].teamData.member[k].status === "สมาชิกโครงการ") {
                //alert("member");
                var urlDetail = "";
                if (window.location.hostname === "localhost") {
                  urlDetail = "http://localhost:4200/detailimember/" + id;
                } else if (window.location.hostname === "inning.dld-test.com") {
                  urlDetail = "https://" + window.location.host + "/detailimember/" + id;
                } else {
                  urlDetail = "https://" + window.location.host + "/detailimember/" + id;
                }
                window.location.href = urlDetail;
              }
           }
        }
      }
    }


    let chkInvite = false;
    if(tempData.data.data[0].status == "active"){
      
      if (this.member.length > 0) {
        for (let i = 0; i < this.member.length; i++) {
          if ((emailLocal === tempData.data.data[0].teamData.member[i].email) || (employeeIdLocal === tempData.data.data[0].teamData.member[i].employeeId)) {
          
            if (tempData.data.data[0].teamData.member[i].status === "รอการตอบรับ") {
              this.inviteJobDescription = tempData.data.data[0].teamData.member[i].jobDescription;
              chkInvite = true;
              break;
            }else if (tempData.data.data[0].teamData.member[i].status === "สนใจเข้าร่วมโครงการ") {
              btnjoin.classList.remove("co-dis");
              btnadd.classList.add("co-dis");
            }else if (tempData.data.data[0].teamData.member[i].status === "ถูกเชิญออกจากโครงการ" 
            && (!tempData.data.data[0].teamData.member[i].viewDeleteDate || tempData.data.data[0].teamData.member[i].viewDeleteDate === null)) {
              deletealert.classList.remove("co-dis");
              this.viewDeleteMember = {
                projectId: id,
                teamMemberId: tempData.data.data[0].teamData.member[i]._id,
              };
              await this.projectSer.updateViewDeleteMember(this.viewDeleteMember);
            } else {
              this.showInvite = '';
            }
          } else {
            this.showInvite = '';
          }
        }
      } else {
        this.showInvite = '';
      }

    }else{
      this.showInvite = 'show';
    }
    
    if(chkInvite){
      this.showInvite = 'show';
    }

    let userid = sessionStorage.getItem("_id");
    let detailTabs = sessionStorage.getItem("TabsDetail");

    if (detailTabs === "แสดงความคิดเห็น") {
      this.tabsDetail = "comment";
    }

    this.projectSer.getLikeStatus(id,userid).then(res => {

      

      if(res.data.data === null || res.data.data.action === "unlike"){
        
        this.countlike = res.data.rs.like;
        this.statuslike = "like";

        $("#like").html("ถูกใจ");
        $("#btnlike").removeClass("btn-like");
        $("#btnlike").addClass("btn-unlike");
        //$("#fly-heart").addClass("co-dis");
        
      }else if(res.data.data.action === "like"){
        
        this.countlike = res.data.rs.like;
        this.statuslike = "unlike";
        
        $("#like").html(this.countlike);
        $("#btnlike").addClass("btn-like");
        $("#btnlike").removeClass("btn-unlike");
        //$("#fly-heart").removeClass("co-dis");
      }
    });
       
  }

  public conFirm() {
    $("#ConfirmModal").modal("show");
  }

  public onSubmitconfirm() {

    const userData = {
      _id: sessionStorage.getItem("_id"),
      email: sessionStorage.getItem("email"),
      position: sessionStorage.getItem("position"),
      employeeId: localStorage.getItem("EmployeeId"),
      displayName: sessionStorage.getItem("displayName"),
      status: "สมาชิกโครงการ",
    }

    this.projectSer.getUpdateStatusJoinTeam(this.itemCard, userData).then(res => {
      if (res.status == 200) {
        $("#ConfirmModal").modal("hide");
        $("#CompleteModal").modal("show");
      }

    });
  }

  public onSubmitComplete() {

    $("#CompleteModal").modal("hide");
    $('.btn-msg').prop( "disabled", true );
    window.location.reload(true);

  }


  public Cancel() {
    $('#CancelModal').modal("show");
  }

  public onSubmitCancel() {

    const userData = {
      _id: sessionStorage.getItem("_id"),
      email: sessionStorage.getItem("email"),
      position: sessionStorage.getItem("position"),
      employeeId: localStorage.getItem("EmployeeId"),
      displayName: sessionStorage.getItem("displayName"),
      status: "ปฏิเสธคำเชิญ",
    }

    this.projectSer.getUpdateStatusJoinTeam(this.itemCard, userData).then(res => {
      if (res.status == 200) {
        $('#CancelModal').modal("hide");
        $('#CompleteModal').modal("show");
      }

    });
  }

  public Join(){
    $('#joinModal').modal("show");
  }

  public onJoinComplete(){
    
    const userData = {
      _id: sessionStorage.getItem("_id"),
      email: sessionStorage.getItem("email"),
      position: sessionStorage.getItem("position"),
      employeeId: localStorage.getItem("EmployeeId"),
      displayName: sessionStorage.getItem("displayName"),
      status: "สนใจเข้าร่วมโครงการ",
    }
    
    this.projectSer.getInvitJoin(this.itemCard, userData).then(res => {
      if (res.status == 200) {
        $('#joinModal').modal("hide");
        $('#sendJoinComplete').modal("show");
        
      }

    });
  }


  public onJoinSuccess(){
    location.reload();
  }

  public onTop(){
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
  }

  public tabscroll(){
    window.scroll({
      top: 400, 
      left: 0, 
      behavior: 'smooth'
    });
  }

  public likeAction(action){
    //alert(status);
    var str = document.getElementById("like").innerHTML; 
    const id = this.route.snapshot.paramMap.get("id");
    let userid = sessionStorage.getItem("_id");

    this.projectSer.getUpdateLikeStatus(id,userid,action).then(res => {
      //

      this.projectSer.getLikeStatus(id,userid).then(res => {
        // 
        // 
        let LikeActive = res.data.rs.likeActive;
        if(res.data.data.action === "unlike"){
          
          this.countlike = res.data.rs.like;
          $('#count-like').html(this.countlike);
          this.statuslike = "like";
  
          $("#like").html("ถูกใจ");
          $("#btnlike").removeClass("btn-like");
          $("#btnlike").addClass("btn-unlike");
          $("#fly-heart").addClass("co-dis");

        }else if(res.data.data.action === "like"){
          
          this.countlike = res.data.rs.like;
          $('#count-like').html(this.countlike);
          this.statuslike = "unlike";
          
          $("#like").html(this.countlike);
          $("#btnlike").addClass("btn-like");
          $("#btnlike").removeClass("btn-unlike");
          $("#fly-heart").removeClass("co-dis");

            this.projectSer.getIdeaById(id).then(res => {
              //
              let itemCard = res.data.data[0];
              let senderid = sessionStorage.getItem("_id");
              let displayName = sessionStorage.getItem("displayName");
              let employeeId = localStorage.getItem("EmployeeId");
              let teamMember = res.data.data[0].teamData.member;


              const userDataNoti = {
                projectId: itemCard._id,
                projectName: itemCard.projectName,
                empIdowner: itemCard.owner.employeeId,
                sender: {
                  id: senderid,
                  displayName: displayName,
                  employeeId: employeeId
                },
                teamMember,
                likeActive : LikeActive
              };

              //

              this.projectSer.getNotiLike(userDataNoti).then(res => {
                //
              });
              
          });
          
        }
      });
    });
      
  }

  public media(x) {
    var content = document.getElementById("content");
    var navbarmb = document.getElementById("navbarnoti-mb");
    var sticky = navbarmb.offsetTop;

    if (x.matches) { // If media query matches
      if (window.pageYOffset >= sticky) {
        content.classList.add("co-dis");
        //alert();
      }else{
        //$('#content').removeClass("co-dis");
        content.classList.remove("co-dis");
      }
      
    } else {
      $('#content').addClass("co-dis");
    }
  }

  public DeleteComplete(){
    var urlDetail = "";
    if (window.location.hostname === "localhost") {
      urlDetail = "http://localhost:4200/";
    } else if (window.location.hostname === "inning.dld-test.com") {
      urlDetail = "https://" + window.location.host;
    } else {
      urlDetail = "https://" + window.location.host;
    }
    window.location.href = urlDetail;
  }


  // public copy(element_id){

  //   var aux = document.createElement("div");
  //   aux.setAttribute("contentEditable", "true");
  //   aux.innerHTML = document.getElementById(element_id).innerHTML;
  //   aux.setAttribute("onfocus", "document.execCommand('selectAll',false,null)"); 
  //   document.body.appendChild(aux);
  //   aux.focus();
  //   // window.scroll(0, 0)
  //   document.execCommand("copy");
  //   // document.body.removeChild(aux);
    
    
  //   // const id = this.route.snapshot.paramMap.get("id");

  // }

  copyText(val: string){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    // alert("ddf");
    $('#copyPanel').modal("show");
    setTimeout(() => {

      $('#copyPanel').modal("hide");
    }, 1000);
    
  }

  public getURL(){
    const id = this.route.snapshot.paramMap.get("id");

    var urlDetail = "";
    if (window.location.hostname === "localhost") {
      urlDetail = "http://localhost:4200/login?pid="+id;
    } else if (window.location.hostname === "inning.dld-test.com") {
      urlDetail = "https://" + window.location.host+"/login?pid="+id;
    } else {
      urlDetail = "https://" + window.location.host+"/login?pid="+id;
    }
    this.URLID = urlDetail;
  }
}
