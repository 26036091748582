import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from "@angular/router";
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";
import { UserServiceService } from "../../../../Services/user-service.service";

import { environment } from "../../../../../environments/environment";

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-detail-incubate-component',
  templateUrl: './detail-incubate-component.component.html',
  styleUrls: ['./detail-incubate-component.component.scss']
})
export class DetailIncubateComponentComponent implements OnInit {
  urlHostImage = environment.URLUPLOAD;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
    private userService: UserServiceService

  ) { }

  itemCard: any;
  imagePath: any;
  owner: any;
  member: any;
  projectName: any;
  dataUser = [];
  tabsDetail:any;
  itemCategory: any = "";
  ngOnInit() {
    this.getInit()
  }

  async getInit() {
    sessionStorage.setItem("barActive", "incubator");
    sessionStorage.setItem("incubatestatus","incubate");
    this.imagePath = "";
    const id = this.route.snapshot.paramMap.get("id");
    let tempData;
    tempData = await this.projectSer.getIdeaById(id);
    this.itemCard = tempData.data.data[0];
    

    let arrCategory = this.itemCard.projectCategory;
    
    for (let index = 0; index < arrCategory.length; index++) {
      
      // 
      // const element = arrCategory[index];
        this.itemCategory += arrCategory[index].category+", ";
    }
    if(this.itemCategory.includes(",")){
      this.itemCategory = this.itemCategory.substr(0, this.itemCategory.length - 2)
    }

    

    let empid = localStorage.getItem("EmployeeId");
    let userid = sessionStorage.getItem("_id");
    let arr = [];
    

    if(this.itemCard.owner.employeeId === empid){
      this.owner = "y";
    }else{
      if(this.itemCard.status == 'active'){
        this.owner = "n";
      }else{
        this.owner = "y";
      }
    }

    for(let index = 0; index < this.itemCard.teamData.member.length; index++){
      if(this.itemCard.teamData.member[index].employeeId === empid && (this.itemCard.teamData.member[index].status === "สมาชิกโครงการ") ){
        arr[index] = this.itemCard.teamData.member[index];
      }
    }

    if(arr.length != 0){
      this.member = "y";
    }else{
      if(this.itemCard.status == 'active'){
        this.member = "n";
      }else{
        this.member = "y";
      }
    }
    
    
    if(this.itemCard.incubator  != undefined){
      if(this.itemCard.incubator.length){
        for(let index=0; index < this.itemCard.incubator.length; index++){

          let user = await this.userService.getUserById(this.itemCard.incubator[index]._id);
          this.dataUser.push({
            user : user.data.data
          });

          if(this.itemCard.incubator[index]._id === userid){
            var y = this.itemCard.incubator[index]._id;
          }
          
          if(this.itemCard.incubator[index]._id != userid){
            var n = this.itemCard.incubator[index]._id;
          }
        }
      }

      if(!this.itemCard.incubator.length){
        let user = await this.userService.getUserById(this.itemCard.incubator._id);
        this.dataUser.push({
          user : user.data.data
        });

        if(this.itemCard.incubator._id === userid){
          var y = this.itemCard.incubator._id;
        }
        
        if(this.itemCard.incubator._id != userid){
          var n = this.itemCard.incubator._id;
        }
      }
        

      
      


      if(y === userid){
        // alert("1");
        if(this.itemCard.status == "active"){
          sessionStorage.setItem("barActive", "myincubator");
          $('#viewer').addClass('co-dis');
          $('#owner').removeClass('co-dis');
        }else{
          sessionStorage.setItem("barActive", "myincubator");
          $('#viewer').removeClass('co-dis');
          $('#owner').addClass('co-dis');
        }
      }else{
        // alert("1.5");
        $('#viewer').removeClass('co-dis');
        $('#owner').addClass('co-dis');
        sessionStorage.setItem("barActive", "incubator");
      }
      
    }else{
      // alert("2");
      sessionStorage.setItem("barActive", "incubator");
      $('#viewer').removeClass('co-dis');
      $('#owner').addClass('co-dis');
    }

    


    let detailTabs = sessionStorage.getItem("TabsDetail");
   

    if (detailTabs === "updatestatus") {
      this.tabsDetail = "status";
    }
      
  }

  public acceptIncubator(){
   // alert(proid);

    var proid = $('#pro_id').val();
    let userid = sessionStorage.getItem("_id");
    let EmployeeId = localStorage.getItem("EmployeeId");
    let displayName = sessionStorage.getItem("displayName");

    //alert(proid+userid+EmployeeId+displayName);

    this.projectSer.getAcceptIncubator(proid,userid,EmployeeId,displayName).then(res => {

      

      // window.location.reload();
      $('#AcceptModal').modal('hide');
      $('#successAccept').modal('show');
      
      
    });
  }

  public accepptModal(id,projectname){
    $('#AcceptModal').modal('show');
    this.projectName = projectname;
    $('#pro_id').val(id);

  }

  
  public onSubmitComplete(){
    $('#CompleteModal').modal('hide');
    window.location.reload();
  }


  public countRowsInText(text) {

    let numerOfRowsInText = text != null ? text.split(/\n/).length : 2;
    let rows = numerOfRowsInText < 2 ? 2 : numerOfRowsInText;
    //
    return rows;
  }

  

  



  

}
