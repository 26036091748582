import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { Http } from "@angular/http";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import axios from "axios";

import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class CommentServiceService {

  constructor(private _http: Http, private http: HttpClient) { }

  public async getCommentByProjectId(projectid: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        projectId: projectid,

      };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      

      let data = await setting.post(environment.URL_GET_COMMENT_BY_PROJECTID, dataUser);

      return data;
    } catch (err) {
      return err.response;
    }
  }

  public async getCommentByIncubatorProjectId(projectid: string) {
    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let dataUser = {
        projectId: projectid,

      };


      // let token = sessionStorage.getItem("authen")
      let token = sessionStorage.getItem("authen");
      

      let data = await setting.post(environment.URL_GET_COMMENT_INCUBATOR_BY_PROJECTID, dataUser);

      return data;
    } catch (err) {
      return err.response;
    }
  }

  public async createComment(dataCreateComment, selectMentionCommentValue) {

    // let commentModel = {
    //   projectId: dataCreateIdea.projectId,
    //   description: dataCreateIdea.description,
    //   attachFile : {
    //     file: dataCreateIdea.attachFile.file,
    //     name: dataCreateIdea.attachFile.name,
    //     fileSize: dataCreateIdea.attachFile.fileSize,
    //   },
    //   attachPic: dataCreateIdea.attachPic,

    //   attachLink: dataCreateIdea.attachLink,
    //   createBy: {
    //     _id: dataCreateIdea.createBy._id,
    //     displayName: dataCreateIdea.createBy.displayName,
    //   },
    // };
    


    let formData = new FormData();
    formData.append("projectId", dataCreateComment.projectId);
    formData.append("description", dataCreateComment.description);
    formData.append("attachFile", dataCreateComment.attachFile);
    formData.append("attachFileSize", dataCreateComment.attachFileSize);
    formData.append("attachPic", dataCreateComment.attachPic);
    formData.append("createById", dataCreateComment.createBy.Id);
    formData.append("createByDisplayName", dataCreateComment.createBy.displayName);
    formData.append("category", dataCreateComment.category); 
    for (var i = 0; i < dataCreateComment.attachLink.length; i++) {
      formData.append("attachLink", dataCreateComment.attachLink[i]);
    }
    for (var i = 0; i < selectMentionCommentValue.length; i++) {
      formData.append("mentionDisplayName", selectMentionCommentValue[i].displayName);
      formData.append("mentionName", selectMentionCommentValue[i].name);
      formData.append("mentionEmployeeId", selectMentionCommentValue[i].employeeId);
      formData.append("mentionEmail", selectMentionCommentValue[i].email);
      
    }
     

    // let token = sessionStorage.getItem("authen")
    let token = sessionStorage.getItem("authen");

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let data = await setting.post(environment.URL_COMMENT_CREATE, formData, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }

  public async createCommentIncubator(dataCreateComment, selectMentionCommentValue) {

    let formData = new FormData();
    formData.append("projectId", dataCreateComment.projectId);
    formData.append("description", dataCreateComment.description);
    formData.append("attachFile", dataCreateComment.attachFile);
    formData.append("attachFileSize", dataCreateComment.attachFileSize);
    formData.append("attachPic", dataCreateComment.attachPic);
    formData.append("createById", dataCreateComment.createBy.Id);
    formData.append("createByDisplayName", dataCreateComment.createBy.displayName);
    formData.append("category", dataCreateComment.category); 
    for (var i = 0; i < dataCreateComment.attachLink.length; i++) {
      formData.append("attachLink", dataCreateComment.attachLink[i]);
    }
    for (var i = 0; i < selectMentionCommentValue.length; i++) {
      formData.append("mentionDisplayName", selectMentionCommentValue[i].displayName);
      formData.append("mentionName", selectMentionCommentValue[i].name);
      formData.append("mentionEmployeeId", selectMentionCommentValue[i].employeeId);
      formData.append("mentionEmail", selectMentionCommentValue[i].email);
    }
     
    let token = sessionStorage.getItem("authen");
    let statusIncu = sessionStorage.getItem("incubatestatus");

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      if(statusIncu === "member"){

        //alert("1");

        let data = await setting.post(environment.URL_COMMENT_CREATE_INCUBATOR_FOR_USER, formData, {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
          
        });
        return data;

      }else if(statusIncu === "owner"){

        //alert("2");

        let data = await setting.post(environment.URL_COMMENT_CREATE_INCUBATOR_FOR_USER, formData, {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
          
        });
        return data;
        
      }else{
        let data = await setting.post(environment.URL_COMMENT_CREATE_INCUBATOR, formData, {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
          
        });
        return data;
      } 

      
    } catch (err) {
      return err.response;
    }
  }

  public async createReply(dataCreateReply , selectMentionReplyValue) {

    


    let formData = new FormData();
    formData.append("projectId", dataCreateReply.projectId);
    formData.append("projectCommentId", dataCreateReply.projectCommentId);
    formData.append("description", dataCreateReply.description);
    formData.append("attachFile", dataCreateReply.attachFile);
    formData.append("attachFileSize", dataCreateReply.attachFileSize);
    formData.append("attachPic", dataCreateReply.attachPic);
    formData.append("replyToId", dataCreateReply.replyTo._id);
    formData.append("replyToName", dataCreateReply.replyTo.displayName);
    formData.append("createById", dataCreateReply.createBy.Id);
    formData.append("createByDisplayName", dataCreateReply.createBy.displayName);
    formData.append("category", dataCreateReply.category); 
    for (var i = 0; i < dataCreateReply.attachLink.length; i++) {
      formData.append("attachLink", dataCreateReply.attachLink[i]);
    }
    for (var i = 0; i < selectMentionReplyValue.length; i++) {
      formData.append("mentionDisplayName", selectMentionReplyValue[i].displayName);
      formData.append("mentionName", selectMentionReplyValue[i].name);
      formData.append("mentionEmployeeId", selectMentionReplyValue[i].employeeId);
      formData.append("mentionEmail", selectMentionReplyValue[i].email);
    }
    // 

    // let token = sessionStorage.getItem("authen")
    let token = sessionStorage.getItem("authen");

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let data = await setting.post(environment.URL_REPLY_CREATE, formData, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }

  public async createReplyIncubator(dataCreateReply , selectMentionReplyValue) {

    


    let formData = new FormData();
    formData.append("projectId", dataCreateReply.projectId);
    formData.append("projectCommentId", dataCreateReply.projectCommentId);
    formData.append("description", dataCreateReply.description);
    formData.append("attachFile", dataCreateReply.attachFile);
    formData.append("attachFileSize", dataCreateReply.attachFileSize);
    formData.append("attachPic", dataCreateReply.attachPic);
    formData.append("replyToId", dataCreateReply.replyTo._id);
    formData.append("replyToName", dataCreateReply.replyTo.displayName);
    formData.append("createById", dataCreateReply.createBy.Id);
    formData.append("createByDisplayName", dataCreateReply.createBy.displayName);
    formData.append("category", dataCreateReply.category); 
    for (var i = 0; i < dataCreateReply.attachLink.length; i++) {
      formData.append("attachLink", dataCreateReply.attachLink[i]);
    }
    for (var i = 0; i < selectMentionReplyValue.length; i++) {
      formData.append("mentionDisplayName", selectMentionReplyValue[i].displayName);
      formData.append("mentionName", selectMentionReplyValue[i].name);
      formData.append("mentionEmployeeId", selectMentionReplyValue[i].employeeId);
      formData.append("mentionEmail", selectMentionReplyValue[i].email);
    }
    // 

    // let token = sessionStorage.getItem("authen")
    let token = sessionStorage.getItem("authen");
    let statusIncu = sessionStorage.getItem("incubatestatus");

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      if(statusIncu === "owner"){
        let data = await setting.post(environment.URL_REPLY_CREATE_INCUBATOR_FOR_USER, formData, {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
        });
        return data;
      }else if(statusIncu === "member"){
        let data = await setting.post(environment.URL_REPLY_CREATE_INCUBATOR_FOR_USER, formData, {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
        });
        return data;
      }else{
        let data = await setting.post(environment.URL_REPLY_CREATE_INCUBATOR, formData, {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
        });
        return data;
      }
        

        

    } catch (err) {
      return err.response;
    }
  }

  public async editComment(dataEditComment,selectMentionCommentEditValue) {
    


    let formData = new FormData();
    formData.append("commentId", dataEditComment.commentId);
    formData.append("projectId", dataEditComment.projectId);
    formData.append("description", dataEditComment.description);
    formData.append("attachFile", dataEditComment.attachFile);
    formData.append("attachFileSize", dataEditComment.attachFileSize);
    formData.append("attachPic", dataEditComment.attachPic);
    formData.append("createById", dataEditComment.createBy.Id);
    formData.append("createByDisplayName", dataEditComment.createBy.displayName);
    for (var i = 0; i < dataEditComment.attachLink.length; i++) {
      formData.append("attachLink", dataEditComment.attachLink[i]);
    }
    for (var i = 0; i < selectMentionCommentEditValue.length; i++) {
      formData.append("mentionDisplayName", selectMentionCommentEditValue[i].displayName);
      formData.append("mentionName", selectMentionCommentEditValue[i].name);
      formData.append("mentionEmployeeId", selectMentionCommentEditValue[i].employeeId);
      formData.append("mentionEmail", selectMentionCommentEditValue[i].email);
    }
    // let token = sessionStorage.getItem("authen")
    let token = sessionStorage.getItem("authen");

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let data = await setting.post(environment.URL_EDIT_COMMENT, formData, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }
  public async editReply(dataEditReply) {
    let formData = new FormData();
    formData.append("replyId", dataEditReply.replyId);
    formData.append("projectId", dataEditReply.projectId);
    formData.append("projectCommentId", dataEditReply.projectCommentId);
    formData.append("description", dataEditReply.description);
    formData.append("attachFile", dataEditReply.attachFile);
    formData.append("attachFileSize", dataEditReply.attachFileSize);
    formData.append("attachPic", dataEditReply.attachPic);
    for (var i = 0; i < dataEditReply.attachLink.length; i++) {
      formData.append("attachLink", dataEditReply.attachLink[i]);
    }
    
    // let token = sessionStorage.getItem("authen")
    let token = sessionStorage.getItem("authen");

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let data = await setting.post(environment.URL_EDIT_REPLY, formData, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }
  public async deleteComment(id) {
    let dataModel = {
      commentId: id
    }
    // let token = sessionStorage.getItem("authen")
    let token = sessionStorage.getItem("authen");

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let data = await setting.post(environment.URL_DELETE_COMMENT, dataModel, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }

  public async deleteReply(id) {
    let dataModel = {
      replyId: id
    }
    // let token = sessionStorage.getItem("authen")
    let token = sessionStorage.getItem("authen");

    try {
      let setting = axios.create({
        baseURL: environment.URL
      });

      let data = await setting.post(environment.URL_DELETE_REPLY, dataModel, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" }
      });

      return data;
    } catch (err) {
      return err.response;
    }
  }

  downloadfile(file: string) {
    var body = {
      filename: file
    };
    
    let setting = axios.create({
      baseURL: environment.URL
    });
    return this.http.post(environment.URL + "project/downloadfile", body, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
  }

}