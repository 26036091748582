import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";


// Import Service
import { ProjectideaServiceService } from "../../../../Services/projectidea-service.service";


@Component({
  selector: 'app-tab-history',
  templateUrl: './tab-history.component.html',
  styleUrls: ['./tab-history.component.scss']
})
export class TabHistoryComponent implements OnInit {

  @Input() typeNameInput: any;
  @Input() regionNameInput: any;
  cate: string;
  region: string;
  itemhistory: any;
  length: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private projectSer: ProjectideaServiceService,
    private _location: Location,
  ) { }

  ngOnInit() {
    this.getInit();
  }

  async getInit(){

    const projectid = this.route.snapshot.paramMap.get("id");

    this.projectSer.getProjectHistory(projectid).then(res => {

        

        

        if(res.data.data === "ไม่พบข้อมูล"){
          this.length = 0;
        }else{
          this.itemhistory = res.data.data;
          
          
          this.length = res.data.data.length;
        }

    });
    
    this.cate = this.typeNameInput
    this.region = this.regionNameInput

  }

}
