import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listjoinidea',
  templateUrl: './listjoinidea.component.html',
  styleUrls: ['./listjoinidea.component.scss']
})
export class ListjoinideaComponent implements OnInit {

  item;
  constructor() { }

  ngOnInit() {
    window.scroll(0,0)
    this.item = [
      { 
        region : 'ROS',
        name: 'Name Project',
        team: 'wanpenet',
        date: '10/10/2018',
        status: 'Wait to update'
      },
      { 
        region : 'ROS',
        name: 'Name Project',
        team: 'wanpenet',
        date: '10/10/2018',
        status: 'Wait to update'
      },
      { 
        region : 'ROS',
        name: 'Name Project',
        team: 'wanpenet',
        date: '10/10/2018',
        status: 'Wait to update'
      },
      { 
        region : 'ROS',
        name: 'Name Project',
        team: 'wanpenet',
        date: '10/10/2018',
        status: 'Wait to update'
      },
      { 
        region : 'ROS',
        name: 'Name Project',
        team: 'wanpenet',
        date: '10/10/2018',
        status: 'Wait to update'
      },
     
    ]
  }

}
